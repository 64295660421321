import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

const StyledNavMainItem = styled.li`
  display: inline-block;
  /* margin: 0 1rem 0 0; */
  padding: 0;
  vertical-align: top;
  line-height: 0;

  &:last-of-type {
    margin: 0;
  }

  a {
    display: block;
    padding: 1rem;
    border-top: solid 5px #fff;
    border-bottom: none;
    color: ${theme.colors.navLinkColor};
    font-size: 0.875em;
    line-height: 1.5;
    font-weight: ${theme.fontWeight.medium};
    text-align: center;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-top: solid 5px ${theme.colors.linkHover};
      border-bottom: none;
    }

    ${breakpoint.greaterThan('md')`
  `}
    ${breakpoint.greaterThan('lg')`
      padding: 1rem 1rem;
      font-size: 1em;
      `}
    ${breakpoint.greaterThan('xl')`
      padding: 1rem 1.875rem;
    `}

  }

  &.nav-main__item--active a {
    border-top: solid 5px ${theme.colors.linkActive};
  }
`;

export interface NavMainItemProps {
  active?: boolean;
  href: string;
  target?: '_self' | '_blank';
  title: string;
}

function NavMainItem({
  active,
  href,
  target = '_self',
  title,
}: NavMainItemProps): ReactElement {
  return (
    <StyledNavMainItem
      className={
        active ? 'nav-main__item nav-main__item--active' : 'nav-main__item'
      }
    >
      <a href={href} target={target}>
        {title}
      </a>
    </StyledNavMainItem>
  );
}

export default NavMainItem;
