import React, { ReactElement } from 'react';
import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';

const StyledMainRoadsList = styled.div`
  h2 {
    margin: 0 0 1.5rem 0;
    padding: 0;
    font-size: 1.25rem;
    font-weight: ${theme.fontWeight.black};

    ${breakpoint.greaterThan('sm')`
      font-size: 1.5rem;
    `}
  }

  ul {
    padding-bottom: 1.5rem;
  }

  li {
    margin: 0 0 0.5rem 0;

    &:last-of-type {
      margin: 0;
    }
  }

  li a {
    color: ${theme.colors.linkColor};
    font-size: 1rem;

    ${breakpoint.greaterThan('sm')`
      font-size: 1.125rem;
    `}
  }

  .main-roads__footer {
    font-size: 1rem;
    color: ${theme.colors.textDark};
    letter-spacing: 0px;

    ${breakpoint.greaterThan('sm')`
      font-size: 1.125rem;
    `}

    a {
      color: ${theme.colors.linkActive};
      font-size: 1rem;
      &:hover {
        text-decoration: underline;
      }

      ${breakpoint.greaterThan('sm')`
        font-size: 1.125rem;
      `}
    }
  }
`;

export interface MainRoadsListProps {
  title?: EpiProps;
  roads?: string[];
  footerContent?: EpiProps;
}

function MainRoadsList({
  title,
  roads,
  footerContent,
}: MainRoadsListProps): ReactElement {
  return (
    <StyledMainRoadsList>
      {title && (
        <EpiProperty
          name={title.name}
          value={title.value}
          isEditMode={title.isEditMode}
          outerTag="h2"
        />
      )}
      {roads && roads.length > 0 && (
        <ul>
          {roads?.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
      <div className="main-roads__footer">
        {footerContent && (
          <EpiProperty
            name={footerContent.name}
            value={footerContent.value}
            isEditMode={footerContent.isEditMode}
            isHTMLContent={footerContent.isHTMLContent}
            outerTag="span"
          />
        )}
      </div>
    </StyledMainRoadsList>
  );
}

export default MainRoadsList;
