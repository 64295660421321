import styled from 'styled-components';
import theme from '../../../lib/theme';

export const StyledLink = styled.a`
  color: inherit;
  font-size: 1.125em;
  font-weight: ${theme.fontWeight.black};
  color: ${theme.colors.textDark};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
  && {
    svg {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 0.5em;
    }
  }
`;
