import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

import { FactTileStyles } from './FactTileStyles';

export interface IFactTile {
  title: string;
  smallTitle?: boolean;
  icon?: IconName;
  caption: string;
  theme?: 'red' | 'blue' | 'green' | 'black';
}

const FactTile = ({
  title,
  smallTitle,
  icon,
  caption,
  theme = 'red',
}: IFactTile): ReactElement => {
  return (
    <FactTileStyles
      className={`theme-${theme} ${smallTitle ? 'small-text' : ''}`}
    >
      <div className="title">
        {icon && <FontAwesomeIcon icon={icon} />}
        <span>{title}</span>
      </div>
      <div className="caption">
        <p>{caption}</p>
      </div>
    </FactTileStyles>
  );
};

export default FactTile;
