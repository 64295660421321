import styled from 'styled-components';
import globalTheme from '../../../lib/theme';

export const DownloadTileStyles = styled.a`
  display: block;
  padding: 1.5em 0.5em;
  background-color: #d14210;
  color: #ffffff;
  width: 100%;
  /*max-width: 330px;*/
  min-height: 200px;
  border-radius: 15px;
  background: #d14210;
  box-shadow: ${globalTheme.elevation[1]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #d14210;
  transition: box-shadow 100ms ease-out, transform 100ms ease-out;
  &:hover {
    color: #ffffff;
    box-shadow: ${globalTheme.elevation[2]};
    transform: translate(0, -2px);
  }
  &:active {
    box-shadow: ${globalTheme.elevation[0]};
    transform: translate(0, 1px);
  }
  &.theme-red {
    background: #d14210;
  }
  &.theme-blue {
    background: #054e66;
  }
  &.theme-green {
    background: #008072;
  }
  &.theme-light {
    color: #000000;
    background: #f5f5f5;
    &:hover {
      color: #000000;
    }
  }
  &.theme-black {
    background: #2d2f32;
  }
  svg {
    font-size: 3.75em;
    width: 1em;
    margin-bottom: 16px;
  }
  span {
    text-align: center;
    line-height: 1.125;
    max-width: 250px;
    /* width: 100%; */
  }
  .label {
    font-size: 1em;
    font-weight: ${globalTheme.fontWeight.regular};
  }
  .meta {
    margin-top: 0.5em;
    font-size: 0.875em;
    font-weight: ${globalTheme.fontWeight.light};
  }
`;
