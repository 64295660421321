import moment from 'moment';
import React, { ReactElement } from 'react';
import { BaseBlockProps } from '../../../types'
import {
  DateTimeStyled,
  ExcerptStyled,
  ImageStyled,
  StyledNewsBlock,
  TagStyled,
  TitleStyled,
} from './styles';

export interface ContentArticleBlockProps extends BaseBlockProps {
  link: string;
  title: string;
  related?: boolean;
  excerpt: string;
  thumbnail: string;
  lastModified?: any; // preferable a Date object
  tags?: {
    href?: string;
    title: string;
  }[];
};

type DateTimeElProps = {
  date: string;
  dateVerbose: string;
};
const DateTimeEl = ({ date, dateVerbose }: DateTimeElProps): ReactElement => {
  return (
    <DateTimeStyled>
      <time dateTime={dateVerbose} title={dateVerbose}>
        {date}
      </time>
    </DateTimeStyled>
  );
};

const ContentArticleBlock = ({
  link,
  title,
  related = false,
  excerpt,
  thumbnail,
  lastModified,
  tags,
  anchorId
}: ContentArticleBlockProps): ReactElement => {
  const date = lastModified && moment(lastModified).format('D MMMM YYYY');
  const dateVerbose =
    lastModified && moment(lastModified).format('MMMM Do YYYY, h:mm:ss a');

  return (
    <StyledNewsBlock id={anchorId} data-comp="ContentArticleBlock">
      <ImageStyled href={link}>
        <img src={thumbnail} alt={title} />
      </ImageStyled>
      <TitleStyled href={link} title={title}>
        <h2>{title}</h2>
      </TitleStyled>
      {date && <DateTimeEl date={date} dateVerbose={dateVerbose} />}
      <ExcerptStyled>{excerpt}</ExcerptStyled>
      <TagStyled related={related}>
        {tags && tags?.length > 0 && (
          <ul>
            {tags.map((tag, i) => (
              <li key={i}>
                {tag.href && <a href={tag.href}>{tag.title}</a>}
                {!tag.href && <span>{tag.title}</span>}
              </li>
            ))}
          </ul>
        )}
      </TagStyled>
    </StyledNewsBlock>
  );
};

export default ContentArticleBlock;
