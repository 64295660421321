import styled from 'styled-components';

import { StyledMainWithSideBar } from '../../../lib/StyledMainWithSideBar';
import theme, { breakpoint } from '../../../lib/theme';
import Button from '../../atoms/Button';
import SearchFormWidget from '../../atoms/SearchFormWidget';
import SelectField from '../../atoms/SelectField';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';

type StyledPageLayoutProp = {
  projectInfoPanelOpen: boolean;
  pageTheme?: string;
};

export const StyledPageLayout = styled.div<StyledPageLayoutProp>`
  ${StyledMainWithSideBar({ flow: 'aside-main', element: 'main' })};
  .page-layout__main {
    padding: 0;
    article {
      margin: 0 0 ${theme.spacing.sm} 0;
    }
    button {
      background-color: ${(props): string => props.pageTheme};
      color: #ffffff;
    }
  }
  .section-heading-wrap-solid {
    position: relative;
    z-index: 1;
    background-color: ${(props): string => props.pageTheme};
    padding: 20px 0;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: -${theme.spacing.lg};
      height: 100%;
      width: calc(100vw + ${theme.spacing.lg});
      background-color: ${(props): string => props.pageTheme};
    }

    h1 {
      margin-bottom: 0;
      color: white;
      font-size: 1.5rem;
      font-weight: ${theme.fontWeight.bold};
    }
    p {
      margin: 0;
      color: white;
    }
  }

  .section-heading-wrap {
    h1 {
      margin-bottom: 0;
    }
    p {
      margin: 0;
    }
    margin-bottom: ${theme.spacing.md};
  }

  h2 {
    font-size: 1.5rem;
    font-weight: ${theme.fontWeight.black};
    margin-bottom: ${theme.spacing.sm};
  }

  .page-layout__aside {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 1;
    &:after {
      content: ${(props): string =>
        props.projectInfoPanelOpen ? '""' : 'none'};
      position: absolute;
      z-index: -1;
      top: 0;
      right: -${theme.spacing.lg};
      width: 100vw;
      height: 100%;
      background-color: ${theme.colors.backgroundLight};
    }
    &__footer {
      margin-top: auto;
      padding-top: ${theme.spacing.sm};
    }
  }
`;

type FullWidthLinkProp = {
  bgColor: string;
};

export const FullWidthLink = styled.div<FullWidthLinkProp>`
  position: relative;
  z-index: 1;
  padding: 20px 0;
  color: white;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: -${theme.spacing.lg};
    height: 100%;
    width: calc(100vw + ${theme.spacing.lg});
    background-color: ${(props): string => props.bgColor};
  }
  a {
    color: white;
    font-size: 1.2rem;
    font-weight: ${theme.fontWeight.bold};
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
  }
`;

export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.sm} 0;
  ${breakpoint.greaterThan('sm')`
    margin: ${theme.spacing.md} 0 ${theme.spacing.md} 0;
  `};

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.lg} 0 ${theme.spacing.md} 0;
  `}
`;

export const StyledButton = styled(Button)`
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 20px;
  background-color: ${(props): string =>
    props.isActual ? theme.colors.buttonToggle : theme.colors.primaryAlt};
  &:hover {
    background-color: ${theme.colors.buttonBg};
  }
`;

export const SearchFormColors = {
  inputBg: theme.colors.textLight,
  placeholderTextColor: theme.colors.textDark,
  activeInputBg: theme.colors.textLight,
  activePlaceholderTextColor: theme.colors.textDark,
};

export const StyledSearchFormWidget = styled(SearchFormWidget)`
  margin-bottom: 40px;
`;

export const StyledTypeFilterList = styled.ul`
  list-style: none;
  margin: 0 -10px 30px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 1 50%;
    padding-right: 10px;
    margin: 0 0 1em 0;
    @media screen and (max-width: 600px) {
      flex: 1 1 50%;
    }
  }
  button {
    width: 100%;
  }
`;

export const StyledClearingProjectsList = styled.ul`
  margin: ${theme.spacing.lg} 00;
  padding: 0;
  list-style: none;
`;

export const StyledClearButton = styled(Button)`
  width: 100%;
  margin: 0 0 ${theme.spacing.md} 0;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const StyledSelectField = styled(SelectField)`
  label {
    margin-top: ${theme.spacing.md};
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  max-width: ${theme.breakpoints.container};
  margin: 0 auto;
  padding: 0 0 0 1.5rem;
  ${breakpoint.lessThan('md')`
    padding: 0;
  `};
`;
