import styled from 'styled-components';
import theme from '../../../lib/theme';

export const ListBlockStyles = styled.section`
  border-radius: 15px;
  padding: 2em;
  &.do-show-background {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background: #f5f5f5;
  }
  h2 {
    font-size: 1.25em;
    margin: 0;
    font-weight: ${theme.fontWeight.black};
  }
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  ul {
    padding-top: 2em;
    padding-bottom: 2em;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.875em;
  }
  .read-more {
    font-size: 1.25em;
    margin: 0 0 0 0.7em;
    font-weight: ${theme.fontWeight.black};
    color: #000;
  }
`;
