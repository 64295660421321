import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledRelatedContentBlock = styled.div`
  color: ${theme.colors.textDark};

  h2 {
    margin: 0 0 1.5rem 0;
    padding: 0;
    font-size: 1.25rem;
    font-weight: ${theme.fontWeight.black};
  }

  svg {
    font-size: 0.95rem;
    margin: 0 0 0.15rem 1rem;
    ${breakpoint.greaterThan('sm')`
      font-size: 1.25rem;
    `}
  }

  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    ${breakpoint.greaterThan('sm')`
      flex-direction: row;
      flex-wrap: wrap;
    `}
  }
  li {
    margin: 0;
    padding: 0;
    width: 100%;
    ${breakpoint.greaterThan('sm')`
      width: calc(50% - 0.75em);
      max-width: 400px;
    `}
  }
  .layout__right-column & {
    li {
      ${breakpoint.greaterThan('sm')`
        width: calc(50% - 0.75em);
        max-width: 347px;
      `}
    }
  }
`;
