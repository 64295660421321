import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

import { StyledLink } from './styles';

// File download
export interface FileLinkProps {
  href: string;
  filetype: string;
  label: string;
}
const FileLink = ({ href, filetype, label }: FileLinkProps): ReactElement => {
  const iconclass: IconName =
    filetype === 'pdf'
      ? 'file-pdf'
      : filetype === 'doc'
      ? 'file-word'
      : 'file-download';

  return (
    <StyledLink href={href} target="_blank" download>
      <FontAwesomeIcon icon={iconclass} />
      <span>{label}</span>
    </StyledLink>
  );
};
export default FileLink;
