import { createGlobalStyle, css } from 'styled-components';

import EPIForm from './EPIForm';
import FlexboxGrid from './FlexboxGrid';
import Normalize from './Normalize';
import NProgress from './NProgress';
import theme, { breakpoint } from './theme';

const HTMLCONTENT = css`
  .hasHTMLContent {
    h2 {
      font-size: 1.5em;
      ${breakpoint.greaterThan('md')`
        font-size: 1.75em;
      `};
    }
    h3 {
      font-size: 1.125em;
      font-weight: 600;
      ${breakpoint.greaterThan('sm')`
        font-size: 1.35rem;
      `};
      ${breakpoint.greaterThan('md')`
        font-size: 1.35em;
      `};
    }
    h4,
    h5,
    h6 {
      font-size: 1em;
    }
    p {
      a {
        display: inline;
        line-height: 1;
        word-break: break-word;
      }
    }
    a {
      border-bottom: none;
      text-decoration: underline dotted;
    }
    a:hover {
      border-bottom: none;
      text-decoration: underline;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    div.mce-toc {
      ul {
        list-style-type: none;
        ul {
          list-style-type: none;
          ul {
            list-style-type: none;
          }
        }
      }
    }
    ul {
      padding-left: 2em;
      margin-bottom: 1em;
      list-style-type: disc;
      ul {
        list-style-type: circle;
        ul {
          list-style-type: square;
        }
      }
    }
    li {
      line-height: 1.5;
      a {
        line-height: 1;
        display: inline-block;
        word-break: break-word;
      }
    }
    table {
      table-layout: fixed;
      width: 100%;
      height: auto !important;
      border-collapse: collapse;
      background-color: white;
      border: 1px solid #343639;

      @media (max-width: 991px) {
        border-collapse: collapse;
        width: 100%;
        overflow: auto;
        overflow-y: hidden;
        overflow-x: auto;
        display: block;
        box-sizing: border-box;
      }
    }
    thead,
    tr:first-child {
      th {
        background-color: #343639;
        color: white;
      }
      ~ tr {
        &:hover {
          td {
            /* background-color: #d0edee; */
          }
        }
      }
    }
    tr {
      &:nth-child(odd) {
        td {
          /* background-color: #f6f6f6; */
        }
      }
    }
    th,
    td {
      transition: background-color 333ms ease-out;
      text-align: center;
      border: 1px solid black;
      padding: 0.75em 1em;
    }
    iframe {
      max-width: 100%;
    }
    .mce-toc {
      border: 1px solid black;
      padding: 3em 2em;
      ul {
        font-size: 0.875em;
        list-style: none;
        padding-left: 1.5em;

        ${breakpoint.greaterThan('sm')`
          font-size: 1em;
        `};
      }
      > ul {
        > li {
          > a {
            font-weight: 400;
          }
        }
      }
      li {
      }
      a {
        color: black;
        text-decoration: none;
        border-bottom: 1px dotted;
        padding-bottom: 0px;
        margin-bottom: 0.5em;
        display: inline-block;
        font-weight: 300;
        font-size: 1em;
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${NProgress};
  ${FlexboxGrid};
  ${HTMLCONTENT};
  ${EPIForm};
  html,
  body {
    background: #fff;
    color: #000;
    font-family: 'Heebo', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    overflow-x: hidden;
    @media print {
      font-size: 14px;
    }
  }

  html {
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.mobile-navbar--open{
      ${breakpoint.lessThan('md')`
        padding-top: 70px;
      `};
    }
  }

  main {
    outline: transparent;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ::-moz-selection {
    background: #054e66;
    color: #fff;
    text-shadow: none;
  }

  ::selection {
    background: #054e66;
    color: #fff;
    text-shadow: none;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  fieldset {
    border: 0;
  }

  figure {
    margin: 0;
  }
  textarea {
    resize: vertical;
  }

  h1, h2, h3, h4, h5 {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-weight: ${theme.fontWeight.black};
    line-height: 1.125;
    letter-spacing: 0;
  }
  h1{
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: ${theme.fontWeight.bold};
  }

  h5 {
    font-size: 1.25rem;
    font-weight: ${theme.fontWeight.bold};
  }

  a {
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.colors.linkColor};
    text-decoration: none;

    transition: color 50ms ease-in-out;
    &:hover {
      color: ${theme.colors.linkHover};
    }

    a:focus,
    &:active {
      outline: 2px dotted ${theme.colors.linkFocus};
    }
  }

  ul {
    margin: 0 0 ${theme.spacing.sm} 0;
    padding: 0 0 0 2rem;
  }

  li {
    line-height: 1.5;
  }

  div.mce-toc {
    margin: 0 0 ${theme.spacing.sm} 0;
    padding: 3rem 2rem;
    border: 1px solid ${theme.colors.borderDark};

    ul {
      list-style-type: none;
    }

    li {
      margin: 0 0 ${theme.spacing.sm} 0;

      /* font-size: 1.12rem; */
      ul li:first-of-type {
        margin: 1.25rem 0;
      }

      ul li a {
        font-weight: 300;
      }
    }
  }

  table {
    margin: 0 0 ${theme.spacing.sm} 0;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border: 1px solid #343639;
  }

  thead,
  tr:first-child {
    th {
      background-color: ${theme.colors.backgroundDark};
      color: white;
    }
    ~ tr {
      &:hover{
        td {
          /* background-color: ${theme.colors.backgroundLight}; */
        }
      }
    }
  }

  tr {
    &:nth-child(odd){
      td{
        /* background-color: ${theme.colors.backgroundLight}; */
      }
    }
  }

  th,
  td {
    padding: 0.75rem 1rem;
    border: 1px solid ${theme.colors.borderDark};
    text-align: left;
    transition: background-color 0.3s ease-in-out;
    h2, h3, h4 {
      margin-bottom: 0;
    }
  }


  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }

  .keen-slider {
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;



    &__slide {
      /* position: relative; */
      overflow: hidden;
      width: 100%;
      min-height: 100%;
    }

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-v] &__slide {
      width: 100%;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }
  }
  .EPiServerForms .FormDateTime .FormDateTime__Input,
  .EPiServerForms .FormDateTimeRange .FormDateTimeRange__Start,
  .EPiServerForms .FormDateTimeRange .FormDateTimeRange__End{
    background-image: url("/images/calendar-icon.svg");
    background-repeat: no-repeat;
    background-position: 96% 50%;
    background-position: calc(100% - 10px) 50%;
    cursor: pointer;
  }
  .imagecaptiongridblock{
    margin-bottom: 12px;
  }

  .contentsearchblock,
  .featurecontentblock,
  .imagecaptionblock,
  .promoblock{
    ${breakpoint.greaterThan('md')`
      margin-bottom: 32px;
      > div:not([style]):not([class]){
        height: 100%;
        > div {
          height: 100%;
        }
      }
    `};
  }

  .react-scrolltop-button{
    svg {
      g {
        transform: translate(-1.5px, 0px);
      }
    }
    @media print {
      display: none;
    }
  }
  .contentimageblock + .imagecaptionblock{
    ${breakpoint.greaterThan('md')`
      margin-bottom: 0;
      > div > div > div:first-child {
        padding: 0;
        height: 100%;
      }
    `};
  }
`;

export default GlobalStyle;
