import React, { ReactElement } from 'react';

import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { ContentListItemProps } from '../../molecules/ContentListItem';
import PageHero from '../../molecules/PageHero';
import { ProjectListItemProps } from '../../molecules/ProjectListItem';
import ContentListGroup from '../../organisms/ContentListGroup';
import ProjectListGroup from '../../organisms/ProjectListGroup';
import ProjectsPageDashboard, {
  IProjectsPageDashboard,
} from '../../organisms/ProjectsPageDashboard';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';

interface ProjectsListingPageProps {
  dashboard: IProjectsPageDashboard;
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  breadcrumbs?: NavBreadcrumbsProps['items'];
  epiTitle: EpiProps;
  // epiContent: EpiProps;
  news?: EpiProps;
  projects?: EpiProps;
  alert?: PageTemplateProps['alert'];
  caption?: string;
  attributionTo?: string;
  attributionLink?: string;
}

const ProjectsListingPage = ({
  ctaBlock,
  header,
  footer,
  breadcrumbs,
  epiTitle,
  // epiContent,
  news,
  projects,
  dashboard,
  alert,
  caption = '',
  attributionTo = '',
  attributionLink = '',
}: ProjectsListingPageProps): ReactElement => {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <PageHero titleOnly={true} epiTitle={epiTitle} breadcrumbs={breadcrumbs} caption={caption}
            attributionTo={attributionTo}
            attributionLink={attributionLink} />

      <ProjectsPageDashboard
        intro={dashboard.intro}
        column1={dashboard.column1}
        column2={dashboard.column2}
        column3={dashboard.column3}
      />
      
      {news && (
        <section
          style={{
          }}
        >
          {/* <Container>
            <h2>News</h2>
            <ContentListGroup list={news.list.slice(0, 4)} />
          </Container> */}
          <Container>
            <EpiProperty
              name={news.name}
              value={news.value}
              isEditMode={news.isEditMode}
              outerTag="div"
            />
          </Container>
        </section>
      )}
      {projects && (
        <section
          style={{
            backgroundColor: '#F5F5F5'
          }}
        >
          {/* <Container>
            <h2>Near you</h2>
            <ProjectListGroup list={projects.list.slice(0, 4)} />
            <h2>Newest Projects</h2>
            <ProjectListGroup list={projects.list.slice(0, 4)} />
          </Container> */}
          <Container>
            <EpiProperty
              name={projects.name}
              value={projects.value}
              isEditMode={projects.isEditMode}
              outerTag="div"
            />
          </Container>
        </section>
      )}
    </PageTemplate>
  );
};

export default ProjectsListingPage;
