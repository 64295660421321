import React, { ReactElement } from 'react';
import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';
import Container from '../../atoms/Container';
import NavUtils, { NavUtilsProps } from '../../molecules/NavUtils';
import NavLinkListGroup, {
  NavLinkListGroupProps,
} from '../../organisms/NavLinkListGroup';

const StyledFooter = styled.footer`
  background-color: #191919;
  color: ${theme.colors.textLight};

  .footer__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem 0;
    border-top: solid 1px ${theme.colors.textLight};
  }

  .footer__copyright {
    font-size: 0.87rem;
    font-weight: ${theme.fontWeight.regular};
  }

  .footer__logo {
    width: 100px;
    margin: 0 0 0.5rem 0;

    ${breakpoint.greaterThan('md')`
        margin: 0 2rem 0 0;
    `}
  }

  .footer__bottom-left {
    width: 100%;

    ${breakpoint.greaterThan('md')`
      width: auto;
    `}
  }

  .footer__bottom-right {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 2rem 0 0 0;
    text-align: center;

    ${breakpoint.greaterThan('md')`
      flex: 1 0 auto;
      justify-content: flex-end;
      flex-flow: row;
      padding: 0;
      text-align: right;
    `}
  }
  @media print {
    display: none;
  }
`;

export interface FooterProps {
  copyright: string;
  footerNavLists: NavLinkListGroupProps['lists'];
  footerUtils: NavUtilsProps['items'];
  footerLogo?: {
    alt: string;
    href: string;
    image: string;
    target?: string;
    title: string;
  };
}

function Footer({
  copyright,
  footerNavLists,
  footerUtils,
  footerLogo,
}: FooterProps): ReactElement {
  return (
    <StyledFooter role="contentinfo">
      <Container>
        <NavLinkListGroup lists={footerNavLists} />
        <div className="footer__bottom">
          <div className="footer__bottom-left">
            <NavUtils items={footerUtils} location="footer" />
          </div>
          <div className="footer__bottom-right">
            {footerLogo && (
              <div className="footer__logo">
                <a
                  href={footerLogo.href}
                  target={footerLogo.target}
                  rel={
                    footerLogo.target === '_blank' ? 'noopener noreferrer' : ''
                  }
                  title={footerLogo.title}
                  aria-label={footerLogo.title}
                >
                  <img
                    src={footerLogo.image}
                    alt={footerLogo.alt || 'Footer Logo'}
                  />
                </a>
              </div>
            )}
            <p
              className="footer__copyright"
              dangerouslySetInnerHTML={{ __html: copyright }}
            />
          </div>
        </div>
      </Container>
    </StyledFooter>
  );
}

export default Footer;
