import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';

const StyledPageSummary = styled.div`
  p {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-weight: 300;
    font-size: 1.5rem;
  }
`;

type PageSummaryProps = {
  children: ReactNode;
};

function PageSummary({ children }: PageSummaryProps): ReactElement {
  return <StyledPageSummary>{children}</StyledPageSummary>;
}

export default PageSummary;
