import React, { useContext } from 'react';

import { TravelMapDirectionsStyled } from './TravelMapDirectionsStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { TravelMapContext } from '../../organisms/TravelMapDashboard';
import Dropdown from '../../atoms/Dropdown';

export interface ITravelMapDirections {
}


const TravelMapDirections = ({
}: ITravelMapDirections) => {
  const { state, dispatch } = useContext(TravelMapContext);
  const { journey: { directions } } = state;

  return (
    <TravelMapDirectionsStyled>
        <div id="travel-map-directions__route"></div>
    </TravelMapDirectionsStyled>
  );
};

export default TravelMapDirections;