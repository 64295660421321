/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import theme from '../../../lib/theme';
import EpiProperty from '../EpiProperty';
import Image from '../Image';
import {
  StyledImageCaptionBlockContent,
  StyledImageCaptionBlockItem,
  StyledTextCaptionBlockContent,
} from './styles';

export interface ImageCaptionBlockProps {
  grid?: string;
  image?: {
    alt: string;
    href: string;
    img: string;
    target: string;
    title: string;
    paddingBottom?: string;
    paddingBottomTablet?: string;
    borderRadius?: string;
  };
  title: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  text?: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  textLink?: {
    href: string;
    target: string;
    title: string;
  };
  bgColor?: {
    name: string;
    value: string;
  };
  color?: {
    name: string;
    value: string;
  };
}

function ImageCaptionBlockItem({
  grid,
  image,
  title,
  text,
  textLink,
  bgColor,
  color,
}: ImageCaptionBlockProps): ReactElement {
  return (
    <StyledImageCaptionBlockItem style={{ gridArea: grid }}>
      {image ? (
        <>
          <Image
            alt={image.alt}
            href={image.href}
            image={image.img}
            target={image.target}
            title={image.title}
            paddingBottom={image.paddingBottom}
            paddingBottomTablet={image.paddingBottomTablet}
            borderRadius={image.borderRadius}
          />
          <StyledImageCaptionBlockContent
            bgColor={bgColor ? bgColor.value : theme.colors.backgroundLight}
            txtColor={color ? color.value : theme.colors.textDark}
          >
            <EpiProperty
              name={title.name}
              value={title.value}
              isEditMode={title.isEditMode}
              outerTag="h2"
            />
            {text && text.value && <p>{text.value}</p>}
            {textLink && textLink.href && (
              <a
                href={textLink.href}
                target={textLink.target}
              >
                {`${textLink.title}`}&nbsp;»
              </a>
            )}
          </StyledImageCaptionBlockContent>
        </>
      ) : (
        <StyledTextCaptionBlockContent
          bgColor={bgColor ? bgColor.value : theme.colors.backgroundLight}
          txtColor={color ? color.value : theme.colors.textLight}
        >
          <EpiProperty
            name={title.name}
            value={title.value}
            isEditMode={title.isEditMode}
            outerTag="h2"
          />
          {textLink && textLink.href && (
            <a
              href={textLink.href}
              target={textLink.target}
            >
              {`${textLink.title}`}&nbsp;»
            </a>
          )}
          {text && text.value && <p>{text.value}</p>}
        </StyledTextCaptionBlockContent>
      )}
    </StyledImageCaptionBlockItem>
  );
}

export default ImageCaptionBlockItem;
