import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const TravelMapAlertsStyled = styled.section`
  display: grid;
  grid-template-rows: auto auto minmax(0,1fr) auto;
  margin: 0rem 2.25rem;

  ${breakpoint.lessThan('lg')`
    margin-bottom: 1rem !important;
  `}

  ${breakpoint.greaterThan('lg')`
    grid-template-rows: auto auto auto minmax(0, 1fr) auto;
    min-height: 70vh;
  `}

  .travel-map-alerts__heading {
    display: none;

    ${breakpoint.greaterThan('lg')`
      display: flex;
      justify-content: space-between;
    `}

    >h3 {
      margin: 0 0 0.5rem 0;
    }

    @media print {
      display: none;
    }

    .travel-map-alerts__button {
      border:none;
      cursor: pointer;
      width: 2rem;
      height: 2rem;
      margin: 0 0.5rem;
      border-radius: 100%;
      background-color: transparent;
      color: ${theme.colors.travelMapButtonBg};
      transition-property: background-color;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
      :hover {
        background-color: #f5f5f5;
      }
      svg {
        width: 0.875em;
        height: 0.875em;
      }
      &:focus {
        outline: 2px solid ${theme.colors.inputFocus};
      }
      &:disabled {
        opacity: 0.3;
      }
    }

    @media print {
      display: none;
    }
  }

  .travel-map-alerts__filters {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0 0 0;

    ${breakpoint.greaterThan('md')`
        margin: 0.5rem 0 0 0;
    `}

    >div {
      display: flex;
      align-items: flex-end;
    }
    p {
      font-size: 0.8rem;
      font-weight: 600;
      padding: 0 0.5rem 0.5rem 0;
      margin: 0;

      ${breakpoint.greaterThan('md')`
        padding: 0.75rem;
      `}
    }
    >button {
      all: unset;
      cursor: pointer;
      padding: 0 0.5rem 0.5rem 0.5rem;
      color: ${theme.colors.textMid};

      &:focus {
        outline: 2px solid ${theme.colors.inputFocus};
      }

      ${breakpoint.greaterThan('md')`
        padding: 0 0.75rem;
      `}
    }
    
    @media print {
      display: none;
    }
  }

  .travel-map-alerts__search {
    position: relative;

    @media print {
      display: none;
    }

    button {
      all: unset;
      position: absolute;
      top: 0.7rem;
      right: 1rem;
      opacity: 0;
      pointer-events: none;
      cursor: pointer;
      transition-property: opacity;
      transition-duration: 250ms;
      transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
      svg {
        height: 1rem;
        width: 1rem;
      }
    }
    
    input.travel-map-alerts__search--filled+button {
      opacity: 1;
      pointer-events: all;
    }
  }

  .travel-map-alerts__status {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    p {
      text-align: center;
      font-size: 0.8rem;
      color: ${theme.colors.textMid};
      margin: 0;
      ${breakpoint.greaterThan('lg')`
        margin: 0.5rem 0;
      `}
    }

    @media print {
      display: none;
    }
  }

  >ul {
    list-style-type: none;
    margin: 0 0 1em 0;
    padding: 0;
    border-top: 1px solid ${theme.colors.borderMediumLight};
    border-bottom: 1px solid ${theme.colors.borderMediumLight};
    overflow: auto;

    @media print {
      border-width: 0;
      li {
        page-break-inside: avoid;
      }
    }

    li {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 1rem;
      padding: 0 0 1rem 0;

      :first-child {
        padding-top: 0.5rem;
      }

      :last-child {
        padding-bottom: 0.5rem;
      }

      &.travel-map-alerts__alert--recent {
        /* background-color: #ffeee9; */
        p:first-child {
          color: #d1420f;
          font-weight: 600;
        }
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
        margin: 0 0 0.2rem 0;
        :first-child {
          display: inline-block;
          text-decoration: underline;
          cursor: pointer;
          margin: 0 0 0.2rem 0;
          &:focus-visible {
            outline: 2px solid ${theme.colors.inputFocus};
          }
        }
      }

      &.travel-map-alerts__loader {
        display: flex;
        justify-content: center;
        overflow: hidden;
        opacity: 0.5;
        user-select: none;
        > div {
          display: inline-block;
          margin: 0 auto;
        }
        svg {
          font-size: 2em;
        }
      }

      &.travel-map-alerts__no-results {
        grid-template-columns: 1fr;
        gap: 0.3rem;
        margin: 1rem 0 0 0;
        text-align: center;
        color: ${theme.colors.textMid};
        h5 {
          margin: 0;
        }
        button {
          all: unset;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .travel-map-alerts__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        -webkit-print-color-adjust: exact !important;
        >img {
          width: 1.3rem;
          height: auto;
        }
      }
    }
  }
`;
