import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

const StyledNavUtils = styled.nav`
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: ${theme.spacing.sm};

    ${breakpoint.lessThan('sm')`
      width: 33.3%;
    `}

    &:last-child {
      margin: 0;
    }

    ${breakpoint.greaterThan('sm')`
      margin: 0 3.75rem 0 0 ;
    `}
  }

  li a {
    padding: 0 0 2px 0;
    border-bottom: dotted 1px ${theme.colors.textLight};
    color: ${theme.colors.textLight};
    font-size: 0.87rem;
    font-weight: ${theme.fontWeight.medium};
    border: none;

    &:hover {
      border-bottom: solid 1px ${theme.colors.textLight};
    }
  }
`;

export interface NavUtilsProps {
  location?: string;
  items: {
    href: string;
    target?: string;
    title: string;
  }[];
}

function NavUtils({ items, location }: NavUtilsProps): ReactElement {
  return (
    <StyledNavUtils
      className="nav-utils"
      role="navigation"
      aria-label="Utilities"
      id={location ? `utility-navigation-${location}` : 'utility-navigation'}
    >
      <ul>
        {items.map(({ href, target = '_self', title }, i) => (
          <li key={i}>
            <a
              href={href}
              target={target}
              rel={target === '_blank' ? 'noopener noreferrer' : ''}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
    </StyledNavUtils>
  );
}

export default NavUtils;
