import React, { ReactElement, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { StyledListingPageFilters } from './styles';

export interface ListingPageFiltersProps {
  epiTitle: EpiProps;
  categories: {
    name: string;
    selected: boolean;
  }[];
  updateFilters?: (filters) => void;
}

function ListingPageFilters({
  epiTitle,
  categories,
  updateFilters,
}: ListingPageFiltersProps): ReactElement {
  const [filters, setFilters] = useState(categories);

  function handleFilterClick(
    event: React.MouseEvent,
    category: { name: string; selected: boolean },
  ): void {
    const newFilters = filters.map(filter => {
      if (filter.name === category.name) {
        // eslint-disable-next-line no-param-reassign
        filter.selected = !filter.selected;
      }
      return filter;
    });
    setFilters(newFilters);
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    updateFilters && updateFilters(filters);
    // eslint-disable-next-line
  }, [filters]);

  return (
    <StyledListingPageFilters>
      <EpiProperty
        name={epiTitle.name}
        value={epiTitle.value}
        isEditMode={epiTitle.isEditMode}
        outerTag="h2"
      />
      <ul>
        {filters.map(f => (
          <li key={f.name}>
            {f.selected && (
              <FontAwesomeIcon
                icon={['far', 'times-circle']}
                onClick={(e): void => handleFilterClick(e, f)}
              />
            )}
            <button
              className={f.selected ? 'filter__selected' : ''}
              onClick={(e): void => handleFilterClick(e, f)}
              type="button"
              title={f.name}
            >
              {f.name}
            </button>
          </li>
        ))}
      </ul>
    </StyledListingPageFilters>
  );
}

export default ListingPageFilters;
