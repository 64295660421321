import React, { ReactElement } from 'react';
import styled from 'styled-components';

import theme from '../../../lib/theme';
import { LinkProps } from '../../../types';
import Button from '../../atoms/Button';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import SearchForm from '../../atoms/SearchForm';
import { SearchFormProps } from '../../atoms/SearchFormWidget';
import { BaseBlockProps } from '../../../types'

const StyledFeaturedPanel = styled.div`
  h2 {
    font-size: 1.75em;
  }
  em {
    font-style: normal;
    color: ${theme.colors.titleColorEmphasis};
  }
`;

export interface FeaturedPanelProps extends BaseBlockProps {
  title: EpiProps;
  summary: EpiProps;
  link?: LinkProps;
  search?: SearchFormProps;
}

// TODO: Review any other comps that should work as a featured panel banner widget
// Enable it to take any HTML element as a prop or children and parse?
const FeaturedPanel = ({
  title,
  summary,
  link,
  search,
  anchorId
}: FeaturedPanelProps): ReactElement => {
  return (
    <StyledFeaturedPanel id={anchorId}>
      <div>
        <EpiProperty
          name={title.name}
          value={title.value}
          isEditMode={title.isEditMode}
          outerTag="h2"
        />
        <EpiProperty
          name={summary.name}
          value={summary.value}
          isEditMode={summary.isEditMode}
          outerTag="p"
        />
        {search && (
          <SearchForm
            minimal
            placeholder={search.placeholder}
            action={search.action}
          />
        )}
        {link && (
          <Button
            href={link.href}
            className="gtm-track"
            title={link.label}
            target={link.target}
          >
            {link.label}
          </Button>
        )}
      </div>
    </StyledFeaturedPanel>
  );
};

export default FeaturedPanel;
