import styled, { css } from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

interface AccordianStyledProps {
  elHeight: string
}

const AccordianStyled = styled.section<AccordianStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .accordian__title {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin: 0.5rem 0;
    padding: 0.5rem 0 0.5rem 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 6px var(--box-shadow-color);
    cursor: pointer;
    h6 {
      display: inline-block;
      font-weight: 500;
      color: var(--primary-blue);
      margin: 0;
      font-size: 1rem;
    }
    >div {
      display: flex;
      align-items: center;
      >svg, >img {
        height: 2.2rem;
        width: 2.2rem;
        margin: 0 0.5rem 0 0;
      }
    }
    >svg {
      height: 1.6rem;
      width: 1.6rem;
      transform: rotate(0deg);
      transition-property: transform, -ms-transform, -moz-transform, -webkit-transform;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0, 0.45, 0.45, 0.84);

    }
  }
  .accordian__content {
    max-height: 0px;
    overflow: hidden;
    box-sizing: border-box;
    transition-property: max-height;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0, 0.45, 0.45, 0.84);
  }

  &.accordian--active {
    .accordian__content {
      max-height: ${(props) => props.elHeight};
    }
    .accordian__title svg:last-child {
      transform: rotate(-180deg);
    }
  }
`;

export default AccordianStyled;