import React, { ReactElement } from 'react';

import { StyledAccreditationListItem } from './styles';

interface AccreditationListItemProps {
  // id: number;
  // externalId: number;
  type: string;
  businessName: string;
  firstName: string;
  lastName: string;
  status: string;
  // currentlyAccredited: string;
  // addressLine1: string;
  // addressLine2: string;
  // suburbTown: string;
  // state: string;
  // country: string;
  // postcode: string;
  emails: string;
  // phones: string;
  certificationScope: string;
  // certificationScopeList?: string[];
  phonesList?: string[];
  // emailsList?: string[];
  formattedAddress: string;
}

const AccreditationListItem = ({
  // id,
  // externalId,
  type,
  businessName,
  firstName,
  lastName,
  status,
  // currentlyAccredited,
  // addressLine1,
  // addressLine2,
  // suburbTown,
  // state,
  // country,
  // postcode,
  emails,
  // phones,
  certificationScope,
  // certificationScopeList,
  phonesList,
  // emailsList,
  formattedAddress,
}: AccreditationListItemProps): ReactElement => {
  return (
    <StyledAccreditationListItem>
      <div className="accreditation-list-item">
        <h2 className="accreditation-list-item__title">
          {businessName && <span>{businessName}</span>}
          {!businessName && (
            <span>
              {firstName} {lastName}
            </span>
          )}
        </h2>
        {type && <p className="accreditation-list-item__modified">{type}</p>}
        {status && (
          <p className="accreditation-list-item__description">{status}</p>
        )}
        {certificationScope && (
          <p className="accreditation-list-item__description">
            <b>Certified:</b> {certificationScope}
          </p>
        )}
        {formattedAddress && (
          <p
            className="accreditation-list-item__description"
            dangerouslySetInnerHTML={{ __html: formattedAddress }}
          />
        )}
        {emails && (
          <p className="accreditation-list-item__description">{emails}</p>
        )}
        {phonesList &&
          phonesList.map((item, i) => (
            <p key={i} className="accreditation-list-item__description__phone">
              {item}
            </p>
          ))}
      </div>
    </StyledAccreditationListItem>
  );
};

export default AccreditationListItem;
