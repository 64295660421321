import styled from 'styled-components';

import { StyledMainWithSideBar } from '../../../lib/StyledMainWithSideBar';
import theme, { breakpoint } from '../../../lib/theme';
import Button from '../../atoms/Button';
import SearchFormWidget from '../../atoms/SearchFormWidget';
import FilterList from '../../molecules/FilterList';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';
import NavSub from '../../molecules/NavSub';

export const StyledPageLayout = styled.div`
  ${StyledMainWithSideBar({ flow: 'aside-main', element: 'main' })};
  .page-layout__main {
    article {
      margin: 0 0 ${theme.spacing.sm} 0;
    }
  }
  .document-list__number {
    margin: 0 0 ${theme.spacing.md} 0;
    font-size: 1rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.125rem;
      text-align: right;
  `}
  }
  .document-list__filters {
    display: none;
    h2 {
      font-size: 1.5em;
    }
    ${breakpoint.greaterThan('md')`
      display: block;
    `}
  }
  .document-list__filters--active {
    display: block;
  }
`;

export const StyledDocumentList = styled.div`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.sm} 0;
  ${breakpoint.greaterThan('sm')`
    margin: ${theme.spacing.md} 0 ${theme.spacing.md} 0;
  `};

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.lg} 0 ${theme.spacing.md} 0;
  `}
`;

export const SearchFormColors = {
  inputBg: theme.colors.textLight,
  placeholderTextColor: theme.colors.textDark,
  activeInputBg: theme.colors.textLight,
  activePlaceholderTextColor: theme.colors.textDark,
};
export const StyledNavSub = styled(NavSub)`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledFilterList = styled(FilterList)`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 0 ${theme.spacing.md} 0;

  ${breakpoint.greaterThan('md')`
    display: none;
  `}
`;

export const StyledClearButton = styled(Button)`
  width: 100%;
  margin: 0 0 ${theme.spacing.md} 0;
`;

const CategoryFilterList = styled.ul`
  margin: 0;
  padding: 0;
  li {
    margin: 0 0 ${theme.spacing.xs} 0;
    list-style: none;
  }
  button {
    width: 100%;
  }
`;

export const StyledCategoryFilterListButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1rem;
  background-color: ${theme.colors.navSubBg};
  &.active {
    background-color: ${theme.colors.linkHover};
  }
  color: ${theme.colors.navSubText};
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 250ms ease-in-out;
  cursor: pointer;
  span {
    border-bottom: dotted 0px ${theme.colors.navSubText};
  }
  &:hover {
    color: #ffffff;
    background-color: ${theme.colors.linkHover};
  }
`;

export const StyledNodeFilterListLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1rem;
  background-color: ${theme.colors.navSubBg};
  &.active {
    background-color: ${theme.colors.linkHover};
  }
  color: ${theme.colors.navSubText};
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  span {
    border-bottom: dotted 0px ${theme.colors.navSubText};
    margin: 0 ${theme.spacing.xs} 0 0;
    text-align: right;
    &.active {
      text-align: left;
    }
  }
  &:hover {
    color: #ffffff;
    background-color: ${theme.colors.linkHover};
  }
  ${breakpoint.greaterThan('sm')`
    margin-right: 1em;
  `};
`;

export const StyledNodeFilterList = styled.ul`
  list-style: none;
  margin: 0 0 30px 0;
  padding: 8px;
  border: 1px solid #aaaaaa;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  li {
    margin-bottom: 0.1875em;
    &.is-active {
      ~ li {
        margin-left: 20px;
        font-size: 1em;
        a {
          padding: 0.5em 1em;
        }
        &.is-active {
          ~ li {
            margin-left: 40px;
            font-size: 0.9em;
            a {
              padding: 0.4em 1em;
            }
            &.is-active {
              ~ li {
                margin-left: 60px;
                font-size: 0.875em;
                &.is-active {
                  ~ li {
                    margin-left: 80px;
                    &.is-active {
                      ~ li {
                        margin-left: 100px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  button {
    width: 100%;
  }
  ${breakpoint.greaterThan('sm')`
    padding: 30px 45px;
  `};
  ${breakpoint.greaterThan('md')`
    border: none;
    padding: 0;
    grid-gap: 9px 4px;
    border-bottom: none;
`};
  ${breakpoint.greaterThan('lg')`
    display: flex;
    flex-wrap: wrap;
  `};
`;

export const StyledTypeFilterList = styled.ul`
  list-style: none;
  margin: 0 0 30px 0;
  padding: 30px 45px;
  border: 1px solid #aaaaaa;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 1em;
    margin-bottom: 1em;
  }
  button {
    width: 100%;
  }
  ${breakpoint.greaterThan('md')`
    border: none;
    padding: 0;
    grid-gap: 9px 4px;
    border-bottom: none;
`};
  ${breakpoint.greaterThan('lg')`
    display: flex;
    flex-wrap: wrap;
  `};
`;

export const StyledSearchFormWidget = styled(SearchFormWidget)`
  margin-bottom: 40px;
`;

export const StyledCategoryFilterList = styled(CategoryFilterList)`
  margin-bottom: 40px;
`;

export const CatWrapper = styled.div`
  position: relative;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const DisclaimerStyled = styled.div`
  padding-left: 1.5rem;
  width: 100%;
  max-width: ${theme.breakpoints.container};
  margin: 0px;
  padding: 0px 1.5rem;
  margin-bottom: 1rem;
  p {
    margin: 0px;
    font-style: italic;
    font-size: 0.75em;
  }

  ${breakpoint.greaterThan('sm')`
    min-width: 450px;
  `};
  ${breakpoint.greaterThan('md')`
    width: 75%;
  `};
  ${breakpoint.greaterThan('lg')`
    min-width: 640px;
  `};
`;
