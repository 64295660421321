import styled from 'styled-components';
import { breakpoint } from '../../../lib/theme';

export const DashboardStyles = styled.section`
  background-color: #f5f5f5;
  padding-top: 48px;
  padding-bottom: 48px;
  .dashboard__intro {
    padding-bottom: 2em;
    p {
      margin: 0;
    }
  }
  .dashboard__grid {
    > div {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    display: flex;
    flex-direction: column;
    gap: 25px;
    ${breakpoint.greaterThan('sm')`
      display: grid;
      grid-template-columns: 1fr;
      grid-template-columns: 1fr 1fr;
    `};
    ${breakpoint.greaterThan('lg')`
      grid-template-columns: 1fr 2fr 1fr;
    `};
    .wide {
      ${breakpoint.greaterThan('sm')`
        order: -1;
        grid-column: span 2;
      `};
      ${breakpoint.greaterThan('lg')`
        order: 0;
        grid-column: auto;
      `};
    }
  }
`;
