import styled from 'styled-components';
import theme from '../../../lib/theme';

type StyledSelectFieldProp = {
  showingPlaceholder: boolean;
};

const StyledSelectField = styled.div<StyledSelectFieldProp>`
  position: relative;
  display: flex;
  margin-bottom: ${theme.spacing.md};
  flex-direction: column;
  label {
    font-size: 1.5rem;
    font-weight: ${theme.fontWeight.black};
    line-height: 1;
    padding: 0;
    color: ${theme.colors.textDark};
    margin-bottom: ${theme.spacing.sm};
  }

  .select-wrap {
    display: inline-flex;
    position: relative;
  }
  select {
    flex: 1 1 100%;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 15px 40px 15px 15px;
    transition: color 0.1s linear;
    color: ${theme.colors.textDark};
  }
  .custom-arrow {
    position: absolute;
    display: block;
    top: 20px;
    right: 15px;
    height: 0;
    width: 0;
    border-top: 10px solid ${theme.colors.textDark};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;

export default StyledSelectField;
