import styled from 'styled-components';
import theme from '../../../lib/theme';

const ToggleCheckboxFilterStyled = styled.div`
  position: relative;
  display: flex;
  margin: 5px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  label {
    flex: 2;
    line-height: 1;
    padding: 0;
    color: ${theme.colors.textDark};
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  input {
    cursor: pointer;
    width: 0;
    opacity: 0;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: box-shadow 0.3s cubic-bezier(0.5, 0, 0, 1);
    }
    &:focus {
      ~ .custom-checkbox {
        box-shadow: 0 0 0 2px #5e9ed6;
      }
    }
    &:checked {
      ~ .custom-checkbox {
        background-color: ${theme.colors.borderDark};
        &:after {
          transform: translateX(20px);
          background-color: ${theme.colors.white};
        }
      }
    }
  }

  .custom-checkbox {
    position: relative;
    display: block;
    pointer-events: none;
    height: 24px;
    width: 44px;
    top: 0px;
    margin-left: 15px;
    border-radius: 12px;
    border: 2px solid ${theme.colors.borderDark};
    background-color: ${theme.colors.bgLight};
    transition: background-color 0.2s 0.1s linear;
    &:after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      background-color: ${theme.colors.borderDark};
        border: 2px solid ${theme.colors.borderDark};
      transform: translateX(0px);
      transition: transform 0.3s cubic-bezier(0.5, 0, 0, 1),
        background-color 0.2s linear;
    }
  }
`;

export default ToggleCheckboxFilterStyled;
