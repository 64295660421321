const restAreaData = [
  {
    id: 0,
    name: 'Overnight Stopping',
    value: 'Yes',
    prop: 'STAY_24_HOUR',
    iconUrl: '/images/travel-map/icon_overnight-stopping',
  },
  {
    id: 1,
    name: 'Light Vehicle Parking',
    value: 'Minor Rest Area',
    prop: 'REST_AREA_TYPE',
    iconUrl: '/images/travel-map/icon_light-vehicle-parking',
  },
  {
    id: 2,
    name: 'Heavy Vehicle Parking',
    value: 'Heavy Vehicle Rest Area',
    prop: 'REST_AREA_TYPE',
    iconUrl: '/images/travel-map/icon_heavy-vehicle-parking',
  },
  {
    id: 3,
    name: 'Toilets',
    value: 'Count',
    prop: 'NUMBER_OF_TOILETS',
    iconUrl: '/images/travel-map/icon_toilets',
  },
  {
    id: 4,
    name: 'Accessible Toilets',
    value: 'Yes',
    prop: 'ACCESSIBLE_TOILETS',
    iconUrl: '/images/travel-map/icon_accessible-toilets',
  },
  {
    id: 5,
    name: 'Information Bay',
    value: 'Yes',
    prop: 'INFORMATION_BOARD',
    iconUrl: '/images/travel-map/icon_info-board',
  },
  {
    id: 6,
    name: 'Picnic Facilities',
    value: 'Yes',
    prop: 'ACCESSIBLE_TABLES',
    iconUrl: '/images/travel-map/icon_restarea',
  },
  {
    id: 7,
    name: 'Roadhouse',
    value: 'Roadhouse',
    prop: 'REST_AREA_TYPE',
    iconUrl: '/images/travel-map/icon_roadhouse',
  },
  {
    id: 8,
    name: 'Bin',
    value: 'Count',
    prop: 'NUMBER_OF_BINS',
    iconUrl: '/images/travel-map/icon_bin',
  },
  {
    id: 9,
    name: 'Effluent Dump Site',
    value: 'Yes',
    prop: 'EFFLUENT_DUMP_SITE',
    iconUrl: '/images/travel-map/icon_effluent-dump-site',
  }
]

export default restAreaData;