import '../../../lib/fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import theme from '../../../lib/theme';
import { LinkProps } from '../../../types';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import ButtonBlock from '../ButtonBlock';
import {
  PromoBlockButtonStyles,
  PromoBlockSummaryStyles,
  StyledPromoBlock,
} from './styles';
import { BaseBlockProps } from '../../../types'

export interface PromoBlockProps extends BaseBlockProps {
  colour: 'red' | 'blue' | 'green' | 'black';
  watermark?:
    | 'watermark_alert'
    | 'watermark_announce'
    | 'watermark_chat'
    | 'watermark_information'
    | 'watermark_login'
    | 'watermark_mainroads'
    | 'watermark_phone'
    | 'watermark_travelmap';
  doShowWatermark?: boolean;
  icon?: IconName;
  title: EpiProps;
  titleLink?: LinkProps;
  subtitle?: EpiProps;
  summary?: EpiProps;
  button?: {
    href: string;
    icon?: IconName;
    children?: React.ReactNode;
    title?: string;
    target?: string;
  };
}

const PromoBlock = ({
  icon,
  watermark,
  doShowWatermark,
  title,
  titleLink,
  subtitle,
  summary,
  button,
  colour = 'red',
  anchorId
}: PromoBlockProps): ReactElement => {
  const buttonColor =
    colour === 'red'
      ? theme.colors.promoBlockTitleBgRed
      : colour === 'blue'
      ? theme.colors.promoBlockTitleBgBlue
      : colour === 'green'
      ? theme.colors.promoBlockTitleBgGreen
      : colour === 'black'
      ? theme.colors.promoBlockTitleBgBlack
      : theme.colors.promoBlockTitleBgBlack;

  const watermarkImage =
    !!watermark && colour !== 'black' && !!doShowWatermark
      ? `/images/${watermark}--${colour}.svg`
      : null;
  return (
    <StyledPromoBlock
      id={anchorId}
      className={`${colour ? `promo-color--${colour}` : ''} ${
        !!watermarkImage ? ' do-show-watermark' : ''
      } ${!summary && !button ? ' promo-block__compact' : ''}`}
      style={
        watermarkImage ? { backgroundImage: `url(${watermarkImage})` } : null
      }
    >
      <div className="promo-block__title">
        {icon && (
          <div className="promo-block__title__icon">
            <FontAwesomeIcon
              icon={icon === 'dot-circle' ? ['far', 'dot-circle'] : icon}
            />
          </div>
        )}
        <div className="promo-block__title__title">
          {titleLink ? (
            <a
              href={titleLink.href}
              title={titleLink.label}
              target={titleLink.target ? titleLink.target : '_self'}
            >
              <EpiProperty
                name={title.name}
                value={`${title.value}&nbsp;»`}
                isEditMode={title.isEditMode}
                outerTag="h2"
              />
            </a>
          ) : (
            <EpiProperty
              name={title.name}
              value={title.value}
              isEditMode={title.isEditMode}
              outerTag="h2"
            />
          )}

          {subtitle && (
            <div className="promo-block__subtitle">
              <EpiProperty
                name={subtitle.name}
                value={subtitle.value}
                isEditMode={subtitle.isEditMode}
                outerTag="h3"
              />
            </div>
          )}
        </div>
      </div>
      {summary && (
        <PromoBlockSummaryStyles>
          <EpiProperty
            name={summary.name}
            value={summary.value}
            isEditMode={summary.isEditMode}
            outerTag="p"
          />
        </PromoBlockSummaryStyles>
      )}
      {button && (
        <PromoBlockButtonStyles>
          <ButtonBlock
            href={button.href}
            color={buttonColor}
            elevation={0}
            iconEnd="long-arrow-alt-right"
            type="button"
            title={button.title}
            target={button.target}
          >
            {button.children}
          </ButtonBlock>
        </PromoBlockButtonStyles>
      )}
    </StyledPromoBlock>
  );
};

export default PromoBlock;
