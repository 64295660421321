/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ReactElement, ReactNode } from 'react';

import { StyledImage, StyledImageLink, StyledImageSpan } from './styles';

export interface ImageProps {
  alt: string;
  href?: string;
  image: string;
  target?: string;
  title: string;
  width?: number;
  height?: number;
  paddingBottom?: string;
  paddingBottomTablet?: string;
  borderRadius?: string;
  boxShadow?: string;
  children?: ReactNode;
  className?: string;
}

function Image({
  alt,
  href,
  image,
  target,
  title,
  paddingBottom,
  paddingBottomTablet,
  borderRadius,
  boxShadow,
  children,
  className,
}: ImageProps): ReactElement {
  return (
    <StyledImage
      className={className}
      paddingBottom={paddingBottom}
      paddingBottomTablet={paddingBottomTablet}
    >
      {href ? (
        <StyledImageLink
          href={href}
          target={target}
          rel={target === '_blank' ? 'noopener noreferrer' : ''}
          title={title}
          style={{ borderRadius, boxShadow }}
        >
          <img src={image} alt={alt} />
          {children}
        </StyledImageLink>
      ) : (
        <StyledImageSpan>
          <img src={image} alt={alt} />
          {children}
        </StyledImageSpan>
      )}
    </StyledImage>
  );
}

export default Image;
