import styled from 'styled-components';
import theme from '../../../lib/theme';

const StyledList = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style: none;
  li {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  @media print {
    display: none;
  }
  a {
    font-size: 1rem;
    font-weight: ${theme.fontWeight.bold};
    border: none;
    &:hover {
      border: none;
    }
  }
`;

export default StyledList;
