import React from 'react';
import {
  FaFilePowerpoint,
  FaFilePdf,
  FaFileWord,
  FaFile,
  FaFileExcel,
  FaFileImage,
} from 'react-icons/fa';

import { DownloadTileStyles } from './DownloadTileStyles';

export interface IDownloadTile {
  theme?: 'red' | 'blue' | 'green' | 'light' | 'black';
  file: {
    url: string;
    label: string;
    size: string;
  };
}

function get_url_extension(url) {
  return url
    .split(/[#?]/)[0]
    .split('.')
    .pop()
    .trim();
}

const DownloadTile = ({ theme = 'red', file }: IDownloadTile) => {
  const ext = get_url_extension(file.url);
  let Icon = <FaFile />;
  switch (ext) {
    case 'gif':
    case 'png':
    case 'jpeg':
    case 'jpg':
      Icon = <FaFileImage />;
      break;
    case 'xls':
    case 'xlsx':
      Icon = <FaFileExcel />;
      break;
    case 'pdf':
      Icon = <FaFilePdf />;
      break;
    case 'ppt':
    case 'pptx':
      Icon = <FaFilePowerpoint />;
      break;
    case 'doc':
    case 'docx':
      Icon = <FaFileWord />;
      break;
    case 'dwg':
    case 'zip':
    default:
      Icon = <FaFile />;
      break;
  }
  return (
    <DownloadTileStyles className={`theme-${theme}`} download href={file.url}>
      {Icon}
      <span className="label">{file.label}</span>
      <span className="meta">File size: {file.size}</span>
    </DownloadTileStyles>
  );
};

export default DownloadTile;
