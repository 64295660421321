import React, { ReactElement, useState } from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { StyledTab, StyledTabPanel } from './styles';
import { BaseBlockProps } from '../../../types'

// Types
type TabItemProps = {
  epiTitle: EpiProps;
  epiContentArea: EpiProps;
};

interface TabBlockProps extends BaseBlockProps {
  tabs: TabItemProps[];
};

/* type TabsProps = {
  tabs: any[];
}; */

// The tab nav item
type TabProps = {
  tabID: any;
  active: boolean;
  onTabClick: any;
  children: any;
};

// Tab content element
type TabPanelProps = {
  tabID: any;
  activeTab: any;
  children: any;
};

// Tab nav element
const Tab = ({ tabID, active, onTabClick, children }: TabProps) => {
  const onClickHandler = () => {
    onTabClick(tabID);
  };
  return (
    <StyledTab className={active ? 'active' : ''} onClick={onClickHandler}>
      {children}
    </StyledTab>
  );
};

const TabPanel = ({ tabID, activeTab, children }: TabPanelProps) => {
  return (
    <StyledTabPanel className={activeTab === tabID ? 'active' : ''}>
      {children}
    </StyledTabPanel>
  );
};
// Tab main comp
const Tabs = ({ tabs, anchorId }: TabBlockProps): ReactElement => {
  const [activeTab, setActiveTab] = useState(0);

  const onTabClickHandler = (tabID: number) => {
    setActiveTab(tabID);
  };
  return (
    <div id={anchorId}>
      {tabs.map((el, i) => (
        <Tab
          key={i}
          tabID={i}
          active={activeTab === i}
          onTabClick={onTabClickHandler}
        >
          <EpiProperty
            name={el.epiTitle.name}
            value={el.epiTitle.value}
            isEditMode={el.epiTitle.isEditMode}
            outerTag="span"
          />
        </Tab>
      ))}
      {tabs.map((el, i) => (
        <TabPanel key={i} tabID={i} activeTab={activeTab}>
          <EpiProperty
            name={el.epiContentArea.name}
            value={el.epiContentArea.value}
            isEditMode={el.epiContentArea.isEditMode}
            outerTag="div"
          />
        </TabPanel>
      ))}
    </div>
  );
};

export default Tabs;
