import React, { ReactElement } from 'react';

import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';

type ContactPageProps = {
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiProjectsMap?: EpiProps;
  alert?: PageTemplateProps['alert'];
};

function ProjectsLandingPage({
  ctaBlock,
  footer,
  header,
  epiProjectsMap,
  alert,
}: ContactPageProps): ReactElement {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <EpiProperty
        name={epiProjectsMap.name}
        value={epiProjectsMap.value}
        isEditMode={epiProjectsMap.isEditMode}
        outerTag="div"
      />
    </PageTemplate>
  );
}

export default ProjectsLandingPage;
