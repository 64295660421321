import React, { ReactElement, ReactNode } from 'react';

import { StyledRightColumnLayout } from './StyledRightColumnLayout';

interface RightColumnLayoutProps {
  mobileTop?: boolean;
  leftColumn: ReactNode;
  rightColumn: ReactNode;
}

const RightColumnLayout = ({
  mobileTop = false,
  leftColumn,
  rightColumn,
}: RightColumnLayoutProps): ReactElement => (
  <StyledRightColumnLayout className={`layout__right-column ${mobileTop ? 'layout__right-column--top' : ''}`}>
    <article className="page-layout__inner">
      <div className="page-layout__main">{leftColumn}</div>
      <aside>{rightColumn}</aside>
    </article>
  </StyledRightColumnLayout>
);

export default RightColumnLayout;
