import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

export const StyledMain = styled.div`
  /* padding should be handled by footer
  padding-bottom: 60px;
  ${breakpoint.greaterThan('md')`
    padding-bottom: 120px;
  `};
  ${breakpoint.greaterThan('lg')`
    padding-bottom: 140px;
`}; */
`;

export const StyledHeader = styled.header`
  background-color: ${theme.colors.backgroundLight};
  margin-bottom: 15px;
  h1 {
    font-size: 1.25em;
    font-weight: 900;
    color: ${theme.colors.titleColor};
    em {
      font-style: normal;
      color: ${theme.colors.titleColorEmphasis};
    }
  }
  p {
    margin: 0 0 1em 0;
    font-size: 1em;
    font-weight: 600;
  }
  ${breakpoint.greaterThan('md')`
    margin-bottom: 30px;
    h1 {
      font-size: 1.875em;
    }
    p{
      font-size: 1.25em;
    }
`};
  ${breakpoint.greaterThan('lg')`
    h1 {
      font-size: 2.75em;
    }
    p{
      font-size: 1.5em;
    }
`};
`;

export const StyledContactPageLayout = styled.div`
  padding: ${theme.spacing.sm} 0;

  .page-layout__left {
    order: 2;
  }
  .page-layout__right {
    order: 1;
  }

  ${breakpoint.greaterThan('md')`
    padding: ${theme.spacing.md} 0;
    .page-layout__left {
      order: 0;
    }
    .page-layout__right {
      order: 0;
    }
  `}
`;
