import React, { ReactElement } from 'react';
import ContentListGroup from '../../organisms/ContentListGroup';
import { ContentListItemProps } from '../../molecules/ContentListItem';
import { LinkProps } from '../../../types';
import {
  ContentListBlockStyles,
  ContentListBlockHeadingStyles,
  StyledReadMoreLink,
} from './ContentListBlockStyles';
import Container from '../../atoms/Container';
import { BaseBlockProps } from '../../../types'

export interface ContentListingBlockProps extends BaseBlockProps {
  title?: LinkProps;
  showTitleLink?: boolean;
  compact?: boolean;
  lists: ContentListItemProps[];
  more?: LinkProps;
  hideBackground?: boolean;
  padding?: boolean;
}

const ContentListingBlock = ({
  title,
  showTitleLink = true,
  compact = false,
  lists,
  more,
  hideBackground = false,
  padding = true,
  anchorId
}: ContentListingBlockProps): ReactElement => {
  return (
    <ContentListBlockStyles id={anchorId} className={`${!hideBackground ? ' do-show-background' : ''}`}>
      <Container padding={padding}>
        {title && (
          <ContentListBlockHeadingStyles>
            {showTitleLink ? (
              <a href={title.href} target={title.target} title={title.label}>
                <h2>{`${title.label}`}&nbsp;»</h2>
              </a>
            ) : (
              <h2>{showTitleLink === false && <span>{title.label}</span>}</h2>
            )}
          </ContentListBlockHeadingStyles>
        )}
        {more && (
          <StyledReadMoreLink
            href={more.href}
            title={more.label}
            className="mobile"
          >
            {`${more.label} »` || 'Read more »'}
          </StyledReadMoreLink>
        )}
        <ContentListGroup list={lists} compact={compact} />
        {more && (
          <StyledReadMoreLink href={more.href} title={more.label}>
            {`${more.label} »` || 'Read more »'}
          </StyledReadMoreLink>
        )}
      </Container>
    </ContentListBlockStyles>
  );
};

export default ContentListingBlock;
