import React, { ReactElement } from 'react';

import { EpiProps } from '../../atoms/EpiProperty';
import PageHero from '../../molecules/PageHero';
import ProjectsSearch, { ProjectStatusFilterItemProps } from '../../organisms/ProjectsSearch';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';

interface ProjectsSearchPageProps {
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  breadcrumbs?: NavBreadcrumbsProps['items'];
  epiTitle: EpiProps;
  epiIntro: EpiProps;
  apiPath: string;
  projectStatusfilterList?: ProjectStatusFilterItemProps[];
  alert?: PageTemplateProps['alert'];
  caption?: string;
  attributionTo?: string;
  attributionLink?: string;
}

const ProjectsSearchPage = ({
  ctaBlock,
  header,
  footer,
  breadcrumbs,
  epiTitle,
  epiIntro,
  apiPath,
  projectStatusfilterList,
  alert,
  caption = '',
  attributionTo = '',
  attributionLink = '',
}: ProjectsSearchPageProps): ReactElement => {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <PageHero titleOnly={true} epiTitle={epiTitle} breadcrumbs={breadcrumbs} caption={caption}
            attributionTo={attributionTo}
            attributionLink={attributionLink} />
      <ProjectsSearch epiIntro={epiIntro} apiPath={apiPath} projectStatusfilterList={projectStatusfilterList} />
    </PageTemplate>
  );
};

export default ProjectsSearchPage;
