import React, { ReactElement } from 'react';

import theme from '../../../lib/theme';
import { LinkProps } from '../../../types';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import SearchFormWidget, {
  SearchFormProps,
} from '../../atoms/SearchFormWidget';
import {
  StyledContentSearchBlock,
  StyledContentSearchBlockContent,
  StyledContentSearchBlockForm,
} from './styles';
import { BaseBlockProps } from '../../../types'

export interface ContentSearchBlockProps extends BaseBlockProps {
  title: EpiProps;
  titleLink?: LinkProps;
  summary: EpiProps;
  placeholderText?: SearchFormProps['placeholder'];
  searchAction?: SearchFormProps['action'];
  searchTitle: EpiProps;
  lightTheme?: boolean;
  compact?: boolean;
  stretch?: boolean;
}
const ContentSearchBlock = ({
  title,
  titleLink,
  summary,
  placeholderText,
  searchAction,
  searchTitle,
  lightTheme = false,
  compact = false,
  stretch = false,
  anchorId
}: ContentSearchBlockProps): ReactElement => {
  return (
    <StyledContentSearchBlock id={anchorId}
      className={`${lightTheme ? 'light-theme' : ''}${
        stretch ? ' stretched' : ''
      }`}
    >
      <div className="content-search-block__inner">
        {!compact && (
          <StyledContentSearchBlockContent
            className={`${lightTheme ? 'light-theme' : ''}`}
          >
            {titleLink && (
              <a
                href={titleLink.href}
                title={titleLink.label}
                target={titleLink.target ? titleLink.target : '_self'}
              >
                <EpiProperty
                  name={title.name}
                  value={`${title.value}&nbsp;»`}
                  isEditMode={title.isEditMode}
                  outerTag="h2"
                />
              </a>
            )}
            {!titleLink && (
              <EpiProperty
                name={title.name}
                value={title.value}
                isEditMode={title.isEditMode}
                outerTag="h2"
              />
            )}
            <EpiProperty
              name={summary.name}
              value={summary.value}
              isEditMode={summary.isEditMode}
              outerTag="p"
            />
          </StyledContentSearchBlockContent>
        )}

        <StyledContentSearchBlockForm
          className={`${compact ? 'compact' : ''} ${
            lightTheme ? 'light-theme' : ''
          }`}
        >
          <EpiProperty
            name={searchTitle.name}
            value={searchTitle.value}
            isEditMode={searchTitle.isEditMode}
            outerTag="h2"
          />
          <SearchFormWidget
            placeholder={placeholderText}
            action={searchAction}
            searchIconBefore={false}
            searchInputWidth="100%"
          />
        </StyledContentSearchBlockForm>
      </div>
    </StyledContentSearchBlock>
  );
};

export default ContentSearchBlock;
