import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

import { StyledPrintFriendlyButton } from './styles';

const PrintFriendlyButton = (): ReactElement => {
  return (
    <StyledPrintFriendlyButton onClick={window.print}>
      <FontAwesomeIcon icon="print" />
      <span>Print</span>
    </StyledPrintFriendlyButton>
  );
};

export default PrintFriendlyButton;
