import { debounce } from 'lodash';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import styled from 'styled-components';
import AccordianStyled from './styles';

interface AccordianProps {
    children?: React.ReactNode;
    title?: string;
    icon?: React.ReactNode;
    defaultActive?: boolean;
    className?: string;
}

const Accordian = ({title = 'Placeholder Text', children, defaultActive = false, icon, className = ''}: AccordianProps): ReactElement => {
  const [active, setActive] = useState(defaultActive);
  const [height, setHeight] = useState('900px');
  const contentEl = useRef(null);

  const updateMaxHeight = () => {
    if (contentEl?.current?.scrollHeight) {
      setHeight(contentEl?.current?.scrollHeight + 'px');
    }
  };

  useEffect(() => {
    updateMaxHeight();
    // add listener for window resize to update max height
    if (active) {
      window.addEventListener('resize', debounce(() => updateMaxHeight(), 100))
    } else {
      window.removeEventListener('resize', debounce(() => updateMaxHeight(), 100))
    }
  }, [active, contentEl])
  return (
    <AccordianStyled className={`${active ? 'accordian--active' : ''} ${className}`} elHeight={height} >
      <div className={'accordian__title'} onClick={() => setActive(!active)}>
        <div>
          {!!icon && icon}
          <h6>{title}</h6>
        </div>
        <FaAngleDown />
      </div>
      <div className={'accordian__content'} ref={contentEl}>
        {children}
      </div>
    </AccordianStyled>
  )
};

export default Accordian;
