import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledTab = styled.button`
  background: transparent;
  color: ${theme.colors.tabTitle};
  border-bottom: 4px solid transparent;
  &.active {
    color: ${theme.colors.tabTitleActive};
    border-bottom: 4px solid ${theme.colors.tabTitleActive};
  }
  cursor: pointer;
  border: none;
  font-weight: 900;

  font-size: 1em;
  padding: 0;
  margin: 0 0.5em 1em;
  &:first-child {
    margin-left: 0;
  }
  ${breakpoint.greaterThan('sm')`
    font-size: 1.5em;
  `};
  ${breakpoint.greaterThan('md')`
    font-size: 2.25em;
    padding: 1px 6px;
    margin: 0;
    margin-bottom: 40px;
    &:not(:last-of-type) {
      margin-right: 1em;
    }
  `};
`;

export const StyledTabPanel = styled.div`
  display: none;
  &.active {
    display: block;
  }
`;
