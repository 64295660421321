import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

export const StyledAccordionItemBlock = styled.div`
  .accordion__item {
    padding-bottom: 1.25rem;
    position: relative;
    > a {
      color: white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
      padding: 0.25em 1em;
      margin-top: 0.75em;
      ${breakpoint.greaterThan('sm')`
        margin-top: 1em;
        margin-left: 50px;
        font-size: 1.25rem;
        margin-top: 0.5em;
        `}
      ${breakpoint.greaterThan('lg')`
        margin-top: 0.7em;
      `}
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .accordion__heading {
    font-size: 0.95rem;
    font-weight: ${theme.fontWeight.bold};
    line-height: 1.125;
    text-align: left;
    border: 1px solid transparent;
    ${breakpoint.greaterThan('sm')`
      font-size: 1.25rem;
    `}
  }

  .accordion__button {
    position: relative;
    padding: 1em 1em;
    font-size: 1em;
    border-radius: 15px;
    display: flex;
    align-items: flex-start;
    gap: 1em;
    color: ${theme.colors.buttonText};
    background-color: ${theme.colors.buttonBg};
    border: 1px solid transparent;
    
    @supports not (aspect-ratio: 1 / 1) {
      .icon {
        margin-right: 1em;
        ${breakpoint.greaterThan('md')`
          margin-right: 1.5em;
        `};
      }
    }
    ${breakpoint.greaterThan('sm')`
      padding: 1em 1.666em;
      gap: 1.6em;
    `}

    &:focus {
      outline: dotted 2px ${theme.colors.linkFocus};
    }
    &:before {
      content: none;
    }
    .icon {
      font-size: 0.625em;
      height: 1.6em;
      width: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        display: block;
      }
    }
    .label {
      flex: 1;
      display: block;
    }
    span {
    }
  }
  &.theme-light {
    .accordion__button {
      background-color: ${theme.colors.buttonText};
      color: ${theme.colors.buttonBg};
    }
  }

  .accordion__panel {
    padding: 1em 1em 1.5em 1em;
    p:first-of-type {
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
    ${breakpoint.greaterThan('sm')`
      padding: 3em 4em 4.5em 4em;
    `}
  }
`;
