import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useEffect, useState } from 'react';

import Container from '../../atoms/Container';
import { LogoProps } from '../../atoms/Logo';
import SearchForm from '../../atoms/SearchForm';
import SearchFormWidget, {
  SearchFormProps,
} from '../../atoms/SearchFormWidget';
import NavMain, { NavMainProps } from '../../molecules/NavMain';
import NavMobile from '../../molecules/NavMobile';
import NavUtils, { NavUtilsProps } from '../../molecules/NavUtils';
import {
  StyledHeader,
  StyledLogo,
  StyledMainNavWrapper,
  StyledOffCanvas,
} from './styles';

export interface HeaderProps {
  govLink: LogoProps['govLink'];
  mrwaLink: LogoProps['mrwaLink'];
  navItems: NavMainProps['items'];
  searchAction?: SearchFormProps['action'];
  searchPlaceholder?: SearchFormProps['placeholder'];
  isSearchPage?: boolean;
  utilItems: NavUtilsProps['items'];
}

const Header = ({
  govLink,
  mrwaLink,
  navItems,
  searchAction,
  searchPlaceholder,
  isSearchPage,
  utilItems,
}: HeaderProps): ReactElement => {
  const [search, setSearch] = useState(false);
  const [offCanvas, setOffCanvas] = useState(false);

  useEffect(() => {
    if (offCanvas === true) {
      document.body.classList.add('mobile-navbar--open');
    } else {
      document.body.classList.remove('mobile-navbar--open');
    }
  }, [offCanvas]);

  return (
    <>
      <StyledHeader
        className={offCanvas ? 'off-canvas--open' : ''}
        role="banner"
      >
        <div className="header__inner">
          <button
            className="header__button"
            type="button"
            title="Toggle Navigation"
            aria-label="Toggle Navigation"
            onClick={(): void => {
              setOffCanvas(!offCanvas);
              setSearch(false);
            }}
          >
            {!offCanvas && <FontAwesomeIcon icon="bars" />}
            {offCanvas && <FontAwesomeIcon icon={['far', 'times-circle']} />}
          </button>
          <StyledLogo govLink={govLink} mrwaLink={mrwaLink} />
          <NavUtils items={utilItems} />
          <SearchFormWidget
            placeholder={searchPlaceholder}
            action={searchAction}
            searchId="search-header"
            theme="dark"
          />
          <button
            className={`header__button${
              isSearchPage ? ' header__button--is-search-page' : ''
            }`}
            type="button"
            title="Toggle Search"
            aria-label="Toggle Search"
            onClick={(): void => {
              if (!isSearchPage) {
                setSearch(!search);
                setOffCanvas(false);
              }
            }}
          >
            {!!search ? (
              <FontAwesomeIcon icon={['far', 'times-circle']} />
            ) : (
              <FontAwesomeIcon icon="search" />
            )}
          </button>
        </div>
      </StyledHeader>
      {search && (
        <SearchForm
          label="Main Roads"
          placeholder={searchPlaceholder}
          action={searchAction}
          isHeader
          searchId="search-header"
        />
      )}
      <StyledMainNavWrapper>
        <Container>
          <NavMain items={navItems} />
        </Container>
      </StyledMainNavWrapper>
      <StyledOffCanvas className={offCanvas ? 'off-canvas--open' : ''}>
        <NavMobile items={[...navItems, ...utilItems]} />
      </StyledOffCanvas>
    </>
  );
};

export default Header;
