import axios from 'axios';
import throttle from 'lodash.throttle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import NProgress from 'nprogress';

import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import Loaderizer from '../../atoms/Loaderizer';
import Pagination from '../../molecules/Pagination';
import ProjectListItem, {
  ProjectListItemProps,
} from '../../molecules/ProjectListItem';
import {
  LoadingStyles,
  SearchContainerStyles,
  SearchResultsContainerStyles,
  SearchResultsGrid,
} from './SearchContainerStyles';

interface ProjectsSearchProps {
  initialSearchQuery?: string;
  epiIntro: EpiProps;
  apiPath: string;
  projectStatusfilterList?: ProjectStatusFilterItemProps[];
}

export interface ProjectStatusFilterItemProps {
  id: string;
  name: string;
  value: string;
}

// Change this base url when debugging
const API_BASE_URL = '';
//const API_BASE_URL =
//  'https://run.mocky.io/v3/20ecc1bf-f4f8-45a4-b6b9-aa2be6ceff99';

const ProjectsSearch = ({
  initialSearchQuery = '',
  epiIntro,
  apiPath,
  projectStatusfilterList,
}: ProjectsSearchProps) => {
  const textInputEl = useRef(null);

  const isMounted = useRef(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    area: [],
    status: [],
  });
  const [searchQueryText, setSearchQueryText] = useState<string>(
    initialSearchQuery,
  );
  const [searchResults, setSearchResults] = useState<ProjectListItemProps[]>(
    [],
  );
  const [pageCount, setPageCount] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchQuery = async ({ firstRun } = { firstRun: false }) => {
    setIsLoading(true);
    NProgress.start();
    // TODO: Replace this with proper feed to grab latest projects
    const areasFilter =
      filters.area.length > 0 ? `${filters.area.join(',')}` : null;
    const planningFilter =
      filters.status.length > 0 ? `${filters.status.join(',')}` : null;

    const q = textInputEl.current.value;

    // Add query params where available
    const SEARCH_URL: string = `${API_BASE_URL}${apiPath}&take=16${
      !!q ? `&q=${q}` : ''
    }${!!currentPage ? `&page=${currentPage}` : ''}${
      !!areasFilter ? `&area=${areasFilter}` : ''
    }${!!planningFilter ? `&status=${planningFilter}` : ''}`;

    await axios(SEARCH_URL)
      .then(res => res.data)
      .then((data: any) => {
        const itemsPerPage: number = data.itemsPerPage;
        const resultsData = {
          itemsPerPage,
          totalItems: data.totalItems,
          page: data.page,
          items: data.items,
        };
        return resultsData;
      })
      .then(data => {
        setPageCount(Math.ceil(data.totalItems / data.itemsPerPage));
        if (currentPage !== data.page && firstRun !== true) {
          setCurrentPage(data.page);
        }
        // TODO: Massive assumption how the data comes out of the database
        // TODO: Re-organise
        const newProjects: ProjectListItemProps[] = data.items.map(item => {
          const project: ProjectListItemProps = {
            id: item.id,
            thumbnail: {
              alt: item.thumbnail.alt,
              href: item.thumbnail.href,
              image: item.thumbnail.image,
            },
            title: {
              text: item.title,
              href: item.uri,
            },
            location: item.location,
            projectStatus: item.projectStatus,
            ribbonColor: item.ribbonColor,
            ribbonLabel: item.ribbonLabel,
            projectIcon: item.projectIcon,
            lastModifiedDate: item.lastModifiedDate,
          };
          return project;
        });
        return newProjects;
      })
      .then(projects => {
        NProgress.done();
        setIsLoading(false);
        setSearchResults(projects);
        return projects;
      })
      .catch(reason => console.error(reason));
  };

  const eventHandler = ev => {
    // handle the event...
    setSearchQueryText(ev.target.value);
    //fetchQuery();
  };

  useEffect(() => {
    if (hasMounted) {
      fetchQuery();
    }
  }, [filters, currentPage]);

  useEffect(() => {
    if (isMounted.current) {
      // console.log('searchQueryText changed:', searchQueryText);
    }
  }, [searchQueryText]);

  useEffect(() => {
    if (hasMounted) {
      // prevent gsap timeline to be created multiple times
      return void 8;
    }
    setHasMounted(true);
    fetchQuery({ firstRun: true });
    isMounted.current = true;
  }, []);

  // throttle the text input search
  const throttledEventHandler = useMemo(() => throttle(eventHandler, 1000), []);

  // change filters
  const changeFilter = ({ isActive, value, type }) => {
    let newValues: string[];
    // are we adding or removing a filter
    if (isActive === true) {
      newValues = filters[type];
      // does to-be-added filter already exist?
      if (newValues.includes(value) === false) {
        // if not, add to the filter type's array of filters
        newValues.push(value);
      }
    } else {
      // remove filter from array
      newValues = filters[type].filter(val => val !== value);
    }
    // reset currentpage
    setCurrentPage(1);
    // update filter array state
    setFilters({
      ...filters, // copy all old values
      [type]: newValues, // overwrite with new values
    });
  };

  return (
    <div>
      <SearchContainerStyles>
        <Container>
          <div className="intro">
            <EpiProperty
              name={epiIntro.name}
              value={epiIntro.value}
              isEditMode={epiIntro.isEditMode}
              outerTag="p"
            />
          </div>
          <div className={`search ${isLoading ? 'loading' : ''}`}>
            <input
              ref={textInputEl}
              type="text"
              name="projectSearch"
              id="projectSearch"
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  setCurrentPage(1);
                  fetchQuery();
                }
              }}
              onChange={throttledEventHandler}
              placeholder="I am looking for ..."
              aria-label="I am looking for ..."
            />
            <div className="input__buttons">
              {isLoading ? (
                <LoadingStyles>
                  <Loaderizer />
                </LoadingStyles>
              ) : null}
              <button
                type="button"
                onClick={() => {
                  setSearchQueryText('');
                  textInputEl.current.value = '';
                  setCurrentPage(1);
                  fetchQuery();
                }}
                className={searchQueryText ? 'available clear' : 'clear'}
                aria-label='Clear'
              >
                <FaTimes />
              </button>
              <button
                type="button"
                onClick={() => {
                  setCurrentPage(1);
                  fetchQuery();
                }}
                aria-label='Search'
              >
                <FaSearch />
              </button>
            </div>
          </div>
          <div className="filters">
            <div className="filter">
              <h3>Filter by area</h3>
              <div>
                <div>
                  <input
                    type="checkbox"
                    name="areaFilter"
                    id="areaFilterMetro"
                    value="metropolitan"
                    checked={filters.area.includes('metropolitan')}
                    className="visually-hidden"
                    onChange={ev => {
                      changeFilter({
                        isActive: ev.target.checked,
                        value: ev.target.value,
                        type: 'area',
                      });
                    }}
                  />
                  <label htmlFor="areaFilterMetro">
                    <div className="filter__icon" />
                    <span>Metropolitan</span>
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="areaFilter"
                    id="areaFilterRegional"
                    value="regional"
                    checked={filters.area.includes('regional')}
                    className="visually-hidden"
                    onChange={ev => {
                      changeFilter({
                        isActive: ev.target.checked,
                        value: ev.target.value,
                        type: 'area',
                      });
                    }}
                  />
                  <label htmlFor="areaFilterRegional">
                    <div className="filter__icon" />
                    <span>Regional</span>
                  </label>
                </div>
              </div>
            </div>
            {projectStatusfilterList && (
              <div className="filter">
                <h3>Project status</h3>
                <div>
                  {projectStatusfilterList.map(statusFilter => (
                    <div>
                      <input
                        type="checkbox"
                        name="statusFilter"
                        id={statusFilter.id}
                        value={statusFilter.value}
                        checked={filters.status.includes(statusFilter.value)}
                        className="visually-hidden"
                        onChange={ev => {
                          changeFilter({
                            isActive: ev.target.checked,
                            value: ev.target.value,
                            type: 'status',
                          });
                        }}
                      />
                      <label htmlFor={statusFilter.id}>
                        <div className="filter__icon" />
                        <span>{statusFilter.name}</span>
                      </label>
                    </div>
                  ))}
                  {/* <div>
                    <input
                      type="checkbox"
                      name="statusFilter"
                      id="statusFilterPlanned"
                      value="planned"
                      checked={filters.status.includes('planned')}
                      className="visually-hidden"
                      onChange={ev => {
                        changeFilter({
                          isActive: ev.target.checked,
                          value: ev.target.value,
                          type: 'status',
                        });
                      }}
                    />
                    <label htmlFor="statusFilterPlanned">
                      <div className="filter__icon" />
                      <span>Planned</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="statusFilter"
                      id="statusFilterUnderConstruction"
                      value="under_construction"
                      checked={filters.status.includes('under_construction')}
                      className="visually-hidden"
                      onChange={ev => {
                        changeFilter({
                          isActive: ev.target.checked,
                          value: ev.target.value,
                          type: 'status',
                        });
                      }}
                    />
                    <label htmlFor="statusFilterUnderConstruction">
                      <div className="filter__icon" />
                      <span>Under Construction</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="statusFilter"
                      id="statusFilterCompleted"
                      value="completed"
                      checked={filters.status.includes('completed')}
                      className="visually-hidden"
                      onChange={ev => {
                        changeFilter({
                          isActive: ev.target.checked,
                          value: ev.target.value,
                          type: 'status',
                        });
                      }}
                    />
                    <label htmlFor="statusFilterCompleted">
                      <div className="filter__icon" />
                      <span>Completed</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="statusFilter"
                      id="statusFilterLocalInitiative"
                      value="local_initiative"
                      checked={filters.status.includes('local_initiative')}
                      className="visually-hidden"
                      onChange={ev => {
                        changeFilter({
                          isActive: ev.target.checked,
                          value: ev.target.value,
                          type: 'status',
                        });
                      }}
                    />
                    <label htmlFor="statusFilterLocalInitiative">
                      <div className="filter__icon" />
                      <span>Local Initiative</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="statusFilter"
                      id="statusFilterConsultation"
                      value="consultation"
                      checked={filters.status.includes('consultation')}
                      className="visually-hidden"
                      onChange={ev => {
                        changeFilter({
                          isActive: ev.target.checked,
                          value: ev.target.value,
                          type: 'status',
                        });
                      }}
                    />
                    <label htmlFor="statusFilterConsultation">
                      <div className="filter__icon" />
                      <span>Consultation</span>
                    </label>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </Container>
      </SearchContainerStyles>
      <SearchResultsContainerStyles>
        <Container>
          <SearchResultsGrid>
            {searchResults?.map(project => (
              <ProjectListItem key={project.id} {...project} />
            ))}
          </SearchResultsGrid>
          <Pagination
            forcePage={currentPage - 1}
            pageCount={pageCount}
            skipHrefUpdate={true}
            onPageChange={(val: any) => {
              document.getElementById('main-content').scrollIntoView({
                behavior: 'smooth',
              });
              return setCurrentPage(val.selected + 1);
            }}
          />
          {searchResults.length === 0 && (
            <p>
              No projects found for your query, please try a different
              search.
            </p>
          )}
        </Container>
      </SearchResultsContainerStyles>
    </div>
  );
};

export default ProjectsSearch;
