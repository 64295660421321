import { css } from 'styled-components';

const EPIForm = css`
  .form__button,
  .form__input-group__map,
  .FormLocationElement__Address,
  .form__input-group__text-area,
  .form__input-group__input {
    width: 100%;
  }
  .form__input-group__components {
    flex-direction: column;
  }
  .EPiServerForms .FormDateTime .FormDateTime__Input {
    width: 100% !important;
  }
  .ui-datepicker {
    .ui-datepicker-prev,
    .ui-datepicker-next {
      span {
        height: 100%;
        padding: 0;
        margin: 0;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        line-height: 1;
        color: transparent;
        &:after {
          color: white;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 2em;
        }
      }
    }
    .ui-datepicker-prev {
      span {
        &:after {
          content: '«';
        }
      }
    }
    .ui-datepicker-next {
      span {
        &:after {
          content: '»';
        }
      }
    }
  }
  .ui-icon {
    .ui-icon-circle-triangle-e {
    }
  }

  @media (min-width: 62rem) {
    .form__input-group__map,
    .FormLocationElement__Address,
    .form__input-group__text-area {
      width: 75%;
    }
    .form__input-group__input {
      width: 50%;
    }
    .EPiServerForms .FormDateTime .FormDateTime__Input {
      width: 15rem !important;
    }
    .form__button {
      width: auto;
    }
    .form__input-group__components {
      flex-direction: row;
    }
  }
`;

export default EPIForm;
