import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

import Button from '../../atoms/Button';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';
import LinkStepBack from '../../blocks/LinkStepBack';
import { StyledMainWithSideBar } from '../../../lib/StyledMainWithSideBar';

type StyledPageLayoutProp = {
  phaseColor: string;
};

export const StyledPageLayout = styled.div<StyledPageLayoutProp>`
  ${StyledMainWithSideBar({ flow: 'aside-main', element: 'main' })};
  .page-layout__main {
    padding: 3.75rem 0;
    background-color: transparent;
    &:after {
      content: none;
    }
    article {
      margin: 0 0 ${theme.spacing.sm} 0;
    }

    .section-heading-wrap {
      margin-bottom: ${theme.spacing.md};
      .phase-indicator {
        position: relative;
        top: 2px;
        display: inline-block;
        width: 35px;
        height: 35px;
        border-radius: 40px;
        background-color: ${(props): string => props.phaseColor};
        margin-right: 15px;
      }
      h1 {
        display: inline-block;
        margin-bottom: 0;
      }
      p {
        margin: 0;
        span {
          font-weight: ${theme.fontWeight.bold};
        }
      }
      .number-submissions {
        font-size: 1.4rem;
        font-weight: ${theme.fontWeight.bold};
        margin-top: 10px;
      }
    }

    .form-wrap {
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      margin: ${theme.spacing.lg} 0 ${theme.spacing.sm};
      border: 1px solid ${theme.colors.formBorder};
    }

    .form__container {
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      margin: ${theme.spacing.lg} 0 ${theme.spacing.sm};
      border: 1px solid ${theme.colors.formBorder};
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: ${theme.fontWeight.black};
    margin-bottom: ${theme.spacing.sm};
  }

  .page-layout__aside {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 1;
    padding-bottom: ${theme.spacing.md};
    padding-right: ${theme.spacing.sm};
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      background-color: ${theme.colors.backgroundLight};
    }
  }

  .project-detail {
    &--label {
      margin: 0;
      font-size: 0.9em;
    }
    &--data {
      margin: 0 0 0.7rem;
      font-weight: ${theme.fontWeight.bold};
    }
  }
`;

export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.sm} 0;
  ${breakpoint.greaterThan('sm')`
    margin: ${theme.spacing.md} 0 ${theme.spacing.md} 0;
  `};

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.lg} 0 ${theme.spacing.md} 0;
  `}
`;

export const StyledButton = styled(Button)`
  margin-top: ${theme.spacing.sm};
  background-color: ${theme.colors.primaryAlt};
  &:hover {
    background-color: ${theme.colors.buttonBg};
  }
`;

export const StyledSubmitCommentButton = styled(Button)`
  margin-top: ${theme.spacing.sm};
  background-color: ${theme.colors.buttonToggle};
  &:hover {
    background-color: ${theme.colors.buttonBg};
  }
`;

export const StyledLinkStepBack = styled(LinkStepBack)`
  border: none;
  &:hover {
    border: none;
  }
  ${breakpoint.greaterThan('sm')`
    grid-column: span 2;
  `};

  ${breakpoint.greaterThan('md')`
    grid-column: auto;
  `};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;
