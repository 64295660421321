/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageSummary from '../../atoms/PageSummary';
import PageTitle from '../../atoms/PageTitle';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import TravelMapDashboard, {
  ITravelMapDashboard,
} from '../../organisms/TravelMapDashboard';
import { StyledTravelMapDashboard } from './styles';

interface TravelMapPageProps {
  breadcrumbs?: PageTemplateProps['breadcrumbs'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  padding: boolean;
  epiTitle: EpiProps;
  epiSummary?: EpiProps;
  epiMainContentArea: EpiProps;
  alert?: PageTemplateProps['alert'];
  googleMapsApiKey: string;
  mapConfigurationUrl: string;
}

function TravelMapPage({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  epiSummary,
  epiMainContentArea,
  padding = true,
  alert,
  googleMapsApiKey,
  mapConfigurationUrl,
}: TravelMapPageProps): ReactElement {
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      ctaBlock={ctaBlock}
      header={header}
      footer={footer}
      alert={alert}
    >
      <Container padding={padding}>
        <PageTitle>
          <EpiProperty
            name={epiTitle.name}
            value={epiTitle.value}
            isEditMode={epiTitle.isEditMode}
            outerTag="span"
          />
        </PageTitle>
        {epiSummary && (
          <PageSummary>
            <EpiProperty
              name={epiSummary.name}
              value={epiSummary.value}
              isEditMode={epiSummary.isEditMode}
              outerTag="p"
            />
          </PageSummary>
        )}
      </Container>
        <StyledTravelMapDashboard>
          <TravelMapDashboard googleMapsApiKey={googleMapsApiKey} mapConfigurationUrl={mapConfigurationUrl}></TravelMapDashboard>
        </StyledTravelMapDashboard>
      <Container padding={padding}>
        <EpiProperty
          name={epiMainContentArea.name}
          value={epiMainContentArea.value}
          isEditMode={epiMainContentArea.isEditMode}
          outerTag="div"
        />
      </Container>
    </PageTemplate>
  );
}

export default TravelMapPage;
