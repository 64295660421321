import React, { ReactElement } from 'react';

import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTitle from '../../atoms/PageTitle';
import { StyledErrorHero } from '../../molecules/ErrorHero';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';

type ErrorPageProps = {
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  message: string;
  status: number;
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  epiMainContentArea: EpiProps;
  alert?: PageTemplateProps['alert'];
};

function ErrorPage({
  ctaBlock,
  footer,
  epiTitle,
  message,
  status,
  header,
  epiMainContentArea,
  alert,
}: ErrorPageProps): ReactElement {
  // TODO: decide whether we use an Epi Content Area or we hardcode (components) in search etc...
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <StyledErrorHero>
        <Container>
          <div className="error-hero__inner">
            <div className="error-hero__status">
              <span>{status}</span>
            </div>
            <div className="error-hero__message">
              <PageTitle>
                <EpiProperty
                  name={epiTitle.name}
                  value={epiTitle.value}
                  isEditMode={epiTitle.isEditMode}
                  outerTag="h1"
                />
              </PageTitle>
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          </div>
        </Container>
      </StyledErrorHero>
      <Container>
        <EpiProperty
          name={epiMainContentArea.name}
          value={epiMainContentArea.value}
          isEditMode={epiMainContentArea.isEditMode}
          outerTag="div"
        />
      </Container>
    </PageTemplate>
  );
}

export default ErrorPage;
