import styled, { css } from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

type CanOpenProp = {
  isOpen: boolean;
};

export const StyledLeftMenuWidget = styled.aside<CanOpenProp>`
  position: relative;
  min-height: 50vh;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${props => breakpoint.lessThan('md')`
    position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 83%;
    height: 100%;
    justify-content: flex-start;
    transition: 1000ms cubic-bezier(0.32, 0.01, 0.15, 0.99);
    transform: translate(${props.isOpen ? '0%' : '-100%'});
  `};

  ${breakpoint.greaterThan('md')`
    width: calc(25% - 1.5rem);
    margin-right: 1.5rem;
  `}
`;

export const StyledLeftMenuContent = styled.div`
  padding-top: 50px;
  width: 100%;
  h2 {
    margin-top: 0;
    margin-bottom: 0.26em;
    padding-left: 24px;
    font-size: 1.275em;
    font-weight: 400;
    span {
      a {
        color: #000;
      }
    }

    ${breakpoint.greaterThan('md')`
    +
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
      margin-bottom: 0.25em;
      padding-left: 0px;
      span {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        max-width: 400px;
        padding-right: 25px;
      }
    `};

    ${breakpoint.greaterThan('container')`
      span {
        max-width: 400px;
      }
    `};
  }
  ul {
    margin-bottom: 60px;
    padding-left: 0;
  }
  li {
    display: block;
    margin: 0;
    padding: 8px 0px 7px 24px;
    ${breakpoint.greaterThan('md')`
      display: flex;
      padding: 8px 0px 8px 0;
      justify-content: flex-end;
    `};
    &:hover,
    &.active {
      background-color: ${theme.colors.linkHover};
      position: relative;
      a {
        color: #fff;
        border: none;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 1.5rem;
        background-color: ${theme.colors.linkHover};
        ${breakpoint.greaterThan('container')`
          width: 100%;
          width: calc((100vw - 1512px) * 0.5);
        `}
      }
    }
    a {
      display: block;
      color: inherit;
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: flex-start;
      font-size: 0.875em;
      font-weight: 300;
      border: none;
    }
    ${breakpoint.greaterThan('container')`
      a {
        max-width: 400px;
      }
    `};
  }
`;

export const StyledToggleButton = styled.button<CanOpenProp>`
  display: none;
  ${props => breakpoint.lessThan('md')`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    cursor: pointer;
    ${
      props.isOpen
        ? css`
            right: 0;
            margin-top: 1em;
            margin-right: 1em;
            border: 2px solid #054e66;
            border-radius: 50%;
            background-color: transparent;
            color: #054e66;
            font-size: 0.55em;
            width: 2em;
            height: 2em;
            &:hover {
              color: ${theme.colors.linkHover};
              border-color: ${theme.colors.linkHover};
            }
          `
        : css`
            left: 100%;
            background-color: #054e66;
            color: #fff;
            width: 2em;
            height: 2em;
            margin-top: 0.625em;
          `
    }
  `};
`;
