import styled from 'styled-components';
import { breakpoint } from '../../../lib/theme';

export const PageLayoutContentStyles = styled.div`
  position: relative;
  padding-bottom: 50px;
  &:not(:first-of-type) {
    padding-top: 50px;
  }
  &.highlighted,
  .highlighted & {
    background-color: #f5f5f5;
    padding-right: 50px;
    &:after {
      background-color: #f5f5f5;
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 1.5rem;
      height: 100%;
    }
    ${breakpoint.greaterThan('lg')`
      &:after {
        content: none;
      }
    `};
    &:before {
      background-color: #f5f5f5;
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 1.5rem;
      height: 100%;

      ${breakpoint.greaterThan('container')`
        width: 100%;
      `};
    }
  }
`;

export const StyledRightColumnLayout = styled.div`
  .page-layout__inner {
    display: flex;
    flex-direction: column;

    ${breakpoint.greaterThan('lg')`
      flex-wrap: wrap;
      flex-direction: row;
    `};
  }

  aside {
    width: 100%;

    ${breakpoint.greaterThan('lg')`
      margin-left: 1.875rem;
      width: calc(25% - 1.875rem);
    `}
  }

  &.layout__right-column--top {
    ${breakpoint.lessThan('lg')`
      aside {
        order: 0;
      }

      .page-layout__main {
        order: 1;
      }
    `}
  }

  .page-layout__main {
    .form__container {
      margin-top: 0;
      margin-left: 0;
      width: 100%;
    }
    ${breakpoint.greaterThan('lg')`
      margin-right: 1.875rem;
      width: calc(75% - 1.875rem);
    `}
  }

  @supports (display: grid) {
    .page-layout__inner {

      ${breakpoint.greaterThan('lg')`
        display: -ms-grid;
        display: grid;
        -ms-grid-template-columns: 3fr 33%;
        grid-template-columns: 3fr 33%;
        grid-gap: 50px;
        gap: 50px;
      `};
      ${breakpoint.greaterThan('xl')`
        -ms-grid-template-columns: 3fr 25%;
        grid-template-columns: 3fr 25%;
        grid-gap: 150px;
        gap: 150px;
      `}
      /* ${breakpoint.greaterThan('md')`
        display: -ms-grid;
        display: grid;
        -ms-grid-template-columns: 3fr 25%;
        grid-template-columns: 3fr 25%;
        grid-gap: 50px;
        gap: 50px;
        `}
      ${breakpoint.greaterThan('lg')`
        grid-gap: 150px;
        gap: 150px;
      `}; */
    }

    .page-layout__main,
    aside {
      padding-bottom: 50px;
      ${breakpoint.greaterThan('lg')`
        margin: 0;
        width: auto;
      `}
    }
  }
`;
