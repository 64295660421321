import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

const StyledDocumentListItem = styled.article`
  display: flex;
  position: relative;
  padding: 1.5rem;
  background-color: ${theme.colors.documentListItemBg};

  ${breakpoint.greaterThan('md')`
    display: flex;
  `};

  .document-list-item__icon img {
    margin: 0 1rem 0 0;
  }

  .document-list-item__title {
    margin: 0 0 1rem 0;
    padding: 0;
    font-size: 1.25rem;

    ${breakpoint.greaterThan('lg')`
      font-size: 1.5rem;
    `}
    a {
      display: inline-block;
      font-weight: ${theme.fontWeight.bold};
      color: ${theme.colors.documentListItemText};
      transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
      border: none;

      &:hover {
        border: none;
        color: ${theme.colors.linkHover};
      }
    }
    span {
      display: inline-block;
      color: ${theme.colors.documentListItemText};
    }
  }

  .document-list-item__breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin: ${theme.spacing.xs} 0 -${theme.spacing.xs} 0;
    padding: 0;

    li {
      position: relative;
      display: inline-block;
      margin: 0 ${theme.spacing.sm} ${theme.spacing.xs} 0;

      &:after {
        position: absolute;
        top: 50%;
        right: -1rem;
        width: 0.6rem;
        height: 0.6rem;
        content: '';
        background-image: url('/images/chevron-right-solid.svg');
        background-repeat: no-repeat;
        transform: translateY(-35%);
      }

      &:last-of-type a {
        border-bottom: none;
      }

      &:last-of-type:after {
        display: none;
      }
    }

    a {
      color: ${theme.colors.documentListItemText};
      font-size: 0.875rem;
      border: none;
      font-weight: ${theme.fontWeight.regular};
      transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      ${breakpoint.greaterThan('md')`
        font-size: 1rem;
      `}

      ${breakpoint.greaterThan('lg')`
        font-size: 1.125rem;
      `}

      &:hover {
        border: none;
        color: ${theme.colors.linkHover};
      }
    }
  }

  .document-list-item__modified {
    margin: 0 0 1.125rem 0;
    color: ${theme.colors.documentListItemDate};
    font-size: 0.875rem;

    ${breakpoint.greaterThan('md')`
      font-size: 1rem;
    `}

    ${breakpoint.greaterThan('lg')`
      font-size: 1.125rem;
    `}
  }

  .document-list-item__description {
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 1.75em;
    ${breakpoint.greaterThan('md')`
      font-size: 1rem;
    `}

    ${breakpoint.greaterThan('lg')`
      font-size: 1.125rem;
    `}
  }

  .document-list-item__downloads {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    ${breakpoint.greaterThan('md')`
      margin: 0 0 -${theme.spacing.sm} 0;
    `}

    li {
      display: inline-block;
      margin: 0 ${theme.spacing.md} ${theme.spacing.sm} 0;
      list-style: none;
    }

    a {
      display: flex;
      align-items: center;
      color: ${theme.colors.documentListItemText};
      font-size: 0.875rem;
      font-weight: ${theme.fontWeight.bold};
      transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
      border: none;
      text-decoration: underline;

      ${breakpoint.greaterThan('sm')`
        text-decoration: none;
      `}

      ${breakpoint.greaterThan('md')`
        font-size: 1rem;
      `}

      ${breakpoint.greaterThan('lg')`
        font-size: 1.125rem;
      `}

      &:hover {
        color: ${theme.colors.linkHover};
        border: none;
      }

      span {
        margin: 0 ${theme.spacing.xs} 0 0;

        ${breakpoint.greaterThan('md')`
          display: inline-block;
          align-items: center;
          font-size: 1.5625rem;
        `}

        ${breakpoint.greaterThan('lg')`
          font-size: 2.1875rem;
        `}
      }
    }
  }
`;

export default StyledDocumentListItem;
