/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import theme, { breakpoint, HEIGHT_BREAKPOINT } from '../../../lib/theme';
import { ContainerStyles } from '../../atoms/Container';
import NavBreadcrumbs from '../NavBreadcrumbs';

export const StyledPageHeroNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: 0;
  padding: 0;
`;

export const StyledPageHero = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${theme.colors.backgroundDark};
  &.title-only{
    background-color: #ffffff;
  }
  ${breakpoint.greaterThan('md')`
    &:not(.title-only){
      min-height: 450px;
      @media screen and (min-height: ${HEIGHT_BREAKPOINT}px) {
        min-height: 650px;
      }
    }
  `}
  .page-hero__container--outer{
    ${ContainerStyles}
  }
  .page-hero__title {
    margin: 0;
    font-size: 1.75rem;
    font-weight: ${theme.fontWeight.black};
    margin-bottom: 0.25em;
    em{
      font-style: normal;
      color: ${theme.colors.titleColorEmphasis};
    }

    ${breakpoint.greaterThan('md')`
      font-size: 2rem;
    `}
  }
  &.title-only{
    .page-hero__title {
      font-size: 1.75em;
      ${breakpoint.greaterThan('md')`
        font-size: 3.75em;
      `}
    }
    .page-hero__container {
      width: 100%;
      padding: 16px 1.5rem 0px;
      ${breakpoint.greaterThan('md')`
        padding: 32px 1.5rem 0px;
      `}
    }
  }
  
  &:not(.title-only) {
    .page-hero__container {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5em 1em 3em;
      z-index: 200;

      ${breakpoint.greaterThan('md')`
        padding-right: 0;
        width: 33vw;
        min-width: 500px;
        flex-direction: column;
      `}
      ${breakpoint.greaterThan('lg')`
      `}
      ${breakpoint.greaterThan('container')`
        // padding-left: 124px;
      `};
    }
  }


  .page-hero__caption {
    position: relative;
    min-width: 100%;
    background-color: #2D2F3265;
    z-index: 999;
    padding: 0.5rem 1rem;

    p {
      color: #ffffff;
      span {
        font-size: 0.9rem;
      }
      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }

    &>.page-hero__container--outer{
      display: flex;
      justify-content: space-between;
    }

    .page-hero__controls {
      display: flex;
      flex-direction: row;
      padding: 0.8rem;
      z-index: 999;
      justify-content: center;
      align-items: center;

      ${breakpoint.greaterThan('sm')`
        justify-content: flex-end;
      `}

      button, a {
        all: unset;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        width: 2.8rem;
        height: 2.8rem;
        margin: 0 0.25rem;
        background-color: #2D2F3200;
        transition-property: background-color;
        transition-duration: 300ms;
        transition-timing-function: ease-out;

        :hover {
          background-color: #2D2F3290;
        }

        ${breakpoint.greaterThan('sm')`
          margin: 0 0 0 0.5rem;
        `}

        svg {
          width: 1.1rem;
          height: 1.1rem;
          fill: #ffffff;
        }
      }
    }
  }

  .page-hero__content {
    padding: 2em 1.5em;
    background-color: #ffffff;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    max-width: 500px;

    .ribbon{
      background-color: #000000;
      &.blue{
        background-color: #0A4D66;
      }
      &.green{
        background-color: #008072;
      }
      &.red{
        background-color: #D14210;
      }
      &.black{
        background-color: #000000;
      }
      position: relative;
      border-radius: 0px 12px 12px 0px;
      padding: 0.5em 0.5em 0.5em 0;
      ${breakpoint.greaterThan('sm')`
        padding: 0.9376em 0.9376em 0.9376em 0;
      `}
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      margin-top: 16px;
      margin-bottom: 32px;
      min-width: 66% ;
      img {
        display: block;
        width: 2em;
        height: 2em;
      }
      span {
        color: #ffffff;
        font-weight: 600;
        font-size: 1em;
        ${breakpoint.greaterThan('sm')`
          font-size: 1.25em;
        `}
      }
      &:before{
        content: '';
        background-color: inherit;
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 1.5em;
        ${breakpoint.greaterThan('md')`
          width: 2em;
        `}
      }



    }

    ${breakpoint.greaterThan('md')`
      /*max-width: none;*/
      padding: 2em;
      // transform: translate(125px, 0);
    `}
    ${breakpoint.greaterThan('lg')`
    `}

    ${breakpoint.greaterThan('container')`
      transform: none;
    `}

    h2,
    p.subtitle{
      font-size: 1.25em;
      margin-top: 0;
      margin-bottom: 0.25em;
      ${breakpoint.greaterThan('sm')`
        // font-size: 2.75em;
      `};
      ${breakpoint.greaterThan('md')`
      `}
    }

  }
  .page-hero__content-description{
    flex: 1;
    p {
      margin: 0;
      color: #2D2F32;
      font-weight: 400;
      ${breakpoint.greaterThan('md')`
        font-size: 1em;
      `}

      ${breakpoint.greaterThan('lg')`
        font-size: 1em;
      `}
    }
  }

  .page-hero__article {
    background-color: #008072;
    color: #ffffff;
    padding: 1.25em 1.4375em;
    border-radius: 16px;
    ${breakpoint.greaterThan('sm')`
      padding: 1.875em 1.875em ;
    `}
  }

  .page-hero__social-links {
    padding-top: ${theme.spacing.sm};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.375em;

    ${breakpoint.greaterThan('sm')`
      gap: 0.875em;
    `}

    span {
      font-size: 1.1rem;
      color: ${theme.colors.backgroundDark};
      font-weight: ${theme.fontWeight.bold};

      ${breakpoint.greaterThan('sm')`
        font-size: 1.125rem;
      `}
    }

    ul {
      display: inline;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline;
      padding: ${theme.spacing.xxs};

      a {
        font-size: 1.5625em;
        color: ${theme.colors.textDark};
        border: none;
        transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

        &:hover {
          color: ${theme.colors.linkHover};
          border: none;
        }
        svg {
          font-size: 1em;
          width: 1em;
          height: 1em;
        }
      }
    }
  }

  .page-hero__image {
    .page-hero__player{
      display: block;
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: 120;
      ${breakpoint.greaterThan('md')`
        width: 75vw!important; 
      `}
      video {
        object-fit: cover;
      }
      &.is-youtube{
        overflow: hidden;
        &:after{
          content: '';
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 200;
          width: 100%;
          height: 100%;
        }
        iframe {
          z-index: 100;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          width: 1280px;
          @media screen and (min-width: 1516px) {
            width: 100%;
            height: max(650px, 56.25vw);
          }
        }
      }
    }
    &.full-width-background{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
      }
      .page-hero__player{
        width: 100% !important;
      }
    }
    z-index: 100; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* height: inherit; */
    min-height: 600px;
    ${breakpoint.greaterThan('md')`
      // position: relative;
      flex: 1;
      min-height: 600px;
    `}
    img{
      display: block;
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: 110; 
      @supports (object-fit: cover) {
      }
      ${breakpoint.greaterThan('md')`
        width: 75vw;
      `}
    }
  }
`;
