import styled from 'styled-components';

export const StyledAccordionContainerBlock = styled.div`
  > h2 {
    font-size: 1.5em;
  }
  .accordion {
    border-radius: 0;
    border: none;
  }
`;
