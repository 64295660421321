import React, { ReactElement } from 'react';
import FileLink, { FileLinkProps } from '../FileLink';
import StyledList from './styles';
import { BaseBlockProps } from '../../../types'

// Files download list
export interface FileLinksListProps extends BaseBlockProps {
  list: FileLinkProps[];
};

const FileLinksList = ({ list, anchorId }: FileLinksListProps): ReactElement => {
  return (
    <StyledList id={anchorId}>
      {list.map((item, i) => {
        return (
          <li key={i}>
            <FileLink
              href={item.href}
              filetype={item.filetype}
              label={item.label}
            />
          </li>
        );
      })}
    </StyledList>
  );
};

export default FileLinksList;
