import React, { ReactElement } from 'react';
import ReactPaginate from 'react-paginate';

import { StyledPagination } from './styles';

export interface PaginationProps {
  forcePage?: number; // To override selected page with parent prop.
  pageCount: number; // Required. The total number of pages.
  onPageChange: any;
  skipHrefUpdate?: boolean;
}

function Pagination({
  forcePage,
  pageCount,
  onPageChange,
  skipHrefUpdate = false
}: PaginationProps): ReactElement {
  if (pageCount <= 1) {
    return null;
  }
  const onPageChangeHandler = ev => {
    /* Scroll to top code:
    document.getElementById('main-content').scrollIntoView({
      behavior: 'smooth',
    }); 
    */
    // history.push()
    // console.log('onPageChangeHandler', ev);
    if (!skipHrefUpdate)
    {
      if (window && window?.location && window?.history && window?.history?.pushState) {
        const url = new URL(window.location.href);
        const pageNumber = ev.selected + 1;

        url.searchParams.set("page", pageNumber);

        console.log(url.href)
        history.pushState({},'',url.href);
      }
    }
    onPageChange(ev);
  };
  return (
    <StyledPagination>
      <ReactPaginate
        forcePage={forcePage}
        pageCount={pageCount}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        onPageChange={onPageChangeHandler}
      />
    </StyledPagination>
  );
}

export default Pagination;
