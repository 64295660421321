import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const ContentListBlockStyles = styled.div`
  padding-top: 60px;
  padding-bottom: 40px;
  ${breakpoint.greaterThan('sm')`
    padding-bottom: 64px;
  `}
  &.do-show-background {
    background: #f5f5f5;
  }
`;
export const ContentListBlockHeadingStyles = styled.div`
  a {
    color: ${theme.colors.textDark};
    border: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledReadMoreLink = styled.a`
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 1em;
  color: ${theme.colors.linkColor};
  display: none;
  &.mobile {
    display: block;
  }
  ${breakpoint.greaterThan('sm')`
    font-size: 1.5em;
  `}
  ${breakpoint.greaterThan('md')`
    display: block;
    &.mobile {
      display: none;
    }
  `};
`;
