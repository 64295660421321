import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';

export const StyledPageTemplate = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .site__content {
    flex: 1 0 auto;
  }

  a.skip-link {
    position: absolute;
    background: ${theme.colors.primary};
    border-radius: 0 0 0.5rem 0.5rem;
    color: #ffffff;
    left: 50%;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    transform: translateX(-50%);
    z-index: 200;

    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;

    &:hover {
      color: #fff;
    }

    &:focus {
      overflow: visible;
      clip: auto;
      width: auto;
      height: auto;
    }
  }
`;
export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.md} 0;

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.sm} 0;
  `}
`;
