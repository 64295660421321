import React, { ReactElement } from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { BaseBlockProps } from '../../../types'

interface WYSIWYGBlockProps extends BaseBlockProps {
  name?: EpiProps;
  content: EpiProps;
};

// for now this block has full width, it would have a parent block where the container
// component will be used and grids will be set.
function WYSIWYGBlock({ content, anchorId }: WYSIWYGBlockProps): ReactElement {
  return (
    <div id={anchorId}>
      <EpiProperty
        name={content.name}
        value={content.value}
        isEditMode={content.isEditMode}
        isHTMLContent={content.isHTMLContent}
        outerTag="div"
      />
    </div>
  );
}

export default WYSIWYGBlock;
