import styled from 'styled-components';

import { breakpoint } from '../../../lib/theme';

export const StyledContentListingGroup = styled.div`
  display: -ms-grid;
  display: grid;
  gap: 1.25em;
  padding-bottom: 20px;
  ${breakpoint.greaterThan('sm')`
    &:not(.compact){
      &.size-sm{
        -ms-grid-template-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
      &.size-md{
        -ms-grid-template-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;

      }
      &.size-lg{
        -ms-grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  `};

  .content-list__item {
    display: -ms-grid;
    display: grid;
    > div {
      height: 100%;
    }
  }
`;
