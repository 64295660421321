import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useState } from 'react';

import LeftMenuWidget, { LinkListProps } from '../LeftMenuWidget';
import { StyledSidebar, StyledToggleButton } from './styles';

type SidebarProps = {
  className?: string;
  children: any;
  links?: LinkListProps[];
  mobilebreakpoint?: 'sm' | 'md' | 'lg';
};
const Sidebar = ({
  className,
  children,
  links,
  mobilebreakpoint = 'md',
}: SidebarProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false); // is sidebar open or collapsed (mobile)

  const onSlideToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledSidebar
      className={className}
      mobilebreakpoint={mobilebreakpoint}
      isOpen={isOpen}
    >
      <StyledToggleButton
        mobilebreakpoint={mobilebreakpoint}
        onClick={onSlideToggle}
        isOpen={isOpen}
        aria-label={isOpen ? 'Close' : 'Open'}
      >
        {isOpen ? (
          <span>
            <FontAwesomeIcon icon="times" />
          </span>
        ) : (
          <FontAwesomeIcon icon="chevron-right" />
        )}
      </StyledToggleButton>
      {links && <LeftMenuWidget links={links} />}
      {children}
    </StyledSidebar>
  );
};

export default Sidebar;
