import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

type InputStyledProps = {
  iconColor: string;
  iconBgColor: string;
}

export const InputStyled = styled.span<InputStyledProps>`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  input, select {
    position: relative;
    background: transparent;
    border: 0px;
    padding: 0.85em 1.1em;
    width: 100%;
    color: #333333;
    border-radius: 8px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #33333350;
    }

    &::-webkit-clear-button,
    &::-webkit-inner-spin-button {
        display: none;
    }

    &::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
    }
    &[type="date"] {
      color: #33333350;
      &.filled {
        color: #333333;
      }
    }
  }
  select {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2rem;
    /** for the dropdown indicator */
    appearance: none;
    //background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8.561' fill='none' stroke='#6f7070' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M31.468,32.416l-7.043,7.043-7.043-7.043a.564.564,0,0,0-.8.8l7.442,7.419a.567.567,0,0,0,.8,0l7.442-7.442a.582.582,0,0,0,0-.8A.56.56,0,0,0,31.468,32.416Z' transform='translate(-16.425 -32.237)'%3e%3c/path%3e%3c/svg%3e");
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .input__body {
      border: 1px solid #B7B7B7;
      border-radius: 8px;
      overflow: hidden;
      &:focus-within {
        outline: 2px solid ${theme.colors.inputFocus};
      }
  }

  .input__label {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    position: absolute;
  }

  .input__icon {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0em 0.7em;
    color: ${props => props.iconColor};
    svg {
      width: 1.8em;
      height: 1.8em;
    }
  }

  &.input__main--lockup {
    border-radius: 8px;
    &:focus-within {
      outline: 2px solid ${theme.colors.inputFocus};
    }
    .input__body {
      border: 0px solid #B7B7B7;
      border-radius: 0px;
      &:focus-within {
        outline: 0px solid ${theme.colors.inputFocus};
      }
    }
    .input__icon {
      padding: 0em 1em;
      border-radius: 8px 0px 0px 8px;
      background-color: ${props => props.iconBgColor};
      svg {
        width: 1.2em;
        height: 1.2em;
      }
    }
    .input__body {
      border-radius: 0px 8px 8px 0px;
      border: 1px solid #B7B7B7;
    }
  }
`;
