import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';

const StyledPageTitle = styled.h1`
  a {
    border: none;
    &:hover {
      border: none;
    }
  }
  em {
    font-style: normal;
    color: ${theme.colors.titleColorEmphasis};
  }
`;

type PageTitleProps = {
  children: ReactNode;
};

function PageTitle({ children }: PageTitleProps): ReactElement {
  return <StyledPageTitle>{children}</StyledPageTitle>;
}

export default PageTitle;
