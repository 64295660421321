import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.headerBg};
`;

const StyledGovLink = styled.a`
  width: 23%;
  border: none;

  &:hover {
    border: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledMRWALink = styled.a`
  width: 70%;
  border: none;

  &:hover {
    border: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export interface LogoProps {
  className?: string;
  govLink: {
    alt: string;
    href: string;
    image: string;
    target?: string;
    title: string;
  };
  mrwaLink: {
    alt: string;
    href: string;
    image: string;
    target?: string;
    title: string;
  };
}

function Logo({ className, govLink, mrwaLink }: LogoProps): ReactElement {
  return (
    <StyledLogo className={className}>
      <StyledGovLink
        href={mrwaLink.href}
        target={mrwaLink.target}
        rel={mrwaLink.target === '_blank' ? 'noopener noreferrer' : ''}
        title={mrwaLink.title}
        aria-label={mrwaLink.title}
      >
        <img
          src={govLink.image}
          alt={govLink.alt || 'Government of Western Australia Logo'}
        />
      </StyledGovLink>
      <StyledMRWALink
        href={mrwaLink.href}
        target={mrwaLink.target}
        rel={mrwaLink.target === '_blank' ? 'noopener noreferrer' : ''}
        title={mrwaLink.title}
        aria-label={mrwaLink.title}
      >
        <img
          src={mrwaLink.image}
          alt={mrwaLink.alt || 'Main Roads Western Australia Logo'}
        />
      </StyledMRWALink>
    </StyledLogo>
  );
}

export default Logo;
