import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledTitle = styled.h2`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: ${theme.spacing.sm};
  font-size: 1.25em;
  font-weight: 500;

  ${breakpoint.greaterThan('sm')`
    font-size: 1.5em;
  `}

  a {
    font-size: 1em;
    display: block;
    font-weight: inherit;
    color: inherit;
    border: none;
    transition: color 0.3s ease-in-out;

    &:after {
      content: '\f054';
      display: inline;
      font-family: 'Font Awesome 5 Free';
      font-size: 0.75em;
      text-align: right;
      padding-left: 0.65em;
      font-weight: 900;
    }
    &:hover {
      border: none;
      color: ${theme.colors.linkHover};
      &:after {
        content: '\f061';
        padding-left: 0.33em;
      }
    }
  }
  svg {
    margin-left: 0.5em;
    font-size: 0.6em;
  }
`;

export const StyledLinkList = styled.div`
  /* flex: 1 0 auto; */
  display: flex;
  flex-direction: column;
  background: ${theme.colors.backgroundLight};
  padding: 2rem;
  margin: 0 0 ${theme.spacing.xs} 0;
  > p {
    margin: 0 0 ${theme.spacing.sm};
  }

  ${breakpoint.greaterThan('sm')`
    margin: 0 ${theme.spacing.xs} 75px 0;
  `}
  ${breakpoint.greaterThan('md')`
    margin-bottom: 150px;
  `}

  &:last-child {
    margin: 0;
  }
`;

export const StyledSubtitle = styled.p`
  margin: 0 0 ${theme.spacing.sm};
  font-size: 1.125em;
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
export const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
  &:not(:last-child) {
    margin-bottom: ${theme.spacing.xs};
  }
  a {
    color: ${theme.colors.textMid};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    border: none;
    transition: color 0.3s ease-in-out;

    &:after {
      content: '\f054';
      display: inline;
      font-family: 'Font Awesome 5 Free';
      font-size: 0.75em;
      text-align: right;
      font-weight: 900;
      padding-left: 0.65em;
    }
    &:hover {
      &:after {
        content: '\f061';
        padding-left: 0.33em;
      }
    }
    &:hover {
      border: none;
      color: ${theme.colors.primary};
    }
  }
`;
