import styled from 'styled-components';
import theme from '../../../lib/theme';

export const StyledFilterList = styled.div`
  h2 {
    font-size: 1.5rem;
    font-weight: ${theme.fontWeight.bold};
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -${theme.spacing.xs} 0;
    padding: 0;
  }

  li {
    display: inline-flex;
    margin: 0 ${theme.spacing.xs} ${theme.spacing.xs} 0;
    list-style: none;
  }
`;
