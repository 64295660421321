import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

const fonts = {
  h2: {
    lg: '1.75rem',
    sm: '1.25rem',
  },
  h3: {
    lg: '1.5rem',
    md: '1.25rem',
    sm: '1.25rem',
  },
  span: {
    lg: '1.125rem',
    md: '1rem',
    sm: '0.875rem',
  },
  p: {
    lg: '1.125rem',
    md: '1rem',
    sm: '0.875rem',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const StyledContactDetailsBlock = styled.div`
  h2 {
    margin: 0;
    padding: 0;
    font-size: ${fonts.h2.sm};
    font-weight: ${theme.fontWeight.black};

    ${breakpoint.greaterThan('lg')`
      font-size: ${fonts.h2.lg};
    `}
  }

  h3 {
    margin: 3rem 0 1rem 0;
    padding: 0;
    font-size: ${fonts.h3.sm};
    font-weight: ${theme.fontWeight.bold};

    ${breakpoint.greaterThan('lg')`
        font-size: ${fonts.h3.lg};
    `}
  }

  p {
    margin: 0;
    font-size: ${fonts.p.sm};

    ${breakpoint.greaterThan('sm')`
        font-size: ${fonts.p.md};
    `}

    ${breakpoint.greaterThan('lg')`
        font-size: ${fonts.p.lg};
    `}
  }

  .contact-details-block__address {
    display: flex;
    margin: ${theme.spacing.sm} 0;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    background-color: ${theme.colors.addressBg};
    color: ${theme.colors.textLight};

    address {
      font-size: ${fonts.p.sm};
      font-style: normal;
      font-weight: ${theme.fontWeight.light};
      line-height: 1.4;

      ${breakpoint.greaterThan('md')`
        font-size:${fonts.p.md};
      `}

      ${breakpoint.greaterThan('lg')`
        font-size:${fonts.p.lg};
      `}
    }

    svg {
      margin-right: 1rem;
      width: 25px;
      height: 25px;
    }
  }

  .contact-details-block__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      margin: 0 0 1em 0;
      ${breakpoint.greaterThan('lg')`
        margin: 0 0 10px 0;
      `};
      &:not(.contact-details-block__list-social-icons) {
        svg {
          margin-bottom: 0.25em;
        }
        ${breakpoint.greaterThan('lg')`
          flex-direction: row;
          svg {
            margin-bottom: 0em;
          }
        `};
      }

      a {
        color: ${theme.colors.textDark};
        border: none;
        transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

        &:hover {
          border: none;
          color: ${theme.colors.linkHover};
          text-decoration: underline;
        }

        &.contact-details-block__social-link {
          border-bottom: none;
          &:hover {
            border-bottom: none;
          }
        }
      }

      span,
      address {
        display: inline-block;
        font-weight: ${theme.fontWeight.light};
        font-size: ${fonts.span.sm};
        font-style: normal;
        word-break: break-word;

        ${breakpoint.greaterThan('sm')`
          font-size: ${fonts.span.md};
        `}

        ${breakpoint.greaterThan('lg')`
          font-size: ${fonts.span.lg};
        `}
      }

      svg {
        width: 15px;
        height: 15px;
        margin-right: 1rem;
        margin-top: 5px;
      }
    }
  }
`;

type StyledContactSidebarProps = {
  doShow: boolean;
};

export const StyledContactSidebar = styled.div<StyledContactSidebarProps>`
  display: ${props => (props.doShow ? 'block' : 'none')};

  border: 1px solid black;
  padding: 0 20px 20px 20px;
  margin-top: 20px;

  ${breakpoint.greaterThan('md')`
    display: block;
    border: none;
    padding: 0;
    margin-top: 0;
  `}
`;

export const ContactSideBarHeadingStyled = styled.div`
  display: none;
  ${breakpoint.greaterThan('md')`
    display: block;
  `}
`;

export const ContactSideBarToggleButtonStyled = styled.button`
  background-color: #2d2f32;
  width: 100%;
  padding: 1.5em 1em;
  margin-bottom: 1em;
  text-align: left;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1 auto;
  }
  h2,
  p {
    color: #fff;
    text-align: center;
  }
  h2 {
  }
  p {
  }
  svg {
    color: #fff;
    font-size: 1.75em;
    margin-right: 0.75em;
  }
  ${breakpoint.greaterThan('md')`
    display: none;
  `}
`;
