import 'react-accessible-accordion/dist/fancy-example.css';
import React, { ReactElement, useEffect, useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import AccordionItem from '../../molecules/AccordionItem';
import { StyledAccordionContainerBlock } from './styles';
import { BaseBlockProps } from '../../../types'

export interface AccordionItemBlockProps {
  epiTitle: EpiProps;
  epiContentArea: EpiProps;
  uuid: string;
  anchorId?: string;
}

export interface AccordionContainerBlockProps extends BaseBlockProps {
  epiTitle?: EpiProps;
  items: AccordionItemBlockProps[];
  openedItems?: string[];
  lightTheme?: boolean;
}

const AccordionContainerBlock = ({
  epiTitle,
  items,
  openedItems,
  lightTheme,
  anchorId
}: AccordionContainerBlockProps): ReactElement => {
  const [openedAccordions, setOpenedAccordions] = useState(
    Array.from(new Set(openedItems)),
  );

  const [hasMounted, setHasMounted] = useState(false);

  const onHashChange = () => {
    const hash = document.location.hash.slice(1);
    items.forEach((item, i) => {
      if (item.anchorId === hash) {
        const newUUID: string = (i + 1).toString();
        let newArr = [...openedAccordions];
        newArr.push(newUUID);
        newArr = Array.from(new Set(newArr));
        setOpenedAccordions(newArr);
      }
    });
  };

  useEffect(() => {
    if (hasMounted) {
      return null;
    }
    setHasMounted(true);
    const hash = document.location.hash.slice(1);
    items.forEach((item, i) => {
      if (item.anchorId === hash) {
        const newUUID: string = (i + 1).toString();
        // openedItems.push(newUUID);
        openedAccordions.push(newUUID);
        let newArr = openedAccordions;
        newArr.push(newUUID);
        // Make unique
        newArr = Array.from(new Set(newArr));
        setOpenedAccordions(newArr);
      }
    });
    window.addEventListener('hashchange', onHashChange, false);
    return () => {
      window.removeEventListener('hashchange', onHashChange, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <StyledAccordionContainerBlock id={anchorId}>
      {epiTitle && (
        <EpiProperty
          name={epiTitle.name}
          value={epiTitle.value}
          isEditMode={epiTitle.isEditMode}
          outerTag="h2"
        />
      )}
      <Accordion
        allowZeroExpanded
        allowMultipleExpanded
        preExpanded={openedAccordions}
      >
        {items.map(item => (
          <AccordionItem
            key={item.uuid}
            item={item}
            lightTheme={lightTheme}
            openedAccordions={openedAccordions}
            setOpenedAccordions={setOpenedAccordions}
          />
        ))}
      </Accordion>
    </StyledAccordionContainerBlock>
  );
};

export default AccordionContainerBlock;
