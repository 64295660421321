import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';

export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.md} 0;

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.lg} 0;
  `}
`;

export const StyledPageLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .page-layout__aside {
    order: 2;
    background-color: ${theme.colors.documentListBg};
    ${breakpoint.greaterThan('md')`
      order: 1;
      margin-right: 1.875rem;
      width: calc(25% - 1.875rem);
    `}
  }

  .page-layout__main {
    order: 1;

    ${breakpoint.greaterThan('md')`
      order: 2;
      margin-left: 1.875rem;
      width: calc(75% - 1.875rem);
    `}
  }

  @supports (display: grid) {
    ${breakpoint.greaterThan('md')`
      display: -ms-grid;
      display: grid;
      -ms-grid-template-columns: 400px auto;
      grid-template-columns: 400px auto;
      grid-gap: ${theme.spacing.lg};
    `}

    .page-layout__aside,
    .page-layout__main {
      ${breakpoint.greaterThan('md')`
        margin: 0;
        width: auto;
      `}
    }
  }
`;
