/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledProjectPageLayout = styled.div`
  padding: ${theme.spacing.sm} 0;

  ${breakpoint.greaterThan('md')`
    padding: ${theme.spacing.md} 0;
  `}

  div.stretched-gray {
    background-color: ${theme.colors.backgroundLight};
    padding: 50px 0;
    margin-bottom: 50px;
    position: relative;
    display: grid;
  }

  div.stretched-gray:before {
    content:"";
    background:${theme.colors.backgroundLight} !important;
    position: absolute;
    height: 100%;
    width: 4000px;
    left: -2000px;
    z-index: -1;
  }

`;
