import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';
import NavMainItem, { NavMainItemProps } from '../../atoms/NavMainItem';

const StyledNavMain = styled.nav`
  background-color: ${theme.colors.navMainBg};

  ul {
    display: flex;
    justify-content: center;
    margin: 0;
  }
`;

export interface NavMainProps {
  items: NavMainItemProps[];
}

function NavMain({ items }: NavMainProps): ReactElement {
  return (
    <StyledNavMain className="nav-main" role="navigation" aria-label="Main">
      <ul>
        {items.map(({ active, href, target = '_self', title }, i) => (
          <NavMainItem
            key={i}
            href={href}
            target={target}
            title={title}
            active={active}
          />
        ))}
      </ul>
    </StyledNavMain>
  );
}

export default NavMain;
