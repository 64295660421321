import { ReactElement, useState } from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { LinkProps } from '../../../types';
import {
  StyledContactDetailsBlock,
  StyledContactSidebar,
  ContactSideBarToggleButtonStyled,
  ContactSideBarHeadingStyled,
} from './styles';
import { BaseBlockProps } from '../../../types'
import { FaAt, FaChevronDown, FaChevronUp, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedin, FaPhone, FaXTwitter, FaYoutube } from 'react-icons/fa6';
import { FaMapMarkerAlt } from 'react-icons/fa';

export interface ContactDetailsBlockProps extends BaseBlockProps {
  epiTitle: EpiProps;
  epiSummary: EpiProps;
  epiAddress: EpiProps;
  epiPhone: EpiProps;
  epiEmail: EpiProps;
  epiPostalAddress: EpiProps;
  epiAbn: EpiProps;
  facebookUrl?: LinkProps;
  instagramUrl?: LinkProps;
  linkedInUrl?: LinkProps;
  youTubeUrl?: LinkProps;
  twitterUrl?: LinkProps;
  twitterUrl2?: LinkProps;
  epiMediaTitle: EpiProps;
  epiMediaPhone: EpiProps;
  epiMediaEmail: EpiProps;
  epiContactInformation: EpiProps;
}

function ContactDetailsBlock({
  epiTitle,
  epiSummary,
  epiAddress,
  epiPhone,
  epiEmail,
  epiPostalAddress,
  epiAbn,
  facebookUrl,
  instagramUrl,
  linkedInUrl,
  youTubeUrl,
  twitterUrl,
  twitterUrl2,
  epiMediaTitle,
  epiMediaPhone,
  epiMediaEmail,
  epiContactInformation,
  anchorId
}: ContactDetailsBlockProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <StyledContactDetailsBlock id={anchorId}>
      <ContactSideBarToggleButtonStyled onClick={toggleOpen}>
        <div>
          <EpiProperty
            name={epiTitle.name}
            value={epiTitle.value}
            isEditMode={epiTitle.isEditMode}
            outerTag="h2"
          />
          <EpiProperty
            name={epiSummary.name}
            value={epiSummary.value}
            isEditMode={epiSummary.isEditMode}
            outerTag="p"
          />
        </div>
        {isOpen ? (
          <FaChevronUp />
        ) : (
          <FaChevronDown />
        )}
      </ContactSideBarToggleButtonStyled>
      <StyledContactSidebar doShow={isOpen}>
        <section>
          <ContactSideBarHeadingStyled>
            <EpiProperty
              name={epiTitle.name}
              value={epiTitle.value}
              isEditMode={epiTitle.isEditMode}
              outerTag="h2"
            />
            <EpiProperty
              name={epiSummary.name}
              value={epiSummary.value}
              isEditMode={epiSummary.isEditMode}
              outerTag="p"
            />
          </ContactSideBarHeadingStyled>

          <div className="contact-details-block__address">
            <FaMapMarkerAlt />
            <EpiProperty
              name={epiAddress.name}
              value={epiAddress.value}
              isEditMode={epiAddress.isEditMode}
              outerTag="address"
            />
          </div>

          <ul className="contact-details-block__list">
            <li>
              <FaPhone />
              <a href={`tel:${epiPhone.value}`}>
                <EpiProperty
                  name={epiPhone.name}
                  value={epiPhone.value}
                  isEditMode={epiPhone.isEditMode}
                  outerTag="span"
                />
              </a>
            </li>

            <li>
              <FaAt />
              <a
                href={`mailto:${epiEmail.value}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EpiProperty
                  name={epiEmail.name}
                  value={epiEmail.value}
                  isEditMode={epiEmail.isEditMode}
                  outerTag="span"
                />
              </a>
            </li>
            <li>
              <FaEnvelope />
              <EpiProperty
                name={epiPostalAddress.name}
                value={epiPostalAddress.value}
                isEditMode={epiPostalAddress.isEditMode}
                outerTag="address"
              />
            </li>
            <li>
              <FaMapMarkerAlt />
              <EpiProperty
                name={epiAbn.name}
                value={epiAbn.value}
                isEditMode={epiAbn.isEditMode}
                outerTag="span"
              />
            </li>

            <li className="contact-details-block__list-social-icons">
              {facebookUrl && (
                <a
                  href={facebookUrl.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={facebookUrl.label}
                  className="contact-details-block__social-link"
                >
                  <FaFacebookF />
                </a>
              )}
              {instagramUrl && (
                <a
                  href={instagramUrl.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={instagramUrl.label}
                  className="contact-details-block__social-link"
                >
                  <FaInstagram />
                </a>
              )}
              {linkedInUrl && (
                <a
                  href={linkedInUrl.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={linkedInUrl.label}
                  className="contact-details-block__social-link"
                >
                  <FaLinkedin />
                </a>
              )}
              {youTubeUrl && (
                <a
                  href={youTubeUrl.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={youTubeUrl.label}
                  className="contact-details-block__social-link"
                >
                  <FaYoutube />
                </a>
              )}
              {twitterUrl && (
                <a
                  href={twitterUrl.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={twitterUrl.label}
                  className="contact-details-block__social-link"
                >
                  <FaXTwitter />
                </a>
              )}
              {twitterUrl2 && (
                <a
                  href={twitterUrl2.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={twitterUrl2.label}
                  className="contact-details-block__social-link"
                >
                  <FaXTwitter />
                </a>
              )}
            </li>
          </ul>
        </section>
        {/* Could be new block - MediaEnquiriesBlock */}
        <section>
          <EpiProperty
            name={epiMediaTitle.name}
            value={epiMediaTitle.value}
            isEditMode={epiMediaTitle.isEditMode}
            outerTag="h3"
          />
          <ul className="contact-details-block__list">
            <li>
              <FaPhone />
              <a href={`tel:${epiMediaPhone.value}`}>
                <EpiProperty
                  name={epiMediaPhone.name}
                  value={epiMediaPhone.value}
                  isEditMode={epiMediaPhone.isEditMode}
                  outerTag="span"
                />
              </a>
            </li>
            <li>
              <FaAt />
              <a
                href={`mailto:${epiMediaEmail.value}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EpiProperty
                  name={epiMediaEmail.name}
                  value={epiMediaEmail.value}
                  isEditMode={epiMediaEmail.isEditMode}
                  outerTag="span"
                />
              </a>
            </li>
          </ul>
        </section>
        <section>
          <EpiProperty
            name={epiContactInformation.name}
            value={epiContactInformation.value}
            isEditMode={epiContactInformation.isEditMode}
            outerTag="dev"
          />
        </section>
      </StyledContactSidebar>
    </StyledContactDetailsBlock>
  );
}

export default ContactDetailsBlock;
