import React, { useContext, useEffect } from 'react';

import { TravelMapFiltersStyled } from './TravelMapFiltersStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { TravelMapContext } from '../../organisms/TravelMapDashboard';
import ToggleCheckboxFilter from '../../atoms/ToggleCheckboxFilter';
import Accordian from '../../atoms/Accordian';
import { FaExclamationCircle, FaTrafficLight } from 'react-icons/fa';
import theme from '../../../lib/theme';

export interface ITravelMapFilters {
}

const TravelMapFilters = ({
}: ITravelMapFilters) => {
  const { state, dispatch } = useContext(TravelMapContext)
  const { traffic = false, alerts: { filters, features, filtersRestArea } } = state;

  const toggleFilter = (name, restArea) => {
    const action = {
      type: 'ALERTS_TOGGLE_FILTER', value: {
        name,
        restArea,
      }
    };
    dispatch(action);
  }

  const toggleTraffic = () => {
    const action = { type: 'TRAFFIC_TOGGLE' };
    dispatch(action);
  }

  return (
    <TravelMapFiltersStyled>
      {!!filters.length && <Accordian
        title={'Alerts and closures'}
        icon={<FaExclamationCircle color={theme.colors.primary} />}
        defaultActive={true}
      >
        <ul>
          {filters.filter(filter => filter.CanHide && filter.Name !== 'RestAreas').map((filter, i) => (
            <li key={filter.id || 'filter' + i}>
              <label
                htmlFor={'travel-map-filters__filter-' + filter.Name}
              >
                <img src={filter.IconUrl + '.svg'} alt={filter.DisplayName + ' Icon'} />
                <span>{filter.DisplayName}</span>
              </label>
              <ToggleCheckboxFilter
                id={'travel-map-filters__filter-' + filter.Name}
                value={filter.filterOn}
                checked={filter.filterOn}
                onChange={(e): void => toggleFilter(filter.Name, false)}
              />
            </li>
          ))}
        </ul>
      </Accordian>}
      <section className={'travel-map-filters__traffic'}>
        <label
          htmlFor='traffic_layer'
        >
          <span className="travel-map-filters__icon"><FaTrafficLight /></span>
          Traffic Layer
        </label>
        <ToggleCheckboxFilter
          id="traffic_layer"
          value={traffic}
          checked={traffic}
          onChange={(e): void => toggleTraffic()}
        />
      </section>
      {!!filters.length && <Accordian
        title={'Rest areas and amenities'}
        icon={<span className="travel-map-filters__icon"><img src="/images/travel-map/icon_restarea--white.svg" alt="Rest Areas Icon" /></span>}
        defaultActive={true}
      >
        <ul>
          {filters.filter(filter => filter.Name === 'RestAreas').map((filter, i) => (
            <li key={filter.id || 'filter' + i}>
              <label
                htmlFor={'travel-map-filters__filter-' + filter.Name}
              >
                <span>See all rest areas</span>
              </label>
              <ToggleCheckboxFilter
                id={'travel-map-filters__filter-' + filter.Name}
                value={filter.filterOn}
                checked={filter.filterOn}
                onChange={(e): void => toggleFilter(filter.Name, false)}
              />
            </li>
          ))}
          {filtersRestArea.map((filter, i) => (
            <li key={filter.id || 'filterRestArea' + i}>
              <label
                htmlFor={'travel-map-filters__filter-' + filter.name}
              >
                {filter?.iconUrl && <img src={filter.iconUrl + '.svg'} alt={filter.name + ' Icon'} />}
                <span>{filter.name}</span>
              </label>
              <ToggleCheckboxFilter
                id={'travel-map-filters__filter-' + filter.name}
                value={filter.filterOn}
                checked={filter.filterOn}
                onChange={(e): void => toggleFilter(filter.name, true)}
              />
            </li>
          ))}
        </ul>
      </Accordian>}
    </TravelMapFiltersStyled>
  );
};

export default TravelMapFilters;