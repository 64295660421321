import React, { ReactElement } from 'react';
import FeaturedPanel, { FeaturedPanelProps } from '../../widgets/FeaturedPanel';

function FeaturedPanelBlock({
  title,
  summary,
  link,
  search,
  anchorId
}: FeaturedPanelProps): ReactElement {
  return (
    <FeaturedPanel
      anchorId={anchorId}
      title={title}
      summary={summary}
      link={link}
      search={search}
    />
  );
}

export default FeaturedPanelBlock;
