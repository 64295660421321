import React from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import ListBlockItem from '../../molecules/ListBlockItem';
import { ListBlockStyles } from './ListBlockStyles';
import { BaseBlockProps } from '../../../types'

export interface ListBlockProps extends BaseBlockProps {
  epiTitle: EpiProps;
  readmoreLink: {
    href: string;
    target?: string;
    title: string;
  };
  list: any[];
  hideBackground?: boolean;
}

const ListBlock = ({ epiTitle, readmoreLink, list, hideBackground, anchorId }: ListBlockProps) => {
  return (
    <ListBlockStyles id={anchorId} className={`${!hideBackground ? ' do-show-background' : ''}`}>
      <EpiProperty
        name={epiTitle.name}
        value={epiTitle.value}
        isEditMode={epiTitle.isEditMode}
        outerTag="h2"
      />
      <ul>
        {list.map(listItem => (
          <li>
            <ListBlockItem
              epiTitle={listItem.epiTitle}
              epiText={listItem.epiText}
            />
          </li>
        ))}
      </ul>
      {readmoreLink?.href && readmoreLink?.title && (
        <a
          href={readmoreLink.href}
          target={readmoreLink.target}
          className="read-more"
        >
          {readmoreLink.title}&nbsp;»
        </a>
      )}
    </ListBlockStyles>
  );
};

export default ListBlock;
