import to from 'await-to-js';
import axios from 'axios';
import NProgress from 'nprogress';
import React, { ReactElement, useEffect, useState } from 'react';

import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTitle from '../../atoms/PageTitle';
import ContentArticleBlock, {
  ContentArticleBlockProps,
} from '../../blocks/ContentArticleBlock';
import ListingPageFilters, {
  ListingPageFiltersProps,
} from '../../molecules/ListingPageFilters';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import Pagination from '../../molecules/Pagination';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import RightColumnLayout from '../../templates/RightColumnLayout';
import {
  StyledButton,
  StyledContentListingGroup,
  StyledListingPageLeftColumn,
  StyledListingPageRightColumn,
  StyledListingPageRightColumnLayout,
  StyledNavBreadcrumbs,
  FilterSectionStyles,
} from './styles';

interface ApiQueryProps {
  filter?: string[];
  pageNumber?: number;
  pageSize?: number;
}

interface ApiResponseProps {
  items: ContentArticleBlockProps[];
  categories: ListingPageFiltersProps['categories'];
  itemsPerPage: number;
  page: number;
  totalItems: number;
}

interface Category {
  name: string;
  selected: boolean;
}

interface ListingPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  padding: boolean;
  epiTitle: EpiProps;
  epiContent: EpiProps;
  filterTitle: EpiProps;
  apiPath: string;
  rightContentArea: EpiProps;
  alert?: PageTemplateProps['alert'];
}

// Change this base url when debugging
const API_BASE_URL = '';

type FilterSectionProps = {
  doShowOn?: ('DESKTOP' | 'MOBILE')[];
  filterTitle: EpiProps;
  apiResponse: ApiResponseProps | undefined;
  updateCategories: (filters) => void;
};

const FilterSection = ({
  doShowOn = ['DESKTOP', 'MOBILE'],
  filterTitle,
  apiResponse,
  updateCategories,
}: FilterSectionProps) => {
  const [showCategories, setShowCategories] = useState(false);

  if (!apiResponse || !apiResponse.categories) {
    return null;
  }
  const className = doShowOn.join(' ').toLowerCase();
  return (
    <FilterSectionStyles className={className}>
      <StyledButton
        icon="chevron-down"
        ghost
        type="button"
        handleOnClick={() => {
          setShowCategories(!showCategories);
        }}
      >
        Filter
      </StyledButton>
      <div
        className={`listing-page__filters ${
          showCategories ? 'listing-page__filters--active' : ''
        }`}
      >
        <ListingPageFilters
          epiTitle={filterTitle}
          categories={apiResponse.categories}
          updateFilters={updateCategories}
        />
      </div>
    </FilterSectionStyles>
  );
};

function ListingPage({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  epiContent,
  filterTitle,
  apiPath,
  rightContentArea,
  padding,
  alert,
}: ListingPageProps): ReactElement {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [categories, setCategories] = useState<Category[]>([]);
  const [apiResponse, setApiResponse] = useState<
    ApiResponseProps | undefined
  >();
  const [performedInitialQuery, setPerformedInitialQuery] = useState<boolean>(
    false,
  );

  async function fetchListItems({
    pageNumber,
    filter,
  }: ApiQueryProps): Promise<void> {
    NProgress.start();
    const APIURL = `${API_BASE_URL}${apiPath}&q=${
      pageNumber ? `&page=${pageNumber}` : ''
    }${filter ? `&filter=${filter}` : ''}`;
    const [err, res] = await to(axios(APIURL));
    // const [err, res] = await to(
    //  axios('https://run.mocky.io/v3/555b6d08-e43b-4f8f-b036-bc3a4159f603'),
    // );
    if (err) {
      NProgress.done();
      return;
    }
    setApiResponse(res.data);
    NProgress.done();
  }

  // Prepare a new query
  const updateQuery = () => {
    const filter = categories
      .filter((c: { selected: boolean }) => c.selected === true)
      .map((c: { name: any }) => c.name);
    const query: ApiQueryProps = {
      pageNumber: currentPage,
      filter,
    };
    fetchListItems(query);
  };

  // Categories changes, set page number to 1
  const updateCategories = (filters: any): void => {
    setCategories(filters);
    setCurrentPage(1);
    if (performedInitialQuery) {
      updateQuery();
    }
  };

  useEffect(() => {
    const query: ApiQueryProps = {
      pageNumber: currentPage,
      // categories from the api response, set to empty on initial load
    };

    fetchListItems(query);
    setPerformedInitialQuery(true);
    // eslint-disable-next-line
  }, []);

  // when paginating
  useEffect(() => {
    // Don't run on mount
    if (performedInitialQuery) {
      updateQuery();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const leftContent = apiResponse?.items && (
    <StyledListingPageLeftColumn>
      <FilterSection
        doShowOn={['MOBILE']}
        filterTitle={filterTitle}
        apiResponse={apiResponse}
        updateCategories={updateCategories}
      />

      <EpiProperty
        name={epiContent.name}
        value={epiContent.value}
        isEditMode={epiContent.isEditMode}
        isHTMLContent={epiContent.isHTMLContent}
        outerTag="div"
      />

      <StyledContentListingGroup>
        <div>
          {apiResponse.items?.map(list => (
            <ContentArticleBlock
              link={list.link}
              thumbnail={list.thumbnail}
              title={list.title}
              excerpt={list.excerpt}
              lastModified={list.lastModified}
              tags={list.tags}
            />
          ))}
        </div>
      </StyledContentListingGroup>

      <Pagination
        forcePage={currentPage - 1}
        pageCount={Math.ceil(apiResponse.totalItems / apiResponse.itemsPerPage)}
        onPageChange={(val: any) => {
          document.getElementById('main-content').scrollIntoView({
            behavior: 'smooth',
          });
          return setCurrentPage(val.selected + 1);
        }}
      />
    </StyledListingPageLeftColumn>
  );

  const rightContent = (
    <StyledListingPageRightColumn>
      <FilterSection
        doShowOn={['DESKTOP']}
        filterTitle={filterTitle}
        apiResponse={apiResponse}
        updateCategories={updateCategories}
      />
      <div className="listing-page__right-content-area">
        <EpiProperty
          name={rightContentArea.name}
          value={rightContentArea.value}
          outerTag="div"
        />
      </div>
    </StyledListingPageRightColumn>
  );

  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      {breadcrumbs && (
        <Container>
          <StyledNavBreadcrumbs items={breadcrumbs} />
        </Container>
      )}
      <Container padding={padding}>
        <PageTitle>
          <EpiProperty
            name={epiTitle.name}
            value={epiTitle.value}
            isEditMode={epiTitle.isEditMode}
            outerTag="span"
          />
        </PageTitle>
        <StyledListingPageRightColumnLayout>
          <RightColumnLayout
            leftColumn={leftContent}
            rightColumn={rightContent}
          />
        </StyledListingPageRightColumnLayout>
      </Container>
    </PageTemplate>
  );
}

export default ListingPage;
