/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, ReactNode } from 'react';
import theme from '../../../lib/theme';

import { InputStyled } from './styles';

export interface InputProps {
  id: string;
  value?: string;
  type?: string;
  placeholder?: string;
  icon?: ReactElement;
  iconColor?: string;
  iconBgColor?: string;
  children?: ReactNode;
  className?: string;
  label?: string;
  handleOnChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  handleOnClick?(event: React.MouseEvent<HTMLInputElement>): void;
}

function Input({
  id,
  type = 'text',
  value = '',
  placeholder = '',
  icon,
  iconBgColor,
  iconColor = iconBgColor ? theme.colors.white : theme.colors.buttonBg,
  children,
  className = '',
  label = '',
  handleOnChange,
  handleOnClick,
}: InputProps): ReactElement {
  return (
    <InputStyled
      className={`${className} ${iconBgColor ? 'input__main input__main--lockup' : 'input__main'}`}
      iconBgColor={iconBgColor ?? theme.colors.white}
      iconColor={iconColor}
    >
      {icon && (
        <span className="input__icon">
          {icon}
        </span>
      )}
      <label className="input__label" htmlFor={id}>
        {label}
      </label>
      <span className="input__body">
        {children ? (
          <>{children}</>
        ) : (
          <input
            id={id}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
            onClick={handleOnClick}
          />
        )}
      </span>
    </InputStyled>
  );
}

export default Input;
