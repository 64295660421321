import styled from 'styled-components';

import { breakpoint } from '../../../lib/theme';

export const QuickLinksButtonStyled = styled.button`
  background-color: #d14210;
  color: #fff;
  width: 100%;
  border: 1px solid transparent;
  padding: 1em 0.5em;
  cursor: pointer;
  margin-bottom: 1em;
  font-weight: 500;
  font-size: 1.25em;
  svg {
    margin-left: 0.5em;
    ${breakpoint.greaterThan('sm')`
      margin-left: 1em;  
    `};
  }
  ${breakpoint.greaterThan('md')`
    display: none;
  `};
`;

export const QuickLinksListStyled = styled.div`
  flex-wrap: wrap;
  display: none;
  &.is-open {
    display: flex;
  }
  @supports (display: grid) {
    grid-gap: 10px 16px;
    &.is-open {
      display: grid;
    }
  }
  ${breakpoint.greaterThan('md')`
    display: flex;
    > a {
      width: calc(25% - 12px);
    }
    @supports(display: grid){
      display: grid;
      > a {
        width: auto;
      }
      grid-template-columns: repeat(4, 1fr);
      &.do-thirds{
        grid-template-columns: repeat(3, 1fr);

      }
    }

  `};
`;

export const StyledQuicklinks = styled.div`
  margin-bottom: 30px;
  a {
    padding: 1em 0.5em;
  }
  ${breakpoint.greaterThan('md')`
    margin-bottom: 0px;
  `};
`;
