import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';
import Container from '../../atoms/Container';
import image from './sign.svg';

export const StyledErrorHero = styled.div`
  background-color: ${theme.colors.backgroundLight};
  margin: 0 0 ${theme.spacing.sm} 0;

  ${breakpoint.greaterThan('md')`
       margin: 0 0 ${theme.spacing.md} 0;
  `}

  .error-hero__inner {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: ${theme.spacing.lg} 0 0 0;
    ${breakpoint.greaterThan('md')`
      flex-flow: row;
    `}
  }

  .error-hero__status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: calc(200px + ${theme.spacing.lg});
    margin: 0 0 ${theme.spacing.md} 0;
    padding: 0 0 ${theme.spacing.lg} 0;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: cover;

    ${breakpoint.greaterThan('md')`
      margin: 0 ${theme.spacing.md} 0 0;
    `}

    span {
      color: ${theme.colors.errorStatus};
      font-size: 2.75rem;
      font-weight: ${theme.fontWeight.black};
      line-height: 1;
    }
  }

  .error-hero__message {
    flex: 1 0 auto;
    width: auto;
    padding: 0 0 ${theme.spacing.lg} 0;

    ${breakpoint.greaterThan('md')`
      max-width: 50%;
    `}

    h1 {
      margin: 0 0 ${theme.spacing.sm} 0;
      font-size: 1.25rem;
      font-weight: ${theme.fontWeight.black};

      ${breakpoint.greaterThan('md')`
        font-size: 2rem;
      `}

      ${breakpoint.greaterThan('lg')`
        font-size: 2.75rem;
      `}
    }

    p {
      margin: 0;
      font-size: 0.875rem;

      ${breakpoint.greaterThan('md')`
        font-size: 1.25rem;
      `}

      ${breakpoint.greaterThan('lg')`
        font-size: 1.5rem;
      `}
    }
  }
`;

export interface ErrorHeroProps {
  status: number;
  title: string;
  message: string;
}

function ErrorHero({ status, title, message }: ErrorHeroProps): ReactElement {
  return (
    <StyledErrorHero>
      <Container>
        <div className="error-hero__inner">
          <div className="error-hero__status">
            <span>{status}</span>
          </div>
          <div className="error-hero__message">
            <h1>{title}</h1>
            <p>{message}</p>
          </div>
        </div>
      </Container>
    </StyledErrorHero>
  );
}

export default ErrorHero;
