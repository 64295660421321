import React, { ReactElement, useState } from 'react';
import PageTitle from '../../atoms/PageTitle';
import Filter, { FilterProps } from '../../atoms/Filter';
import { StyledFilterList } from './StyledFilterList';

export interface FilterListProps {
  className?: string;
  items: FilterProps[];
  title: string;
}

export const FilterContext = React.createContext({});

function FilterList({
  className,
  title,
  items,
}: FilterListProps): ReactElement {
  const [filters, setFilters] = useState([]);

  function handleFilterClick(event: React.MouseEvent, label: string): void {
    event.preventDefault();
    if (filters.indexOf(label) > -1) {
      return setFilters(filters.filter(item => label !== item));
    }
    return setFilters([...filters, label]);
  }

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      <StyledFilterList className={className}>
        <PageTitle>
          <h2>{title}</h2>
        </PageTitle>
        <ul>
          {items.map(({ icon, label }, i) => (
            <li key={i}>
              <Filter
                active={filters.indexOf(label) > -1}
                icon={icon}
                label={label}
                handleFilterClick={(e): void => handleFilterClick(e, label)}
              />
            </li>
          ))}
        </ul>
      </StyledFilterList>
    </FilterContext.Provider>
  );
}

export default FilterList;
