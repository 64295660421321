import React from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { ListItem } from './ListBlockStyles';

export interface ListBlockItemProps {
  epiTitle: {
    href: string;
    target?: string;
    title: string;
  };
  epiText?: EpiProps;
}

const ListBlockItem = ({ epiTitle, epiText }: ListBlockItemProps) => {
  const FILE_TYPES = ['pdf', 'docx', 'jpg', 'dwg', 'zip', 'xls'];
  const fileType = epiTitle.href
    .split(/[#?]/)[0]
    .split('.')
    .pop()
    .trim();
  const isFile = FILE_TYPES.includes(fileType);
  const anchorEl =
    isFile || epiTitle.target === '_blank' ? (
      <a
        href={epiTitle.href}
        target="_blank"
        download={isFile}
        rel="noreferrer noopener"
      >
        {epiTitle.title}
      </a>
    ) : (
      <a href={epiTitle.href}>{epiTitle.title}</a>
    );
  return (
    <ListItem>
      <h3>{anchorEl}</h3>
      {epiText?.value && (
        <EpiProperty
          name={epiText.name}
          value={epiText.value}
          isEditMode={epiText.isEditMode}
          outerTag="p"
        />
      )}
    </ListItem>
  );
};

export default ListBlockItem;
