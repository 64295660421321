import React, { ReactElement } from 'react';

import { LinkProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty from '../../atoms/EpiProperty';
import Quicklinks from '../../blocks/QuicklinksBlock';
import NavBreadcrumbs, {
  NavBreadcrumbsProps,
} from '../../molecules/NavBreadcrumbs';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import {
  HeavyMainSection,
  StyledContainer,
  StyledHeader,
  StyledMain,
} from './styles';

// FEATURED SECTION COMP
interface FeaturedContentSectionProps {
  epiFeaturedContentArea: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
}

function FeaturedContentSection({
  epiFeaturedContentArea,
}: FeaturedContentSectionProps): ReactElement {
  return (
    <>
      <section>
        <StyledContainer>
          <EpiProperty
            name={epiFeaturedContentArea.name}
            value={epiFeaturedContentArea.value}
            isEditMode={epiFeaturedContentArea.isEditMode}
            outerTag="div"
          />
        </StyledContainer>
      </section>
    </>
  );
}

interface HeavyVehiclesLandingPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  quickLinks?: LinkProps[];
  epiMainContentArea: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  epiRightContentArea: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  lastReviewed?: string;
  lastUpdated?: string;
  alert?: PageTemplateProps['alert'];
}

function HeavyVehiclesLandingPage({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  quickLinks,
  epiMainContentArea,
  epiRightContentArea,
  epiFeaturedContentArea,
  lastReviewed = undefined,
  lastUpdated = undefined,
  alert,
}: HeavyVehiclesLandingPageProps & FeaturedContentSectionProps): ReactElement {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <StyledMain>
        <StyledHeader>
          <Container>
            <NavBreadcrumbs items={breadcrumbs} />
            <EpiProperty
              name={epiTitle.name}
              value={epiTitle.value}
              isEditMode={epiTitle.isEditMode}
              outerTag="h1"
            />
            <Quicklinks links={quickLinks} />
          </Container>
        </StyledHeader>
        <HeavyMainSection>
          <div>
            <EpiProperty
              name={epiMainContentArea.name}
              value={epiMainContentArea.value}
              isEditMode={epiMainContentArea.isEditMode}
              outerTag="div"
            />

            <PageLastReviewed
              lastReviewed={lastReviewed}
              lastUpdated={lastUpdated}
              pageType="page"
            />
          </div>
          <aside>
            <EpiProperty
              name={epiRightContentArea.name}
              value={epiRightContentArea.value}
              isEditMode={epiRightContentArea.isEditMode}
              outerTag="div"
            />
          </aside>
        </HeavyMainSection>
        <FeaturedContentSection
          epiFeaturedContentArea={epiFeaturedContentArea}
        />
      </StyledMain>
    </PageTemplate>
  );
}

export default HeavyVehiclesLandingPage;
