import styled, { keyframes } from 'styled-components';
import theme from '../../../lib/theme';

const shimmerBg = keyframes`
  0%{background-position:100% 50%}
  /* 50%{background-position:100% 50%}
  100%{background-position:0% 50%} */
  100%{background-position:0% 50%}
`;

export const RoadWorksFeedStyles = styled.section`
  margin-bottom: 50px;
  header {
    background-color: #2d2f32;
    padding: 1em 1.25em;
    h2 {
      color: #ffffff;
      font-size: 1.125em;
      margin: 0;
    }
  }
  .info {
    padding: 1.25em 1.375em 0 1.375em;
    p {
      color: #707070;
      margin: 0;
    }
  }
  .roadworks__feed {
    padding: 10px 1.375em 1.375em 1.375em;
    ul.roadworks__feed__item {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }
`;

export const RoadWorkItemStyles = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  p {
    margin: 0;
    line-height: 1.125;
  }
  .roadname {
    color: #d14210;
    font-size: 1.125em;
    font-weight: ${theme.fontWeight.black};
  }
  .timeframe {
    color: #000000;
    font-size: 1.125em;
    font-weight: ${theme.fontWeight.black};
  }
  .meta {
    display: flex;
    gap: 1em;
  }
  .roadworks__type {
    font-size: 1.125em;
    display: flex;
    align-items: center;
    font-weight: ${theme.fontWeight.regular};
    svg {
      height: 100%;
      margin-right: 0.5em;
    }
  }
  .roadworks__link {
    color: #000000;
    display: flex;
    align-items: center;
    font-weight: ${theme.fontWeight.regular};
    span {
      text-decoration: underline;
    }
    svg {
      margin-right: 0.5em;
    }
  }
  .infoText {
    color: #000000;
    font-size: 1.125em;
    font-weight: ${theme.fontWeight.regular};
  }
  &.shimmering {
    gap: 4px;
    font-size: 0.9em;
    .roadname {
      background: linear-gradient(
        90deg,
        rgba(209, 66, 16, 1) 0%,
        rgba(247, 136, 97, 1) 50%,
        rgba(209, 66, 16, 1) 100%
      );
    }
    .timeframe,
    .meta,
    .roadworks__type,
    .roadworks__link,
    .infoText {
      background: linear-gradient(
        90deg,
        rgb(0, 0, 0) 0%,
        rgb(50, 50, 50) 50%,
        rgb(0, 0, 0) 100%
      );
    }

    .roadname,
    .timeframe,
    .meta,
    .roadworks__type,
    .roadworks__link,
    .infoText {
      opacity: 0.4;
      border-radius: 6px;
      display: inline-block;
      color: transparent;
      background-size: 400% 100%;
      animation: ${shimmerBg} 2s linear infinite;
    }
  }
`;

export const LoadingStyles = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  > div {
    display: inline-block;
    margin: 0 auto;
  }
  svg {
    font-size: 2em;
  }
`;
