/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

const fonts = {
  h2: {
    sm: '1.125rem',
    lg: '1.5rem',
  },
  button: {
    sm: '0.75rem',
    lg: '1rem',
  },
  svg: {
    sm: '0.75rem',
    lg: '1rem',
  },
};

export const StyledListingPageFilters = styled.div`
  border: 1px solid ${theme.colors.borderMedium};
  padding: ${theme.spacing.sm} ${theme.spacing.md};

  ${breakpoint.greaterThan('lg')`
    padding: ${theme.spacing.md} ${theme.spacing.lg};
  `};

  h2 {
    margin: 0 0 ${theme.spacing.xs} 0;
    padding: 0;
    font-size: ${fonts.h2.sm};
    font-weight: ${theme.fontWeight.regular};

    ${breakpoint.greaterThan('lg')`
      font-size: ${fonts.h2.lg}
    `};
  }

  ul {
    padding-left: 0;
  }

  li {
    margin: 0 0 0.8rem 0;
    list-style: none;
    line-height: 1;

    &:last-of-type {
      margin: 0;
    }

    svg {
      font-size: ${fonts.svg.sm};
      color: ${theme.colors.linkFocus};
      margin: 0rem 0.7rem 0rem -1.5rem;

      ${breakpoint.greaterThan('lg')`
        font-size: ${fonts.svg.lg};
        margin: 0rem ${theme.spacing.sm} 0rem -2rem;
      `};
    }
  }

  li button {
    background-color: ${theme.colors.bgLight};
    color: ${theme.colors.textDark};
    font-size: ${fonts.button.sm};
    font-weight: ${theme.fontWeight.light};
    text-decoration: none;
    border: none;
    border-bottom: dotted 1px ${theme.colors.textDark};
    cursor: pointer;
    padding: 0;
    text-align: left;

    ${breakpoint.greaterThan('lg')`
      font-size: ${fonts.button.lg}
    `};

    &.filter__selected {
      border: none;
      border-bottom: dotted 1px ${theme.colors.linkFocus};
      font-weight: ${theme.fontWeight.black};
      color: ${theme.colors.linkFocus};
    }
  }
`;
