import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const TileStyles = styled.article`
  background-color: #f5f5f5;
  padding: 1em;
  border-radius: 15px;
  box-shadow: ${theme.elevation[1]};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${breakpoint.greaterThan('sm')`
    max-width: 400px;
  `}
  h3 {
    margin: 0.666em 0 0 0;
    font-size: 1.25em;
    ${breakpoint.greaterThan('sm')`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
    ${breakpoint.greaterThan('lg')`
      font-size: 1.5em;
    `}
  }
  figure {
    margin: 0;
    padding: 0 0 55.56% 0;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  a {
    color: black;
    font-size: 1rem;
    border: none;
    font-weight: ${theme.fontWeight.regular};
    &:hover {
      text-decoration: underline;
      border: none;
    }
  }
`;
