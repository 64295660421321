import styled from 'styled-components';
import theme from '../../../lib/theme';
import Button from '../../atoms/Button';

type StyledProjectInfoPanelProp = {
  phaseColor: string;
};

const StyledProjectInfoPanel = styled.div<StyledProjectInfoPanelProp>`
  position: relative;
  background-color: white;
  margin-top: ${theme.spacing.sm};
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  flex: 1 1 100%;

  .project-panel {
    &__top-section {
      padding: ${theme.spacing.sm} ${theme.spacing.md};
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.2);
    }

    &__bottom-section {
      padding: ${theme.spacing.sm} ${theme.spacing.md};
    }

    &__detail-label {
      margin: 0;
      font-size: 0.9em;
    }
    &__detail-data {
      margin: 0 0 0.7rem;
      font-weight: ${theme.fontWeight.bold};
    }
  }

  .project-title {
    position: relative;
    padding-right: 30px;
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  .phase-indicator {
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin-right: 10px;
    background: ${(props): string => props.phaseColor};
  }

  .project-phase {
    margin-top: 0;
    font-size: 0.9em;
    font-weight: ${theme.fontWeight.bold};
  }

  .reference-number {
    margin-top: 0;
    color: ${theme.colors.formText};
    font-weight: ${theme.fontWeight.light};
    font-size: 0.9rem;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    margin: 0;
    border-radius: 50%;
    background-color: white;
    border: none;
    cursor: pointer;
  }
`;

export const StyledDownloadButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  background-color: ${theme.colors.primaryAlt};
  color: ${theme.colors.buttonText};

  &:hover {
    background-color: ${theme.colors.buttonText};
  }
`;

export const StyledMoreButton = styled(Button)`
  padding: 0.7rem 4rem;
`;

export default StyledProjectInfoPanel;
