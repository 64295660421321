import styled from 'styled-components';
import theme from '../../../lib/theme';

export const TravelMapFiltersStyled = styled.div`
  >section {
    margin: 0 2.25rem;
    padding: 1rem 0;
    border-bottom: 1px solid ${theme.colors.borderMediumLight};
    :last-child {
      border-bottom: 0;
    }
  }

  .travel-map-filters__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.2rem;
      width: 2.2rem;
      margin: 0 0.5rem 0 0;
      background-color: #008072;
      border-radius: 50%;
      svg, img {
        height: 60%;
        width: 60%;
      }
  }

  .travel-map-filters__traffic {
    display: flex;
    justify-content: space-between;
    label {
      display: flex;
      font-weight: 500;
      color: var(--primary-blue);
      margin: 0;
      font-size: 1rem;
      align-items: center;
      .travel-map-filters__icon {
        color: #fff;
        background-color: ${theme.colors.backgroundDark};
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    >li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      >label {
        display: flex;
        align-items: center;
        img {
          width: 2rem;
          height: 2rem;
          margin: 0 1rem 0 0;
        }
      }
    }
  }
`;
