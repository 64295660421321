import React, { ReactElement } from 'react';
import StyledTogglePill from './styles';

type TogglePillProp = {
  id: string;
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function TogglePill({
  id = 'id',
  name = 'name',
  value = 'value',
  label = 'Label',
  checked = true,
  onChange,
}: TogglePillProp): ReactElement {
  return (
    <StyledTogglePill>
      <input
        id={id}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </StyledTogglePill>
  );
}

export default TogglePill;
