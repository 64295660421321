import React, { ReactElement } from 'react';

import { LinkProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PrintFriendlyButton from '../../atoms/PrintFriendlyButton';
import { FileLinkProps } from '../../blocks/FileLink';
import FileLinksList from '../../blocks/FileLinksList';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import Sidebar from '../../molecules/Sidebar';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import {
  StyledAuthor,
  StyledLinkStepBack,
  StyledNavBreadcrumbs,
  StyledPageLayout,
  StyledReferences,
  StyledRelatedContent,
  StyledRevisions,
} from './styles';

/*
type AsideToggleButtonProps = {
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  // onClick: MouseClick;
  isCollapsed: boolean;
};
 const AsideToggleButton = ({
  onClick,
  isCollapsed,
}: AsideToggleButtonProps) => {
  return (
    <StyledAsideToggleButton
      onClick={onClick}
      isCollapsed={isCollapsed}
      title="toggle side bar"
    >
      <span className="visually-hidden">Toggle side bar</span>
      {isCollapsed ? (
        <FontAwesomeIcon icon="chevron-down" />
      ) : (
        <FontAwesomeIcon icon="chevron-up" />
      )}
    </StyledAsideToggleButton>
  );
}; */
// DocumentPage
type DocumentPageProps = {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  epiContent: EpiProps;
  epiMainContentArea: EpiProps;
  contentType?: string;
  categories?: LinkProps[];
  guidelineOwner?: string;
  contactEmail?: string;
  relatedItems?: {
    href: string;
    target?: '_self' | '_blank';
    title: string;
  }[];
  uniqueReference?: string;
  files?: FileLinkProps[];
  revisionInfo?: string;
  revisionLink: string;
  revisionLinkLabel: string;
  hideRevisionLink?: boolean;
  backtoRootLink?: {
    href: string;
    title: string;
  };
  lastReviewed?: string;
  // lastUpdated?: string;
  alert?: PageTemplateProps['alert'];
};
const DocumentPage = ({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  epiContent,
  epiMainContentArea,
  contentType,
  categories,
  guidelineOwner,
  contactEmail,
  relatedItems,
  uniqueReference,
  files,
  revisionInfo,
  revisionLink,
  revisionLinkLabel,
  hideRevisionLink = false,
  backtoRootLink,
  lastReviewed = undefined,
  alert,
}: // lastUpdated = undefined,
DocumentPageProps): ReactElement => {
  // const lastReviewedFormatted = lastReviewed && moment(lastReviewed).format('D/MM/YYYY');
  const lastReviewedFormatted = lastReviewed && lastReviewed;

  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout mobilebreakpoint="lg">
          <Sidebar mobilebreakpoint="lg">
            {breadcrumbs && (
              <>
                <StyledNavBreadcrumbs items={breadcrumbs} />
                {backtoRootLink && (
                  <StyledLinkStepBack
                    href={backtoRootLink.href}
                    label={`${backtoRootLink.title} Home`}
                  />
                )}
              </>
            )}
            {revisionInfo && revisionInfo.length > 0 && (
              <>
                <StyledRevisions>
                  <h2>Current version number</h2>
                  <p>{revisionInfo}</p>
                  {!hideRevisionLink && (
                    <a href={revisionLink}>{`${revisionLinkLabel}`}&nbsp;»</a>
                  )}
                </StyledRevisions>
              </>
            )}
            {(categories || contentType || uniqueReference) && (
              <StyledReferences>
                {(categories || contentType) && (
                  <>
                    <h2>Category</h2>
                    {contentType && contentType.length > 0 && (
                      <p>{contentType}</p>
                    )}
                    {categories && (
                      <ul>
                        {categories?.map(({ label }, i) => (
                          <li key={i}>
                            <span>{label}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
                {/* {uniqueReference && uniqueReference.length > 0 && (
                    <span>Unique reference: {uniqueReference}</span>
                  )} */}
              </StyledReferences>
            )}
            {(guidelineOwner || contactEmail) && (
              <StyledAuthor>
                {guidelineOwner && guidelineOwner.length > 0 && (
                  <>
                    <h2>Guideline Owner</h2>
                    <p>{guidelineOwner}</p>
                  </>
                )}
                {contactEmail && contactEmail.length > 0 && (
                  <a href={contactEmail}>Contact Main Roads&nbsp;»</a>
                )}
              </StyledAuthor>
            )}
            {lastReviewedFormatted && (
              <StyledAuthor>
                <>
                  <h2>Page Last Reviewed</h2>
                  <p>{lastReviewedFormatted}</p>
                </>
              </StyledAuthor>
            )}
            {files && <FileLinksList list={files} />}
            {relatedItems && (
              <StyledRelatedContent>
                <h2>Related Documents</h2>
                <ul>
                  {relatedItems.map(item => (
                    <li key={item.title}>
                      <a href={item.href} target={item.target}>
                        {`${item.title}`}&nbsp;»
                      </a>
                    </li>
                  ))}
                </ul>
              </StyledRelatedContent>
            )}
          </Sidebar>
          <div className="page-layout__main">
            <Container>
              <PrintFriendlyButton />
              <EpiProperty
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="h1"
              />
              <EpiProperty
                name={epiContent.name}
                value={epiContent.value}
                isEditMode={epiContent.isEditMode}
                isHTMLContent={epiContent.isHTMLContent}
                outerTag="div"
              />
              <EpiProperty
                name={epiMainContentArea.name}
                value={epiMainContentArea.value}
                isEditMode={epiMainContentArea.isEditMode}
                outerTag="div"
              />
            </Container>
            {/* <PageLastReviewed
              lastReviewed={lastReviewed}
              lastUpdated={lastUpdated}
              pageType="document"
            /> */}
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
};

export default DocumentPage;
