import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledContentListItem = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  transition: background-color 0.3s ease-in-out;

  padding: 28px;
  border-radius: 28px;
  background: #fff;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.22);
  ${breakpoint.greaterThan('sm')`
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.22);
    transition: box-shadow 250ms ease-out, transform 250ms ease-out;
    &:hover {
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.22);
      transform: translate(0, -2px);
    }
  `};
`;

export const StyledContentListItemCallout = styled.div`
  width: 100%;
  height: 100%;
  figure {
    margin: 0;
    padding: 0;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
  }
  img {
    max-width: 100%;
  }

  h2 {
    margin: ${theme.spacing.sm} 0 ${theme.spacing.xs} 0;
    font-size: 1.125em;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.textDark};
    transition: color 0.2s linear;
    ${breakpoint.greaterThan('md')`
      font-size: 1.25em;
    `}
  }

  h4 {
    font-size: 1rem;
    margin: 0.9rem 0rem 0.3rem;
  }

  > span {
    font-size: 0.875rem;
    color: ${theme.colors.textMid};
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    border-bottom: none;
    h2:after {
      content: '\f054';
      display: inline;
      font-family: 'Font Awesome 5 Free';
      font-size: 0.75em;
      text-align: right;
      padding-left: 0.65em;
      font-weight: 900;
    }

    &:hover {
      h2 {
        color: ${theme.colors.linkHover};
        border-bottom: none;
        &:after {
          content: '\f061';
          padding-left: 0.33em;
        }
      }
    }
  }
  .content-card__labels {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    display: flex;
    gap: 0.25em;
    left: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
    /* .is-homepage & {
      display: none;
      } */
  }
  .content-card__label {
    background-color: ${theme.colors.textBg};
    color: ${theme.colors.textLight};
    padding: 0.5em 1.75em;
    font-size: 0.75rem;
    border-radius: 100px;
  }

  ${breakpoint.greaterThan('md')`
      > time {
        font-size: 1rem;
        color: ${theme.colors.textMid};
      }
      figure {
        overflow: hidden;
        img {
          transition: 500ms cubic-bezier(0.33, 0, 0.33, 1);
        }
      }
    `};
  &.compact {
    ${breakpoint.greaterThan('sm')`
      max-width: 900px;

      display: flex;
      gap: 20px;
      h2 {
        margin-top: 0;
        font-size: 1.5rem;
      }

      img {
        max-height: 170px;
      }

      .content-card__labels {
        justify-content: flex-start;
        right: 0.5rem;
        left: 0.5rem;
        font-size: 0.666rem;
      }
      .content-card__label {
        padding: 0.25rem 0.5rem;
      }

      figure {
        // float: left;
        width: 100%;
        max-width: 200px;
        border-radius: 10px;
        // padding-bottom: 10px;
        // float: left;
        // margin-right: 40px;
      }
    `};
  }
`;
