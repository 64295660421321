import React, { ReactElement } from 'react';

import Image from '../../atoms/Image';
import { StyledContentListItem, StyledContentListItemCallout } from './styles';

export interface ContentListItemProps {
  compact?: boolean;
  thumbnail: {
    alt: string;
    href: string;
    image: string;
    target?: string;
    title: string;
    labels?: string[];
  };
  title: {
    href: string;
    text: string;
    target?: string;
  };
  summary: string;
  lastModifiedDate?: string;
}

const ContentListItem = ({
  thumbnail,
  title,
  summary,
  lastModifiedDate,
  compact = false,
}: ContentListItemProps): ReactElement => {
  return (
    <StyledContentListItem>
      <StyledContentListItemCallout className={compact ? 'compact' : ''}>
        <figure>
          <Image
            alt={thumbnail.alt}
            href={thumbnail.href}
            image={thumbnail.image}
            target={thumbnail.target}
            title={thumbnail.title}
            paddingBottom="62.5%"
          >
            {!compact && thumbnail.labels && (
              <div className="content-card__labels">
                {thumbnail.labels.map(label => (
                  <div className="content-card__label">{label}</div>
                ))}
              </div>
            )}
          </Image>
        </figure>
        <div>
          {title.href && title.href !== '' && (
            <a href={title.href} target={title.target} title={title.text}>
              <h2>{title.text}</h2>
            </a>
          )}
          {(!title.href || title.href === '') && title.text && (
            <h2>{title.text}</h2>
          )}
          {!compact && lastModifiedDate && <time>{lastModifiedDate}</time>}
          <p>{summary}</p>
        </div>
      </StyledContentListItemCallout>
    </StyledContentListItem>
  );
};

export default ContentListItem;
