import styled from 'styled-components';
import theme from '../../../lib/theme';
import Button from '../Button';

export const StyledButton = styled(Button)`
  background-color: ${theme.colors.backgroundLight};
`;

type StyledClearingProjectsListItemProps = {
  phaseColor: string;
};

const StyledClearingProjectsListItem = styled.li<
  StyledClearingProjectsListItemProps
>`
  margin: ${theme.spacing.sm} 0 0;
  padding: ${theme.spacing.xs} ${theme.spacing.sm} ${theme.spacing.sm};
  background-color: white;
  .project-title {
    position: relative;
    padding-right: 30px;
    font-size: 1.5rem;
    margin-bottom: 5px;
    color: ${theme.colors.textDark};
  }

  .phase-indicator {
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin-right: 10px;
    background: ${(props): string => props.phaseColor};
  }

  a {
    /* text-decoration: underline; */
    &:hover {
      .project-title {
        text-decoration: underline;
        color: ${(props): string => props.phaseColor};
      }
    }
  }

  .ref-number {
    margin: 0;
  }

  .location-details {
    margin: 0;
    color: ${theme.colors.textMid};
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    margin-right: -30px;
    margin-top: ${theme.spacing.sm};

    &__item {
      max-width: 100%;
      width: 230px;
      padding-right: 30px;
      margin-bottom: 20px;
      &__label {
        margin: 0;
      }
      &__data {
        margin: 0;
        font-weight: ${theme.fontWeight.bold};
      }
    }
  }
`;

export default StyledClearingProjectsListItem;
