import React, { ReactElement } from 'react';
import { ImageProps, LinkProps } from '../../../types';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import {
  ImageSectionStyles,
  ContentStyles,
  StyledFeaturedCTABlock,
} from './styles';
import { BaseBlockProps } from '../../../types'

export interface FeaturedCTABlockProps extends BaseBlockProps {
  img?: ImageProps;
  epiName: EpiProps;
  epiTitle: EpiProps;
  epiContent?: EpiProps;
  primaryLink?: LinkProps;
  // secondaryLink?: LinkProps;
  lightTheme?: boolean;
};

const FeaturedCTABlock = ({
  img,
  epiName,
  epiTitle,
  epiContent,
  primaryLink,
  // secondaryLink,
  lightTheme,
  anchorId
}: FeaturedCTABlockProps): ReactElement => {
  return (
    <StyledFeaturedCTABlock id={anchorId} className={`${lightTheme ? 'light' : ''}`}>
      <div className="featured-cta-block__inner">
        {img && !img.usePicture && (
          <ImageSectionStyles>
            <img src={img.src} alt={img.alt} />
          </ImageSectionStyles>
        )}
        {img && img.usePicture && (
          <ImageSectionStyles
            dangerouslySetInnerHTML={{ __html: img.picture }}
          >
          </ImageSectionStyles>
        )}
        <ContentStyles className={`${lightTheme ? 'light' : ''}`}>
          <div>
            <EpiProperty
              name={epiName.name}
              value={epiName.value}
              isEditMode={epiName.isEditMode}
              className="subtitle"
              outerTag="p"
            />
            <EpiProperty
              name={epiTitle.name}
              value={epiTitle.value}
              isEditMode={epiTitle.isEditMode}
              outerTag="h2"
            />
            {epiContent && (
              <EpiProperty
                name={epiContent.name}
                value={epiContent.value}
                isEditMode={epiContent.isEditMode}
                outerTag="p"
              />
            )}
          </div>
          <div className="buttons">
            {primaryLink && (
              <a
                href={primaryLink.href}
                className="gtm-track"
                target={primaryLink.target ? primaryLink.target : '_self'}
                title={primaryLink.label}
              >
                {primaryLink.label}
              </a>
            )}
          </div>
        </ContentStyles>
      </div>
    </StyledFeaturedCTABlock>
  );
};

export default FeaturedCTABlock;
