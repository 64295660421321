import styled from 'styled-components';

import { breakpoint } from '../../../lib/theme';

export const StyledImageCaptionBlockItem = styled.div`
  /* grid-area: ${({ grid }: any): string =>
    // eslint-disable-next-line no-nested-ternary
    grid}; */
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

type CaptionBlockProps = {
  bgColor: string;
  txtColor: string;
};
export const StyledImageCaptionBlockContent = styled.div<CaptionBlockProps>`
  height: 100%;
  min-height: 90px;
  padding: 1.5em 2em 1em 2em;
  background-color: ${({ bgColor }: any): string => bgColor};
  border-radius: 0 0 0.5rem 0.5rem;

  ${breakpoint.greaterThan('sm')`
    min-height: 110px;
    padding: 1.5em 1em 1em 1em;
  `};

  p {
    margin: 0;
    color: ${({ txtColor }: any): string => txtColor};
  }
  h2 {
    margin: 0;
    font-size: 1.25rem;
    color: ${({ txtColor }: any): string => txtColor};

    ${breakpoint.greaterThan('lg')`
      font-size: 1.5rem;
    `}
  }

  a {
    display: block;
    color: ${({ txtColor }: any): string => txtColor};
    font-size: 1rem;
    border: none;

    &:hover {
      text-decoration: underline;
      border: none;
    }

    ${breakpoint.greaterThan('lg')`
      font-size: 1.125rem;
    `}
  }
`;

export const StyledTextCaptionBlockContent = styled.div<CaptionBlockProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  background-color: ${({ bgColor }: any): string => bgColor};
  color: ${({ txtColor }: any): string => txtColor};
  text-align: center;

  ${breakpoint.greaterThan('md')`
    border-radius: 5px;
  `};
  ${breakpoint.greaterThan('lg')`
    min-height: 300px;
    // min-height: 360px;
  `}

  h2 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
    ${breakpoint.greaterThan('md')`
      font-size: 1.25rem;
    `};
    ${breakpoint.greaterThan('lg')`
      font-size: 1.5rem;
    `};
    ${breakpoint.greaterThan('xl')`
      font-size: 2rem;
    `};
  }

  p {
    margin: 0;
    color: ${({ txtColor }: any): string => txtColor};
  }
  a {
    color: ${({ txtColor }: any): string => txtColor};
    font-size: 1rem;
    border: none;

    &:hover {
      text-decoration: underline;
      border: none;
    }

    ${breakpoint.greaterThan('lg')`
      font-size: 1.125rem;
    `}
  }

  span {
    font-size: 1.25rem;

    ${breakpoint.greaterThan('lg')`
      font-size: 1.95rem;
    `}
  }
`;
