import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledNewsBlock = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

export const ImageStyled = styled.a`
  display: block;
  overflow: hidden;
  border-radius: 5px;
  will-change: transform;
  border: none;
  &:hover {
    border: none;
    img {
      transform: scale(1.05);
    }
  }
  img {
    width: 100%;
    height: 50vh;
    max-height: 240px;
    object-fit: cover;
    transition: 500ms cubic-bezier(0.14, 0.07, 0.18, 0.96);
  }
`;

export const TitleStyled = styled.a`
  && {
    color: ${theme.colors.contentBlockArticleTitle};
  }
  display: block;
  padding-top: 12px;
  margin-top: 0;
  margin-bottom: 12px;
  border: none;
  ${breakpoint.greaterThan('sm')`
    padding-top: 20px;
  `};
  ${breakpoint.greaterThan('md')`
    padding-top: 30px;
  `};
  h2 {
    margin: 0;
    line-height: 1.3;
    font-size: 1em;
    transition: font-size 100ms ease-out;
    ${breakpoint.greaterThan('md')`
      font-size: 1.5em;
    `};
    &:after {
      content: '\f054';
      display: inline;
      font-family: 'Font Awesome 5 Free';
      font-size: 0.75em;
      text-align: right;
      font-weight: 900;
      padding-left: 0.65em;
    }
  }
  &:hover {
    border: none;
    && {
      text-decoration: none;
    }
    h2:after {
      content: '\f061';
      padding-left: 0.33em;
    }
  }
`;

export const ExcerptStyled = styled.p`
  && {
    display: block;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 0.875em;
    ${breakpoint.greaterThan('md')`
    font-size: 1.125em;
    `};
  }
`;

export const DateTimeStyled = styled.p`
  && {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 0.75em;
    ${breakpoint.greaterThan('md')`
    font-size: 1em;
    `};
  }
`;

type TagStyledProps = {
  related: boolean;
};
export const TagStyled = styled.div<TagStyledProps>`
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
  }
  li {
    margin-left: 0.25em;
    margin-bottom: 0.25em;
  }
  a {
    display: block;
    border: none;
    font-weight: 500;
    padding: 0.5em 2em;
    font-size: 0.875em;

    background: ${theme.colors.tagBg};
    color: ${theme.colors.white} !important;

    && {
    }
    &:hover {
      border: none;
      transform: translate(0, -1px);
      color: ${theme.colors.white};
    }
  }
  span {
    display: block;
    background: ${props =>
      props.related ? theme.colors.primaryAlt : theme.colors.primary};
    background: ${theme.colors.tagBg};
    color: ${theme.colors.white};

    font-weight: 500;
    padding: 0.5em 2em;
    font-size: 0.875em;
  }
`;
