import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { breakpoint } from '../../../lib/theme';
import NavLinkList, { NavLinkListProps } from '../../blocks/NavLinkList';

const StyledNavLinkListGroup = styled.div`
  padding: 3.75rem 0;

  display: flex;
  flex-wrap: wrap;
  background-color: #191919;

  .nav-link-list__item {
    width: calc(50% - 1.26rem);
    margin-right: 2.5rem;
    margin-bottom: 2.5rem;

    ${breakpoint.lessThan('sm')`
      &:nth-child(2n) {
        margin: 0;
      }
    `}

    ${breakpoint.greaterThan('sm')`
      width: calc(25% - 1.88rem);
    `}

    ${breakpoint.between('sm', 'lg')`
      &:nth-child(4n) {
        margin: 0;
      }
    `}

    ${breakpoint.greaterThan('lg')`
      width: calc(14.5% - 2.36rem); // 100%/7 - margin (excluding last element margin)

      &:nth-child(7n) {
        margin: 0;
      }
    `}
  }
`;

export interface NavLinkListGroupProps {
  lists: NavLinkListProps[];
}

function NavLinkListGroup({ lists }: NavLinkListGroupProps): ReactElement {
  return (
    <StyledNavLinkListGroup>
      {lists.map((list, i) => (
        <div className="nav-link-list__item" key={i}>
          <NavLinkList
            href={list.href}
            target={list.target}
            title={list.title}
            items={list.items}
          />
        </div>
      ))}
    </StyledNavLinkListGroup>
  );
}

export default NavLinkListGroup;
