import { generateMedia } from 'styled-media-query';

const palette = {
  abbey: '#585c62',
  black: '#000',
  deepSeaGreen: '#0A4D66',
  doveGray: '#707070',
  mineShaft: '#333333',
  shark: '#2d2f32',
  silver: '#bababa',
  surfieGreen: '#0C8072',
  tiaMaria: '#d14210',
  tundora: '#4c4c4c',
  wildSand: '#f5f5f5',
  white: '#fff',
  gray: '#7d7d7d',
};

/* export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  container = 'container',
  xl = 'xl',
} */

/* 
ye olden settaengs:
xs: '22.5rem', // 360px
sm: '37.5rem', // 600px
mdminus1: '959px',
md: '60rem', // 960px
mdplus1: '961px',
lg: '80rem', // 1280px
container: '97.5rem', // 1560px
xl: '120rem', // 1920px */

const breakpoints = {
  xs: '22.5rem', // 360px
  sm: '36rem', // 576
  mdminus1: '767px',
  md: '48rem', // 768px
  mdplus1: '769px',
  lg: '60rem', // 960px
  // lg: '62rem', // 992px
  xl: '75rem', // 1200px
  container: '90rem', // 1440px
  // container: '97.5rem', // 1560px
  // container: '106.25rem', // 1700px
  huge: '112.5rem', // 1800px
  massive: '120rem', // '1920px'
};

export const HEIGHT_BREAKPOINT = 890;

export const breakpoint = generateMedia(breakpoints);

const theme = {
  breakpoints,
  elevation: [
    '0px 0px 0px rgba(0, 0, 0, 0)',
    '0px 3px 6px rgba(0, 0, 0, 0.16)',
    '0px 6px 6px rgba(0, 0, 0, 0.16)',
  ],
  colors: {
    alert: palette.tiaMaria,
    addressBg: palette.surfieGreen,
    backgroundDark: palette.shark,
    backgroundExtraDark: palette.black,
    backgroundLight: palette.wildSand,
    breadcrumb: palette.doveGray,
    buttonBg: palette.shark,
    buttonHover: palette.tiaMaria,
    buttonToggle: palette.deepSeaGreen,
    buttonText: palette.white,
    contentBlockArticleTagRelated: palette.surfieGreen,
    contentBlockArticleTitle: palette.black,
    ctaBlockCalloutBg: palette.tundora,
    ctaBlockCalloutText: palette.tundora,
    documentListBg: palette.wildSand,
    documentListFilter: palette.shark,
    documentListFilterReverse: palette.white,
    documentListItemBg: palette.white,
    documentListItemDate: palette.doveGray,
    documentListItemText: palette.black,
    errorStatus: palette.white,
    error: palette.tiaMaria,
    formBorder: palette.silver,
    formBtnText: palette.doveGray,
    formText: palette.black,
    formInputBorder: palette.black,
    headerBg: palette.shark,
    headerBtn: palette.white,
    headerSearchBorder: palette.abbey,
    headerSearchText: palette.white,
    headerSearchPlaceholderText: palette.silver,
    linkActive: palette.tiaMaria,
    linkColor: palette.tundora,
    linkFocus: palette.tiaMaria,
    linkHover: palette.tiaMaria,
    navMainBg: palette.white,
    navMainBorder: palette.wildSand,
    navLinkColor: palette.black,
    navMobileBg: palette.wildSand,
    navMobileBorder: palette.silver,
    navSubBg: palette.shark,
    navSubText: palette.white,
    paginationBorder: palette.silver,
    paginationButtonBg: palette.black,
    paginationButtonColor: palette.white,
    paginationButtonHover: palette.tiaMaria,
    paginationText: palette.mineShaft,
    placeholderText: palette.silver,
    primary: palette.tiaMaria,
    primaryAlt: palette.surfieGreen,
    progressBar: palette.tiaMaria,
    promoBlockTitleBgRed: palette.tiaMaria,
    promoBlockTitleBgBlue: palette.deepSeaGreen,
    promoBlockTitleBgGreen: palette.surfieGreen,
    promoBlockTitleBgBlack: palette.shark,
    searchInputBorder: palette.silver,
    searchBtnText: palette.doveGray,
    searchFormText: palette.black,
    sidebarBg: palette.wildSand,
    seperator: palette.silver,
    tabTitle: palette.silver,
    tabTitleActive: palette.tiaMaria,
    tag: palette.tiaMaria,
    tagBg: palette.shark,
    textBg: palette.shark,
    textDark: palette.black,
    textLight: palette.white,
    textMid: palette.doveGray,
    titleColor: palette.black,
    titleColorEmphasis: palette.tiaMaria,
    travelMapToggle: palette.shark,
    travelMapButtonBg: palette.shark,
    travelMapFilterBg: palette.surfieGreen,
    travelMapMarkerDefaultBg: palette.shark,
    inputFocus: palette.tiaMaria,
    inputBg: palette.shark,
    bgLight: palette.white,
    bgDark: palette.shark,
    borderMediumLight: palette.silver,
    borderMedium: palette.doveGray,
    borderLight: palette.white,
    borderDark: palette.shark,
    white: palette.white,
  },

  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },

  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    sm: '1.25rem',
    md: '2.5rem',
    lg: '3.75rem',
  },
};

export default theme;
