import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { TravelMapViewStyled } from './TravelMapViewStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import mapStyle from './GoogleMapStyle';
import { TravelMapContext } from '../../organisms/TravelMapDashboard';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { CustomClusterRenderer } from './CustomClusterRenderer';
import { intToLetter } from '../../../lib/util';
import { FaRedoAlt } from 'react-icons/fa';
import theme from '../../../lib/theme';
import createInfoWindow from './InfoWindowCreator';
import Button from '../../atoms/Button';

const directionsMarkerOptions = {
  zIndex:9999999,
  label: {
    color: "rgba(255,255,255,1)",
    fontSize: "16px",
    fontWeight: "600",
  },
  // animation: 2 as google.maps.Animation.DROP,
} as google.maps.MarkerOptions

const directionsRendererOptions = {
  //markerOptions: directionsMarkerOptions,
  suppressMarkers: true,
} as google.maps.DirectionsRendererOptions;

let mapOptions = {
  center: {
    lat: -24.8950836,
    lng: 117.49347,
  },
  zoom: 6,
  gestureHandling: 'cooperative',
  fullscreenControl: true,
  streetViewControl: false,
  mapTypeControl: false,
} as google.maps.MapOptions;

interface TravelMapViewProps {
  panel: boolean;
  setPanel: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TravelMapView = ({ panel, setPanel, setOpen }: TravelMapViewProps) => {
  const mapDiv = useRef();

  const [loaded, setLoaded] = useState(false);

  const [clusterer, setClusterer] = useState(null);
  const [trafficLayer, setTrafficLayer] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [directionsMarkers, setDirectionsMarkers] = useState([]);
  const [directionsRouteListener, setDirectionsRouteListener] = useState(null);
  const [currentWaypoints, setCurrentWaypoints] = useState([]);
  // Markers and Info Windows
  const [markers, setMarkers] = useState([]);
  const [currentMarkers, setCurrentMarkers] = useState([]);
  const [infoWindow, setInfoWindow] = useState(null);
  // Seperate marker not in cluser to facilitate hidden markers and clusters
  const [infoMarker, setInfoMarker] = useState(null);
  // Reference for both point and line features
  const [pois, setPois] = useState([]);
  const [customMapStyle, setCustomMapStyle] = useState(true);

  const { state, dispatch } = useContext(TravelMapContext);
  const { mobile, google, map, info, traffic = false, journey: { date, waypoints }, alerts: { features, filteredFeatures, alerts, filtersRestArea } } = state;

  // Reset map view
  const resetView = () => {
    if (map) {
      map.setZoom(mapOptions.zoom);
      map.panTo(mapOptions.center);
      setInfo(null);
    }
  }

  // Toggle Map Style
  const toggleMapStyle = () => {
    if (map) {
      if (customMapStyle) {
        map.setMapTypeId('roadmap');
      } else {
        map.setMapTypeId('styled_map');
      }
      setCustomMapStyle(!customMapStyle);
    }
  }

  // ========================
  // Info Window
  // ========================

  // Set info window state
  const setInfo = (id) => {
    const action = { type: 'INFO_SET', value: { id } };
    dispatch(action);
  }

  // Remove info window marker
  const removeInfoMarker = () => {
    if (infoMarker) {
      infoMarker.setMap(null);
      setInfoMarker(null)
    }
  }

  // Listen for info changes
  useEffect(() => {
    if (infoWindow) {
      if ((info.id || info.id === 0) && pois.length) {
        const feature = pois.find(poi => poi?.feature?.properties?.Id === info.id);
        if (feature) {
          // Ensure window is closed before changing map view
          infoWindow.close();
          // If polyline fit map view to line bounds
          if (feature?.feature?.geometry?.type === 'LineString') {
            // IF string calc bounds of polyine and midway point of path for info window anchor
            const bounds = feature?.getBounds();
            const path = feature?.getPath();
            const pathMidpoint = Math.round((path?.getLength() - 1) / 2)
            const anchor = path.getAt(pathMidpoint);

            map.fitBounds(bounds, 20)
            openInfoWindow(feature, feature?.feature, anchor);
          } else if (feature?.position) {
            map.setZoom(18);
            map.panTo(feature?.position);
            openInfoWindow(feature, feature?.feature);
          }
        } else {
          // If not found check in non visible features
          const hiddenFeature = [].concat.apply([], features).find(feature => feature?.properties?.Id === info.id && feature?.geometry?.type === 'Point');
          if (hiddenFeature) {
            // Remove existing info marker
            removeInfoMarker();
            // Create marker not already available
            const marker = createFeatureMarker(hiddenFeature);
            marker.setMap(map);
  
            // Add marker click listener to open info window
            marker.addListener("click", () => {
              setInfo(hiddenFeature?.properties?.Id)
            });

            // set marker in state for later reference
            setInfoMarker(marker);

            map.setZoom(18);
            map.panTo(marker?.position);

            openInfoWindow(marker, hiddenFeature);
          }
        }
      } else if (info.id !== 0 && !info.id) {
        // If info wind is disabled close and update url
        infoWindow.close();
      }
    }
  },[info])

  const openInfoWindow = (marker, feature, latLng?: google.maps.LatLng) => {
    // Close info window
    infoWindow.close();

    // Create and set info for feature
    const infoContent = createInfoWindow(feature, filtersRestArea);
    infoWindow.setContent(infoContent);

    // Open info window at specified LatLng or marker coord
    if (latLng) { 
      infoWindow.setPosition(latLng);
      infoWindow.open({map, shouldFocus: true});
    } else {
      infoWindow.open({map, shouldFocus: true}, marker);
    }
  }

  const toggleTrafficMap = (toggle: boolean) => {
    if (toggle) {
      trafficLayer.setMap(map);
    } else {
      trafficLayer.setMap(null);
    }
  }

  // ========================
  // Directions and Waypoints
  // ========================

  // Clear custom direction markers for journey routes
  const clearDirectionsMarkers = () => {
    if (directionsMarkers.length) {
      directionsMarkers.forEach(marker => {marker.setMap(null)});
      setDirectionsMarkers([]);
    }
  }

  const updateDirectionsMarker = (marker) => {
    const { title, location } = marker
    // If not same marker remove and cereate new one else recenter map view
    if (directionsMarkers[0]?.position !== location && directionsMarkers[0]?.title !== title) {
      if (directionsMarkers[0]) {
        directionsMarkers.forEach(marker => {marker.setMap(null)});
        setDirectionsMarkers([]);
      }

      const singleMarkerOptions = {
        ...directionsMarkerOptions,
        label: {
          text: 'A',
          color: "rgba(255,255,255,1)",
          fontSize: "16px",
          fontWeight: "600",
        },
      }
      // Init new single waypoint marker
      const initDirectionsMarker = new google.maps.Marker({
        position: location,
        map,
        title: title,
        ...singleMarkerOptions,
      });

      // Pan and zoom to location
      map.setZoom(10);
      map.panTo(location);

      setDirectionsMarkers([initDirectionsMarker]);
    } else {
      // Pan and zoom to location
      map.setZoom(10);
      map.panTo(location);
    }
    // Clear extra multiple markers if they exist
    if (directionsMarkers.length > 1) {
      for (let i = 1; i < directionsMarkers.length;i++ ) {
        directionsMarkers[i].setMap(null)
      }
      setDirectionsMarkers(directionsMarkers.slice(0,1));
    } 
  }

  // Calculate alerts on routes
  const calcRouteAlerts = (result, index) => {
    // Get active route and init route polyline
    const activeRoute = result.routes[index];
    const routePolyline = new google.maps.Polyline({
      path: []
    });

    // Iterate over route legs, steps and segments to make route polyline
    for (let i = 0; i < activeRoute.legs.length; i++) {
      const steps = activeRoute.legs[i].steps;
      for (let j = 0; j < steps.length; j++) {
        const nextSegment = steps[j].path;
        for (let k = 0; k < nextSegment.length; k++) {
          routePolyline.getPath().push(nextSegment[k]);
        }
      }
    }

    const alertTolerance = 10e-5;

    // Calculate Alerts along route
    // First initally filter by polyline bounds
    const filteredBoundsAlerts = alerts.filter(alert => {
      if (alert?.geometry?.type === 'Point' && alert?.geometry?.coordinates) {
        const coord = alert.geometry.coordinates;

        // Extend bounds by tolerance
        const bounds = activeRoute.bounds.toJSON();
        bounds.north += alertTolerance;
        bounds.south -= alertTolerance;
        bounds.west -= alertTolerance;
        bounds.east += alertTolerance;

        const newBounds = new google.maps.LatLngBounds({lat: bounds.south, lng: bounds.west}, {lat: bounds.north, lng: bounds.east});
        return newBounds.contains(new google.maps.LatLng(coord[1], coord[0]));
      } else {
        return false
      }
    })

    // Next filter by proximity to line, using in google maps built isLocationOnEdge
    const filteredAlerts = filteredBoundsAlerts.filter(alert => {
      const coord = alert.geometry.coordinates;
      return google.maps.geometry.poly.isLocationOnEdge(new google.maps.LatLng(coord[1], coord[0]), routePolyline, alertTolerance)
    })

    const groupedAlerts = filteredAlerts.reduce((alertGroups, currentAlert) => {
      if (currentAlert?.featureType?.DisplayName) {
        if (alertGroups?.[currentAlert?.featureType?.DisplayName]) {
          alertGroups?.[currentAlert?.featureType?.DisplayName].push(currentAlert);
        } else {
          alertGroups[currentAlert?.featureType?.DisplayName] = [currentAlert];
        }
        return alertGroups;
      }
    }, {})

    // Update route alert state
    const action = { type: 'JOURNEY_SET_ALERTS', value: groupedAlerts };
    dispatch(action);
  }

  const displayRoute = (waypts: google.maps.DirectionsWaypoint[]) => {
    // Clone waypoints and remove first and last element for origin and destination
    let newWaypts = [...waypts]
    const origin = newWaypts.shift().location;
    const destination = newWaypts.pop().location;
    newWaypts = newWaypts.map(waypt => ({ location: waypt?.location, stopover: true } as google.maps.DirectionsWaypoint));

    directionsService
      .route({
        origin,
        destination,
        waypoints: newWaypts,
        provideRouteAlternatives: true,
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: date ? new Date(date) : new Date(),
        }
      })
      .then((response) => {
        // Directions route and steps panel
        directionsRenderer.setDirections(response);
        directionsRenderer.setPanel(
          document.getElementById('travel-map-directions__route') as HTMLElement
        );

        // Update directions with state response
        const action = { type: 'JOURNEY_SET_DIRECTIONS', value: response };
        dispatch(action);

        const activeRoute = response.routes[0];

        // Create custom direction markers and create polyline
        const newMarkers = [];
        for (let i = 0; i < activeRoute.legs.length; i++) {
          // Set letter based on index
          const markerOptions = {
            ...directionsMarkerOptions,
            label: {
              text: intToLetter(i, true),
              color: "rgba(255,255,255,1)",
              fontSize: "16px",
              fontWeight: "600",
            },
          }
          let marker = new google.maps.Marker({
              position: activeRoute.legs[i].start_location,
              map: map,
              ...markerOptions,
          });
          newMarkers.push(marker);
        }

        // Create Last directions marker
        const lastMarkerOptions = {
          ...directionsMarkerOptions,
          label: {
            text: intToLetter(activeRoute.legs.length, true),
            color: "rgba(255,255,255,1)",
            fontSize: "16px",
            fontWeight: "600",
          },
        }
        const lastMarker = new google.maps.Marker({
            position: activeRoute.legs[activeRoute.legs.length - 1].end_location,
            map: map,
            ...lastMarkerOptions,
        });
        newMarkers.push(lastMarker);
        setDirectionsMarkers(newMarkers);

        // Calc inital route alerts
        calcRouteAlerts(response, 0);


        if (directionsRouteListener) google.maps.event.removeListener(directionsRouteListener);

        // Listen for route change to re-calculate alerts
        const listener = google.maps.event.addListener(directionsRenderer, 'routeindex_changed', () => {
          calcRouteAlerts(directionsRenderer.getDirections(), directionsRenderer.getRouteIndex());
        })

        setDirectionsRouteListener(listener);

        // set map
        directionsRenderer.setMap(map);
      })
      .catch((e) => console.error("Directions request failed due to " + e));
  }

  useEffect(() => {
    // Filter waypoints for empty inputs
    const filteredWaypoints = waypoints.filter(waypt => waypt?.title?.trim() && waypt?.location);
    if (google && map) {
      if (filteredWaypoints.length > 1) {
        // Check if same filtered waypoints same returns true if all match or false if any differences are found
        const filterCheck = filteredWaypoints.every((waypt, i) => waypt.location === currentWaypoints[i]?.location)
        if (!filterCheck) {
          // Clear single directions marker if exists
          clearDirectionsMarkers();

          // Close info window if open
          if (info.id || info.id === 0) setInfo(null);

          // Clear existing directions
          directionsRenderer.setMap(null);

          // Create new route
          displayRoute(filteredWaypoints);
        }
      } else if (filteredWaypoints.length === 1) {
        // Close info window if open
        if (info.id || info.id === 0) setInfo(null);

        // Clear existing directions
        directionsRenderer.setMap(null);

        // Update single marker
        updateDirectionsMarker(waypoints[0]);
      } else {
        // Clear single directions marker if exists
        if (directionsMarkers.length) {
          directionsMarkers.forEach(marker => {marker.setMap(null)});
          setDirectionsMarkers([]);
        }
        // Clear existing directions
        directionsRenderer.setMap(null);
      }
      // Update current waypoints
      setCurrentWaypoints(filteredWaypoints);
    }
  }, [waypoints, date]);

  // Listen for mobile changes and reset directions renderer panel, as journey component is conditionally rendered to meet mobile design changes
  useEffect(() => {
    directionsRenderer?.setPanel(
      document.getElementById('travel-map-directions__route') as HTMLElement
    );
  }, [mobile])

  // ========================
  // Features
  // ========================

  // Create feature marker
  const createFeatureMarker = (feature) => {
    const coords = feature?.geometry?.coordinates;
    return new google.maps.Marker({
      title: feature?.properties?.Descriptio || feature?.properties?.EventDescr || feature?.properties?.SignalStat || feature?.properties?.Location || '',
      position: new google.maps.LatLng(coords[1], coords[0]),
      icon: {
        url: feature.featureType?.IconUrl + '--point.png',
        scaledSize: new google.maps.Size(38, 38),
      },
      feature: feature,
    });
  }

  // Add feature markers
  useEffect(() => {
    if (clusterer) {
      // Remove existing info marker on filter toggle
      removeInfoMarker();

      // Merge arrays
      const featuresList = [].concat.apply([], filteredFeatures);

      const newMarkers = [];
      const newLines = [];

      for (let i = 0; i < featuresList.length; i++) {
        if (featuresList[i]?.geometry?.type === 'Point' && featuresList[i]?.geometry?.coordinates) {
          // Create Marker
          const marker = createFeatureMarker(featuresList[i])

          newMarkers.push(marker);

          // Add marker click listener to open info window
          marker.addListener("click", () => {
            setInfo(featuresList[i]?.properties?.Id)
          });
        } else if (featuresList[i]?.geometry?.type === 'LineString' && featuresList[i]?.geometry?.coordinates?.length) {
          // Convert coords to LatLng map type array
          const coords = featuresList[i]?.geometry?.coordinates.map(coord => new google.maps.LatLng(coord[1], coord[0]));
          const line = new google.maps.Polyline({
            path: coords,
            geodesic: true,
            strokeColor: featuresList[i].featureType?.IconColor ?? theme.colors.bgDark,
            strokeOpacity: 1.0,
            strokeWeight: 6,
            feature: featuresList[i],
          });
          line.setMap(map);

          newLines.push(line);

          // Add marker click listener to open info window
          line.addListener("click", (event) => {
            setInfo(featuresList[i]?.properties?.Id)
          });
        }
      }
      
      // Reference both points and lines, but keeping lines before hand so that info find the line bounds before finding duplicate marker
      setPois(newLines.concat(newMarkers))
      
      // Set initial view Markers
      let currentMarkers = [];
      clusterer.clearMarkers();
      setMarkers(newMarkers);
      clusterer.addMarkers(newMarkers);

      // Not sure if this improves performance
      /* clusterer.addMarkers(currentMarkers = newMarkers.filter(marker => 
        marker.getVisible() && map.getBounds().contains(marker.getPosition())
      ));
      setCurrentMarkers(currentMarkers); */

      // Add listen for tile load to dynamically add markers to clusterer based on current map view
      // https://tighten.com/blog/improving-google-maps-performance-on-large-datasets/
      /* google.maps.event.addListener(map, "tilesloaded", () => {
        clusterer.clearMarkers();
        //clusterer.addMarkers(newMarkers);
        clusterer.addMarkers(currentMarkers = newMarkers.filter(marker => 
          marker.getVisible() && map.getBounds().contains(marker.getPosition())
        ));
        setCurrentMarkers(currentMarkers);
      }); */

      // If first load and markers are in, check if search param for feature in url and open info window
      if (!loaded && newMarkers.length) {
        // get url feature parameter
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseInt(urlParams.get('fid'));

        // Check if defined and is a number
        if (!Number.isNaN(id)) {
          // Update info window context and loaded state
          setInfo(id);
          setLoaded(true);

          // Create onclick function for inital active info window
          setTimeout(() => document.getElementById('info-window__share-button')?.addEventListener('click', () => {
            document.getElementById('info-window__share')?.classList.toggle('info-window__share--active')
          }), 100)
        }
      }
    }
  }, [filteredFeatures, clusterer]);

  // ========================
  // Generic and Initialisation
  // ========================

  // Watch to toggle traffic map
  useEffect(() => {
    if (trafficLayer && google && map) {
      toggleTrafficMap(traffic);
    }
  }, [traffic]);

  // Setup map and other services wehn google is loaded
  useEffect(() => {
    if (google) {

      const initMap = new google.maps.Map(
        mapDiv.current,
        mapOptions);

      initMap.mapTypes.set('styled_map', new google.maps.StyledMapType(mapStyle as any[], { name: "Styled" }));
      initMap.setMapTypeId('styled_map');

      // Set map in context
      const setMap = { type: 'MAP_SET', value: initMap };
      dispatch(setMap);

      // Init traffic layer
      const initTrafficLayer = new google.maps.TrafficLayer();
      setTrafficLayer(initTrafficLayer);

      // Init Clusterer for markers
      const initClusterer = new MarkerClusterer({
        map: initMap,
        markers: [],
        renderer: new CustomClusterRenderer,
        algorithm: new SuperClusterAlgorithm({
          minPoints: 3,
          maxZoom: 15,
        }),
      });
      setClusterer(initClusterer);

      // Init Info Window
      const initInfoWindow = new google.maps.InfoWindow({
        content: ''
      });
      // Add share button toggle function
      google.maps.event.addListener(initInfoWindow, 'domready', () => {
        setTimeout(() => {
          document.getElementById('info-window__share-button')?.addEventListener('click', () => {
            document.getElementById('info-window__share')?.classList.toggle('info-window__share--active')
          })
          
          document.getElementById('info-window__share')?.addEventListener('click', (e) => {
            const target = e?.target as HTMLElement
            navigator.clipboard.writeText(target?.innerText).then(() => {
              document.getElementById('info-window__share').classList.add('info-window__share--copied');
            }, (error) => {
              console.error(error);
            });
          })
        }, 100)
      })

      // Update info state if window closed
      google.maps.event.addListener(initInfoWindow, 'closeclick', () => {
        // Remove info marker if exists
        if (!info.id && info.id !== 0) {
          setInfo(null);
        }
      })

      setInfoWindow(initInfoWindow);

      // Init directions service
      const initDirectionsService = new google.maps.DirectionsService();
      const initDirectionsRenderer = new google.maps.DirectionsRenderer(directionsRendererOptions);
      setDirectionsService(initDirectionsService);
      setDirectionsRenderer(initDirectionsRenderer);

      // Direction marker options
      const directionsMarkerIcon = {
        url: `/images/travel-map/icon_default--point.png`,
        scaledSize: new google.maps.Size(42,42),
        labelOrigin: new google.maps.Point(21,16),
      } as google.maps.Icon

      directionsMarkerOptions.icon = directionsMarkerIcon;

      // Add method to calc Polyline bounds, built-in getBounds was removed from GoogleMaps JS API v3
      // https://stackoverflow.com/questions/3284808/getting-the-bounds-of-a-polyline-in-google-maps-api-v3
      google.maps.Polyline.prototype.getBounds = function() {
        let bounds = new google.maps.LatLngBounds();
        this.getPath().forEach(function(item, index) {
            bounds.extend(new google.maps.LatLng(item.lat(), item.lng()));
        });
        return bounds;
      };
    }
  }, [google]);

  return (
    <TravelMapViewStyled
    >
      <Button
        className={'travel-map-dashboard__filter-toggle'}
        color={theme.colors.travelMapFilterBg}
        iconEnd={panel ? 'times' : 'angle-right'}
        handleOnClick={(): void => { setPanel(!panel); setOpen(true) }}
        theme={'travel'}
      >
        Filters & legend
      </Button>
      <button
        className={'travel-map-view__reset'}
        onClick={(): void => { resetView() }}
        title="Reset Map View"
        aria-label="Reset Map View"
      >
        <FaRedoAlt />
      </button>
      <button
        className={'travel-map-view__style'}
        onClick={(): void => { toggleMapStyle() }}
        title="Toggle Map Style"
        aria-label="Toggle Map Style"
      >
        {!!customMapStyle ? (
          <img src="/images/travel-map/map-style-control-image--default.jpg" alt="Google Maps Default Style" width="60" height="60" />
        ) : (
          <img src="/images/travel-map/map-style-control-image--styled.jpg" alt="Styled Map" width="60" height="60" />
        )}
      </button>
      <div 
        className={'travel-map-view__map'}
        ref={mapDiv}
      >
      </div>
    </TravelMapViewStyled>
  );
};

export default TravelMapView;