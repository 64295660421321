import React, { ReactElement } from 'react';

import { StyledHVSTravelImpactListItem } from './styles';

interface HVSTravelImpactListItemProps {
  // id: number;
  status: string;
  referenceNumber: string;
  suburbs?: string[];
  regions?: string[];
  roadName: string;
  direction: string;
  //coordinates: string;
  //googleMapLink: string;
  restrictionTypes?: string[];
  commencementDate: string;
  endDate: string;
  //groundClearanceRequired: string;
  //seekAlternateRoute: string;
  details: string;
  detailsTitle: string;
  contactFullName: string;
  contactNumber: string;
  contactEmail: string;
  //createdBy: string;
  timeOfImpact: string;
  osomDetails: string;
  ravDetails: string;
  hasOSOMRestriction: boolean;
  hasRAVRestriction: boolean;
}

const HVSTravelImpactListItem = ({
  // id,
  status,
  referenceNumber,
  suburbs,
  regions,
  roadName,
  direction,
  //coordinates,
  //googleMapLink,
  restrictionTypes,
  commencementDate,
  endDate,
  //groundClearanceRequired,
  //seekAlternateRoute,
  details,
  detailsTitle,
  contactFullName,
  contactNumber,
  contactEmail,
  //createdBy,
  timeOfImpact,
  osomDetails,
  ravDetails,
  hasOSOMRestriction,
  hasRAVRestriction
}: HVSTravelImpactListItemProps): ReactElement => {
  return (
    <StyledHVSTravelImpactListItem>
      <div className="accreditation-list-item">
        <h2 className="accreditation-list-item__title">
          {referenceNumber && <span>{referenceNumber}</span>}
        </h2>
        {restrictionTypes && (
            <div className="content-card__labels">
              {restrictionTypes.map(label => (
                <div className="content-card__label">{label}</div>
              ))}
            </div>
          )}
        <h3 className="accreditation-list-item__subtitle">
          {roadName && <span>{roadName}</span>}
        </h3>
        {suburbs &&
         <p className="accreditation-list-item__suburbs">
            {suburbs.map((item, i) => (
              <b key={i} className="accreditation-list-item__suburb__item">
                {item}{i+1 < suburbs.length && ' | '} 
              </b>
            ))}
          </p>
        }
        {regions &&
         <p className="accreditation-list-item__regions">
           <b>Regions: </b>
          {regions.map((item, i) => (
            <span key={i} className="accreditation-list-item__region__item">
              {item}{i+1 < regions.length && ' | '}  
            </span>
          ))}
        </p>
        }
        {direction && <p className="accreditation-list-item__direction">{direction}</p>}
        {commencementDate && <p className="accreditation-list-item__startdate"><b>Commencement:</b> {commencementDate}</p>}
        {endDate && <p className="accreditation-list-item__enddate"><b>Completion:</b> {endDate}</p>}
        {timeOfImpact && <p className="accreditation-list-item__timeofimpact"><b>Time of Impact:</b> {timeOfImpact}</p>}
        {hasOSOMRestriction && <><b>OSOM</b><div className="accreditation-list-item__details hasHTMLContent" dangerouslySetInnerHTML={{ __html: osomDetails }}></div></>}
        {hasRAVRestriction && <><b>RAV</b><div className="accreditation-list-item__details hasHTMLContent" dangerouslySetInnerHTML={{ __html: ravDetails }}></div></>}
        {(contactFullName || contactNumber || contactEmail) && (
          <p>
              <b>Contact</b>
              {contactFullName && <p className="accreditation-list-item__contactname">{contactFullName}</p>}
              {contactNumber && <p className="accreditation-list-item__contactnumber">{contactNumber}</p>}
              {contactEmail && <p className="accreditation-list-item__email">{contactEmail}</p>}
          </p>
        )}
       
      </div>
    </StyledHVSTravelImpactListItem>
  );
};

export default HVSTravelImpactListItem;
