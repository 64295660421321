import styled from 'styled-components';
import { hideVisually } from 'polished';
import theme, { breakpoint } from '../../../lib/theme';

type StyledSearchFormProps = {
  minimal?: boolean;
  isHeader?: boolean;
};

const StyledSearchForm = styled.form<StyledSearchFormProps>`
  ${props => breakpoint.greaterThan('md')`
    display: ${props.isHeader === true ? 'none' : 'block'};
  `}
  font-size: 1rem;
  margin: ${props => (props.isHeader === true ? '1em 0 0 0' : '0')};
  padding: ${props => (props.minimal ? '0' : '2.5em 1.5em')};
  background-color: ${props =>
    props.minimal ? 'transparent' : theme.colors.navMobileBg};
  button {
    padding: 0.75em 1em;
    background: none;
    border: solid 1px transparent;
    color: #aaaaaa;
    font-size: 1em;
    line-height: 0;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    -webkit-appearance: none;

    &:hover {
      color: ${theme.colors.linkHover};
    }

    span {
      ${hideVisually}
    }
    svg {
      width: 0.75em;
    }
  }

  input {
    width: 100%;
    padding: 0.75em 1em;
    background: none;
    border: 1px solid transparent;
    color: ${theme.colors.searchFormText};
    font-weight: ${theme.fontWeight.light};

    line-height: 1;
    &::placeholder {
      color: ${theme.colors.placeholderText};
      line-height: normal !important;
    }
    &:focus::placeholder {
      transition: 125ms ease-out;
      color: transparent;
    }
    ${breakpoint.greaterThan('md')`
      padding: 1em;
    `};
  }
  && p {
    margin-top: 0.5em;
    font-size: 1rem;
    a {
      display: inline-block;
      line-height: 1;
    }
  }

  .search-form__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #707070;
  }

  &.search-form-active {
    .search-form__input {
      border: solid 1px ${theme.colors.primary};
    }

    button,
    input {
      color: #000;
    }

    input::placeholder {
      color: #000;
    }

    button:hover {
      color: ${theme.colors.linkHover};
    }
  }

  label {
    display: ${props => (props.minimal ? 'none' : 'block')};
    margin: 0 0 1em 0;
    font-size: 1.25em;
    font-weight: ${theme.fontWeight.black};
    span {
      color: ${theme.colors.primary};
    }
  }
`;

export default StyledSearchForm;
