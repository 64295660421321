import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledProjectListItem = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  transition: background-color 0.3s ease-in-out;

  padding: 1em;
  border-radius: 1em;
  background: #fff;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.22);
  ${breakpoint.greaterThan('sm')`
    padding: 1.75em;
    border-radius: 1.75em;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.22);
    transition: box-shadow 250ms ease-out, transform 250ms ease-out;
    &:hover {
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.22);
      transform: translate(0, -2px);
    }
  `};
`;

export const StyledProjectListItemCallout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .description {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
  }
  .meta {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  figure {
    margin: 0;
    padding: 0;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    background: radial-gradient(
      circle at 75% 25%,
      #042c3b 0%,
      rgba(2, 0, 36, 1) 100%
    );
  }
  img {
    max-width: 100%;
  }

  h2 {
    margin: ${theme.spacing.sm} 0 ${theme.spacing.xs} 0;
    font-size: 1.125em;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.textDark};
    transition: color 0.2s linear;
    ${breakpoint.greaterThan('sm')`
      font-size: 1.5em;
    `}
  }

  h4 {
    font-size: 1rem;
    margin: 0.9rem 0rem 0.3rem;
  }

  > span {
    font-size: 0.875rem;
    color: ${theme.colors.textMid};
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    display: block;
    border-bottom: none;
    transition: 50ms ease-out;
    &:hover {
      h2 {
        color: ${theme.colors.linkHover};
        border-bottom: none;
      }
    }
  }

  ${breakpoint.greaterThan('md')`
    > span {
      font-size: 1rem;
      color: ${theme.colors.textMid};
    }
    figure {
      overflow: hidden;
      img {
        transition: 500ms cubic-bezier(0.33, 0, 0.33, 1);
      }
    }
  `};
  .meta {
    ${breakpoint.greaterThan('md')`
    `};
  }
  .location {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
    //border-bottom: 1px solid #707070;
    padding-bottom: 0.75em;
    font-size: 0.75em;
    ${breakpoint.greaterThan('sm')`
      font-size: 1em;
    `}
    svg {
      width: 1.28em;
      font-size: 1.5625em;
    }
    span {
      font-weight: ${theme.fontWeight.medium};
      color: #757575;
    }
  }
  .meta__group {
    padding-top: 0.75em;
    display: flex;
    align-items: center;
    font-size: 0.75em;
    ${breakpoint.greaterThan('sm')`
      font-size: 1em;
    `}
    img {
      width: 2em;
    }
    span {
      font-weight: ${theme.fontWeight.medium};
    }
    time {
      font-weight: ${theme.fontWeight.medium};
      color: #757575;
    }
    &.under_construction {
      span {
        color: #0a4d66;
      }
    }
    &.consultation,
    &.planned {
      span {
        color: #d14210;
      }
    }
    &.completed,
    &.local_initiative {
      span {
        color: #008072;
      }
    }
    &.blue{
      span {
        color: #0A4D66;
      }
    }
    &.green{
      span {
        color: #008072;
      }
    }
    &.red{
      span {
        color: #D14210;
      }
    }
    &.black{
      span {
        color: #000000;
      }
    }
  }
  .status {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 8px;
    ${breakpoint.greaterThan('md')`
      gap: 16px;
    `}
  }
  &.compact {
    h2 {
      margin: ${theme.spacing.sm} 0 ${theme.spacing.xs} 0;
      font-size: 1em;
      ${breakpoint.greaterThan('sm')`
        margin-top: 0;
        font-size: 1.25em;
      `}
    }
    .description {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .meta {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .location {
      border-bottom: none;
      padding-bottom: 0;
      border-bottom: 1px solid #707070;
      padding-bottom: 0.625em;
      font-size: 0.75em;
      @media screen and (min-width: 768px) {
        font-size: 1em;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    .meta__group {
      padding-top: 0.625em;
      font-size: 0.75em;
      @media screen and (min-width: 768px) {
        font-size: 1em;
        padding-top: 0;
      }
    }
    ${breakpoint.greaterThan('sm')`
      max-width: 900px;

      display: flex;
      flex-direction: row;
      gap: 20px;

      img {
        max-height: 170px;
      }

      figure {
        width: 100%;
        max-width: 200px;
        border-radius: 10px;
      }
    `};
  }
`;
