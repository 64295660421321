import React, { ReactElement } from 'react';
import { ImageProps, LinkProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import {
  ImageSectionStyles,
  ContentStyles,
  StyledFeaturedCTASection,
} from './styles';
import { BaseBlockProps } from '../../../types'

export interface FeaturedCTASectionProps extends BaseBlockProps {
  img?: ImageProps;
  epiName: EpiProps;
  epiTitle: EpiProps;
  epiContent: EpiProps;
  primaryLink?: LinkProps;
  secondaryLink?: LinkProps;
  lightTheme?: boolean;
};

const FeaturedCTASection = ({
  img,
  epiName,
  epiTitle,
  epiContent,
  primaryLink,
  secondaryLink,
  lightTheme,
  anchorId
}: FeaturedCTASectionProps): ReactElement => {
  return (
    <StyledFeaturedCTASection id={anchorId} className={`${lightTheme ? 'light' : ''}`}>
      <Container>
        <div className="featured-cta-section__inner">
          {img && !img.usePicture && (
            <ImageSectionStyles>
              <img src={img.src} alt={img.alt} />
            </ImageSectionStyles>
          )}
          {img && img.usePicture && (
            <ImageSectionStyles
              dangerouslySetInnerHTML={{ __html: img.picture }}
            >
            </ImageSectionStyles>
          )}
          <ContentStyles className={`${lightTheme ? 'light' : ''}`}>
            <div className="featured-cta-content">
              <EpiProperty
                name={epiName.name}
                value={epiName.value}
                isEditMode={epiName.isEditMode}
                outerTag="h3"
              />
              <EpiProperty
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="h2"
              />
              <EpiProperty
                name={epiContent.name}
                value={epiContent.value}
                isEditMode={epiContent.isEditMode}
                outerTag="p"
              />
            </div>
            <div className="buttons">
              {primaryLink && (
                <a
                  href={primaryLink.href}
                  className="gtm-track"
                  target={primaryLink.target ? primaryLink.target : '_self'}
                  title={primaryLink.label}
                >
                  {primaryLink.label}&nbsp;»
                </a>
              )}
              {secondaryLink && (
                <>
                  <span>or</span>
                  <a
                    href={secondaryLink.href}
                    className="gtm-track"
                    target={
                      secondaryLink.target ? secondaryLink.target : '_self'
                    }
                    title={secondaryLink.label}
                  >
                    {secondaryLink.label}&nbsp;»
                  </a>
                </>
              )}
            </div>
          </ContentStyles>
        </div>
      </Container>
    </StyledFeaturedCTASection>
  );
};

export default FeaturedCTASection;
