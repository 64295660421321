import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment';
import NProgress from 'nprogress';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { fileSizetoString } from '../../../lib/util';
import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import Pagination from '../../molecules/Pagination';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import {
  StyledDateline,
  StyledFilterToggleButton,
  StyledHeading,
  StyledResultItem,
  StyledResults,
  StyledResultsContainer,
  StyledSearchForm,
  StyledSearchTypeFilter,
  StyledSearchTypeFilterList,
  StyledTopicFilter,
  StyledTopicFilterList,
  StyledTopicFilterListButton,
} from './styles';

// import { useHistory } from 'react-router-dom';

// Change this base url when debugging

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/';
// const API_BASE_URL = 'https://www.mainroads.wa.gov.au/api';
const APIURL = `${API_BASE_URL}/search/content`;
const createSearchURL = ({
  base,
  query,
  filter,
  type,
  pagenumber,
  perpage,
}) => {
  // Suggested API call from docs (subject to change, update if it does):
  // /api/search-content/?q={keyword}&filter={category1,category2}&type={type1,type2}&page={pagenumber}&itemsPerPage={#}
  // Suggested API call from docs (subject to change, update if it does):
  // /api/search-content/?q={keyword}&filter={category1,category2}&type={type1,type2}&page={pagenumber}&itemsPerPage={#}
  /* let url =
  `${APIURL}?q=${encodeURIComponent(keyword)}` +
  `${filter ? `&filter=${filters}` : ''}` +
  `${type ? `&type=${types}` : ''}` +
  `${pagenumber ? `&page=${pagenumber}` : ''}` +
  `${
    itemsPerPageSetting && itemsPerPageSetting !== 0
      ? `&take=${itemsPerPageSetting}`
      : '&take=8'
  }`; */
  let res = `${base}?q=${query}`;
  if (!!filter) {
    res += `&filter=${filter}`;
  }
  if (!!type) {
    res += `&type=${type}`;
  }
  if (!!pagenumber) {
    res += `&page=${pagenumber}`;
  }
  if (!!perpage && perpage !== 0) {
    res += `&take=${perpage}`;
  } else {
    res += '&take=8';
  }
  return res;
};

// TYPES
interface SearchResultsPageProps {
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  placeholderText?: string;
  initialSearchQuery?: string;
  itemsPerPageSetting?: number;
  epiTitle: EpiProps;
  alert?: PageTemplateProps['alert'];
}

type FilterItem = {
  name: string;
  selected: boolean;
};
type SearchQueryProps = {
  keyword: string;
  filter?: string[];
  type?: string[];
  pagenumber?: number;
};

type TypeFilterListButtonProps = {
  active: boolean;
  label: string;
  onClick: (label: string, active: boolean) => void;
};

// top bar filter button el
const TypeFilterListButton = ({
  active,
  label,
  onClick = () => {},
}: TypeFilterListButtonProps) => {
  const onClickHandler = () => {
    onClick(label, !active);
  };
  return (
    <Button type="button" ghost active={active} handleOnClick={onClickHandler}>
      {label}
    </Button>
  );
};

type TopicFilterListButtonProps = {
  active: boolean;
  label: string;
  onClick: (label: string, active: boolean) => void;
};
// sidebar filter button el
const TopicFilterListButton = ({
  active,
  label,
  onClick,
}: TopicFilterListButtonProps) => {
  const onClickHandler = () => {
    onClick(label, !active);
  };
  return (
    <StyledTopicFilterListButton
      className={active ? 'active' : ''}
      onClick={onClickHandler}
    >
      <FontAwesomeIcon icon={['far', 'times-circle']} />
      <span>{label}</span>
    </StyledTopicFilterListButton>
  );
};

// Search result item
interface ResultItemProps {
  url: string;
  title: string;
  updated: Date;
  updatedLabel?: string;
  // contentType: string[] | string;
  summary: string;
  documentType: string;
  fileSize: string;
  revisionInfo?: string;
}

const ResultItem = ({
  title,
  url,
  updated,
  updatedLabel,
  // contentType,
  summary,
  documentType,
  fileSize,
  revisionInfo = undefined,
}: ResultItemProps) => {
  const date = moment(updated).format('D MMMM YYYY');
  const dateVerbose = moment(updated).format('MMMM Do YYYY, h:mm:ss a');
  return (
    <StyledResultItem>
      <a href={url}>{title}</a>
      <div>
        <h4>{title}</h4>
        <StyledDateline>
          {!!revisionInfo && `Current version number: ${revisionInfo} | `}
          {updatedLabel && `${updatedLabel}: `}
          <time dateTime={dateVerbose} title={`Modified on: ${dateVerbose}`}>
            {date}
          </time>
          {documentType && ` | ${documentType}`}
          {fileSize && ` | ${fileSizetoString(Number(fileSize))}`}
        </StyledDateline>
        {summary && <p>{summary}</p>}
      </div>
    </StyledResultItem>
  );
};

type SearchStats = {
  totalItems?: number;
  page?: number;
  itemsPerPage?: number;
};

const SearchResultsPage = ({
  ctaBlock,
  header,
  footer,
  placeholderText,
  epiTitle,
  initialSearchQuery,
  itemsPerPageSetting = 10,
  alert,
}: SearchResultsPageProps): ReactElement => {
  // const history = useHistory();
  const [searchResults, setSearchResults] = useState<any[] | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<FilterItem[]>([]);
  const [contentTypes, setContentTypes] = useState<FilterItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchStats, setSearchStats] = useState<SearchStats>({});

  const [showFilter, setShowFilter] = useState(false);

  // NOTE: The initial search can either be taken from the URL PARAM 'q',
  // or supplied as initialSearchQuery,
  // otherwise it'll fall back to undefined
  // value is respected in that order.

  // const [searchQuery, setSearchQuery] = useState<string>(initialSearchQuery);
  const [searchQuery] = useState<string>(initialSearchQuery);

  const isMounted = useRef(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  // query API
  const getResults = async ({
    keyword,
    filter,
    type,
    pagenumber,
  }: SearchQueryProps) => {
    if (keyword.length <= 2) {
      // only respect 3 chars or longer search keywords...
      return null;
    }
    setIsLoading(true);
    NProgress.start();
    const filters = filter?.map(f => encodeURIComponent(f)).join(',');
    const types = type?.map(t => encodeURIComponent(t)).join(',');

    const url = createSearchURL({
      base: APIURL,
      query: encodeURIComponent(keyword),
      filter: filters,
      type: types,
      pagenumber,
      perpage: itemsPerPageSetting,
    });

    const results = await axios(url)
      .then(res => {
        setIsLoading(false);
        NProgress.done();
        return res.data;
      })
      .catch(reason => {
        // console.error(reason);
        setIsLoading(false);
        NProgress.done();
        return Promise.reject(reason);
      });
    return results;
  };

  // when filtering by document type (top bar)
  const onTypeChangeHandler = (typeLabel: string, isActive: boolean) => {
    const newTypes = contentTypes.map(type => {
      if (type.name === typeLabel) {
        type.selected = isActive;
      }
      return type;
    });
    setContentTypes(newTypes);
  };

  // when filtering by topic (sidebar)
  const onTopicClickHandler = (categoryLabel: string, isActive: boolean) => {
    const newCats = categories.map(cat => {
      if (cat.name === categoryLabel) {
        cat.selected = isActive;
      }
      return cat;
    });
    setCategories(newCats);
  };
  // search input field
  // const onSubmitHandler = (val: string) => {
  //   setSearchQuery(val);
  // };

  // Prepare a new query
  const updateQuery = () => {
    if (!!searchQuery && searchQuery.length > 0) {
      // console.log('i am changing the search query');
      const base = window.location.pathname;
      const originalHref = window.location.href;

      // e.g. page=5
      const pageNumber = originalHref
        .split('&')
        .filter(item => item.indexOf('page=') >= 0)[0];

      let newUrl = `${base}?q=${searchQuery}`;
      if (!!pageNumber) {
        newUrl = `${newUrl}&${pageNumber}`;
      }
      // console.log(newUrl);
      // history.push(newUrl);
      // const base = window.location.protocol + "//" + window.location.host + window.location.pathname;
      // const newUrl = `${base}/?q=${searchQuery}`;

      if (originalHref !== newUrl) {
        if (window && window.history && window.history.pushState) {
          window.history.pushState({ path: newUrl }, '', newUrl);
        }
      }
    }

    const filter = categories.filter(c => c.selected === true).map(c => c.name);
    const type = contentTypes.filter(t => t.selected === true).map(c => c.name);
    const query: SearchQueryProps = {
      keyword: searchQuery,
      filter,
      type,
      pagenumber: currentPage,
    };
    getResults(query).then(res => {
      // console.log('getResults res', res);
      // bit of a hacky way to prevent false refreshes of these comps. Resulting in a infinite loop.
      if (JSON.stringify(res.contentTypes) !== JSON.stringify(contentTypes)) {
        setContentTypes(res.contentTypes);
      }
      if (JSON.stringify(res.categories) !== JSON.stringify(categories)) {
        setCategories(res.categories);
      }
      if (JSON.stringify(res.items) !== JSON.stringify(searchResults)) {
        setSearchResults(res.items || []);
      }
      setSearchStats({
        totalItems: res.totalItems,
        page: res.page,
        itemsPerPage: res.itemsPerPage,
      });
    });
  };

  // when filters change...
  useEffect(() => {
    if (isMounted.current) {
      // filters changed, reset the pagination to 1
      setCurrentPage(1);
      updateQuery();
    }
    // eslint-disable-next-line
  }, [categories, contentTypes, searchQuery]);

  // when paginating
  useEffect(() => {
    if (isMounted.current) {
      updateQuery();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  // on comp mount...
  useEffect(() => {
    // If user reaches page with a search query in the queryparams:
    if (searchQuery) {
      getResults({ keyword: searchQuery }).then(res => {
        setCategories(res.categories);
        setContentTypes(res.contentTypes);
        setSearchResults(res.items || []);
        setSearchStats({
          totalItems: res.totalItems,
          page: res.page,
          itemsPerPage: res.itemsPerPage,
        });
        isMounted.current = true;
      });
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <PageTemplate
      ctaBlock={ctaBlock}
      header={header}
      footer={footer}
      isSearchPage
      alert={alert}
    >
      <StyledHeading>
        <Container>
          <EpiProperty
            name={epiTitle.name}
            value={epiTitle.value}
            isEditMode={epiTitle.isEditMode}
            outerTag="h1"
          />
          <StyledSearchForm
            initialValue={searchQuery}
            placeholder={placeholderText}
            /* onSubmit={onSubmitHandler} */
          />

          {searchQuery && isLoading ? (
            <p>Searching </p>
          ) : searchResults?.length > 0 ? (
            <p>
              Showing {searchStats.totalItems} result
              {searchResults.length !== 1 && 's'} for “
              <strong>{searchQuery}</strong>”.
            </p>
          ) : (
            searchQuery?.length > 0 && (
              <p>No results found for “{searchQuery}”.</p>
            )
          )}
          <br />
        </Container>
      </StyledHeading>
      {searchQuery && searchResults?.length >= 0 && (
        <>
          <Container>
            <StyledFilterToggleButton
              handleOnClick={toggleFilter}
              ghost
              icon={showFilter ? 'chevron-down' : 'chevron-up'}
              type="button"
            >
              Filter results
            </StyledFilterToggleButton>
          </Container>

          <StyledSearchTypeFilter doShow={showFilter}>
            <Container>
              <StyledSearchTypeFilterList>
                {contentTypes.map((item, i) => (
                  <li key={i}>
                    <TypeFilterListButton
                      active={item.selected}
                      label={item.name}
                      onClick={onTypeChangeHandler}
                    />
                  </li>
                ))}
              </StyledSearchTypeFilterList>
            </Container>
          </StyledSearchTypeFilter>

          <StyledResults>
            <Container>
              <StyledResultsContainer>
                <div>
                  {searchResults?.map((item, i) => (
                    <ResultItem
                      key={i}
                      title={item.title}
                      url={item.url}
                      updated={new Date(item.lastUpdated)}
                      updatedLabel={item.lastUpdatedLabel}
                      // contentType={item.contentType}
                      summary={item.summary}
                      documentType={item.documentType}
                      fileSize={item.fileSize}
                      revisionInfo={item.revisionInfo || undefined}
                    />
                  ))}
                  {searchStats.totalItems > searchStats.itemsPerPage && (
                    <Pagination
                      forcePage={currentPage - 1}
                      pageCount={Math.ceil(
                        searchStats.totalItems / searchStats.itemsPerPage,
                      )}
                      onPageChange={(val: any) => {
                        document.getElementById('main-content').scrollIntoView({
                          behavior: 'smooth',
                        });
                        return setCurrentPage(val.selected + 1);
                      }}
                    />
                  )}
                </div>
                {categories.length > 0 && (
                  <StyledTopicFilter doShow={showFilter}>
                    <h3>Filter by topic</h3>
                    <StyledTopicFilterList>
                      {categories.map(item => {
                        return (
                          <li key={item.name}>
                            <TopicFilterListButton
                              active={item.selected}
                              onClick={onTopicClickHandler}
                              label={item.name}
                            />
                          </li>
                        );
                      })}
                    </StyledTopicFilterList>
                  </StyledTopicFilter>
                )}
              </StyledResultsContainer>
            </Container>
          </StyledResults>
        </>
      )}
    </PageTemplate>
  );
};

export default SearchResultsPage;
