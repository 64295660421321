import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledCTABlock = styled.div`
  background-color: ${theme.colors.backgroundDark};

  .cta-block__inner {
    justify-content: space-between;
    padding: 3.75rem 0;

    ${breakpoint.greaterThan('lg')`
      display: flex;
      /* > * {
        padding-right: 20px;
        &:last-child {
          padding-right: 0;
        }
      } */
    `}
  }
  @media print {
    display: none;
  }
`;
export const StyledCTABlockContent = styled.div`
  padding: 0 0 2rem 0;
  color: ${theme.colors.textLight};

  ${breakpoint.greaterThan('md')`
    width: 75%;
  `};

  ${breakpoint.greaterThan('lg')`
    width: 50%;
    padding: 0 1.5rem 0 1.5rem;
    &:last-child {
      padding-right: 0;
    }
    &:first-child {
      padding-left: 0;
    }
  `};

  h2 {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-size: 1.25rem;

    ${breakpoint.greaterThan('lg')`
      font-size: 1.5rem;
    `}
  }

  p {
    font-size: 0.875rem;

    ${breakpoint.greaterThan('md')`
      font-size: 1rem;
    `}

    ${breakpoint.greaterThan('lg')`
      font-size: 1.125rem;
    `}
  }
  @media print {
    display: none;
  }
`;
export const StyledCTABlockCallout = styled.div`
  padding: 1.5rem;
  background-color: ${theme.colors.ctaBlockCalloutBg};
  color: ${theme.colors.textLight};

  ${breakpoint.greaterThan('md')`
    min-width: 626px;
    width: 50%;
  `}

  ${breakpoint.greaterThan('lg')`
    width: 525px;
  `}

  h2 {
    font-size: 1.25rem;
  }

  p {
    font-size: 0.875rem;

    ${breakpoint.greaterThan('sm')`
      font-size: 1.125rem;
    `}
  }

  a {
    display: inline-block;
    padding: 1rem 2rem;
    border: 1px solid ${theme.colors.borderLight};
    color: ${theme.colors.textLight};
    font-size: 0.875rem;
    cursor: pointer;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      border-width: 1px;
      border-color: white;
      color: ${theme.colors.ctaBlockCalloutText};
      background-color: ${theme.colors.backgroundLight};
    }
  }
  @media print {
    display: none;
  }
`;
