import React, { ReactElement } from 'react';

import ContentBlockGridStyled from './styles';

type ContentBlockGridProps = {
  children: any;
  className?: string;
  align?: 'left' | 'right' | 'center';
  anchorId?: string;
};
const ContentBlockGrid = ({
  children,
  className = '',
  align = 'center',
  anchorId
}: ContentBlockGridProps): ReactElement => {
  return (
    <ContentBlockGridStyled id={anchorId} className={className} alignment={align}>
      {children}
    </ContentBlockGridStyled>
  );
};

export default ContentBlockGrid;
