import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledContentImageBlock = styled.div`
  background-color: ${theme.colors.backgroundDark};
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .content-image-block__inner {
    flex: 1 0 auto;
    ${breakpoint.greaterThan('md')`
    display: flex;
  `}
  }
`;

export const StyledContentImageBlockContent = styled.div`
  color: ${theme.colors.textLight};
  padding: 2rem;
  ${breakpoint.greaterThan('md')`
    width: 50%;
`}

  h2 {
    margin: 0;
    font-size: 1.25rem;

    ${breakpoint.greaterThan('lg')`
    font-size: 2rem;
  `}
  }

  p {
    font-size: 1rem;
  }

  a {
    color: ${theme.colors.textLight};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

type CompactProp = {
  compact: boolean;
};

export const StyledContentImageBlockImage = styled.div<CompactProp>`
  color: ${theme.colors.textLight};

  ${breakpoint.greaterThan('md')`
    width: 50%;
  `}

  .content-image-block__image {
    padding: 0;
    width: 100%;
    height: ${props => (props.compact === true ? '0' : '100%')};
    position: relative;
    overflow: hidden;

    padding-bottom: ${props => (props.compact === true ? '62.5%' : '0')};
    img {
      width: ${props => (props.compact === true ? '100%' : 'auto')};
      height: ${props => (props.compact === true ? 'auto' : '100%')};
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
    @supports (object-fit: cover) {
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
