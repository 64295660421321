import styled from 'styled-components';

const StyledTogglePill = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  label {
    width: 100%;
    text-align: center;
    line-height: 1;
    margin: 0;
    color: #707070;
    border: 1px solid #707070;
    border-radius: 50px;
    padding: 0.8em 0.5em;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s linear, color 0.2s linear;
  }
  input {
    cursor: pointer;
    width: 0;
    opacity: 0;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: box-shadow 0.3s cubic-bezier(0.5, 0, 0, 1);
    }
    &:focus {
      ~ label {
        box-shadow: 0 0 0 2px #5e9ed6;
      }
    }
    &:checked,
    &:hover {
      ~ label {
        background-color: #707070;
        color: white;
      }
    }
  }
`;

export default StyledTogglePill;
