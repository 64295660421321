import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import NProgress from 'nprogress';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTitle from '../../atoms/PageTitle';
import DocumentListItem from '../../molecules/DocumentListItem';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import Pagination from '../../molecules/Pagination';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import {
  CatWrapper,
  DisclaimerStyled,
  LoadingWrapper,
  StyledButton,
  StyledCategoryFilterList,
  StyledCategoryFilterListButton,
  StyledClearButton,
  StyledDocumentList,
  StyledNavBreadcrumbs,
  StyledNodeFilterList,
  StyledNodeFilterListLink,
  StyledPageLayout,
  StyledSearchFormWidget,
  StyledTypeFilterList,
} from './styles';

// TYPES
interface DocumentListingPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  initialSearchQuery?: string;
  initialQueryString?: string;
  sectionFilter?: string;
  itemsPerPage: number;
  initialPage: number;
  alert?: PageTemplateProps['alert'];
  epiLeftContentArea?: EpiProps;
}

type FilterItem = {
  name: string;
  icon?: string;
  href?: string;
  selected: boolean;
};

type TypeFilterListButtonProps = {
  active: boolean;
  label: string;
  onClick: (label, active) => void;
};

type QueryStats = {
  totalItems?: number;
  page?: number;
  itemsPerPage?: number;
};

// Sidebar Category filter button
const CategoryFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButtonProps): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <StyledCategoryFilterListButton
      type="button"
      className={active ? 'active' : ''}
      onClick={onClickHandler}
    >
      <span>{label}</span>
      <FontAwesomeIcon icon="chevron-right" />
    </StyledCategoryFilterListButton>
  );
};

// Sidebar Type filter button
const TypeFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButtonProps): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <Button
      type="button"
      ghost
      className={active ? 'active' : ''}
      active={active}
      handleOnClick={onClickHandler}
    >
      {label}
    </Button>
  );
};

// Sidebar Type filter button
const NodeFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButtonProps): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <StyledNodeFilterListLink
      className={active ? 'active' : ''}
      onClick={onClickHandler}
    >
      {active && <FontAwesomeIcon icon={['far', 'times-circle']} />}
      <span>{label}</span>
      {!active && <FontAwesomeIcon icon="chevron-right" />}
    </StyledNodeFilterListLink>
  );
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/';
// const API_BASE_URL = "https://epiinternet.mainroads.wa.gov.au/api";
const APIURL = `${API_BASE_URL}/documents/search`;
// let APIURL = `https://run.mocky.io/v3/55fc191e-5822-462d-abd7-8091858c9339`;

const DocumentListingPage = ({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  initialSearchQuery,
  initialQueryString,
  sectionFilter,
  itemsPerPage = 10,
  initialPage = 1,
  alert,
  epiLeftContentArea,
}: DocumentListingPageProps): ReactElement => {
  const [showFilters, setShowFilters] = useState(false);
  const [docsResults, setDocsResults] = useState<any[] | undefined>(undefined);
  const [categories, setCategories] = useState<FilterItem[]>([]);
  const [contentTypes, setContentTypes] = useState<FilterItem[]>([]);
  const [nodeTypes, setNodeTypes] = useState<FilterItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [isLoading, setIsLoading] = useState(false);
  const [queryStats, setQueryStats] = useState<QueryStats>({});
  const [searchQuery, setSearchQuery] = useState<string>(initialSearchQuery);
  const [queryString, setQueryString] = useState<string>(initialQueryString);
  // const [currentSection, setCurrentSection] = useState<string>(sectionFilter);
  const currentSection = sectionFilter;

  const isMounted = useRef(false);

  const fetchResults = async () => {
    // local front end
    // const API_BASE_URL = 'https://next.json-generator.com/api/json/get/NJ95f1YO_';
    // local front end
    NProgress.start();
    setIsLoading(true);
    // assumed API call format from docs (subject to change, update if it does):
    // /api/search-content/search?q=?itemsPerPage={#}&category={category1,category2}&type={type1,type2}&page={pagenumber}&itemsPerPage={#}
    const url =
      queryString.split('&').length > 0
        ? `${APIURL}${queryString}`
        : `${APIURL}?q=${searchQuery}` +
          `&take=${itemsPerPage}` +
          `&filter=` +
          `&type=` +
          `&page=1` +
          `&sectionFilter=${encodeURIComponent(
            currentSection || sectionFilter,
          )}` +
          `&node=`;

    const results = await axios(url)
      .then(res => {
        NProgress.done();
        setIsLoading(false);
        return res.data;
      })
      .catch(reason => {
        // console.error('ERROR GETTING SEARCH QUERY', reason);
        NProgress.done();
        setIsLoading(false);
        return Promise.reject(reason);
      });
    return results;
  };
  const updateQueryString = (): void => {
    const selectedCategories =
      categories &&
      categories.filter(c => c.selected === true).map(c => c.name);
    const selectedContentTypes =
      contentTypes &&
      contentTypes.filter(t => t.selected === true).map(t => t.name);
    const selectedNodeTypes =
      nodeTypes && nodeTypes.filter(n => n.selected === true).map(n => n.name);
    const resCategories = selectedCategories
      ? selectedCategories.map(f => encodeURIComponent(f)).join(',')
      : '';
    const resTypes = selectedContentTypes
      ? selectedContentTypes.map(t => encodeURIComponent(t)).join(',')
      : '';
    const resNodes = selectedNodeTypes
      ? selectedNodeTypes.map(n => encodeURIComponent(n)).join(',')
      : '';

    const qString =
      `?q=${searchQuery}` +
      `&take=${itemsPerPage}` +
      `&filter=${resCategories}` +
      `&type=${resTypes}` +
      `&page=${currentPage}` +
      `&sectionFilter=${encodeURIComponent(currentSection)}` +
      `&node=${resNodes}`;

    setQueryString(qString);
  };

  // on mount:
  useEffect(() => {
    fetchResults()
      .then(res => {
        setCategories(res.categories);
        setContentTypes(res.contentTypes);
        setNodeTypes(res.nodeTypes);
        setDocsResults(res.items || []);
        setQueryStats({
          totalItems: res.totalItems,
          page: res.page,
          itemsPerPage: res.itemsPerPage,
        });
        isMounted.current = true;
      })
      .catch(reason => console.error(reason));
    // eslint-disable-next-line
  }, []);

  // when paginating:
  useEffect(() => {
    if (isMounted.current) {
      updateQueryString();
    }
    // eslint-disable-next-line
  }, [currentPage, searchQuery]);

  // when querystring changes:
  useEffect(() => {
    if (isMounted.current) {
      const url = `//${window.location.host}${window.location.pathname}${queryString}`;
      window.location.href = url;
    }
    // eslint-disable-next-line
  }, [queryString]);

  // when filtering by topic (sidebar)
  const onCategoryClickHandler = (
    categoryLabel: string,
    isActive: boolean,
  ): void => {
    const newCats = categories.map(cat => {
      if (cat.name === categoryLabel) {
        cat.selected = isActive;
      }
      return cat;
    });
    setCategories(newCats);
    setCurrentPage(1);
    updateQueryString();
  };

  // when filtering by document type (top bar)
  const onTypeClickHandler = (typeLabel: string, isActive: boolean): void => {
    const newTypes = contentTypes.map(type => {
      if (type.name === typeLabel) {
        type.selected = isActive;
      }
      return type;
    });
    setContentTypes(newTypes);
    setCurrentPage(1);
    updateQueryString();
  };

  // when filtering by node type (side bar)
  const onNodeClickHandler = (nodeLabel: string, isActive: boolean): void => {
    let deActivateRemainingNodes = false;
    const newNodes = nodeTypes.map(node => {
      if (node.name === nodeLabel) {
        node.selected = isActive;
        if (!isActive) {
          deActivateRemainingNodes = true;
        }
      } else if (deActivateRemainingNodes) {
        node.selected = false;
      }
      /* if (node.name === nodeLabel) {
        node.selected = isActive;
      } else
      {
        node.selected = false;
      } */
      return node;
    });
    setNodeTypes(newNodes);
    setCurrentPage(1);
    updateQueryString();
  };

  // search input field
  const onSearchSubmitHandler = (val: string): void => {
    setSearchQuery(val);
    setCurrentPage(1);
  };

  // search input field
  const onClearSubmitHandler = (): void => {
    const url = `//${window.location.host}${window.location.pathname}`;
    window.location.href = url;
  };

  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout>
          <aside className="page-layout__aside">
            {breadcrumbs && <StyledNavBreadcrumbs items={breadcrumbs} />}
            <PageTitle>
              <EpiProperty
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="span"
              />
            </PageTitle>
            <StyledButton
              icon="chevron-down"
              ghost
              type="button"
              handleOnClick={(): void => setShowFilters(!showFilters)}
            >
              Apply Search Filters
            </StyledButton>
            <div
              className={`document-list__filters ${
                showFilters ? 'document-list__filters--active' : ''
              }`}
            >
              <h2>Search Library</h2>
              <StyledSearchFormWidget
                initialValue={searchQuery}
                placeholder="e.g. Pre-Calibration Checklist"
                searchInputWidth="100%"
                onSubmit={onSearchSubmitHandler}
              />
              {categories && categories?.length > 0 && (
                <>
                  <h2>Browse by Topic</h2>
                  <StyledCategoryFilterList>
                    {categories.map((item, i) => {
                      return (
                        <li key={i}>
                          <CategoryFilterListButton
                            active={item.selected}
                            onClick={onCategoryClickHandler}
                            label={item.name}
                          />
                        </li>
                      );
                    })}
                  </StyledCategoryFilterList>
                </>
              )}
              {nodeTypes && nodeTypes?.length > 0 && (
                <>
                  <h2>Browse by Category</h2>
                  <CatWrapper>
                    {isLoading && <LoadingWrapper />}
                    <StyledNodeFilterList>
                      {nodeTypes.map((item, i) => (
                        <li
                          key={i}
                          className={item.selected ? 'is-active' : undefined}
                        >
                          <NodeFilterListButton
                            active={item.selected}
                            onClick={onNodeClickHandler}
                            label={item.name} />
                        </li>
                      ))}
                    </StyledNodeFilterList>
                  </CatWrapper>
                </>
              )}
              {contentTypes && contentTypes?.length > 0 && (
                <>
                  <h2>Filter by Type</h2>
                  <StyledTypeFilterList>
                    {contentTypes.map((item, i) => {
                      return (
                        <li key={i}>
                          <TypeFilterListButton
                            active={item.selected}
                            onClick={onTypeClickHandler}
                            label={item.name}
                          />
                        </li>
                      );
                    })}
                  </StyledTypeFilterList>
                </>
              )}
              <StyledClearButton
                type="button"
                theme="alternative"
                handleOnClick={onClearSubmitHandler}
              >
                Clear Filters
              </StyledClearButton>
            </div>
            {epiLeftContentArea && <EpiProperty name={epiLeftContentArea.name} value={epiLeftContentArea.value} isEditMode={epiLeftContentArea.isEditMode} outerTag="div" />}
          </aside>
          <div className="page-layout__main">
            <DisclaimerStyled>
              <p>
                This information is intended to reflect the preferred practice
                of Main Roads Western Australia ("Main Roads"). Main Roads
                reserves the right to update this information at any time
                without notice. To the extent permitted by law, Main Roads, its
                employees, agents, authors and contributors are not liable for
                any loss resulting from any action taken or reliance made by you
                on the information herein displayed.
              </p>
            </DisclaimerStyled>
            {docsResults && queryStats && (
              <Container>
                <p className="document-list__number">
                  {queryStats.totalItems <= queryStats.itemsPerPage ? (
                    <>
                      <strong>{queryStats.totalItems}</strong>
                      {` Publication${queryStats.totalItems !== 1 ? 's' : ''}`}
                    </>
                  ) : (
                    <>
                      <strong>
                        {queryStats.page * queryStats.itemsPerPage -
                          (queryStats.itemsPerPage - 1)}
                        {' - '}
                        {queryStats.page * queryStats.itemsPerPage}
                      </strong>
                      {' Publications of '}
                      <strong>
                        {queryStats.totalItems &&
                          queryStats.totalItems.toLocaleString()}
                      </strong>
                    </>
                  )}
                </p>
                <StyledDocumentList>
                  {docsResults?.map((item, i) => (
                    <DocumentListItem
                      query={`?q=&take=${itemsPerPage}&filter=${encodeURIComponent(
                        currentSection,
                      )}&type=&page=${currentPage}&sectionFilter=${sectionFilter}&`}
                      key={i}
                      breadcrumbs={item.breadcrumbs}
                      lastUpdated={item.lastUpdated}
                      lastUpdatedLabel={item.lastUpdatedLabel}
                      files={item.files}
                      summary={item.summary}
                      // slug={item.slug}
                      thumbnail={item.thumbnail}
                      tags={item.tags}
                      title={item.title}
                      documentType={item.documentType}
                      fileSize={item.fileSize}
                      revisionInfo={item.revisionInfo || undefined}
                    />
                  ))}
                </StyledDocumentList>
                {queryStats.totalItems > queryStats.itemsPerPage && (
                  <Pagination
                    forcePage={currentPage - 1}
                    pageCount={Math.ceil(
                      queryStats.totalItems / queryStats.itemsPerPage,
                    )}
                    onPageChange={(val: any): void => {
                      document.getElementById('main-content').scrollIntoView({
                        behavior: 'smooth',
                      });
                      return setCurrentPage(val.selected + 1);
                    }}
                  />
                )}
                {docsResults.length === 0 && (
                  <p>
                    No publications found for your query, please try a different
                    search.
                  </p>
                )}
              </Container>
            )}
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
};

export default DocumentListingPage;
