import React, { ReactElement } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Image from '../../atoms/Image';
import { StyledProjectListItem, StyledProjectListItemCallout } from './styles';

import {
  UnderConstructionIcon,
  PlannedIcon,
  LocalInitiativeIcon,
  CompleteIcon,
  ConsultationIcon,
} from './icons';

const getIcon = ({ label, color }) => {
  if (label === 'under_construction') {
    return <UnderConstructionIcon color={color} />;
  }
  if (label === 'planned') {
    return <PlannedIcon color={color} />;
  }
  if (label === 'completed') {
    return <CompleteIcon color={color} />;
  }
  if (label === 'local_initiative') {
    return <LocalInitiativeIcon color={color} />;
  }
  if (label === 'consultation') {
    return <ConsultationIcon color={color} />;
  }
  return null;
};

const PROJECT_STATUSES = {
  under_construction: {
    icon: '/images/icon_construction.svg',
    color: '#054E66',
    label: 'Under construction',
  },
  planned: {
    icon: '/images/icon_planning.svg',
    color: '#D14210',
    label: 'In planning',
  },
  completed: {
    icon: '/images/icon_complete.svg',
    color: '#008072',
    label: 'Complete',
  },
  local_initiative: {
    icon: '/images/icon_magnify.svg',
    color: '#008072',
    label: 'Local roads initiative',
  },
  consultation: {
    icon: '/images/icon_comments.svg',
    color: '#D14210',
    label: 'Community consultation',
  },
  other: {
    icon: '',
    color: '#D14210',
    label: 'Community consultation',
  },
};

export interface ProjectListItemProps {
  id?: string;
  projectStatus?:
    | 'under_construction'
    | 'planned'
    | 'completed'
    | 'local_initiative'
    | 'consultation'
    | 'other';
  ribbonColor?: string;
  ribbonLabel?: string;
  projectIcon?: string;
  compact?: boolean;
  thumbnail: {
    alt: string;
    image: string;
    href?: string;
    target?: string;
    title?: string;
  };
  title: {
    href: string;
    text: string;
    target?: string;
  };
  location: string;
  lastModifiedDate?: string;
}

const ProjectListItem = ({
  thumbnail,
  title,
  location,
  lastModifiedDate,
  compact = false,
  projectStatus,
  ribbonLabel,
  ribbonColor = 'blue',
  projectIcon,
}: ProjectListItemProps): ReactElement => {
  return (
    <StyledProjectListItem>
      <StyledProjectListItemCallout className={compact ? 'compact' : ''}>
        <figure>
          <Image
            alt={thumbnail.alt}
            image={thumbnail.image}
            target={thumbnail.target || null}
            href={thumbnail.href || null}
            title={thumbnail.title || thumbnail.alt || title.text}
            paddingBottom="62.5%"
          />
        </figure>
        <div className="description">
          {title.href && title.href !== '' && (
            <a href={title.href} target={title.target} title={title.text}>
              <h2>{title.text}</h2>
            </a>
          )}
          <div className="meta">
            <div className="location">
              {location && (
                <>
                  <FaMapMarkerAlt />
                  <span>{location}</span>
                </>
              )}
            </div>
            <div className={`meta__group ${ribbonColor}`}>
              {projectStatus && (
                <div className="status">
                  {projectStatus == 'other' ? (
                    <img
                      src={projectIcon}
                      alt={PROJECT_STATUSES[projectStatus].label}
                    />
                  ) : (
                    getIcon({ label: projectStatus, color: ribbonColor })
                  )}
                  <span>{ribbonLabel}</span>
                </div>
              )}
              {!compact && lastModifiedDate && <time>{lastModifiedDate}</time>}
            </div>
          </div>
        </div>
      </StyledProjectListItemCallout>
    </StyledProjectListItem>
  );
};

export default ProjectListItem;
