import React, { ReactElement } from 'react';
import { ImageProps, LinkProps } from '../../../types';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import {
  StyledContentImageBlock,
  StyledContentImageBlockContent,
  StyledContentImageBlockImage,
} from './styles';
import { BaseBlockProps } from '../../../types'

export interface ContentImageBlockProps extends BaseBlockProps {
  title: EpiProps;
  titleLink?: LinkProps;
  summary: EpiProps;
  image?: ImageProps;
  compact?: boolean;
}

function ContentImageBlock({
  title,
  titleLink,
  summary,
  image,
  compact = true,
  anchorId
}: ContentImageBlockProps): ReactElement {
  return (
    <StyledContentImageBlock id={anchorId}>
      <div className="content-image-block__inner">
        <StyledContentImageBlockContent>
          {titleLink && (
            <a
              href={titleLink.href}
              title={titleLink.label}
              target={titleLink.target ? titleLink.target : '_self'}
            >
              <EpiProperty
                name={title.name}
                value={`${title.value}&nbsp;»`}
                isEditMode={title.isEditMode}
                outerTag="h2"
              />
            </a>
          )}
          {!titleLink && (
            <EpiProperty
              name={title.name}
              value={title.value}
              isEditMode={title.isEditMode}
              outerTag="h2"
            />
          )}
          <EpiProperty
            name={summary.name}
            value={summary.value}
            isEditMode={summary.isEditMode}
            outerTag="p"
          />
        </StyledContentImageBlockContent>

        <StyledContentImageBlockImage compact={compact}>
          {image && (
            <div className="content-image-block__image">
              <img src={image.src} alt={image.alt} />
            </div>
          )}
        </StyledContentImageBlockImage>
      </div>
    </StyledContentImageBlock>
  );
}

export default ContentImageBlock;
