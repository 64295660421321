import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

type DropdownProps = {
  dropdownHeight: number;
  right: boolean;
}

export const DropdownStyled = styled.div<DropdownProps>`
  position: relative;
  button {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    all: unset;
    cursor: pointer;
    padding: 0.75rem;
    font-size: 0.8rem;
    font-weight: 500;
    z-index: 2;

    &:focus, &:focus-visible {
      outline: 2px solid ${theme.colors.inputFocus};
    }

    ${breakpoint.lessThan('sm')`
      padding: 0 0.5rem 0.5rem 0;
    `}

    svg {
      margin: 0 0 0 0.5rem;
      width: 1.2rem;
      height: 1.2rem;
      transform: rotate(0deg);
      transition-property: transform, -moz-transfrom, -webkit-transform;
      transition-duration: 400ms;
      transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
    }
  }

  ul {
    display: grid;
    grid-template-columns: max-content;
    position: absolute;
    ${(props) => props.right ? 'right: 0;' : ''}
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: white;
    border: 1px solid ${theme.colors.borderMediumLight};
    border-width: 0px;
    max-height: 0px;
    overflow: hidden;
    transition-property: max-height border-width;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
    z-index: 1;
    li {
      display: flex;
      align-items: center;
      z-index: 2;
      font-size: 0.8rem;
      :hover {
        background-color: #f4f4f4;
      }
      &:focus-within {
        background-color: #f4f4f4;
        outline: 2px solid ${theme.colors.inputFocus};
      }

      label {
        cursor: pointer;
        padding: 1rem;
        flex-grow: 1;
      }

      input {
        opacity: 0;
        max-height: 0;
        max-width: 0;
        &:checked + label {
          background-color: transparent;
          :before {
            background-color: ${theme.colors.primary};
          }
        } 
      }

      .dropdown__option-input {
        display: flex;
        align-items: center;
        flex-grow: 0;
        padding: 1rem;
        :before {
          content: '';
          display: inline-block;
          height: 0.75rem;
          width: 0.75rem;
          border: 1px solid ${theme.colors.borderMediumLight};
          margin: 0 1rem 0 0;
          background-color: white;
          transition-property: background-color;
          transition-duration: 100ms;
          transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
        }
        &.dropdown__option-input--radio:before {
          border-radius: 50%;
        }
      }
    }

    .dropdown__hidden-close {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      background-color: transparent;
    }
  }

  &.dropdown__main--active {
    button {
      svg {
        transform: rotate(-180deg);
      }
    }

    ul {
      max-height: ${(props) => props.dropdownHeight}px;
      border-width: 1px;
      .dropdown__hidden-close {
        display: block;
      }
    }
  }
`;
