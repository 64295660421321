import React, { ReactElement } from 'react';

export interface EpiProps {
  isEditMode?: boolean;
  name: string;
  className?: string;
  isHTMLContent?: boolean;
  outerTag?:
    | string
    | React.FunctionComponent<{
        className?: string;
        dangerouslySetInnerHTML: { __html: string };
      }>;
  value: string;
}

const EpiProperty = ({
  name,
  className = '',
  value,
  isHTMLContent = false,
  isEditMode = false,
  outerTag: Element = 'div',
}: EpiProps): ReactElement => {
  if (isEditMode === true) {
    return (
      <Element
        data-epi-edit={name}
        className={className}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }
  if (!value || value.length <= 0) {
    return null;
  }
  return (
    <Element
      className={`${className ? className : ''} ${
        isHTMLContent ? 'hasHTMLContent' : ''
      }`}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default EpiProperty;
