import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { ProjectProps } from '../../../types';
import ClearingProjectsListItem from '../../atoms/ClearingProjectsListItem';
import ClearingProjectsMap from '../../atoms/ClearingProjectsMap';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTitle from '../../atoms/PageTitle';
import ToggleCheckbox from '../../atoms/ToggleCheckbox';
import TogglePill from '../../atoms/TogglePill';
import ClearingProjectsInfoPanel from '../../molecules/ClearingProjectsInfoPanel';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import Pagination from '../../molecules/Pagination';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import {
  FullWidthLink,
  ListContainer,
  StyledButton,
  StyledClearButton,
  StyledClearingProjectsList,
  StyledNavBreadcrumbs,
  StyledPageLayout,
  StyledSearchFormWidget,
  StyledSelectField,
  StyledTypeFilterList,
} from './styles';

interface ClearingProjectsPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  epiSubtitle?: EpiProps;
  epiLeftPanelSummary?: EpiProps;
  // hideFilters: boolean;
  epiLeftPanelHeading?: EpiProps;
  epiLeftPanelContent?: EpiProps;
  projectPhaseList?: any;
  // regionsList?: any;
  projectList?: ProjectProps[];
  googleMapsKey: string;
  googleMapsIsClientId: boolean;
  pageTheme?: string;
  panels?: {
    href: string;
    title: string;
    text: string;
    bgColor: string;
  }[];
  pageType?: string;
  alert?: PageTemplateProps['alert'];
}

// These values must match exactly the project properties we want to search
const sortByOptions = [
  { label: 'Default', value: '' },
  { label: 'Reference Number', value: 'referenceNumber' },
  { label: 'Project Name', value: 'name' },
  { label: 'Road Name', value: 'roadName' },
  { label: 'Local Government', value: 'localGov' },
  { label: 'Region', value: 'region' },
];

type TypeFilterListButtonProps = {
  active: boolean;
  label: string;
  // href?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

//const getListViewParam = (): boolean => new URLSearchParams(document.location.search).get("list") === 'true';
const getListViewParam = (): boolean => false;

// Sidebar Type filter button
const TypeFilterListButton = ({
  active,
  label,
  onChange,
}: TypeFilterListButtonProps): ReactElement => {
  const slugifiedLabel = label.toLowerCase().replace(/\s/g, '');

  return (
    <TogglePill
      label={label}
      id={slugifiedLabel}
      name={slugifiedLabel}
      value={label}
      checked={active}
      onChange={onChange}
    />
  );
};

const ClearingProjectsPage = ({
  breadcrumbs,
  ctaBlock,
  header,
  footer,
  epiTitle,
  epiSubtitle,
  epiLeftPanelSummary,
  epiLeftPanelHeading,
  epiLeftPanelContent,
  projectPhaseList,
  projectList,
  panels,
  googleMapsKey,
  googleMapsIsClientId = false,
  pageTheme = '#0C8072',
  pageType = 'proposed',
  alert,
}: ClearingProjectsPageProps): ReactElement => {
  const itemsPerPage = 10;

  const [mounted, setMounted] = useState(false);
  const [isListView, setIsListView] = useState(getListViewParam() || false);
  const [selectedProject, setSelectedProject] = useState<ProjectProps | null>(
    null,
  );
  const [activePhaseFilters, setActivePhaseFilters] = useState(
    projectPhaseList.map(item => item.name),
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState(sortByOptions[0].value);
  const [filteredProjectList, setFilteredProjectList] = useState(projectList);
  const [currentPageProjects, setCurrentPageProjects] = useState(
    projectList.slice(0, itemsPerPage - 1),
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [mapZoomTrigger, setMapZoomTrigger] = useState(true);

  const regionFilterListInit = () => {
    // Put all regions in an array
    // remove dulicates
    // put values into object properties of new array
    return projectList
      .map(item => item.region)
      .filter((item, i, arr) => arr.indexOf(item) === i)
      .map(item => ({
        name: item,
        selected: false,
      }));
  };
  const [regionFilter, setRegionFilter] = useState(regionFilterListInit());

  // Returns true if any project has a phase property that is not null
  const showPhaseFilters =
    projectList.filter(project => project.phase !== null).length !== 0;

  const onPhaseFilterChange = e => {
    if (e.target.checked && e.target.checked === true) {
      setActivePhaseFilters([...activePhaseFilters, e.target.value]);
    } else if (e.target.checked === false) {
      setActivePhaseFilters(
        activePhaseFilters.filter(item => item !== e.target.value),
      );
    }
  };

  const onRegionFilterChange = e => {
    setRegionFilter(
      regionFilter.map(item => {
        if (item.name === e.target.value) {
          // item.selected = e.target.checked;
          return { ...item, selected: e.target.checked };
        }
        return item;
      }),
    );
  };

  // Update the filteredProjectList on filter or search query change
  useEffect(() => {
    let updatedProjectList;

    // Filter the list by selected project phases or show if project has no phase property
    updatedProjectList = [
      ...projectList.filter(
        project =>
          activePhaseFilters.indexOf(project.phase) !== -1 ||
          project.phase === null,
      ),
    ];

    // Filter by selected regions
    const selectedRegions = regionFilter
      .filter(region => region.selected === true)
      .map(item => item.name);

    updatedProjectList = [
      ...updatedProjectList.filter(project =>
        selectedRegions.length > 0
          ? selectedRegions.indexOf(project.region) !== -1
          : true,
      ),
    ];

    // Filter by search query if search query has been entered
    if (searchQuery.length) {
      const searchObjProp = (obj, propName, query): boolean => {
        if (obj[propName]) {
          return (
            obj[propName].toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
        }
        return false;
      };

      updatedProjectList = updatedProjectList.filter(
        project =>
          searchObjProp(project, 'name', searchQuery) ||
          searchObjProp(project, 'referenceNumber', searchQuery) ||
          searchObjProp(project, 'localGov', searchQuery) ||
          searchObjProp(project, 'roadName', searchQuery) ||
          searchObjProp(project, 'clearingLocation', searchQuery),
      );
    }

    // Sort the list
    const sortString = (a, b): number => {
      // If the value is null set it to 'z' so it comes last in the comparison
      const x = a !== null ? a.toLowerCase() : 'z';
      const y = b !== null ? b.toLowerCase() : 'z';
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    };

    updatedProjectList = sortBy
      ? [...updatedProjectList.sort((a, b) => sortString(a[sortBy], b[sortBy]))]
      : updatedProjectList;

    // Set results for current page only
    const startSlice = (currentPage - 1) * itemsPerPage;
    const endSlice = currentPage * itemsPerPage;
    setCurrentPageProjects(updatedProjectList.slice(startSlice, endSlice));

    // If not enough items for the current page go to the last page that has items or page 1 if there are no items
    if (Math.ceil(updatedProjectList.length / itemsPerPage) < currentPage) {
      setCurrentPage(Math.ceil(updatedProjectList.length / itemsPerPage) || 1);
    }

    // Update state with the filtered and sorted result
    setFilteredProjectList(updatedProjectList);
    
  }, [activePhaseFilters, sortBy, regionFilter, currentPage, searchQuery]);

  useEffect(() => {
    document.getElementById('main-content').scrollIntoView({
      behavior: 'smooth',
    });
    // eslint-disable-next-line
  }, [currentPage]);

  // Set a selected project on clicking the map marker
  const onMapMarkerClick = (id): void => {
    setSelectedProject(
      projectList.filter(item => item.id === id)[0],
    );
  };

  const clearAllFilters = (): void => {
    setSortBy(sortByOptions[0].value);
    setRegionFilter(regionFilterListInit());
    setSearchQuery('');
  };

  // Reset filters on view change
  useEffect(() => {
    clearAllFilters();
    
    // Update list/map view state in search params
    const url = new URL(window.location.href);
    url.searchParams.set("list", isListView.toString());

    console.log(url.href)
    history.pushState({},'',url.href);
    // eslint-disable-next-line
  }, [isListView]);

  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout
          projectInfoPanelOpen={
            selectedProject && selectedProject.name.length > 0
          }
          pageTheme={pageTheme}
        >
          <aside className="page-layout__aside">
            {isListView ? (
              <>
                {breadcrumbs && <StyledNavBreadcrumbs items={breadcrumbs} />}
                <div className="section-heading-wrap">
                  <PageTitle>
                    <EpiProperty
                      name={epiTitle.name}
                      value={epiTitle.value}
                      isEditMode={epiTitle.isEditMode}
                      outerTag="span"
                    />
                  </PageTitle>
                  {epiSubtitle && (
                    <EpiProperty
                      name={epiSubtitle.name}
                      value={epiSubtitle.value}
                      isEditMode={epiSubtitle.isEditMode}
                      outerTag="p"
                    />
                  )}
                </div>
                <h2>Search</h2>
                <StyledSearchFormWidget
                  initialValue={searchQuery}
                  placeholder="e.g. Project Name"
                  searchInputWidth="100%"
                  onSubmit={setSearchQuery}
                />
                {showPhaseFilters && (
                  <>
                    <h2>
                      Filter by
                      {pageType === 'proposed' ? ' phase' : pageType === 'actual' ? ' type' : ' method'}
                    </h2>
                    {projectPhaseList.map(item => (
                      <ToggleCheckbox
                        key={item.name}
                        id={item.name}
                        toggleColor={item.color}
                        label={item.label}
                        value={item.name}
                        checked={
                          activePhaseFilters.filter(
                            filterItem => filterItem === item.name,
                          ).length > 0
                        }
                        onChange={(e): void => {
                          onPhaseFilterChange(e);
                        }}
                      />
                    ))}
                  </>
                )}
                {sortByOptions && sortByOptions.length > 0 && (
                  <StyledSelectField
                    id="sort-by"
                    name="sort-by"
                    label="Sort by"
                    values={sortByOptions.map(item => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    selectedValue={sortBy}
                    onChange={(e): void => setSortBy(e.target.value)}
                    placeholder=""
                  />
                )}
                {regionFilter && regionFilter.length > 0 && (
                  <>
                    <h2>Select Main Roads Region</h2>
                    <StyledTypeFilterList>
                      {regionFilter.map(item => (
                        <li key={item.name}>
                          <TypeFilterListButton
                            active={item.selected}
                            onChange={onRegionFilterChange}
                            label={item.name}
                          />
                        </li>
                      ))}
                    </StyledTypeFilterList>
                  </>
                )}
                <StyledClearButton
                  type="button"
                  theme="alternative"
                  handleOnClick={clearAllFilters}
                >
                  Clear Filters
                </StyledClearButton>
              </>
            ) : (
              <>
                <div className="section-heading-wrap-solid">
                  <PageTitle>
                    <EpiProperty
                      name={epiTitle.name}
                      value={epiTitle.value}
                      isEditMode={epiTitle.isEditMode}
                      outerTag="span"
                    />
                  </PageTitle>
                  {epiSubtitle && (
                    <EpiProperty
                      name={epiSubtitle.name}
                      value={epiSubtitle.value}
                      isEditMode={epiSubtitle.isEditMode}
                      outerTag="p"
                    />
                  )}
                </div>

                {selectedProject && selectedProject.name !== null ? (
                  <ClearingProjectsInfoPanel
                    project={selectedProject}
                    closePanel={(): void => {
                      setMapZoomTrigger(!mapZoomTrigger);
                      setSelectedProject(null);
                    }}
                  />
                ) : (
                  <>
                    <div className="page-layout__aside__content">
                      {epiLeftPanelSummary && (
                        <EpiProperty
                          name={epiLeftPanelSummary.name}
                          value={epiLeftPanelSummary.value}
                          isEditMode={epiLeftPanelSummary.isEditMode}
                          outerTag="p"
                        />
                      )}
                    </div>
                    {showPhaseFilters ? (
                      <div className="filter-section">
                        <h3>
                          Filter by
                          {pageType === 'proposed' ? ' phase' : pageType === 'actual' ? ' type' : ' method'}
                        </h3>
                        {projectPhaseList.map(item => (
                          <ToggleCheckbox
                            key={item.name}
                            id={item.name}
                            value={item.name}
                            label={item.label}
                            toggleColor={item.color}
                            checked={
                              activePhaseFilters.filter(
                                filterItem => filterItem === item.name,
                              ).length > 0
                            }
                            onChange={(e): void => {
                              onPhaseFilterChange(e);
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      <>
                        <div className="page-layout__aside__heading">
                          {epiLeftPanelHeading && (
                            <EpiProperty
                              name={epiLeftPanelHeading.name}
                              value={epiLeftPanelHeading.value}
                              isEditMode={epiLeftPanelHeading.isEditMode}
                              outerTag="p"
                            />
                          )}
                        </div>
                        <div className="page-layout__aside__content">
                          {epiLeftPanelContent && (
                            <EpiProperty
                              name={epiLeftPanelContent.name}
                              value={epiLeftPanelContent.value}
                              isEditMode={epiLeftPanelContent.isEditMode}
                              outerTag="div"
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <div className="page-layout__aside__footer">
              {panels &&
                panels.map(panel => (
                  <FullWidthLink
                    key={panel.title}
                    className="full-width-link"
                    bgColor={panel.bgColor}
                  >
                    <a href={panel.href}>{`${panel.title} »`}</a>
                    <p>{panel.text}</p>
                  </FullWidthLink>
                ))}
            </div>
          </aside>
          <div className="page-layout__main">
            <StyledButton
              className="switch-view-btn"
              theme="default"
              icon={isListView ? 'map' : 'list-ul'}
              isActual={
                projectList.length > 0 &&
                projectList[0].projectType === 'actual'
              }
              handleOnClick={(): void => {
                setIsListView(!isListView);
              }}
            >
              {isListView ? 'Switch to map view' : 'Switch to list view'}
            </StyledButton>
            {isListView ? (
              <ListContainer>
                <p className="document-list__number">
                  {filteredProjectList.length <= itemsPerPage ? (
                    <>
                      <strong>{filteredProjectList.length}</strong>
                      {` Publication${
                        filteredProjectList.length !== 1 ? 's' : ''
                      }`}
                    </>
                  ) : (
                    <>
                      <strong>
                        {currentPage * itemsPerPage - (itemsPerPage - 1)}
                        {' - '}
                        {currentPage * itemsPerPage > filteredProjectList.length
                          ? filteredProjectList.length
                          : currentPage * itemsPerPage}
                      </strong>
                      {' Publications of '}
                      <strong>{filteredProjectList.length}</strong>
                    </>
                  )}
                </p>
                <StyledClearingProjectsList>
                  {currentPageProjects.map((project, i) => (
                    <ClearingProjectsListItem
                      project={project}
                      key={project?.id}
                    />
                  ))}
                </StyledClearingProjectsList>
                {filteredProjectList.length > itemsPerPage && (
                  <Pagination
                    forcePage={currentPage - 1}
                    pageCount={Math.ceil(
                      filteredProjectList.length / itemsPerPage,
                    )}
                    onPageChange={(val: { selected: number }): void => {
                      setCurrentPage(val.selected + 1);
                    }}
                  />
                )}
              </ListContainer>
            ) : (
              <>
                {googleMapsIsClientId ? (
                  <ClearingProjectsMap
                    mapMarkers={filteredProjectList}
                    onMapMarkerClick={onMapMarkerClick}
                    setDefaultZoom={mapZoomTrigger}
                    googleMapsClientId={googleMapsKey}
                  />
                ) : (
                  <ClearingProjectsMap
                    mapMarkers={filteredProjectList}
                    onMapMarkerClick={onMapMarkerClick}
                    setDefaultZoom={mapZoomTrigger}
                    googleMapsApiKey={googleMapsKey}
                  />
                )}
              </>
            )}
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
};

export default ClearingProjectsPage;
