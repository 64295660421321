import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const TimelineBlockStyles = styled.section`
  margin-bottom: 45px;
  h2 {
    font-size: 1.625em;
    ${breakpoint.greaterThan('sm')`
      font-size: 1.875em;
    `}
  }
`;

export const TimelineListStyles = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const TimelineItemStyles = styled.li`
  display: flex;
  gap: 30px;
  p {
    margin: 0;
    line-height: 1.125;
  }
  .gutter {
    position: relative;
    @supports not (aspect-ratio: 1 / 1) {
      margin-right: 30px;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 100;
      top: 0;
      left: calc(0.5em - 1px);
      width: 2px;
      height: 100%;
      border-right: 2px solid rgba(0, 0, 0, 0.5);
    }
  }
  .icon {
    background-color: #ffffff;
    position: relative;
    z-index: 200;
    width: 1em;
    height: 1em;
    border: 1px solid #000000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      position: relative;
      font-size: 0.5em;
      z-index: 1000;
    }
  }
  &:not(:last-of-type) {
    .context {
      padding-bottom: 1.25em;
    }
  }
  &:last-of-type {
    .gutter {
      &:after {
        border-right: none;
      }
    }
  }
  .title {
    font-size: 1.125em;
    margin-bottom: 0.25em;
    font-weight: ${theme.fontWeight.black};
  }
  &.status-in_progress {
    .gutter {
      &:after {
        border-right: 2px solid rgba(0, 0, 0, 1);
      }
    }
    .icon {
      color: #ffffff;
      background-color: #000000;
    }
    .title {
      color: #d14210;
    }
  }
  &.status-future,
  &.status-deadline {
    .title,
    .time,
    .caption {
      color: #969798;
    }
  }
  &.status-future {
    .gutter {
      &:after {
        border-right: 2px dashed rgba(0, 0, 0, 0.5);
      }
    }
    .icon {
      border-color: transparent;
      background-color: transparent;
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        width: 0.8125em;
        height: 0.8125em;
        border: 1px solid;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }
  }
  &.status-deadline {
    .icon {
      border-color: transparent;
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        border-radius: 50%;
        border: 1px solid;
      }
      &:before {
        width: 1.25em;
        height: 1.25em;
        background-color: #ffffff;
      }
      &:after {
        width: 0.8125em;
        height: 0.8125em;
      }
    }
  }
  &.status-project_completed {
    .icon {
      color: #ffffff;
      background-color: #000000;
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        border-radius: 50%;
      }
      &:before {
        width: 1.25em;
        height: 1.25em;
        border: 1px solid #000000;
        background-color: #ffffff;
      }
      &:after {
        width: 0.875em;
        height: 0.875em;
        background-color: #000000;
        z-index: 100;
      }
    }
    .title {
      color: #d14210;
    }
  }
  .time {
    /* font-size: 0.875em; */
    font-size: 1.125em;
    margin-bottom: 0;
    font-weight: ${theme.fontWeight.regular};
  }
  .caption {
    font-size: 1.125em;
    /*display: none;*/
    > * {
      font-size: 1em;
    }
    ${breakpoint.greaterThan('sm')`
      display: block;
      .is-sidebar & {
        /*display: none;*/
      }
    `}
  }
`;
