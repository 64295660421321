import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

export const StyledFeaturedCTABlock = styled.div`
  background-color: ${theme.colors.backgroundDark};
  color: ${theme.colors.textLight};
  padding: 1em;
  border-radius: 15px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.22);
  &.light {
    background-color: ${theme.colors.bgLight};
    color: ${theme.colors.textDark};
  }
  margin-bottom: 2em;
  margin-top: calc(2em - 2px);
  ${breakpoint.greaterThan('sm')`
    padding: 2em;
  `};
`;

export const ImageSectionStyles = styled.figure`
  position: relative;
  border: 4px solid #fff;
  box-shadow: ${theme.elevation[1]};
  border-radius: 2.1875em;
  margin: 0;
  height: 0;
  padding: 100% 0 0 0;
  margin-top: -4em;
  margin-bottom: 1.5em;
  background-color: #000;
  overflow: hidden;

  @media screen and (min-width: 360px) {
    border-width: 5px;
  }
  @media screen and (min-width: 768px) {
    flex: 1;
    margin-top: -4em;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @supports (object-fit: cover) {
      object-fit: cover;
    }
  }
`;

export const ContentStyles = styled.div`
  width: 100%;
  flex: 1;
  > div:first-child {
    flex: 1;
  }
  ${breakpoint.greaterThan('sm')`
    margin: 0 auto;
  `};

  ${breakpoint.greaterThan('md')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `}

  h2 {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-size: 1.375em;
    line-height: 1.1;
    font-weight: ${theme.fontWeight.black};

    ${breakpoint.greaterThan('md')`
      font-size: 1.75rem;
    `}
  }

  p.subtitle {
    margin: 0;
    font-size: 1.125em;
    font-weight: ${theme.fontWeight.bold};

    ${breakpoint.greaterThan('md')`
      font-size: 1.33em;
    `}
  }

  p {
    margin: 0 0 1em 0;
    font-size: 0.75rem;

    ${breakpoint.greaterThan('md')`
      font-size: 1em;
    `}

    ${breakpoint.greaterThan('lg')`
      font-size: 1.125em;
    `}
  }

  span {
    opacity: 0;

    ${breakpoint.greaterThan('md')`
      padding: 0 1.5em;
      opacity: 1;
    `}
  }

  a {
    display: block;
    width: 100%;
    padding: 1em 2em;
    line-height: 1.25;
    border-radius: 100px;
    background-color: ${theme.colors.primary};
    &.light {
      background-color: ${theme.colors.buttonBg};
    }
    color: ${theme.colors.buttonText};
    font-size: 0.875em;
    font-weight: ${theme.fontWeight.bold};
    text-align: center;
    cursor: pointer;
    border: none;
    transition: color 50ms ease-out, background-color 50ms ease-out;

    &:hover {
      border: none;
      color: ${theme.colors.linkActive};
      background-color: ${theme.colors.backgroundLight};
      &.light {
        color: ${theme.colors.buttonText};
        background-color: ${theme.colors.primary};
      }
    }

    ${breakpoint.greaterThan('sm')`
      padding: 1em 1.5em;
      font-size: 0.8125em;
    `};
    ${breakpoint.greaterThan('md')`
      padding: 1em 2em;
      font-size: 0.875em;
      display: inline-block;
    `}
  }
  .buttons {
    @media screen and (min-width: 768px) {
    }
  }
`;
