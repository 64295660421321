import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledWhatWeManageWidget = styled.div`
  display: block;
  width: 100%;
  margin: 12px auto;
  ${breakpoint.greaterThan('md')`
    margin: 50px auto;
    max-width: 900px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    transition: transform 1500ms cubic-bezier(0.125, 0.66, 0.25, 1);
    transform: translate3d(0,0,0);
    z-index: 1000;
    position: relative;
    &:hover{
      transform: translate3d(0, -10px, 0);
    }
  `};
  ${breakpoint.greaterThan('lg')`
    max-width: 71vw;
  `};
  ${breakpoint.greaterThan('xl')`
    /*max-width: min(71vw, 62rem);*/
  `};

  .what-we-manage-widget__inner {
    padding: 1.5em;
    background-color: ${theme.colors.backgroundDark};

    ${breakpoint.greaterThan('md')`
      padding: 2rem 2rem 2em 2rem;
      justify-content: space-between;
      border-radius: 26px;
    `};

    ${breakpoint.greaterThan('lg')`
      display: flex;
    `}
  }
`;
export const StyledWhatWeManageContent = styled.div`
  color: ${theme.colors.textLight};

  padding-bottom: 1.5rem;
  ${breakpoint.greaterThan('lg')`
    width: 50%;
    padding: 0 2.5rem 0 0;
  `}

  h2 {
    margin: 0;
    font-size: 1.25rem;

    ${breakpoint.greaterThan('lg')`
      padding-bottom: 0;
      text-align: right;
      font-size: 1.75rem;
    `}
  }

  a {
    border: none;
    color: ${theme.colors.textLight};
    &:hover {
      border: none;
      color: ${theme.colors.textLight};
      text-decoration: underline;
    }
  }

  span {
    font-size: 0.75rem;

    ${breakpoint.greaterThan('sm')`
      font-size: 1rem;
    `}

    ${breakpoint.greaterThan('lg')`
      display: block;
      text-align: right;
      font-size: 1.125rem;
    `}
  }
`;
export const StyledWhatWeManageForm = styled.div`
  color: ${theme.colors.textDark};
  align-self: center;
  position: relative;

  ${breakpoint.greaterThan('lg')`
    width: 50%;
  `}

  h2 {
    font-size: 1.25rem;

    ${breakpoint.greaterThan('lg')`
      font-size: 1.5rem;
    `}
  }
`;
export const StyledWhatWeManageResults = styled.div`
  background-color: ${theme.colors.bgLight};
  color: ${theme.colors.textDark};
  border: 1px solid #2d2f32;
  box-shadow: ${theme.elevation[1]};
  padding: 1.5rem;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1000;

  .list__separator {
    padding-top: 2rem;
  }
`;
