import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import NProgress from 'nprogress';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTitle from '../../atoms/PageTitle';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import Pagination from '../../molecules/Pagination';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import HVSTravelImpactListItem from '../../molecules/HVSTravelImpactListItem';
import {
  StyledHVSTravelImpactList,
  StyledHVSImpactTypeFilterList,
  StyledButton,
  StyledClearButton,
  StyledNavBreadcrumbs,
  StyledPageLayout,
  StyledSearchFormWidget,
  StyledSelectInput,
  StyledStatusFilterList,
  StyledTypeFilterListButton,
} from './styles';

type ApiQueryProps = {
  region?: string[];
  status?: string[];
  suburb?: string[];
  restrictionType?: string[];
  pageNumber?: number;
  pageSize?: number;
  sectionFilterResults?: string;
};

type QueryStats = {
  totalItems?: number;
  page?: number;
  itemsPerPage?: number;
  lastUpdatedDate?: string;
};

type FilterItem = {
  name: string;
  icon?: string;
  href?: string;
  selected: boolean;
};

type TypeFilterListButton = {
  active: boolean;
  label: string;
  // href?: string;
  onClick: (label, active) => void;
};

// Sidebar Status filter button
const StatusFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButton): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <StyledTypeFilterListButton
      className={active ? 'active' : ''}
      type="button"
      onClick={onClickHandler}
    >
      {active && <FontAwesomeIcon icon={['far', 'times-circle']} />}
      <span>{label}</span>
      <FontAwesomeIcon icon="chevron-right" />
    </StyledTypeFilterListButton>
  );
};

// Sidebar RestrictionType filter button
const RestrictionTypeFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButton): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <StyledTypeFilterListButton
      className={active ? 'active' : ''}
      type="button"
      onClick={onClickHandler}
    >
      {active && <FontAwesomeIcon icon={['far', 'times-circle']} />}
      <span>{label}</span>
      <FontAwesomeIcon icon="chevron-right" />
    </StyledTypeFilterListButton>
  );
};

// Sidebar Region filter button
const RegionFilterListButton = ({
  active,
  label,
  onClick,
}: TypeFilterListButton): ReactElement => {
  const onClickHandler = (): void => {
    onClick(label, !active);
  };
  return (
    <Button type="button" ghost active={active} handleOnClick={onClickHandler}>
      {label}
    </Button>
  );
};

// Change this base url when debugging
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/';
const APIURL = `${API_BASE_URL}/hvstravelimpact/search`;
//const APIURL = `https://run.mocky.io/v3/3de0a410-a960-426b-bac1-d3ac7755b346`;

interface HVSTravelImpactListPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  initialListingQuery?: string;
  sectionFilter?: string;
  itemsPerPageSetting: number;
  lastReviewed?: string;
  lastUpdated?: string;
  alert?: PageTemplateProps['alert'];
  epiLeftContentArea?: EpiProps;
}
const HVSTravelImpactListPage = ({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  initialListingQuery,
  sectionFilter,
  itemsPerPageSetting = 10,
  lastReviewed = undefined,
  lastUpdated = undefined,
  alert,
  epiLeftContentArea,
}: HVSTravelImpactListPageProps): ReactElement => {
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [regions, setRegions] = useState<FilterItem[]>([]);
  const [statuses, setStatuses] = useState<FilterItem[]>([]);
  const [suburbs, setSuburbs] = useState<FilterItem[]>([]);
  const [restrictionTypes, setRestrictionTypes] = useState<FilterItem[]>([]);
  const [apiResults, setApiResults] = useState<any[] | undefined>(undefined);
  const [queryStats, setQueryStats] = useState<QueryStats>({});

  // NOTE: The initial query can be supplied from initialSearchQuery as a prop,
  // otherwise it'll fall back to undefined
  const [searchQuery, setSearchQuery] = useState<string>(initialListingQuery);
  const [currentSection, setCurrentSection] = useState<string>(sectionFilter);

  const isMounted = useRef(false);

  // search input field
  const onSearchSubmitHandler = (val: string): void => {
    setSearchQuery(val);
  };

  // query API
  const getResults = async ({
    region,
    status,
    suburb,
    restrictionType,
    sectionFilterResults,
    pageNumber = 1,
  }: ApiQueryProps) => {
    // local front end
    // const API_BASE_URL = 'https://next.json-generator.com/api/json/get/NJ95f1YO_';
    // local front end
    NProgress.start();
    const resRegions = region 
      ? region.map(f => encodeURIComponent(f)).join(',') 
      : '';
    const resStatuses = status
      ? status.map(f => encodeURIComponent(f)).join(',')
      : '';
    const resSuburbs = suburb
      ? suburb.map(f => encodeURIComponent(f)).join(',')
      : '';
      const resRestrictionTypes = restrictionType
      ? restrictionType.map(f => encodeURIComponent(f)).join(',')
      : '';
    // assumed API call format from docs (subject to change, update if it does):
    // /api/search-content/search?q=?itemsPerPage={#}&category={category1,category2}&type={type1,type2}&page={pagenumber}&itemsPerPage={#}
    const url =
      searchQuery.split('&').length > 1
        ? `${APIURL}${searchQuery}`
        : `${APIURL}?q=${searchQuery}` +
          `&take=${itemsPerPageSetting}` +
          `&region=${resRegions}` +
          `&status=${resStatuses}` +
          `&suburb=${resSuburbs}` +
          `&restrictionType=${resRestrictionTypes}` +
          `&page=${pageNumber}` +
          `&sectionFilter=${encodeURIComponent(
            currentSection ?? sectionFilterResults,
          )}`;

    const results = await axios(url)
      .then(res => {
        NProgress.done();
        return res.data;
      })
      .catch(reason => {
        console.error('ERROR GETTING SEARCH QUERY', reason);
        NProgress.done();
        return Promise.reject(reason);
      });
    return results;
  };

  // Prepare new query...
  const updateQuery = (): void => {
    const region =
      regions && regions.filter(c => c.selected === true).map(c => c.name);
    const status =
      statuses && statuses.filter(c => c.selected === true).map(c => c.name);
    const suburb =
      suburbs && suburbs.filter(c => c.selected === true).map(c => c.name);
    const restrictionType =
      restrictionTypes && restrictionTypes.filter(c => c.selected === true).map(c => c.name);

    const query: ApiQueryProps = {
      region,
      status,
      suburb,
      restrictionType,
      pageNumber: currentPage,
      sectionFilterResults: currentSection,
    };

    getResults(query).then(res => {
      // console.log(res);

      // bit of a hacky way to prevent false refreshes of these comps. Resulting in a infinite loop.
      if (JSON.stringify(res.regions) !== JSON.stringify(regions)) {
        setRegions(res.regions);
      }
      if (JSON.stringify(res.statuses) !== JSON.stringify(statuses)) {
        setStatuses(res.statuses);
      }
      if (JSON.stringify(res.suburbs) !== JSON.stringify(suburbs)) {
        setSuburbs(res.suburbs);
      }
      if (JSON.stringify(res.restrictionTypes) !== JSON.stringify(restrictionTypes)) {
        setRestrictionTypes(res.restrictionTypes);
      }

      if (JSON.stringify(res.items) !== JSON.stringify(apiResults)) {
        setApiResults(res.items);
      }
      setQueryStats({
        totalItems: res.totalItems,
        page: res.page,
        itemsPerPage: res.itemsPerPage,
        lastUpdatedDate: res.lastUpdatedDate
      });
    });
  };

  // when filtering by region (sidebar)
  const onRegionClickHandler = (regionLabel: string, isActive: boolean): void => {
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newRegions = regions.map(region => {
      if (region.name === regionLabel) {
        region.selected = isActive;
      }
      return region;
    });
    setRegions(newRegions);
    updateQuery();
  };

  // when filtering by status (sidebar)
  const onStatusClickHandler = (
    statusLabel: string,
    isActive: boolean,
  ): void => {
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newStatuses = statuses.map(status => {
      if (status.name === statusLabel) {
        status.selected = isActive;
      }
      return status;
    });
    setStatuses(newStatuses);
    updateQuery();
  };

  // when filtering by restrictiontype (sidebar)
  const onRestrictionTypeClickHandler = (restrictionTypeLabel: string, isActive: boolean): void => {
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newRestrictionTypes = restrictionTypes.map(restrictionType => {
      if (restrictionType.name === restrictionTypeLabel) {
        restrictionType.selected = isActive;
      }
      return restrictionType;
    });
    setRestrictionTypes(newRestrictionTypes);
    updateQuery();
  };

  // when filtering by suburb (sidebar)
  const onSuburbChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const suburbLabel = event.target.value;
    console.log(suburbLabel);
    setSearchQuery(searchQuery !== initialListingQuery ? searchQuery : '');
    setCurrentSection(currentSection);
    const newSuburbs = suburbs.map(suburb => {
      if (suburb.name === suburbLabel) {
        suburb.selected = true;
      } else {
        suburb.selected = false;
      }
      return suburb;
    });
    setSuburbs(newSuburbs);
    updateQuery();
  };

  // when paginating
  useEffect(() => {
    if (isMounted.current) {
      updateQuery();
    }
    // eslint-disable-next-line
  }, [currentPage, searchQuery]);

  // on mount:
  useEffect(() => {
    getResults({})
      .then(res => {
        // console.log(res);
        setRegions(res.regions);
        setStatuses(res.statuses);
        setSuburbs(res.suburbs);
        setRestrictionTypes(res.restrictionTypes);
        setApiResults(res.items);
        setQueryStats({
          totalItems: res.totalItems,
          page: res.page,
          itemsPerPage: res.itemsPerPage,
          lastUpdatedDate: res.lastUpdatedDate
        });
        isMounted.current = true;
      })
      .catch(reason => console.error(reason));
    // eslint-disable-next-line
  }, [currentSection]);

  // search input field
  const onClearSubmitHandler = (): void => {
    const url = `//${window.location.host}${window.location.pathname}`;
    window.location.href = url;
  };

  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout>
          <aside className="page-layout__aside">
            {breadcrumbs && <StyledNavBreadcrumbs items={breadcrumbs} />}
            <PageTitle>
              <EpiProperty
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="span"
              />
            </PageTitle>
            <StyledButton
              icon="chevron-down"
              ghost
              type="button"
              handleOnClick={(): void => setShowFilters(!showFilters)}
            />
            <div
              className={`document-list__filters ${
                showFilters ? 'document-list__filters--active' : ''
              }`}
            >
              {restrictionTypes && restrictionTypes?.length > 0 && (
                <>
                  <h2>Browse by Type</h2>
                  <StyledHVSImpactTypeFilterList>
                    {restrictionTypes.map((item, i) => (
                      <li
                        key={i}
                        className={item.selected ? 'is-active' : undefined}
                      >
                        <RestrictionTypeFilterListButton
                          active={item.selected}
                          onClick={onRestrictionTypeClickHandler}
                          label={item.name}
                        />
                      </li>
                    ))}
                  </StyledHVSImpactTypeFilterList>
                </>
              )}
              {regions && regions?.length > 0 && (
                <>
                  <h2>Region</h2>
                  <StyledStatusFilterList>
                    {regions.map((item, i) => {
                      return (
                        <li key={i}>
                          <RegionFilterListButton
                            active={item.selected}
                            onClick={onRegionClickHandler}
                            label={item.name}
                          />
                        </li>
                      );
                    })}
                  </StyledStatusFilterList>
                </>
              )}
              <h2>Search</h2>
              <StyledSearchFormWidget
                placeholder="TIN or location"
                searchInputWidth="100%"
                onSubmit={onSearchSubmitHandler}
              />
            </div>
            <StyledClearButton
              type="button"
              theme="alternative"
              handleOnClick={onClearSubmitHandler}
            >
              Clear Filters
            </StyledClearButton>
            {epiLeftContentArea && <EpiProperty name={epiLeftContentArea.name} value={epiLeftContentArea.value} isEditMode={epiLeftContentArea.isEditMode} outerTag="div" />}
          </aside>
          <div className="page-layout__main">
            {apiResults && queryStats && (
              <Container>
                <div className="document-list__head">
                  <div className='document-list__updated'>
                    {queryStats.lastUpdatedDate && (
                      <>
                        <strong>Last updated: </strong>
                        {queryStats.lastUpdatedDate}
                      </>
                    )}
                  </div>
                  <div className="document-list__number">
                    {queryStats.totalItems <= queryStats.itemsPerPage ? (
                      <>
                        <strong>{queryStats.totalItems}</strong>
                        {` Item${queryStats.totalItems !== 1 ? 's' : ''}`}
                      </>
                    ) : (
                      <>
                        <strong>
                          {queryStats.page * queryStats.itemsPerPage -
                            (queryStats.itemsPerPage - 1)}
                          {' - '}
                          {queryStats.page * queryStats.itemsPerPage}
                        </strong>
                        {' Items of '}
                        <strong>
                          {queryStats.totalItems &&
                            queryStats.totalItems.toLocaleString()}
                        </strong>
                      </>
                    )}
                  </div>
                </div>
                <StyledHVSTravelImpactList>
                  {apiResults?.map((item, i) => (
                    <HVSTravelImpactListItem
                      key={i}
                      // id={item.id}
                      status={item.status}
                      referenceNumber={item.referenceNumber}
                      suburbs={item.suburbs}
                      regions={item.regions}
                      roadName={item.roadName}
                      direction={item.direction}
                      // coordinates={item.coordinates}
                      // googleMapLink={item.googleMapLink}
                      restrictionTypes={item.restrictionTypes}
                      commencementDate={item.commencementDate}
                      endDate={item.endDate}
                      // groundClearanceRequired={item.groundClearanceRequired}
                      // seekAlternateRoute={item.seekAlternateRoute}
                      details={item.details}
                      detailsTitle={item.detailsTitle}
                      contactFullName={item.contactFullName}
                      contactNumber={item.contactNumber}
                      contactEmail={item.contactEmail}
                      // createdBy={item.createdBy}
                      timeOfImpact={item.timeOfImpact}
                      osomDetails={item.osomDetails}
                      ravDetails={item.ravDetails}
                      hasOSOMRestriction={item.hasOSOMRestriction}
                      hasRAVRestriction={item.hasRAVRestriction}
                    />
                  ))}
                </StyledHVSTravelImpactList>
                {queryStats.totalItems > queryStats.itemsPerPage && (
                  <Pagination
                    forcePage={currentPage - 1}
                    pageCount={Math.ceil(
                      queryStats.totalItems / queryStats.itemsPerPage,
                    )}
                    onPageChange={(val: any): void => {
                      document.getElementById('main-content').scrollIntoView({
                        behavior: 'smooth',
                      });
                      return setCurrentPage(val.selected + 1);
                    }}
                  />
                )}
                {apiResults.length === 0 && (
                  <p>
                    No travel impacts found for your query, please try a different
                    search.
                  </p>
                )}
              </Container>
            )}
            <PageLastReviewed
              lastReviewed={lastReviewed}
              lastUpdated={lastUpdated}
              pageType="page"
            />
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
};

export default HVSTravelImpactListPage;
