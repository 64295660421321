import React, { ReactElement } from 'react';
import StyledSelectField from './styles';

type SelectFieldProp = {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedValue: string;
  placeholder?: string;
  className?: string;
  values: { value: string; label: string }[];
};

const SelectField = ({
  id = 'id',
  name = 'name',
  label = 'Label',
  required = false,
  onChange,
  values = [{ value: 'value', label: 'Item label' }],
  selectedValue,
  placeholder,
  className,
}: SelectFieldProp): ReactElement => {
  return (
    <StyledSelectField
      className={className}
      showingPlaceholder={selectedValue.length === 0}
    >
      <label htmlFor={id}>{label}</label>
      <div className="select-wrap">
        <select
          name={name}
          id={id}
          required={required}
          onChange={onChange}
          value={selectedValue}
        >
          {placeholder && placeholder.length > 0 && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}

          {values.map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <span className="custom-arrow" />
      </div>
    </StyledSelectField>
  );
};

export default SelectField;
