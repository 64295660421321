import React, { ReactElement } from 'react';
import ImageCaptionBlockItem, {
  ImageCaptionBlockProps,
} from '../../atoms/ImageCaptionBlockItem';
import ContentBlockGrid from '../../organisms/ContentBlockGrid';
import { BaseBlockProps } from '../../../types'

export interface ImageCaptionGridBlockProps extends BaseBlockProps {
  items?: ImageCaptionBlockProps[];
  align?: 'left' | 'right' | 'center';
};

function ImageCaptionGridBlock({
  items,
  align = 'center',
  anchorId
}: ImageCaptionGridBlockProps): ReactElement {
  return (
    <>
      <ContentBlockGrid anchorId={anchorId} align={align}>
        {items.map((item, i) => {
          return (
            <ImageCaptionBlockItem
              key={i}
              image={item.image}
              title={item.title}
              text={item.text}
              textLink={item.textLink}
              color={item.color}
              bgColor={item.bgColor}
            />
          );
        })}
      </ContentBlockGrid>
    </>
  );
}

export default ImageCaptionGridBlock;
