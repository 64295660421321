import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledLinkStepBack = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125em;
  color: ${theme.colors.textDark};
  font-weight: 900;
  margin-bottom: 30px;
  svg {
    margin-right: 0.5em;
  }
  span {
    display: block;
  }
  &:hover {
    text-decoration: underline;
  }
  ${breakpoint.greaterThan('md')`
    font-size: 1.5em;
  `};
  @media print {
    display: none;
  }
`;
