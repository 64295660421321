import React, { ReactElement } from 'react';
import ContentListItem, {
  ContentListItemProps as TileBlockProps,
} from '../../molecules/ContentListItem';

function TileBlock({
  thumbnail,
  title,
  summary,
  lastModifiedDate,
}: TileBlockProps): ReactElement {
  return (
    <ContentListItem
      thumbnail={thumbnail}
      title={title}
      summary={summary}
      lastModifiedDate={lastModifiedDate}
    />
  );
}

export default TileBlock;
