import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

export const StyledPagination = styled.div`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px ${theme.colors.paginationBorder};
    padding: 0;
  }

  li {
    position: relative;
    display: none;
    display: inline-flex;
    padding: ${theme.spacing.sm} 0;

    ${breakpoint.greaterThan('sm')`
      display: inline-flex;
    `}
      :before {
      position: absolute;
      display: block;
      top: -2.5px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: ${theme.colors.paginationBorder};
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      content: '';
    }

    &:hover:before {
      opacity: 1;
    }
    a {
      border: none;
    }
  }

  .selected,
  .break,
  .previous,
  .next {
    display: inline-flex;
  }

  .previous,
  .next {
    fleX: 1 auto;
  }
  .next {
    justify-content: flex-end;
  }

  .previous:before,
  .next:before {
    display: none;
  }

  a {
    padding: 1.5em 0.875em;
    color: ${theme.colors.paginationText};
    font-size: 0.625rem;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
    font-weight: 600;

    ${breakpoint.greaterThan('sm')`
      font-size: 0.675rem;
      padding: 1.5em 1.5em;
    `}
    ${breakpoint.greaterThan('md')`
      font-size: 0.75rem;
      padding: 1em 2em;
    `}
    ${breakpoint.greaterThan('lg')`
      font-size: 0.875rem;
      padding: 1rem 2rem;
    `}

    &:hover {
      color: ${theme.colors.paginationButtonHover};
    }
  }

  .previous a,
  .next a {
    display: block;
    width: 70px;
    padding: 1em 0;
    text-align: center;
    background-color: ${theme.colors.paginationButtonBg};
    color: ${theme.colors.paginationButtonColor};
    font-size: 0.70rem;
    font-weight: ${theme.fontWeight.medium};
    transition: background-color 0.3s ease-in-out;

    ${breakpoint.greaterThan('sm')`
      width: 80px;
      font-size: 0.75rem;
    `};
    ${breakpoint.greaterThan('lg')`
      font-size: 1rem;
      width: 120px;
    `}

    &:hover {
      background-color: ${theme.colors.paginationButtonHover};
    }
  }

  .disabled a {
    opacity: 0;
    cursor: default;

    &:hover {
      background-color: ${theme.colors.paginationButtonBg};
    }
  }

  .selected {
    position: relative;
    &:before {
      position: absolute;
      display: block;
      top: -2.5px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: ${theme.colors.paginationButtonHover};
      opacity: 1;
      content: '';
    }
  }
`;
