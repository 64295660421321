import styled from 'styled-components';

export const StyledClearingProjectsMapWrap = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  height: 100%;
  width: calc(100% + ((100vw - (100% + 400px + 3.75rem)) / 2));
  min-height: calc(100vh - 166px);
  @media screen and (max-width: 1180px) {
    width: 100%;
    min-height: 80vh;
    &:before {
      content: '';
      width: 0;
      height: 0;
      padding-top: 100%;
    }
  }
  .google-map {
    flex: 1 1 100%;
  }
  .region-label {
    position: relative;
    p {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 16px;
      font-weight: bold;
      transform: translate(-50%, -50%);
      color: #0c8072;
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
        1px 1px 0 white;
      white-space: nowrap;
    }
    &.left {
      p {
        transform: translate(0%, -50%);
      }
    }
    &.right {
      p {
        transform: translate(-100%, -50%);
      }
    }
  }
`;
