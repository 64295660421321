import React, { ReactElement } from 'react';

import { LinkProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty from '../../atoms/EpiProperty';
import Quicklinks from '../../blocks/QuicklinksBlock';
import NavBreadcrumbs, {
  NavBreadcrumbsProps,
} from '../../molecules/NavBreadcrumbs';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import { StyledHeader, StyledMain } from './styles';

interface HeavyVehiclesChildLandingPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  lastReviewed?: string;
  lastUpdated?: string;
  quickLinks?: LinkProps[];
  epiTitle: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  epiMainContentArea: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  alert?: PageTemplateProps['alert'];
}

const getActiveLink = links => {
  const activeLink = links.filter(link => link.active === true);
  if (activeLink.length > 0) {
    return <em>{activeLink[0].label}</em>;
  }
  return undefined;
};

function HeavyVehiclesChildLandingPage({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  quickLinks,
  epiMainContentArea,
  lastReviewed = undefined,
  lastUpdated = undefined,
  alert,
}: HeavyVehiclesChildLandingPageProps): ReactElement {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <StyledMain>
        <StyledHeader>
          <Container>
            <NavBreadcrumbs items={breadcrumbs} />
            {quickLinks && quickLinks.length > 0 && (
              <p>I'm here about: {getActiveLink(quickLinks)}</p>
            )}
            <Quicklinks links={quickLinks} />
          </Container>
        </StyledHeader>
        <article>
          <Container>
            <EpiProperty
              name={epiTitle.name}
              value={epiTitle.value}
              isEditMode={epiTitle.isEditMode}
              outerTag="h1"
            />
            <EpiProperty
              name={epiMainContentArea.name}
              value={epiMainContentArea.value}
              isEditMode={epiMainContentArea.isEditMode}
              outerTag="div"
            />
          </Container>
          <PageLastReviewed
            lastReviewed={lastReviewed}
            lastUpdated={lastUpdated}
            pageType="page"
          />
        </article>
      </StyledMain>
    </PageTemplate>
  );
}

export default HeavyVehiclesChildLandingPage;
