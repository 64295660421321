/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import theme from '../../../lib/theme';

const StyledArticle = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }

  caption {
    display: block;
    margin: 10px 0;
    text-align: left;
    font-size: 0.75rem;
    color: ${theme.colors.textMid};
  }

  .article__back {
    font-weight: ${theme.fontWeight.bold};
    border: none;
    font-weight: ${theme.fontWeight.black};
    color: ${theme.colors.textDark};
    transition: color 0.3s ease-in-out;
    &:hover {
      border: none;
      color: ${theme.colors.linkHover};
    }

    svg {
      margin-right: 0.75rem;
    }
  }
`;

export default StyledArticle;
