import styled from 'styled-components';
import { breakpoint } from '../../../lib/theme';

export const FactTilesGridStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px 8px;

  ${breakpoint.greaterThan('sm')`
    gap: 30px 16px;
  `}
  &.cols-2 {
    ${breakpoint.greaterThan('xs')`
      grid-template-columns: 1fr 1fr;
    `}
  }
  &.cols-3 {
    ${breakpoint.greaterThan('md')`
      grid-template-columns: 1fr 1fr 1fr;
    `}
  }
  &.cols-4 {
    ${breakpoint.greaterThan('xs')`
      grid-template-columns: 1fr 1fr;
    `}
    ${breakpoint.greaterThan('lg')`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `}
  }
`;
