import '../../../lib/fontawesome';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

import { ImageProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageSummary from '../../atoms/PageSummary';
import PageTitle from '../../atoms/PageTitle';
import { LinkItem } from '../../blocks/LinkList';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import RightColumnLayout from '../../templates/RightColumnLayout';
import StyledArticle from './styles';

/* eslint-disable jsx-a11y/anchor-is-valid */
interface ArticleProps {
  // breadcrumbs?: NavBreadcrumbsProps['items'];
  epiTitle: EpiProps;
  epiSummary?: EpiProps;
  epiContent?: EpiProps;
  epiMainContentArea: EpiProps;
  image?: ImageProps;
  epiCaption?: EpiProps;
  // categories?: {
  //   href: string;
  //   label: string;
  // }[];
  publicationDate?: string;
  updatedDate?: string;
  epiComment?: EpiProps;
  parentLink?: LinkItem;
  lastReviewed?: string;
  lastUpdated?: string;
}

interface RightContentProps {
  epiRightContentArea: EpiProps;
}

interface ArticlePageProps {
  header: PageTemplateProps['header'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  footer: PageTemplateProps['footer'];
  breadcrumbs?: NavBreadcrumbsProps['items'];
}

function Article({
  epiTitle,
  epiSummary,
  epiContent,
  epiMainContentArea,
  image,
  epiCaption,
  // categories,
  publicationDate,
  updatedDate,
  epiComment,
  parentLink,
  lastReviewed = undefined,
  lastUpdated = undefined,
}: ArticleProps): ReactElement {
  return (
    <StyledArticle>
      <PageTitle>
        <EpiProperty
          name={epiTitle.name}
          value={epiTitle.value}
          isEditMode={epiTitle.isEditMode}
          outerTag="h1"
        />
      </PageTitle>
      {epiSummary && (
        <PageSummary>
          <EpiProperty
            name={epiSummary.name}
            value={epiSummary.value}
            isEditMode={epiSummary.isEditMode}
            outerTag="p"
          />
        </PageSummary>
      )}
      {publicationDate && (
        <p>
          <strong>Published: </strong>
          {publicationDate}
          {updatedDate && (
            <>
              , <strong>Updated: </strong>
              {updatedDate}
            </>
          )}
        </p>
      )}

      {image && <img src={image.src} alt={image.alt} />}
      {epiCaption && (
        <EpiProperty
          name={epiCaption.name}
          value={epiCaption.value}
          isEditMode={epiCaption.isEditMode}
          outerTag="caption"
        />
      )}

      {epiContent && (
        <EpiProperty
          name={epiContent.name}
          value={epiContent.value}
          isEditMode={epiContent.isEditMode}
          isHTMLContent={epiContent.isHTMLContent}
          outerTag="div"
        />
      )}

      <EpiProperty
        name={epiMainContentArea.name}
        value={epiMainContentArea.value}
        isEditMode={epiMainContentArea.isEditMode}
        outerTag="div"
      />

      {epiComment && (
        <p>
          <strong>Comment: </strong>
          <EpiProperty
            name={epiComment.name}
            value={epiComment.value}
            isEditMode={epiComment.isEditMode}
            outerTag="span"
          />
        </p>
      )}
      {parentLink && (
        <a className="article__back" href={parentLink.href}>
          <FontAwesomeIcon icon="chevron-left" />
          Back to&nbsp;
          {parentLink.title}
        </a>
      )}
      <PageLastReviewed
        lastReviewed={lastReviewed}
        lastUpdated={lastUpdated}
        pageType="page"
      />
    </StyledArticle>
  );
}

function RightContent({
  epiRightContentArea,
}: RightContentProps): ReactElement {
  return (
    <EpiProperty
      name={epiRightContentArea.name}
      value={epiRightContentArea.value}
      isEditMode={epiRightContentArea.isEditMode}
      outerTag="div"
    />
  );
}

function ArticlePage({
  header,
  footer,
  ctaBlock,
  breadcrumbs,
  epiTitle,
  epiSummary,
  epiContent,
  epiMainContentArea,
  image,
  epiCaption,
  // categories,
  publicationDate,
  updatedDate,
  epiComment,
  parentLink,
  epiRightContentArea,
  lastReviewed = undefined,
  lastUpdated = undefined,
}: ArticlePageProps & ArticleProps & RightContentProps): ReactElement {
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      ctaBlock={ctaBlock}
      header={header}
      footer={footer}
    >
      <Container>
        <RightColumnLayout
          leftColumn={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              <Article
                epiTitle={epiTitle}
                epiSummary={epiSummary}
                epiContent={epiContent}
                epiMainContentArea={epiMainContentArea}
                image={image}
                epiCaption={epiCaption}
                // categories={categories}
                publicationDate={publicationDate}
                updatedDate={updatedDate}
                epiComment={epiComment}
                parentLink={parentLink}
                lastReviewed={lastReviewed}
                lastUpdated={lastUpdated}
              />
            </>
          }
          rightColumn={
            <RightContent epiRightContentArea={epiRightContentArea} />
          }
        />
      </Container>
    </PageTemplate>
  );
}

export default ArticlePage;
