import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const ContainerStyles = css`
  width: 100%;
  max-width: 100rem;
  margin: 0;
  padding: 0;
  position: relative;
  &.padded {
    max-width: ${theme.breakpoints.container};
    margin: 0 auto;
    padding: 0 1.5rem;
    transition: max-width 250ms ease-out, padding 250ms ease-out;
    ${breakpoint.greaterThan('xl')`
      padding: 0 3em;
    `}
    ${breakpoint.greaterThan('container')`
      padding: 0 4em;
    `}
    ${breakpoint.greaterThan('huge')`
      padding: 0 5em;
      max-width: ${theme.breakpoints.huge};
    `}
    ${breakpoint.greaterThan('massive')`
      padding: 0 6em;
      max-width: ${theme.breakpoints.massive};
    `}
  }
  &.highlighted {
    background-color: #f5f5f5;
    padding-right: 50px;
    &:before,
    &:after {
      position: absolute;
      height: 100%;
      //width: 50%;
      background-color:  #f5f5f5;
      top: 0;
      bottom: 0;
      left: -9999px;
      right: 0;
      content: '';
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
    
  }
  @media print {
    max-width: none;
    padding: 0;
  }
`;

const StyledContainer = styled.div`
  ${ContainerStyles}
`;

type ContainerProps = {
  children?: ReactNode;
  className?: string;
  padding?: boolean;
  marginTop?: number;
  marginBottom?: number;
  paddingTop?: number;
  paddingBottom?: number;
  highlighted?: boolean;
};

const Container = ({
  children,
  className,
  padding = true,
  marginTop = 0,
  marginBottom = 0,
  paddingTop = 0,
  paddingBottom = 0,
  highlighted = false,
}: ContainerProps): ReactElement => {
  return (
    <StyledContainer
      className={`${className ? className : ''}${padding ? ' padded' : ''}${highlighted ? ' highlighted' : ''}`}
      style={{
        marginTop: marginTop > 0 ? marginTop : null,
        marginBottom: marginBottom > 0 ? marginBottom : null,
        paddingTop: paddingTop > 0 ? paddingTop : null,
        paddingBottom: paddingBottom > 0 ? paddingBottom : null
      }}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
