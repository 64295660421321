import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

const StyledFeaturedContentBlock = styled.div`
  border-radius: 15px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);

  background: ${theme.colors.backgroundDark};
  color: ${theme.colors.textLight};
  padding: 12px;
  ${breakpoint.greaterThan('sm')`
    padding: 32px;  
  `};
  text-align: left;
  &.align-left {
    text-align: left;
  }
  &.align-center {
    text-align: center;
  }
  &.align-right {
    text-align: right;
  }
  &.do-stretch {
    border-radius: 0;
    box-shadow: none;
  }
  header {
    display: flex;
    align-items: center;
    gap: 12px;
    ${breakpoint.greaterThan('sm')`
      margin: 0 0 1.25em 0;
    `};
    h2 {
      flex: 1;
      margin: 0;
      font-size: 1.125em;
      font-weight: 900;
      em {
        font-style: normal;
        color: ${theme.colors.titleColorEmphasis};
      }
      ${breakpoint.greaterThan('sm')`
        font-size: 1.5em;
      `};
      ${breakpoint.greaterThan('md')`
      `};
    }
    a {
      width: 38px;
      height: 38px;
      border-radius: 15px;
      background: #d14210;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      ${breakpoint.greaterThan('sm')`
        width: 75px;
        display: none;
      `};
    }
  }
  .content {
    display: none;
    p {
      margin-top: 0;
    }
    ${breakpoint.greaterThan('sm')`
      display: block;
    `};
  }

  a.button {
    display: block;
    border-radius: 50px;
    margin: 32px 0 0 0;
    padding: 1em 2em;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.textLight};
    font-size: 1rem;
    font-weight: ${theme.fontWeight.black};
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 50ms ease-out;
    &:hover {
      color: ${theme.colors.primary};
      background-color: ${theme.colors.backgroundLight};
    }

    ${breakpoint.greaterThan('lg')`
      width: auto;
      min-width: 200px;
    `}
  }
  &.compact {
    ${breakpoint.greaterThan('sm')`
      padding: 25px;
    `};
    header {
      margin: 0;
      h2 {
        ${breakpoint.greaterThan('sm')`
          font-size: 1.5em;
        `};
      }
      a {
        ${breakpoint.greaterThan('sm')`
          display: flex;
          width: 75px;
          height: 75px;
          font-size: 2em;
        `};
      }
    }
    .content {
      display: none;
    }
  }
  &.do-stretch {
    a.button {
      max-width: 500px;
      min-width: auto;
      margin: 32px auto 0;
    }
  }
`;

export default StyledFeaturedContentBlock;
