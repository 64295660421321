import React, { ReactElement } from 'react';

import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageContent from '../../atoms/PageContent';
import PageSummary from '../../atoms/PageSummary';
import PageTitle from '../../atoms/PageTitle';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import RightColumnLayout from '../../templates/RightColumnLayout';
import { PageLayoutContentStyles } from '../../templates/RightColumnLayout/StyledRightColumnLayout';

interface LeftContentProps {
  epiTitle: EpiProps;
  epiSummary?: EpiProps;
  epiContent?: EpiProps | EpiProps[];
  epiMainContentArea?: EpiProps;
}

interface RightContentProps {
  epiRightContentArea: EpiProps;
}

interface RightColumnPageProps extends LeftContentProps, RightContentProps {
  breadcrumbs?: PageTemplateProps['breadcrumbs'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  alert?: PageTemplateProps['alert'];
  mobileTop?: boolean;
}

const LeftContent = ({
  epiTitle,
  epiSummary,
  epiContent,
  epiMainContentArea,
}: LeftContentProps): ReactElement => {
  return (
    <>
      <PageTitle>
        <EpiProperty
          name={epiTitle.name}
          value={epiTitle.value}
          isEditMode={epiTitle.isEditMode}
          outerTag="h1"
        />
      </PageTitle>
      {epiSummary && (
        <PageSummary>
          <EpiProperty
            name={epiSummary.name}
            value={epiSummary.value}
            isEditMode={epiSummary.isEditMode}
            outerTag="p"
          />
        </PageSummary>
      )}
      {epiContent &&
        (Array.isArray(epiContent) ? (
          <>
            {epiContent.map(epiContentListItem => (
              // Wrap this in an element with .highlighted
              // to give it a highlighted background color
              <PageLayoutContentStyles>
                <PageContent>
                  <EpiProperty
                    name={epiContentListItem.name}
                    value={epiContentListItem.value}
                    isEditMode={epiContentListItem.isEditMode}
                    isHTMLContent={epiContentListItem.isHTMLContent}
                    outerTag="div"
                  />
                </PageContent>
              </PageLayoutContentStyles>
            ))}
          </>
        ) : (
          <PageContent>
            <EpiProperty
              name={epiContent.name}
              value={epiContent.value}
              isEditMode={epiContent.isEditMode}
              isHTMLContent={epiContent.isHTMLContent}
              outerTag="div"
            />
          </PageContent>
        ))}
      {epiMainContentArea?.value && (
        <EpiProperty
          name={epiMainContentArea.name}
          value={epiMainContentArea.value}
          isEditMode={epiMainContentArea.isEditMode}
          outerTag="div"
        />
      )}
    </>
  );
};

const RightContent = ({
  epiRightContentArea,
}: RightContentProps): ReactElement => {
  return (
    <EpiProperty
      name={epiRightContentArea.name}
      value={epiRightContentArea.value}
      isEditMode={epiRightContentArea.isEditMode}
      outerTag="div"
    />
  );
};

const RightColumnPage = ({
  breadcrumbs,
  header,
  ctaBlock,
  footer,
  epiTitle,
  epiSummary,
  epiContent,
  epiMainContentArea,
  epiRightContentArea,
  alert,
  mobileTop = false,
}: RightColumnPageProps): ReactElement => {
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      ctaBlock={ctaBlock}
      header={header}
      footer={footer}
      alert={alert}
    >
      <Container>
        <RightColumnLayout
          mobileTop={mobileTop}
          leftColumn={
            <LeftContent
              epiTitle={epiTitle}
              epiSummary={epiSummary}
              epiContent={epiContent}
              epiMainContentArea={epiMainContentArea}
            />
          }
          rightColumn={
            <RightContent epiRightContentArea={epiRightContentArea} />
          }
        />
      </Container>
    </PageTemplate>
  );
};

export default RightColumnPage;
