import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledMain = styled.div`
  /* padding should be handled by footer
  padding-bottom: 60px;
  ${breakpoint.greaterThan('md')`
    padding-bottom: 120px;
  `};
  ${breakpoint.greaterThan('lg')`
    padding-bottom: 140px;
`}; */
`;

export const StyledHeader = styled.div`
  background-color: ${theme.colors.backgroundLight};
  padding-top: 40px;
  padding-bottom: 25px;
  h1 {
    font-weight: 900;
    color: ${theme.colors.titleColor};
    em {
      font-style: normal;
      color: ${theme.colors.titleColorEmphasis};
    }
  }
  p {
    margin: 0 0 1em 0;
    font-size: 1em;
    font-weight: 600;
  }
  ${breakpoint.greaterThan('md')`
    padding-top: 50px;
    padding-bottom: 30px;
    p{
      font-size: 1.25em;
    }
  `};
  ${breakpoint.greaterThan('lg')`
      padding-top: 75px;
      padding-bottom: 1em;
      p{
        font-size: 1.5em;
      }
  `};
`;

export const HeavyMainSection = styled.section`
  max-width: ${theme.breakpoints.container};
  margin: 0 auto;
  padding: 40px 1.5rem;
  display: flex;
  flex-direction: column-reverse;
  > div {
    order: 2;
  }
  > aside {
    order: 1;
    margin-bottom: 50px;
  }
  ${breakpoint.greaterThan('md')`
    > div {
      order: 1;
    }
    > aside {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 90px;
      > div {
        width: 50%;
      }
    }
  `};
  ${breakpoint.greaterThan('lg')`
    padding: 100px 1.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 66.66%;
      padding-right: 40px;
    }
    > aside {
      display: block;
      width: 33.33%;
      min-width: 460px;
      border-left: 1px solid ${theme.colors.seperator};
      padding-left: 40px;
      > div {
        width: 100%;
      }
    }
  `};
`;

export const StyledContainer = styled.div`
  max-width: ${theme.breakpoints.container};
  margin: 0 auto;
  ${breakpoint.greaterThan('sm')`
    padding: 0 1.5rem;
  `};
`;
