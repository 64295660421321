import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

export const StyledContentSearchBlock = styled.div`
  height: 100%;
  position: relative;
  background-color: ${theme.colors.backgroundDark};

  border-radius: 15px;

  &.stretched {
    &:before,
    &:after {
      position: absolute;
      height: 100%;
      width: 50%;
      background-color: ${theme.colors.backgroundDark};
      top: 0;
      content: '';
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }
  &.light-theme {
    background-color: ${theme.colors.bgLight};
    &.stretched {
      &:before,
      &:after {
        background-color: ${theme.colors.bgLight};
      }
    }
  }

  .content-search-block__inner {
    > div {
      padding: 2rem;
      &:nth-child(1) {
        padding-bottom: 0.5rem;
      }
      &:nth-child(2) {
        padding-top: 0.5rem;
      }
    }

    > div.compact {
      padding: 2rem 0;
      width: 100% !important;
    }

    ${breakpoint.greaterThan('md')`
      display: flex;
      > div{
        &:nth-child(1) {
          padding-bottom: 2rem;
        }
        &:nth-child(2) {
          padding-top: 2rem;
        }
      }
    `}
  }
`;

export const StyledContentSearchBlockContent = styled.div`
  color: ${theme.colors.textLight};
  &.light-theme {
    color: ${theme.colors.textDark};
  }
  ${breakpoint.greaterThan('md')`
      width: 50%;
  `}
  h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 900;
    ${breakpoint.greaterThan('lg')`
      font-size: 2rem;
    `}
  }
  p {
    font-size: 1rem;
  }
  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledContentSearchBlockForm = styled.div`
  color: ${theme.colors.textLight};
  &.light-theme {
    color: ${theme.colors.textDark};
  }

  h2 {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-size: 1.25rem;

    ${breakpoint.greaterThan('lg')`
      font-size: 1.5rem;
    `}
  }

  em {
    font-style: normal;
    color: ${theme.colors.titleColorEmphasis};
  }

  input {
    color: ${theme.colors.textLight};
    &.light-theme {
      color: ${theme.colors.textDark};
    }
  }
  ${breakpoint.greaterThan('md')`
    width: 50%;
    border-left: 1px solid ${theme.colors.borderMedium};
    &.light-theme {
      border-left: none;
    }
  `}
`;
