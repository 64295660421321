import React, { ReactElement, useEffect, useRef, useState } from 'react';
import theme from '../../../lib/theme';

import ProjectListItem, {
  ProjectListItemProps,
} from '../../molecules/ProjectListItem';
import { StyledContentListingGroup } from './styles';

export interface ProjectListGroupProps {
  compact?: boolean;
  list: ProjectListItemProps[];
}

const ProjectListGroup = ({
  list,
  compact,
}: ProjectListGroupProps): ReactElement => {
  const [mounted, setMounted] = useState(false);
  const [breakpoint, setBreakpoint] = useState('md');
  const sectionRef = useRef(null);
  const onResizeHandler = () => {
    
    const componentWidth = parseInt(
      getComputedStyle(sectionRef.current).width,
      10,
    );

    console.log('resizes - width: ' + componentWidth);

    if (componentWidth > parseInt(theme.breakpoints.lg, 10) * 16) {
      return setBreakpoint('lg');
    } else if (componentWidth > parseInt(theme.breakpoints.md, 10) * 16) {
      return setBreakpoint('md');
    }
    return setBreakpoint('sm');
  };
  useEffect(() => {
    if (mounted === true) {
      return undefined;
    }
    window.addEventListener('resize', onResizeHandler);
    onResizeHandler();
    // mounted
    setMounted(true);
    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);
  return (
    <StyledContentListingGroup
      className={`${compact ? 'compact' : ''} size-${breakpoint}`}
      ref={sectionRef}
    >
      {list.map((listItem, i) => (
        <div
          key={i}
          className={`content-list__item${
            compact ? ' content-list__item--compact' : ''
          }`}
        >
          <ProjectListItem
            thumbnail={listItem.thumbnail}
            title={listItem.title}
            location={listItem.location}
            compact={compact}
            lastModifiedDate={listItem.lastModifiedDate}
            projectStatus={listItem.projectStatus}
            ribbonColor={listItem.ribbonColor}
            ribbonLabel={listItem.ribbonLabel}
            projectIcon={listItem.projectIcon}
          />
        </div>
      ))}
    </StyledContentListingGroup>
  );
};

export default ProjectListGroup;
