import React, { ReactElement, ReactNode } from 'react';

type PageContentProps = {
  children: ReactNode;
};

function PageContent({ children }: PageContentProps): ReactElement {
  return <>{children}</>;
}

export default PageContent;
