import styled, { keyframes } from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

const shimmerBg = keyframes`
  0%{background-position:100% 50%}
  /* 50%{background-position:100% 50%}
  100%{background-position:0% 50%} */
  100%{background-position:0% 50%}
`;
export const SearchContainerStyles = styled.section`
  padding-top: 75px;
  padding-bottom: 64px;
  background-color: #f5f5f5;
  .intro {
    padding-bottom: 24px;
    p {
      margin: 0;
    }
  }
  .search {
    position: relative;
    &:before {
      position: absolute;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: #333333;
      top: -2px;
      left: -2px;
      content: '';
      z-index: 100;
    }
    &.loading {
      &:before {
        background: linear-gradient(
          297deg,
          rgba(51, 51, 51, 1) 0%,
          rgba(255, 0, 0, 1) 50%,
          rgba(51, 51, 51, 1) 100%
        );

        background-size: 400% 100%;
        animation: ${shimmerBg} 500ms linear infinite;
      }
    }
    > * {
      z-index: 200;
    }
    input {
      position: relative;
      /* width: calc(100% - 2px); */
      width: 100%;
      font-size: 1.625em;
      padding: 1em 1em;
      border: 1px solid #333333;
      border-color: transparent;
    }
    .input__buttons {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 12px;
      gap: 12px;
    }

    button {
      border: 4px solid currentColor;
      color: #707070;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25em;
      width: 2em;
      height: 2em;
      cursor: pointer;
      transition: 50ms ease-out;
      &.clear {
        transition: 150ms ease-out;
        opacity: 0;
        transform: scale(0);
        &.available {
          opacity: 1;
          transform: scale(1);
        }
      }
      &:hover {
        color: #d1420f;
      }
    }
  }
  .filters {
    padding-top: 1.625em;
    display: flex;
    gap: 16px 80px;
    flex-wrap: wrap;
  }
  .filter {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      > div {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    h3 {
      margin: 0;
      font-size: 1.125em;
      font-weight: ${theme.fontWeight.bold};
    }
    .filter__icon {
      width: 26px;
      height: 26px;
      background-color: #ffffff;
      border: 1px solid #707070;
      position: relative;
      &:after {
        background-color: #d1420f;
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;
        display: block;
        opacity: 0;
        transition: 100ms ease-out;
        transform: scale(0);
      }
    }
    label {
      font-size: 1.125em;
      display: flex;
      gap: 10px;
      @supports not (aspect-ratio: 1 / 1) {
        > * {
          padding: 0 5px;
        }
      }
      align-items: center;
      flex-wrap: wrap;
      cursor: pointer;
      span {
        display: block;
      }
      &:hover {
        .filter__icon {
          &:after {
            opacity: 1;
            transform: scale(0.33);
            background-color: #707070;
          }
        }
      }
    }
    input[type='radio']:checked + label,
    input[type='checkbox']:checked + label {
      background-color: transparent;

      .filter__icon {
        &:after {
          opacity: 1;
          transform: scale(1);
          background-color: #d1420f;
        }
      }
    }
  }
`;
export const SearchResultsContainerStyles = styled.section`
  padding-top: 75px;
  padding-bottom: 140px;
`;
export const SearchResultsGrid = styled.div`
  padding-bottom: 120px;
  display: grid;
  gap: 25px;
  @supports not (aspect-ratio: 1 / 1) {
    > * {
      padding: 12px;
    }
  }
  grid-template-columns: 1fr;
  ${breakpoint.greaterThan('sm')`
  `}
  ${breakpoint.greaterThan('md')`
    grid-template-columns: 1fr 1fr;
  `}
  ${breakpoint.greaterThan('xl')`
    grid-template-columns: 1fr 1fr 1fr;
  `}
  ${breakpoint.greaterThan('container')`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`;

export const LoadingStyles = styled.div`
  /* padding: 1em; */
  border: 4px solid transparent;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  font-size: 1.25em;
  > div {
    padding: 0;
  }
`;
