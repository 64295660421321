import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

const StyledAlertBannerBlock = styled.div`
  display: none;
  width: 100%;
  background: ${theme.colors.alert};
  &.active {
    display: block;
  }
  & > div {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0 1rem;
    align-items: center;
    > div {
      color: #fff;
      margin: 1em 0 1em 0;
    }
    a {
      color: #fff;
      text-decoration: underline;
      font-weight: 600;
    }
    & > svg {
      color: #fff;
      font-size: 1.4rem;
      cursor: pointer;
    }
  }
  &.alert-color--red {
    background-color: ${theme.colors.promoBlockTitleBgRed};
  }
  &.alert-color--blue {
    background-color: ${theme.colors.promoBlockTitleBgBlue};
  }
  &.alert-color--green {
    background-color: ${theme.colors.promoBlockTitleBgGreen};
  }
  &.alert-color--black {
    background-color: ${theme.colors.promoBlockTitleBgBlack};
  }
`;

export default StyledAlertBannerBlock;
