// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
// import { Grid, Cell } from 'styled-css-grid';

// Lib
import GlobalStyle from './lib/GlobalStyle';
import { URLSearchParams } from 'url';

// Blocks
import ButtonBlock from './components/blocks/ButtonBlock';
import NavLinkList from './components/blocks/NavLinkList';
import ContactDetailsBlock from './components/blocks/ContactDetailsBlock';
import CTABlock from './components/blocks/CTABlock';
import FeaturedCTABlock from './components/blocks/FeaturedCTABlock';
import RelatedContentBlock from './components/blocks/RelatedContentBlock';
import HeroCTABlock from './components/blocks/HeroCTABlock';
import PromoBlock from './components/blocks/PromoBlock';
import WYSIWYGBlock from './components/blocks/WYSIWYGBlock';
import LinkList from './components/blocks/LinkList';
import AccordionContainerBlock from './components/blocks/AccordionContainerBlock';
import ContentListingBlock from './components/blocks/ContentListingBlock';
import TileBlock from './components/blocks/TileBlock';
import ContainerBlock from './components/blocks/ContainerBlock';
import ContentSearchBlock from './components/blocks/ContentSearchBlock';
import WhatWeManageBlock from './components/blocks/WhatWeManageBlock';
import ImageCaptionGridBlock from './components/blocks/ImageCaptionGridBlock';
import Tabs from './components/blocks/TabsBlock';
import FeaturedPanelBlock from './components/blocks/FeaturedPanelBlock';
import ContentImageBlock from './components/blocks/ContentImageBlock';
import FeaturedContentBlock from './components/blocks/FeaturedContentBlock';
import SlideshowBlock from './components/blocks/SlideshowBlock';
import ImageCaptionBlock from './components/blocks/ImageCaptionBlock';
import FactsTilesBlock from './components/blocks/FactsTilesBlock';
import GroupedProjectsBlock from './components/blocks/GroupedProjectsBlock';
import FeaturedCTASection from './components/blocks/FeaturedCTASection';
import DownloadsTilesBlock from './components/blocks/DownloadsTilesBlock';
import TimelineBlock from './components/blocks/TimelineBlock';
import RoadworksFeedBlock from './components/blocks/RoadworksFeedBlock';
import ProjectListingBlock from './components/blocks/ProjectListingBlock';
import DashboardLinkBlock from './components/blocks/DashboardLinkBlock';
import ListBlock from './components/blocks/ListBlock';

// Widgets
import Header from './components/widgets/Header';
import Footer from './components/widgets/Footer';
import LeftMenuWidget from './components/molecules/LeftMenuWidget';
// import ErrorHero from './components/molecules/ErrorHero';

// Pages
import ContactPage from './components/pages/ContactPage';
import HomePage from './components/pages/HomePage';
import FullWidthPage from './components/pages/FullWidthPage';
import LeftColumnPage from './components/pages/LeftColumnPage';
import RightColumnPage from './components/pages/RightColumnPage';
import ErrorPage from './components/pages/ErrorPage';
import DocumentListingPage from './components/pages/DocumentListingPage';
import DocumentPage from './components/pages/DocumentPage';
import ListingPage from './components/pages/ListingPage';
import ProjectPage from './components/pages/ProjectPage';
import SearchResultsPage from './components/pages/SearchResultsPage';
import HeavyVehiclesPage from './components/pages/HeavyVehiclesPage';
import HeavyVehiclesLandingPage from './components/pages/HeavyVehiclesLandingPage';
import ProjectsListingPage from './components/pages/ProjectsListingPage';
import ProjectsLandingPage from './components/pages/ProjectsLandingPage';
import ArticlePage from './components/pages/ArticlePage';
import SiteLandingPage from './components/pages/SiteLandingPage';
import HeavyVehiclesChildLandingPage from './components/pages/HeavyVehiclesChildLandingPage';
import AccreditationListPage from './components/pages/AccreditationListPage';
import ClearingProjectDetailsPage from './components/pages/ClearingProjectDetailsPage';
import ClearingProjectsPage from './components/pages/ClearingProjectsPage';
import ProjectsSearchPage from './components/pages/ProjectsSearchPage';
import HVSTravelImpactListPage from './components/pages/HVSTravelImpactListPage';
import TravelMapPage from './components/pages/TravelMapPage';

declare const global: any;

if (typeof window === 'undefined') {
  global.window = new Object();
  global.window.document = new Object();
}

if (typeof Object.assign !== 'function') {
  Object.assign = function(target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (let index = 1; index < arguments.length; index++) {
      const source = arguments[index];
      if (source != null) {
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

// Expose React
global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

// Lib
global.GlobalStyle = GlobalStyle;
global.URLSearchParams = URLSearchParams

// Blocks
global.ButtonBlock = ButtonBlock;
global.ContactDetailsBlock = ContactDetailsBlock;
global.CTABlock = CTABlock;
global.FeaturedCTABlock = FeaturedCTABlock;
global.RelatedContentBlock = RelatedContentBlock;
global.HeroCTABlock = HeroCTABlock;
global.PromoBlock = PromoBlock;
global.WYSIWYGBlock = WYSIWYGBlock;
global.LinkList = LinkList;
global.NavLinkList = NavLinkList;
global.AccordionContainerBlock = AccordionContainerBlock;
global.ContentListingBlock = ContentListingBlock;
global.ProjectListingBlock = ProjectListingBlock;
global.TileBlock = TileBlock;
global.ImageCaptionBlock = ImageCaptionBlock;
global.ContainerBlock = ContainerBlock;
global.ContentSearchBlock = ContentSearchBlock;
global.WhatWeManageBlock = WhatWeManageBlock;
global.ImageCaptionGridBlock = ImageCaptionGridBlock;
global.Tabs = Tabs;
global.FeaturedPanelBlock = FeaturedPanelBlock;
global.ContentImageBlock = ContentImageBlock;
global.FeaturedContentBlock = FeaturedContentBlock;
global.SlideshowBlock = SlideshowBlock;
global.FactsTilesBlock = FactsTilesBlock;
global.GroupedProjectBlock = GroupedProjectsBlock;
global.FeaturedCTASection = FeaturedCTASection;
global.DownloadsTilesBlock = DownloadsTilesBlock;
global.TimelineBlock = TimelineBlock;
global.RoadworksFeedBlock = RoadworksFeedBlock;
global.DashboardLinkBlock = DashboardLinkBlock;
global.ListBlock = ListBlock;

// Widgets
global.Header = Header;
global.Footer = Footer;
global.LeftMenuWidget = LeftMenuWidget;

// Pages
global.ContactPage = ContactPage;
global.HomePage = HomePage;
global.FullWidthPage = FullWidthPage;
global.LeftColumnPage = LeftColumnPage;
global.RightColumnPage = RightColumnPage;
global.ErrorPage = ErrorPage;
global.DocumentListingPage = DocumentListingPage;
global.DocumentPage = DocumentPage;
global.ListingPage = ListingPage;
global.ProjectPage = ProjectPage;
global.SearchResultsPage = SearchResultsPage;
global.HeavyVehiclesPage = HeavyVehiclesPage;
global.HeavyVehiclesLandingPage = HeavyVehiclesLandingPage;
global.ProjectsListingPage = ProjectsListingPage;
global.ProjectsLandingPage = ProjectsLandingPage;
global.ArticlePage = ArticlePage;
global.SiteLandingPage = SiteLandingPage;
global.HeavyVehiclesChildLandingPage = HeavyVehiclesChildLandingPage;
global.AccreditationListPage = AccreditationListPage;
global.ClearingProjectDetailsPage = ClearingProjectDetailsPage;
global.ClearingProjectsPage = ClearingProjectsPage;
global.ProjectsSearchPage = ProjectsSearchPage;
global.HVSTravelImpactListPage = HVSTravelImpactListPage;
global.TravelMapPage = TravelMapPage;
