import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { StyledMainWithSideBar } from '../../../lib/StyledMainWithSideBar';
import theme, { breakpoint } from '../../../lib/theme';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageContent from '../../atoms/PageContent';
import PageSummary from '../../atoms/PageSummary';
import PageTitle from '../../atoms/PageTitle';
import { LinkListProps } from '../../molecules/LeftMenuWidget';
import NavBreadcrumbs, {
  NavBreadcrumbsProps,
} from '../../molecules/NavBreadcrumbs';
import Sidebar from '../../molecules/Sidebar';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';

const StyledPageLayout = styled.div`
  ${StyledMainWithSideBar({ flow: 'aside-main', element: 'aside' })}
`;

const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.md} 0;

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.lg} 0;
  `}
`;

type LeftColumnPageProps = {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  epiSummary?: EpiProps;
  epiContent?: EpiProps;
  epiMainContentArea: EpiProps;
  sidebarLinks?: LinkListProps[];
  epiLeftContentArea: EpiProps;
  alert?: PageTemplateProps['alert'];
};

function LeftColumnPage({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  epiSummary,
  epiContent,
  epiMainContentArea,
  sidebarLinks,
  epiLeftContentArea,
  alert,
}: LeftColumnPageProps): ReactElement {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout>
          <Sidebar links={sidebarLinks}>
            <EpiProperty
              name={epiLeftContentArea.name}
              value={epiLeftContentArea.value}
              isEditMode={epiLeftContentArea.isEditMode}
              outerTag="div"
            />
          </Sidebar>
          <div className="page-layout__main">
            {breadcrumbs && <StyledNavBreadcrumbs items={breadcrumbs} />}
            <PageTitle>
              <EpiProperty
                name={epiTitle.name}
                value={epiTitle.value}
                isEditMode={epiTitle.isEditMode}
                outerTag="h1"
              />
            </PageTitle>
            {epiSummary && (
              <PageSummary>
                <EpiProperty
                  name={epiSummary.name}
                  value={epiSummary.value}
                  isEditMode={epiSummary.isEditMode}
                  outerTag="p"
                />
              </PageSummary>
            )}
            {epiContent && (
              <PageContent>
                <EpiProperty
                  name={epiContent.name}
                  value={epiContent.value}
                  isEditMode={epiContent.isEditMode}
                  isHTMLContent={epiContent.isHTMLContent}
                  outerTag="div"
                />
              </PageContent>
            )}
            <EpiProperty
              name={epiMainContentArea.name}
              value={epiMainContentArea.value}
              isEditMode={epiMainContentArea.isEditMode}
              outerTag="div"
            />
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
}

export default LeftColumnPage;
