import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const FactTileStyles = styled.div`
  border-radius: 15px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.16);
  padding: 20px 26px;
  color: white;
  background: #d14210;
  &.theme-red {
    background: #d14210;
  }
  &.theme-green {
    background: #008072;
  }
  &.theme-blue {
    background: #054e66;
  }
  &.theme-black {
    background: #2d2f32;
  }
  &.small-text {
    .title {
      font-size: 1.2em;
      ${breakpoint.greaterThan('md')`
        font-size: 1.325em;
      `}
    }
  }
  .title {
    font-size: 1.875em;
    ${breakpoint.greaterThan('md')`
      font-size: 2.35em;
    `}
    font-weight: ${theme.fontWeight.black};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1.125;
    svg {
      margin-right: 4px;
      font-size: 0.666em;
      ${breakpoint.greaterThan('md')`
        margin-right: 10px;
        font-size: 0.9em;
      `}
    }
  }
  .caption {
    margin-top: 0.45em;
    p {
      line-height: 1.125;
      font-size: 1em;
      font-weight: ${theme.fontWeight.medium};
      margin: 0;
      ${breakpoint.greaterThan('md')`
        font-size: 1.125em;
      `}
    }
  }
`;
