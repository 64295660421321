import styled from 'styled-components';
import { hideVisually } from 'polished';
import theme, { breakpoint } from '../../../lib/theme';
import Logo from '../../atoms/Logo';

export const StyledLogo = styled(Logo)`
  width: 165px;
  ${breakpoint.greaterThan('lg')`
    width: 315px;
  `}
`;

export const StyledHeader = styled.header`
  width: 100%;
  padding: 1rem 0;
  max-height: 70px;
  background-color: ${theme.colors.headerBg};
  ${breakpoint.greaterThan('lg')`
    max-height: 105px;
  `}

  &.off-canvas--open {
    position: fixed;
    top: 0;
    z-index: 6000;
  }

  .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${theme.breakpoints.container};
    margin: 0 auto;
    padding: 0 1.5rem;
  }

  .header__button {
    width: 42px;
    height: 42px;
    padding: 0.5rem;
    background: none;
    border: solid 1px transparent;
    color: ${theme.colors.headerBtn};
    font-size: 1rem;
    line-height: 0;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    -webkit-appearance: none;

    &:focus {
      outline: dotted 2px ${theme.colors.linkFocus};
    }

    &:hover {
      color: ${theme.colors.linkHover};
    }

    span {
      ${hideVisually}
    }

    ${breakpoint.greaterThan('lg')`
      display: none;
    `}
  }
  .header__button--is-search-page {
    opacity: 0;
    pointer-events: none;
  }

  .nav-utils {
    display: none;

    ${breakpoint.greaterThan('lg')`
      fleX: 1 auto;
      display: flex;
      justify-content: flex-end;
      padding-left: 1em;
      padding-right: 1em;
    `}
  }

  .search-form {
    display: none;

    ${breakpoint.greaterThan('lg')`
      display: flex;
    `}
  }
`;

export const StyledMainNavWrapper = styled.div`
  border-bottom: solid 1px ${theme.colors.navMainBorder};

  ${breakpoint.lessThan('lg')`
  display: none;
`}

  @media print {
    display: none;
  }
`;

export const StyledOffCanvas = styled.div`
  position: fixed;
  top: 70px;
  right: 0;
  width: 100vw;
  min-width: 300px;
  height: 100%;
  padding: 0 1.5rem;
  background-color: ${theme.colors.navMobileBg};
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.3s ease-in-out;
  z-index: 5000;

  /* &:before {
  position: absolute;
  left: -15vw;
  width: 15vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
} */

  &.off-canvas--open {
    height: 100vh;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    overflow-y: scroll;
  }

  ${breakpoint.greaterThan('lg')`
    display: none;
  `}
`;
