interface IconColor {
  color?: string;
}
// xmlnsXlink

const convertColor = color => {
  if (color === 'green') {
    return '#008072';
  }
  if (color === 'red') {
    return '#d14210';
  }
  if (color === 'blue') {
    return '#0A4D66';
  }
  if (color === 'black') {
    return '#000000';
  }
  return '#008072';
};

export const UnderConstructionIcon = (
  { color }: IconColor = { color: '#008072' },
) => {
  const fill = convertColor(color);
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>construction</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12.667,0.667 C11.1940566,0.667 10,1.86105657 10,3.334 C10,4.80694343 11.1940566,6.001 12.667,6.001 C14.1399434,6.001 15.334,4.80694343 15.334,3.334 C15.334,1.86105657 14.1399434,0.667 12.667,0.667 L12.667,0.667 Z M8,6.682 C6.51790402,6.67487502 5.04983376,6.96916952 3.685,7.547 L3.375,7.747 L2.594,9.3 L2.573,9.313 C2.14242553,9.09188587 1.62582547,9.12155028 1.2233915,9.39049774 C0.820957541,9.6594452 0.595936452,10.1254078 0.635527904,10.6078167 C0.675119355,11.0902256 0.973101129,11.5132651 1.414,11.713 L20.138,20.705 C19.55,21.624 19.01,22.429 18.625,22.387 C18.11,22.331 17.948,22.245 17.505,22.874 C16.738318,23.9993389 16.0371546,25.1679446 15.405,26.374 C15.3071149,26.5802292 15.321497,26.8221939 15.4431224,27.0153757 C15.5647479,27.2085575 15.7767212,27.3261205 16.005,27.327 L26.669,27.327 C26.8959087,27.327 27.1070507,27.2109326 27.2286756,27.0193734 C27.3503005,26.8278142 27.3655132,26.5873601 27.269,26.382 C26.353,24.435 23.252,18.129 22.415,18.129 C22.1463676,18.1673199 21.9009876,18.3024567 21.725,18.509 L15.825,15.67 L14.578,9.98 C14.3410979,9.21258658 13.7682228,8.59445551 13.021,8.3 L9.307,6.8 L9.08,6.73 C8.72255262,6.67748634 8.3606967,6.66140386 8,6.682 Z M7.664,9.359 L6.734,11.3 L5,10.461 L5.378,9.703 C6.12932095,9.52606565 6.89388085,9.41101377 7.664,9.359 L7.664,9.359 Z M12.185,11.459 L12.779,14.204 L11.339,13.511 L12.185,11.459 Z M4.435,15.85 L2.055,25.72 C1.961582,26.1092666 2.05170844,26.5198767 2.29956783,26.834235 C2.54742722,27.1485932 2.9256809,27.3320006 3.326,27.3320006 L3.334,27.3320006 C3.92497843,27.3325732 4.44281226,26.9365103 4.597,26.366 L7.076,17.184 L4.435,15.85 Z M8.149,17.733 L10,20.667 L10,26.026 C10.0005515,26.7476076 10.5853924,27.3324485 11.307,27.333 L11.356,27.333 C11.7030767,27.3327351 12.035818,27.1945367 12.2809568,26.9488352 C12.5260957,26.7031337 12.6635308,26.3700764 12.6630015,26.023 L12.653,20.023 L8.149,17.733 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const PlannedIcon = ({ color }: IconColor = { color: '#008072' }) => {
  const fill = convertColor(color);
  return (
    <svg
      width="24px"
      height="29px"
      viewBox="0 0 24 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>planning</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M20.144,27.924 L3.856,27.924 C2.41884551,27.9136506 1.25634944,26.7511545 1.246,25.314 L1.246,6.134 C1.25634944,4.69684551 2.41884551,3.53434944 3.856,3.524 L20.144,3.524 C21.5811545,3.53434944 22.7436506,4.69684551 22.754,6.134 L22.754,25.31 C22.7458285,26.7487163 21.5827021,27.9136253 20.144,27.924 L20.144,27.924 Z M3.856,5.26396247 C3.62460695,5.26184235 3.40206319,5.35280977 3.23843648,5.51643648 C3.07480977,5.68006319 2.98384235,5.90260695 2.98596247,6.134 L2.98596247,25.31 C2.98384235,25.5413931 3.07480977,25.7639368 3.23843648,25.9275635 C3.40206319,26.0911902 3.62460695,26.1821577 3.856,26.1800375 L20.144,26.1800375 C20.3753931,26.1821577 20.5979368,26.0911902 20.7615635,25.9275635 C20.9251902,25.7639368 21.0161577,25.5413931 21.0140375,25.31 L21.0140375,6.134 C21.0161577,5.90260695 20.9251902,5.68006319 20.7615635,5.51643648 C20.5979368,5.35280977 20.3753931,5.26184235 20.144,5.26396247 L3.856,5.26396247 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
        <path
          d="M14.993,4.739 L13.393,4.039 C13.4883848,3.84433134 13.5360328,3.62974383 13.532,3.413 C13.532,2.54812208 12.8308779,1.847 11.966,1.847 C11.1011221,1.847 10.4,2.54812208 10.4,3.413 C10.3920776,3.61925961 10.4401079,3.82382013 10.539,4.005 L8.939,4.666 C8.77129365,4.25761712 8.68828228,3.81942594 8.695,3.378 C8.69500005,1.55214666 10.1751467,0.0720000985 12.001,0.0720000985 C13.8268533,0.0720000985 15.307,1.55214666 15.307,3.378 C15.2941348,3.84806608 15.1873738,4.31081028 14.993,4.739 L14.993,4.739 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
        <path
          d="M15.792,7.77 L8.172,7.77 C7.70913149,7.77458988 7.26388387,7.59274324 6.93657031,7.26542969 C6.60925676,6.93811613 6.42741012,6.49286851 6.43191517,6.03 L6.43191517,4.394 L17.5320848,4.394 L17.5320848,6.03 C17.5365899,6.49286851 17.3547432,6.93811613 17.0274297,7.26542969 C16.7001161,7.59274324 16.2548685,7.77458988 15.792,7.77 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
        <path
          d="M9.425,12.225 L10.538,11.112 C10.7555747,10.8944253 10.8405474,10.5773032 10.7609095,10.2800905 C10.6812716,9.9828779 10.4491221,9.75072841 10.1519095,9.67109053 C9.85469684,9.59145265 9.53757474,9.67642526 9.32,9.894 L8.206,11.008 L7.092,9.894 C6.75422856,9.56651034 6.2161268,9.57077884 5.88359317,9.90358571 C5.55105955,10.2363926 5.54723302,10.7744977 5.875,11.112 L6.989,12.226 L5.875,13.34 C5.55331716,13.6882001 5.56785834,14.2293624 5.90777594,14.5597851 C6.24769354,14.8902078 6.78905062,14.8894158 7.128,14.558 L8.242,13.444 L9.356,14.558 C9.69494938,14.8894158 10.2363065,14.8902078 10.5762241,14.5597851 C10.9161417,14.2293624 10.9306828,13.6882001 10.609,13.34 L9.425,12.225 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
        <path
          d="M17.047,20.021 L18.161,18.907 C18.3785748,18.6894253 18.4635474,18.3723032 18.3839095,18.0750905 C18.3042716,17.7778779 18.0721221,17.5457284 17.7749095,17.4660905 C17.4776968,17.3864526 17.1605747,17.4714252 16.943,17.689 L15.795,18.768 L14.681,17.653 C14.3446586,17.3166586 13.7993414,17.3166586 13.463,17.653 C13.1266586,17.9893414 13.1266586,18.5346586 13.463,18.871 L14.577,19.985 L13.463,21.099 C13.1413172,21.4472001 13.1558583,21.9883624 13.4957759,22.3187851 C13.8356935,22.6492078 14.3770506,22.6484158 14.716,22.317 L15.83,21.203 L16.944,22.317 C17.2829494,22.6484158 17.8243065,22.6492078 18.1642241,22.3187851 C18.5041417,21.9883624 18.5186828,21.4472001 18.197,21.099 L17.047,20.021 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
        <path
          d="M16.489,9.50999551 C15.3371973,9.51329559 14.4042956,10.4461973 14.401,11.598 C14.3964098,11.8486464 14.4437353,12.0975312 14.54,12.329 L8.276,18.593 C8.02362702,18.4821634 7.75160999,18.4229997 7.476,18.419 C6.32282944,18.419 5.388,19.3538294 5.388,20.507 C5.388,21.6601706 6.32282944,22.595 7.476,22.595 C8.62917056,22.595 9.564,21.6601706 9.564,20.507 C9.56477009,20.2828416 9.53104208,20.0598994 9.464,19.846 L15.798,13.546 C16.0323867,13.6321382 16.2793561,13.6790995 16.529,13.685 C17.6808027,13.6817044 18.6137044,12.7488027 18.617,11.597 C18.5967898,10.4368635 17.6493102,9.50763883 16.489,9.50999551 Z"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const LocalInitiativeIcon = (
  { color }: IconColor = { color: '#008072' },
) => {
  const fill = convertColor(color);
  return (
    <svg
      width="32px"
      height="33px"
      viewBox="0 0 32 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>magnify</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M31.565,28.666 L25.333,22.435 C25.0506575,22.1549576 24.6686666,21.9984924 24.271,22 L23.252,22 C27.4851186,16.5887771 26.7768995,8.81791841 21.635314,4.26108562 C16.4937286,-0.295747179 8.69407151,-0.0651637195 3.8306602,4.78744961 C-1.03275111,9.64006294 -1.2806704,17.4391883 3.26472286,22.5908895 C7.81011613,27.7425908 15.5793815,28.4680805 21,24.247 L21,25.266 C20.9990236,25.6639531 21.1562247,26.0459842 21.437,26.328 L27.669,32.559 C27.9494262,32.8410699 28.3307535,32.999674 28.7285,32.999674 C29.1262465,32.999674 29.5075738,32.8410699 29.788,32.559 L31.557,30.79 C32.1429325,30.2041925 32.1456135,29.2551085 31.563,28.666 L31.565,28.666 Z M13,23 C8.02943725,23 4,18.9705627 4,14 C4,9.02943725 8.02943725,5 13,5 C17.9705627,5 22,9.02943725 22,14 C22.0015932,16.3874365 21.0538933,18.6775475 19.3657204,20.3657204 C17.6775475,22.0538933 15.3874365,23.0015932 13,23 Z M13,8 C10.4515964,8 8.3855522,10.0655964 8.385,12.614 C8.385,14.674 11.401,18.43 12.557,19.793 C12.666628,19.9243629 12.8289021,20.0002974 13,20.0002974 C13.1710979,20.0002974 13.333372,19.9243629 13.443,19.793 C14.599,18.43 17.615,14.675 17.615,12.614 C17.6144478,10.0655964 15.5484036,8 13,8 Z M13,14 C12.1715729,14 11.5,13.3284271 11.5,12.5 C11.5,11.6715729 12.1715729,11 13,11 C13.8284271,11 14.5,11.6715729 14.5,12.5 C14.5,13.3284271 13.8284271,14 13,14 Z"
          id="search-location-solid"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const ConsultationIcon = (
  { color }: IconColor = { color: '#008072' },
) => {
  const fill = convertColor(color);
  return (
    <svg
      width="32px"
      height="26px"
      viewBox="0 0 32 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon_comments</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M23.111,9.445 C23.111,4.534 17.939,0.556 11.555,0.556 C5.171,0.556 0,4.533 0,9.445 C0.030700443,11.3545929 0.785196662,13.1813244 2.111,14.556 C1.59847511,15.6562137 0.928442875,16.6759158 0.122,17.583 C-0.000561761845,17.7125183 -0.0332941619,17.9029972 0.039,18.066 C0.107376264,18.2293386 0.267937608,18.3349294 0.445,18.333 C2.18185236,18.3157309 3.88278505,17.8363081 5.373,16.944 C7.30232364,17.8689547 9.41644109,18.3438874 11.556,18.333 C17.939,18.333 23.111,14.356 23.111,9.445 Z"
          id="comments-solid-path"
          fill={fill}
          fillRule="nonzero"
        ></path>
        <path
          d="M29.889,21.667 C31.215741,20.2929382 31.9703971,18.4658196 32,16.556 C32,12.839 29.028,9.656 24.817,8.328 C24.8662412,8.69827582 24.8902965,9.0714666 24.889,9.445 C24.889,15.328 18.906,20.112 11.556,20.112 C10.9675396,20.1078005 10.3797153,20.0724176 9.795,20.006 C11.545,23.2 15.656,25.445 20.445,25.445 C22.5848504,25.4585849 24.6995667,24.9835177 26.628,24.056 C28.1182149,24.9483081 29.8191476,25.4277309 31.556,25.445 C31.7328217,25.4461125 31.892969,25.3407939 31.962,25.178 C32.0334659,25.0148952 32.000815,24.8248909 31.879,24.695 C31.0706589,23.7887977 30.4001363,22.7685251 29.889,21.667 Z"
          id="comments-solid-path"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const OtherIcon = ({ color }: IconColor = { color: '#008072' }) => {
  const fill = convertColor(color);
  return null;
};

export const CompleteIcon = ({ color }: IconColor = { color: '#008072' }) => {
  const fill = convertColor(color);
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>complete</title>
      <g
        id="complete"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M23.823,12 C23.8224477,18.5289801 18.5293135,23.8213682 12.0003334,23.821 C5.47135327,23.8206318 0.179000047,18.5276468 0.179000047,11.9986667 C0.179000047,5.46968656 5.47201987,0.177 12.001,0.177 C15.1365626,0.177 18.1436861,1.42266583 20.3607699,3.63993718 C22.5778538,5.85720853 23.8232652,8.8644374 23.823,12 Z M10.633,18.261 L19.404,9.49 C19.7019401,9.19203511 19.7019401,8.70896489 19.404,8.411 L18.326,7.331 C18.0280351,7.03305993 17.5449649,7.03305993 17.247,7.331 L10.094,14.484 L6.754,11.144 C6.45603511,10.8460599 5.97296489,10.8460599 5.675,11.144 L4.597,12.223 C4.29905993,12.5209649 4.29905993,13.0040351 4.597,13.302 L9.554,18.261 C9.85196489,18.5589401 10.3350351,18.5589401 10.633,18.261 Z"
          id="check-circle-solid"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
