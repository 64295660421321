import React, { useContext, useEffect, useState } from 'react';

import { TravelMapRouteAlertsStyled } from './TravelMapRouteAlertsStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { TravelMapContext } from '../../organisms/TravelMapDashboard';
import { format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';
import Accordian from '../../atoms/Accordian';


export interface ITravelMapRouteAlerts {
}

const TravelMapRouteAlerts = ({
}: ITravelMapRouteAlerts) => {
  const { state, dispatch } = useContext(TravelMapContext);
  const { routeAlerts } = state.journey;

  const updateInfo = (i) => {
    const id = parseInt(i);
    if (!Number.isNaN(id)) {
      const action = { type: 'INFO_SET', value: { id } };
      dispatch(action);
    }
  }

  const parseDate = (dateString) => {
    return parse(dateString, 'dd/MM/yyyy HH:mm:ss', new Date());
  }

  const formatDate = (dateString) => {
    const date = parseDate(dateString);
    return format(date, 'dd/MM/yyyy hh:mm a');
  }

  return (
    <TravelMapRouteAlertsStyled>
      {Object.keys(routeAlerts).map((key, i) => (
        <>
          {key === 'Alert' || key === 'Road Closed' ? (
            <>
              {!!routeAlerts?.[key]?.length && (
                <ul key={'alert-group' + i} style={{ backgroundColor: routeAlerts?.[key]?.[0]?.featureType?.IconColor }}>
                  {routeAlerts?.[key]?.map((alert, j) => (
                    <li key={key + j}>
                      <span className='travel-map-route-alerts__icon'>
                        <img src={alert.featureType?.IconUrl + '--white.svg'} alt={`${alert.featureType.DisplayName} Icon`} width="20" height="20"/>
                      </span>
                      <p
                      className="travel-map-route-alerts__link"
                        role="link"
                        onClick={() => {
                          updateInfo(alert?.properties?.Id);
                        }}
                      >
                        {alert.properties?.Descriptio || alert.properties?.EventDescr || alert.properties?.SignalStat || alert.properties?.Location}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <div key={'alert-group' + i} style={{ backgroundColor: routeAlerts?.[key]?.[0]?.featureType?.IconColor }}>
              <Accordian
                title={key + ' (' + routeAlerts?.[key]?.length + ')'}
                icon={<span className='travel-map-route-alerts__icon'><img src={routeAlerts?.[key]?.[0]?.featureType?.IconUrl + '--white.svg'} alt={`${routeAlerts?.[key]?.[0]?.featureType.DisplayName} Icon`}  width="20" height="20" /></span>}
              >
                <ul>
                  {routeAlerts?.[key]?.map((alert, j) => (
                    <li key={key + j}>
                      <p
                      className="travel-map-route-alerts__link"
                        role="link"
                        onClick={() => {
                          updateInfo(alert?.properties?.Id);
                        }}
                      >
                        {alert.properties?.Descriptio || alert.properties?.EventDescr || alert.properties?.SignalStat || alert.properties?.Location}
                      </p>
                    </li>
                  ))}
                </ul>
              </Accordian>
            </div>
          )}
        </>
      ))}
    </TravelMapRouteAlertsStyled>
  );
};

export default TravelMapRouteAlerts;