/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react';

import { ImageProps, LinkProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageHero from '../../molecules/PageHero';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import RightColumnLayout from '../../templates/RightColumnLayout';
import { StyledProjectPageLayout } from './styles';

interface LeftContentProps {
  epiMainContent: EpiProps;
  epiMainContentArea?: EpiProps;
  epiMiddleContentArea?: EpiProps;
  epiLowerContentArea?: EpiProps;
}

function LeftContent({
  epiMainContent,
  epiMainContentArea,
  epiMiddleContentArea,
  epiLowerContentArea,
}: LeftContentProps): ReactElement {
  return (
    <>
      <EpiProperty
        name={epiMainContent.name}
        value={epiMainContent.value}
        isEditMode={epiMainContent.isEditMode}
        isHTMLContent={epiMainContent.isHTMLContent}
        outerTag="div"
      />
      {epiMainContentArea && (
        <EpiProperty
          name={epiMainContentArea.name}
          value={epiMainContentArea.value}
          isEditMode={epiMainContentArea.isEditMode}
          outerTag="div"
        />
      )}
      {epiMiddleContentArea && (
        <EpiProperty
          name={epiMiddleContentArea.name}
          value={epiMiddleContentArea.value}
          isEditMode={epiMiddleContentArea.isEditMode}
          outerTag="div"
          className="stretched-gray"
        />
      )}
      {epiLowerContentArea && (
        <EpiProperty
          name={epiLowerContentArea.name}
          value={epiLowerContentArea.value}
          isEditMode={epiLowerContentArea.isEditMode}
          outerTag="div"
        />
      )}
    </>
  );
}

interface RightContentProps {
  epiRightContentArea: EpiProps;
}

function RightContent({
  epiRightContentArea,
}: RightContentProps): ReactElement {
  return (
    <>
      <EpiProperty
        name={epiRightContentArea.name}
        value={epiRightContentArea.value}
        isEditMode={epiRightContentArea.isEditMode}
        outerTag="div"
      />
    </>
  );
}

interface ProjectPageProps {
  projectStatus?:
    | 'under_construction'
    | 'planned'
    | 'completed'
    | 'local_initiative'
    | 'consultation'
    | 'other';
  projectIcon?: string;
  ribbonColor?: string;
  ribbonLabel?: string;
  header: PageTemplateProps['header'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  footer: PageTemplateProps['footer'];
  breadcrumbs?: NavBreadcrumbsProps['items'];
  epiTitle: EpiProps;
  epiSubtitle?: EpiProps;
  epiDescription: EpiProps;
  image?: ImageProps;
  video?: {
    source: string;
  };
  facebookUrl?: LinkProps;
  twitterUrl?: LinkProps;
  linkedInUrl?: LinkProps;
  lastReviewed?: string;
  lastUpdated?: string;
  fullWidthBackground?: boolean;
  alert?: PageTemplateProps['alert'];
  caption?: string;
  attributionTo?: string;
  attributionLink?: string;
  mobileTop?: boolean; 
}

function ProjectPage({
  header,
  ctaBlock,
  footer,
  breadcrumbs,
  epiTitle,
  epiSubtitle,
  epiDescription,
  image,
  video,
  facebookUrl,
  twitterUrl,
  linkedInUrl,
  epiMainContent,
  epiMainContentArea,
  epiMiddleContentArea,
  epiLowerContentArea,
  epiRightContentArea,
  lastReviewed = undefined,
  lastUpdated = undefined,
  projectIcon,
  ribbonColor,
  ribbonLabel,
  projectStatus,
  fullWidthBackground = true,
  alert,
  caption = '',
  attributionTo = '',
  attributionLink = '',
  mobileTop = false,
}: ProjectPageProps & LeftContentProps & RightContentProps): ReactElement {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <PageHero
        breadcrumbs={breadcrumbs}
        epiTitle={epiTitle}
        epiSubtitle={epiSubtitle}
        projectIcon={projectIcon}
        ribbonColor={ribbonColor}
        ribbonLabel={ribbonLabel}
        projectStatus={projectStatus}
        epiDescription={epiDescription}
        image={image}
        video={video}
        facebookUrl={facebookUrl}
        twitterUrl={twitterUrl}
        linkedInUrl={linkedInUrl}
        fullWidthBackground={fullWidthBackground}
        caption={caption}
        attributionTo={attributionTo}
        attributionLink={attributionLink}
      />

      <StyledProjectPageLayout>
        <Container padding>
          <RightColumnLayout
            mobileTop={mobileTop}
            leftColumn={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <LeftContent
                epiMainContent={epiMainContent}
                epiMainContentArea={epiMainContentArea}
                epiMiddleContentArea={epiMiddleContentArea}
                epiLowerContentArea={epiLowerContentArea}
              />
            }
            rightColumn={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <RightContent epiRightContentArea={epiRightContentArea} />
            }
          />
          <PageLastReviewed
            lastReviewed={lastReviewed}
            lastUpdated={lastUpdated}
            pageType="page"
          />
        </Container>
      </StyledProjectPageLayout>
    </PageTemplate>
  );
}

export default ProjectPage;
