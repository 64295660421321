import { FaTimes } from 'react-icons/fa';
import React, { ReactElement, useState } from 'react';
import theme from '../../../lib/theme';
import StyledAlertBannerBlock from './styles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import Container from '../../atoms/Container';

export interface AlertBannerBlockProps {
  colour?: 'red' | 'blue' | 'green' | 'black';
  alert: EpiProps;
}

const AlertBannerBlock = ({
  colour = 'red',
  alert,
}: AlertBannerBlockProps): ReactElement => {
  const [active, setActive] = useState(true)
  return (
    <StyledAlertBannerBlock className={`${active ? 'active' : ''} ${colour ? `alert-color--${colour}` : ''}`}>
      <Container>
        <EpiProperty
          name={alert.name}
          value={alert.value}
          isEditMode={alert.isEditMode}
          isHTMLContent={alert.isHTMLContent}
          outerTag="div"
        />
        <FaTimes role="button" onClick={() => setActive(!active)} />
      </Container>
    </StyledAlertBannerBlock>
  );
};

export default AlertBannerBlock;
