import React, { ReactElement } from 'react';
import { LinkProps } from '../../../types';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import WhatWeManageWidget, {
  WhatWeManageWidgetProps,
} from '../../widgets/WhatWeManageWidget';
import { StyledWhatWeManageBlock, StyledWhatWeManageContent } from './styles';

export interface WhatWeManageBlockProps extends WhatWeManageWidgetProps {
  blockTitle?: EpiProps;
  blockContent?: EpiProps;
  whatWeManageLink?: LinkProps;
  anchorId?: string;
}

const WhatWeManageBlock = ({
  blockTitle,
  blockContent,
  whatWeManageLink,
  title,
  summary,
  summaryHref,
  searchPlaceholder,
  searchAction,
  managedRoadsProps,
  notManagedRoadProps,
  noResultsProps,
  anchorId
}: WhatWeManageBlockProps): ReactElement => {
  return blockTitle?.value || blockContent?.value || whatWeManageLink?.label ? (
    <StyledWhatWeManageBlock id={anchorId}>
      <div className="what-we-manage-block__title">
        <EpiProperty
          name={blockTitle.name}
          value={blockTitle.value}
          isEditMode={blockTitle.isEditMode}
          outerTag="h2"
        />
        {whatWeManageLink?.label && (
          <a href={whatWeManageLink.href} target={whatWeManageLink.target}>
            {whatWeManageLink.label}&nbsp;»
          </a>
        )}
      </div>
      <StyledWhatWeManageContent>
        <EpiProperty
          name={blockContent.name}
          value={blockContent.value}
          isEditMode={blockContent.isEditMode}
          outerTag="p"
        />
      </StyledWhatWeManageContent>
      <div>
        <WhatWeManageWidget
          title={title}
          summary={summary}
          summaryHref={summaryHref}
          searchPlaceholder={searchPlaceholder}
          searchAction={searchAction}
          managedRoadsProps={managedRoadsProps}
          notManagedRoadProps={notManagedRoadProps}
          noResultsProps={noResultsProps}
        />
      </div>
    </StyledWhatWeManageBlock>
  ) : (
    <WhatWeManageWidget
      title={title}
      summary={summary}
      summaryHref={summaryHref}
      searchPlaceholder={searchPlaceholder}
      searchAction={searchAction}
      managedRoadsProps={managedRoadsProps}
      notManagedRoadProps={notManagedRoadProps}
      noResultsProps={noResultsProps}
    />
  );
};

export default WhatWeManageBlock;
