import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderizerStyles = styled.div`
  overflow: hidden;
  padding: 0.5em;
  animation: ${rotate} 1000ms infinite steps(8, end);
`;

const Loaderizer = () => {
  return (
    <LoaderizerStyles>
      <FaSpinner />
    </LoaderizerStyles>
  );
};

export default Loaderizer;
