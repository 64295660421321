import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

// eslint-disable-next-line import/prefer-default-export
const fonts = {
  h2: {
    sm: '1.5rem',
    md: '1.5rem',
    lg: '2.25rem',
  },
  p: {
    sm: '1rem',
    md: '1rem',
    lg: '1.125rem',
  },
  a: {
    sm: '1rem',
    md: '1rem',
    lg: '1.125rem',
  },
};

export const StyledWhatWeManageBlock = styled.div`
  background-color: ${theme.colors.backgroundDark};
  padding-bottom: 0.1px;
  color: ${theme.colors.textLight};

  ${breakpoint.greaterThan('sm')`
    background-color: ${theme.colors.backgroundLight};
    color: ${theme.colors.textDark};
  `}

  .what-we-manage-block__title {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: ${theme.colors.backgroundDark};
    color: ${theme.colors.textLight};

    h2 {
      flex: 1 0 auto;
      margin: 0;
      padding: 0;
      font-size: ${fonts.h2.sm};

      ${breakpoint.greaterThan('lg')`
        font-size: ${fonts.h2.lg};
      `}
    }

    a {
      color: ${theme.colors.textLight};
      font-size: ${fonts.a.sm};
      border: none;
      &:hover {
        border: none;
        text-decoration: underline;
      }

      ${breakpoint.lessThan('sm')`
        display: none;
      `}

      ${breakpoint.greaterThan('lg')`
        font-size: ${fonts.a.lg};
      `}
    }
  }
`;

export const StyledWhatWeManageContent = styled.div`
  margin: 1.5rem;

  ${breakpoint.lessThan('sm')`
    margin-top: 0.5rem;
  `}

  p {
    margin: 0 0 1.5rem 0;
    font-size: ${fonts.p.sm};

    ${breakpoint.greaterThan('lg')`
      font-size: ${fonts.p.lg};
    `}
  }
`;
