import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useState } from 'react';
import { LinkProps } from '../../../types';
import Button from '../../atoms/Button';
import {
  QuickLinksButtonStyled,
  QuickLinksListStyled,
  StyledQuicklinks,
} from './styles';
import { BaseBlockProps } from '../../../types'

export interface IQuickLinks extends BaseBlockProps {
  links: LinkProps[];
}

const Quicklinks = ({ links, anchorId }: IQuickLinks): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  if (!links) {
    return null;
  }

  // should buttons align in cols of 3? or default to 4
  const doThirds = [1, 2, 3, 5, 6, 9].includes(links.length);

  return (
    <StyledQuicklinks id={anchorId}>
      <QuickLinksButtonStyled
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={isOpen ? 'is-open' : ''}
      >
        I'm here about:
        <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
      </QuickLinksButtonStyled>
      <QuickLinksListStyled
        className={`${isOpen ? 'is-open' : ''}${doThirds ? ' do-thirds' : ''}`}
      >
        {links.map((item, i) => (
          <Button
            key={i}
            href={item.href}
            type="button"
            active={item.active}
            border
          >
            {item.label}
          </Button>
        ))}
      </QuickLinksListStyled>
    </StyledQuicklinks>
  );
};
export default Quicklinks;
