import { css } from 'styled-components';

import theme, { breakpoint } from './theme';

type Flow = 'aside-main' | 'main-aside';
type Element = 'aside' | 'main';

type InfinityPoolProp = {
  flow: Flow;
  element: Element;
  mobilebreakpoint?: 'sm' | 'md' | 'lg';
};

/**
 * infinityPool - mixin. Which side of the target element should the background colour
 * @param element - target element (aside or main)
 * @param flow  - the order of the elements
 */
const infinityPool = (
  element: Element,
  flow: Flow,
  mobilebreakpoint: 'sm' | 'md' | 'lg' = 'md',
) => css`
  position: relative;
  background-color: ${theme.colors.documentListBg};
  &:after,
  &:before {
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  ${breakpoint.greaterThan('container')`
    &:after,
    &:before {
      width: calc((100vw - 95.5rem) / 2);
    }
  `};
  &:before {
    right: 100%;
    background-color: ${theme.colors.documentListBg};

    ${breakpoint.greaterThan(mobilebreakpoint)`
      content: ${
        flow === 'aside-main' && element === 'main'
          ? 'none'
          : flow === 'main-aside' && element === 'aside'
          ? 'none'
          : ''
      };
    `};
  }
  &:after {
    left: 100%;
    background-color: ${theme.colors.documentListBg};
    ${breakpoint.greaterThan(mobilebreakpoint)`
      content: ${
        flow === 'aside-main' && element === 'main'
          ? ''
          : flow === 'main-aside' && element === 'aside'
          ? ''
          : 'none'
      };
    `};
  }
`;

/**
 * StyledMainWithSideBar - A mixin to handle main/aside designs.
 * Flow can either be "aside-main" or "main-aside".
 * Element designates which element (aside or main) has the background colour
 * @param {InfinityPoolProp} {flow, element}
 */
export const StyledMainWithSideBar = ({
  flow,
  element,
  mobilebreakpoint = 'md',
}: InfinityPoolProp) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  /* aside, */
  .page-layout__aside {
    order: ${flow === 'main-aside' ? '20' : '10'};
    width: 100%;
    ${breakpoint.greaterThan(mobilebreakpoint)`
      width: calc(25% - 1.875rem);
      ${
        flow === 'main-aside'
          ? css`
              margin-right: 1.875rem;
            `
          : css`
              margin-left: 1.875rem;
            `
      };
    `}
    ${element === 'aside' && infinityPool(element, flow, mobilebreakpoint)};
  }

  .page-layout__main {
    width: 100%;
    order: ${flow === 'main-aside' ? '10' : '20'};
    padding: ${theme.spacing.lg} 0;
    ${element === 'main' && infinityPool(element, flow, mobilebreakpoint)};
    ${breakpoint.greaterThan(mobilebreakpoint)`
      margin-left: 1.875rem;
      width: calc(75% - 1.875rem);
    `}
  }

  @supports (display: grid) {
    ${breakpoint.greaterThan(mobilebreakpoint)`
      display: grid;
      grid-template-columns: 400px auto;
      grid-gap: ${theme.spacing.lg};
      .page-layout__aside,
      .page-layout__main {
          margin: 0;
          width: auto;
          > nav {
            
          }
      }
    `};
  }
`;
