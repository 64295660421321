import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const TravelMapViewStyled = styled.div`
  position: relative;
  grid-area: map;
  width: 100%;

  ${breakpoint.greaterThan('lg')`
    height: 100vh;
  `}

  @media print {
    display: none;
  }

  .travel-map-view__map {
    width: 100%;
    height: 100%;
  }

  .travel-map-view__reset {
    position: absolute;
    top: 0;
    right: 50px;
    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 10px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    z-index: 1;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    overflow: hidden;
    color: #666666;
    svg {
      fill: #666666;
    }
  }

  .travel-map-view__style {
    position: absolute;
    bottom: 14px;
    right: 50px;
    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 10px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 60px;
    width: 60px;
    z-index: 1;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    overflow: hidden;
    color: #666666;
    image {
      width: 100%;
      height: 100%;
    }
  }

  .travel-map-dashboard__filter-toggle {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
    font-size: 1.1rem;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  }

  /* Remove padding around google maps info window */
  .gm-style .gm-style-iw-c {
    max-width: 90vw !important;
    max-height: 80vh !important;
    padding: 0;
    >div {
      max-height: 80vh !important;
    }
  }

  .gm-style-iw .gm-style-iw-d {
    overflow: hidden !important;
  }

  /* Customize close button styling */
  .gm-style-iw-t button.gm-ui-hover-effect {
    top: 0.5rem !important;
    right: 0.5rem !important;
    width: 1.8rem !important;
    height: 1.8rem !important;
    opacity: 1 !important;
    span {
      width: 100% !important;
      height: 100% !important;
      color: #fff !important;
      margin: 0 !important;
      -webkit-mask-image: none !important;
      background-image: url('/images/travel-map/icon_xmark--white.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: transparent !important;
    }
  }

  /* Custom styling for info window */
  .info-window__main {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    font-family: 'Heebo', Arial, Helvetica,sans-serif;
    ${breakpoint.greaterThan('sm')`
      width: 360px;
    `}
    .info-window__title {
      display: flex;
      align-items: center;
      padding: 1.25rem 2rem 1.25rem 1.5rem;

      h4 {
        color: #fff;
        margin: 0;
      }

      img {
        width: 2.2rem;
        height: 2.2rem;
        margin: 0 0.5rem 0 0;
      }
    }
    .info-window__body {
      padding: 1.25rem 1.5rem 0.25rem 1.5rem;

      .info-window__feature-type {
        display: flex;
        align-items: center;
        margin: 0 0 0.75rem 0;
        h5 {
          margin: 0;
          font-weight: 800;
        }
      }

      .info-window__date {
        display: grid;
        margin: 0 0 0.5rem 0;
        grid-template-columns: repeat(auto-fill, minmax(1fr, auto));
        grid-gap: 0.5rem;
        list-style: none;
        margin: 0 0 0.5rem 0;
        padding: 0;
      }

      .info-window__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem;
        list-style: none;
        margin: 0 0 0.5rem 0;
        padding: 0;
      }

      ul.info-window__amenities {
        li {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          
          figure {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            background-color: #008072;
            border-radius: 50%;
            margin: 0 0.5rem 0 0;

            img {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }

      p {
        font-size: 0.9rem;
        margin: 0 0 0.25rem 0;
        strong {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }

    .info-window__share {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      width: 100%;
      background-color: #f2f2f2;
      max-height: 0px;
      transition-property: max-height;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
      overflow: hidden;
      padding: 0 1rem;
      outline: 0;
      border: 0;

      &:active { 
        background-color: #f5f5f5;
      }

      cursor: pointer;

      &--active {
        max-height: 300px;
      }

      p {
        overflow-wrap: anywhere;
        padding: 0.5rem 0;
        text-align: left;
        user-select: text;
      }

      div {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.75rem 0 0;
        svg {
          position: absolute;
          top: 0;
          left: 0;
          fill: #d2d2d2;
          width: 1.5rem;
          height: 1.5rem;
          transition-property: opacity;
          transition-duration: 300ms;
          transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
          opacity: 1;
          &:last-child {
            opacity: 0;
          }
        }
      }

      &.info-window__share--copied {
        svg:first-child {
          opacity: 0;
        }
        svg:last-child {
          opacity: 1;
        }
      }
    }

    .info-window__footer {
      display: flex;
      justify-content: flex-end;
      padding: 0 1.25rem 1.5rem 1.25rem;
      >a, >button {
        all: unset;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        color: #fff;
        background-color: ${theme.colors.travelMapButtonBg};
        border: 1px solid #fff;
        padding: 0.6rem 1.4rem;
        border-radius: 5px;
        margin: 0.25rem 0.8rem 0 0;
        transition-property: color, background-color;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
        :last-child {
          margin-right: 0;
        }
        :hover {
          background-color: #fff;
          color: ${theme.colors.travelMapButtonBg};
          border: 1px solid ${theme.colors.travelMapButtonBg};
        }
      }
    }
  }
`;
