import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const TravelMapDirectionsStyled = styled.section`

  // Google Directions Panel Custom Styling
  .adp-placemark {
    display: none;
  }

  .adp-list {
    padding: 0;
    border: 0;
    >table {
      border: 0;
      td {
        border:0;
        .adp-listinfo {
          display: grid;
          grid-template-columns: auto 1fr auto auto;
          grid-gap: 0.25rem 0.5rem;
          b:nth-child(1) {
            grid-column: span 4;
          }
          span:nth-child(3) {
            display: none;
          }
          span:nth-child(4) {
            grid-column: span 3;
          }
          span:nth-child(5) {
            display: none;
          }
        }
      }
    }
  }

  .adp-directions {
    border: 0;
    tr {
      display: grid;
      grid-template-columns: 2rem auto 1fr auto;
      .adp-substep {
        padding: 1rem 0.5rem 1rem 0rem;
        border: 0;
        :last-child {
          padding-right: 0;
        }
      }
    }
  }
`;
