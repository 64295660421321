import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { LinkProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty from '../../atoms/EpiProperty';
import Quicklinks from '../../blocks/QuicklinksBlock';
import { LinkListProps } from '../../molecules/LeftMenuWidget';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageLastReviewed from '../../molecules/PageLastReviewed';
import Sidebar from '../../molecules/Sidebar';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import { StyledNavBreadcrumbs, StyledPageLayout } from './styles';

const StyledQuicklinks = styled.div`
  span {
    font-size: 1rem;
  }
`;

export interface HeavyVehiclesPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  quickLinks?: LinkProps[];
  epiTitle: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  epiContent: {
    name: string;
    value: string;
    isEditMode?: boolean;
    isHTMLContent?: boolean;
  };
  epiMainContentArea: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  epiLeftContentArea: {
    name: string;
    value: string;
    isEditMode?: boolean;
  };
  sidebarLinks?: LinkListProps[];
  lastReviewed?: string;
  lastUpdated?: string;
  alert?: PageTemplateProps['alert'];
}

function HeavyVehiclesPage({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  quickLinks,
  epiContent,
  epiMainContentArea,
  epiLeftContentArea,
  lastReviewed = undefined,
  lastUpdated = undefined,
  sidebarLinks,
  alert,
}: HeavyVehiclesPageProps): ReactElement {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout>
          <Sidebar links={sidebarLinks}>
            <EpiProperty
              name={epiLeftContentArea.name}
              value={epiLeftContentArea.value}
              isEditMode={epiLeftContentArea.isEditMode}
              outerTag="div"
            />
          </Sidebar>

          <div className="page-layout__main">
            {breadcrumbs && <StyledNavBreadcrumbs items={breadcrumbs} />}
            <StyledQuicklinks>
              <Quicklinks links={quickLinks} />
            </StyledQuicklinks>
            <EpiProperty
              name={epiTitle.name}
              value={epiTitle.value}
              isEditMode={epiTitle.isEditMode}
              outerTag="h1"
            />
            <EpiProperty
              name={epiContent.name}
              value={epiContent.value}
              isEditMode={epiContent.isEditMode}
              isHTMLContent={epiContent.isHTMLContent}
              outerTag="div"
            />
            <EpiProperty
              name={epiMainContentArea.name}
              value={epiMainContentArea.value}
              isEditMode={epiMainContentArea.isEditMode}
              outerTag="div"
            />
            <PageLastReviewed
              lastReviewed={lastReviewed}
              lastUpdated={lastUpdated}
              pageType="page"
            />
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
}

export default HeavyVehiclesPage;
