import React, { ReactElement } from 'react';
import StyledClearingProjectsListItem, { StyledButton } from './styles';
import { ProjectProps } from '../../../types';

type ClearingProjectsListItemProps = {
  project: ProjectProps;
};

const ClearingProjectsListItem = ({
  project,
}: ClearingProjectsListItemProps): ReactElement => {
  return (
    <StyledClearingProjectsListItem phaseColor={project.color}>
      {project.projectType === 'actual' && (
        <h3 className="project-title">
          <span className="phase-indicator" />
          {project.name}
        </h3>
      )}

      {project.projectType === 'proposed' && (
        <a href={project.link}>
          <h3 className="project-title">
            <span className="phase-indicator" />
            {project.name}
          </h3>
        </a>
      )}

      {project.projectType === 'revegetation' && (
        <h3 className="project-title">
          <span className="phase-indicator" />
          {project.name}
        </h3>
      )}

      <div className="details">
        <>
          {project.projectType === 'proposed' && (
            <>
              {project.referenceNumber && (
                <div className="details__item">
                  <p className="details__item__label">Reference number:</p>
                  <p className="details__item__data">
                    {project.referenceNumber}
                  </p>
                </div>
              )}
              {project.roadName && (
                <div className="details__item">
                  <p className="details__item__label">Road name:</p>
                  <p className="details__item__data">{project.roadName}</p>
                </div>
              )}
              {project.localGov && (
                <div className="details__item">
                  <p className="details__item__label">Local Government:</p>
                  <p className="details__item__data">{project.localGov}</p>
                </div>
              )}
            </>
          )}
          {project.projectType === 'actual' && (
            <>
              {project.referenceNumber && (
                <div className="details__item">
                  <p className="details__item__label">Reference number:</p>
                  <p className="details__item__data">
                    {project.referenceNumber}
                  </p>
                </div>
              )}
              {project.roadName && (
                <div className="details__item">
                  <p className="details__item__label">Road name:</p>
                  <p className="details__item__data">{project.roadName}</p>
                </div>
              )}
              {project.localGov && (
                <div className="details__item">
                  <p className="details__item__label">Local Government:</p>
                  <p className="details__item__data">{project.localGov}</p>
                </div>
              )}
              {project.clearingLocation && (
                <div className="details__item">
                  <p className="details__item__label">
                    Central location of clearing:
                  </p>
                  <p
                    className="details__item__data"
                    dangerouslySetInnerHTML={{
                      __html: project.clearingLocation,
                    }}
                  />
                </div>
              )}
              {project.region && (
                <div className="details__item">
                  <p className="details__item__label">Region:</p>
                  <p className="details__item__data">{project.region}</p>
                </div>
              )}
              {project.clearingSize && (
                <div className="details__item">
                  <p className="details__item__label">Size of clearing:</p>
                  <p className="details__item__data">{project.clearingSize}</p>
                </div>
              )}
              {project.clearingStartDate && (
                <div className="details__item">
                  <p className="details__item__label">Clearing start date:</p>
                  <p className="details__item__data">
                    {project.clearingStartDate}
                  </p>
                </div>
              )}
              {project.clearingEndDate && (
                <div className="details__item">
                  <p className="details__item__label">Clearing end date:</p>
                  <p className="details__item__data">
                    {project.clearingEndDate}
                  </p>
                </div>
              )}
            </>
          )}
          {project.projectType === 'revegetation' && (
            <>
              {project.referenceNumber && (
                <div className="details__item">
                  <p className="details__item__label">Reference number:</p>
                  <p className="details__item__data">
                    {project.referenceNumber}
                  </p>
                </div>
              )}
              {project.area && (
                <div className="details__item">
                  <p className="details__item__label">Area (ha):</p>
                  <p className="details__item__data">{project.area}</p>
                </div>
              )}
              {project.year && (
                <div className="details__item">
                  <p className="details__item__label">Year(s) Undertaken:</p>
                  <p className="details__item__data">{project.year}</p>
                </div>
              )}
              {project.phase && (
                <div className="details__item">
                  <p className="details__item__label">Method:</p>
                  <p className="details__item__data">{project.phase}</p>
                </div>
              )}
              {project.region && (
                <div className="details__item">
                  <p className="details__item__label">Region:</p>
                  <p className="details__item__data">{project.region}</p>
                </div>
              )}
              {project.roadName && (
                <div className="details__item">
                  <p className="details__item__label">Road name:</p>
                  <p className="details__item__data">{project.roadName}</p>
                </div>
              )}
              {project.roadNumber && (
                <div className="details__item">
                  <p className="details__item__label">Road number:</p>
                  <p className="details__item__data">{project.roadNumber}</p>
                </div>
              )}
              {project.clearingLocation && (
                <div className="details__item">
                  <p className="details__item__label">Location:</p>
                  <p
                    className="details__item__data"
                    dangerouslySetInnerHTML={{
                      __html: project.clearingLocation,
                    }}
                  />
                </div>
              )}
              {project.lastUpdated && (
                <div className="details__item">
                  <p className="details__item__label">Last updated:</p>
                  <p className="details__item__data">{project.lastUpdated}</p>
                </div>
              )}
            </>
          )}
        </>
      </div>
      {project.projectType === 'actual' && project.downloadLink && (
        <StyledButton href={project.downloadLink} target="_blank" ghost={true}>
          Download Final Report
        </StyledButton>
      )}
      {project.projectType === 'revegetation' && project.downloadLink && (
        <StyledButton href={project.downloadLink} target="_blank" ghost={true}>
          Download Report
        </StyledButton>
      )}
      {project.projectType === 'proposed' && project.downloadLink && (
        <StyledButton href={project.downloadLink} target="_blank" ghost={true}>
          Download Final Assessment Report
        </StyledButton>
      )}
    </StyledClearingProjectsListItem>
  );
};

export default ClearingProjectsListItem;
