import styled from 'styled-components';

import { StyledMainWithSideBar } from '../../../lib/StyledMainWithSideBar';
import theme, { breakpoint } from '../../../lib/theme';
import Button from '../../atoms/Button';
import LinkStepBack from '../../blocks/LinkStepBack';
import FilterList from '../../molecules/FilterList';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';
import NavSub from '../../molecules/NavSub';

type StyledPageLayoutProps = {
  mobilebreakpoint?: 'sm' | 'md' | 'lg';
};

export const StyledPageLayout = styled.div<StyledPageLayoutProps>`
  ${props =>
    StyledMainWithSideBar({
      flow: 'aside-main',
      element: 'aside',
      mobilebreakpoint: props.mobilebreakpoint || 'md',
    })};

  .page-layout__main {
    article {
      margin: 0 0 ${theme.spacing.sm} 0;
    }
    table.minimalistBlack {
      border: 1px solid #000000;
      width: 100%;
      text-align: left;
      border-collapse: collapse;
      td,
      th {
        border: 1px solid #000000;
        padding: 5px 4px;
      }
      tbody td {
      }
      thead {
        background: #cfcfcf;
        border-bottom: 1px solid #000000;
      }
      thead th {
        font-weight: bold;
        color: #000000;
        text-align: left;
      }
    }
  }

  .document-list__number {
    margin: 0 0 ${theme.spacing.md} 0;
    font-size: 1rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.125rem;
      text-align: right;
    `};
  }

  .document-list__filters {
    display: none;
    ${breakpoint.greaterThan('md')`
      display: block;
    `};
  }

  .document-list__filters--active {
    display: block;
  }
  @media print {
    -ms-grid-template-rows: auto auto;
    grid-template-rows: auto auto;
    -ms-grid-template-columns: auto;
    grid-template-columns: auto;
    .page-layout__aside {
      > div {
        display: -ms-grid;
        display: grid;
        -ms-grid-template-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
      nav {
        grid-column: span 2;
      }
    }
  }
`;

type Collapse = {
  isCollapsed: boolean;
};
export const StyledAside = styled.aside<Collapse>`
  padding-bottom: ${props => (props.isCollapsed ? '0' : '20px;')};
  > div {
    ${props => breakpoint.lessThan('md')`
      max-height: ${props.isCollapsed ? '0' : '100%'};
      overflow: hidden;
      transition: 500ms cubic-bezier(0, 0, 0, 1);
    `};
  }
`;

export const StyledAsideToggleButton = styled.button<Collapse>`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  background-color: ${theme.colors.buttonToggle};
  color: white;
  border: 1px solid transparent;
  cursor: pointer;
  order: 15;
  aspect-ratio: 1 / 1;
  ${breakpoint.greaterThan('md')`
    display: none;
  `};
`;

export const StyledDocumentList = styled.div`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.sm} 0;
  ${breakpoint.greaterThan('sm')`
    margin: ${theme.spacing.md} 0 ${theme.spacing.md} 0;
    grid-column: span 2;
  `};

  ${breakpoint.greaterThan('md')`
    grid-column: auto;
  `};

  ${breakpoint.greaterThan('lg')`
  margin: ${theme.spacing.lg} 0 ${theme.spacing.md} 0;
`}
`;

export const StyledNavSub = styled(NavSub)`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledFilterList = styled(FilterList)`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 0 ${theme.spacing.md} 0;

  ${breakpoint.greaterThan('md')`
    display: none;
  `}
`;

export const StyledRevisions = styled.div`
  margin-bottom: 40px;
  h2 {
    font-weight: ${theme.fontWeight.bold};
    font-size: 1rem;
    margin-bottom: 0.5rem;
    ${breakpoint.greaterThan('md')`
      font-size: 1.3rem;
    `};
  }
  p {
    margin-top: 0;
    margin-bottom: 0.25em;
    font-weight: 300;
    font-size: 1.33rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.5625rem;
    `};
  }
  a {
    color: inherit;
    font-size: 1.125em;
    font-weight: ${theme.fontWeight.regular};
    line-height: 1;
    border-bottom: none;
    text-decoration: underline;
    &:hover {
      border: none;
    }
  }
`;

export const StyledLinkStepBack = styled(LinkStepBack)`
  border: none;
  &:hover {
    border: none;
  }
  ${breakpoint.greaterThan('sm')`
    grid-column: span 2;
  `};

  ${breakpoint.greaterThan('md')`
    grid-column: auto;
  `};
`;

export const StyledReferences = styled.div`
  margin-bottom: 50px;
  h2 {
    font-weight: ${theme.fontWeight.bold};
    font-size: 1rem;
    margin-bottom: 0.5rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.3rem;
    `};
  }
  p {
    margin-top: 0;
    margin-bottom: 0.25em;
    font-weight: ${theme.fontWeight.regular};
    font-size: 1.33rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.5625rem;
    `};
  }
  ul {
    padding-left: 0;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    ${breakpoint.greaterThan('md')`
      margin-top: 20px;
    `};
  }
  li {
    background-color: ${theme.colors.tag};
    display: block;
    margin-right: 8px;
    margin-bottom: 8px;
    transition: 50ms ease-out;
    font-size: 0.75em;
    &:hover {
      transform: translate(0, -2px);
    }
    &:active {
      transform: translate(0, 1px);
    }
  }
  a,
  li > span {
    display: block;
    color: inherit;
    color: white;
    padding: 0.5em 1.25em;
  }
  span {
    font-size: 1em;
    font-weight: 300;
    ${breakpoint.greaterThan('md')`
      font-size: 1.25em;
    `};
  }
`;

export const StyledAuthor = styled.div`
  margin-bottom: 60px;
  h3 {
    font-weight: ${theme.fontWeight.bold};
    font-size: 1rem;
    margin-bottom: 0.5rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.3rem;
    `};
  }
  p {
    margin-top: 0;
    margin-bottom: 0.25em;
    font-weight: 300;
    font-size: 1rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.25rem;
    `};

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @supports (overflow-wrap: break-word) {
      white-space: normal;
      text-overflow: initial;
      overflow: auto;
      overflow-wrap: break-word;
    }
  }
  a {
    color: inherit;
    font-size: 1.125em;
    border: none;
    font-weight: ${theme.fontWeight.regular};
    text-decoration: underline;
    &:hover {
      border: none;
    }
  }
`;

export const StyledRelatedContent = styled.div`
  margin-top: 60px;
  h2 {
    font-weight: ${theme.fontWeight.bold};
    font-size: 1rem;
    margin-bottom: 0.5rem;
    ${breakpoint.greaterThan('lg')`
      font-size: 1.3rem;
    `};
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  li {
    margin: 0 0 0.5rem 0;

    &:last-of-type {
      margin: 0;
    }
  }

  a {
    color: ${theme.colors.textDark};
    font-size: 1rem;
    font-weight: ${theme.fontWeight.regular};
    text-decoration: underline;
    border: none;
    &:hover {
      color: ${theme.colors.primary};
      border: none;
    }
  }

  @media print {
    margin-top: 0;
  }
`;
