import React, { ReactElement } from 'react';
import {
  AccordionItem as AccordionItemComp,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import EpiProperty from '../../atoms/EpiProperty';
import { AccordionItemBlockProps } from '../../blocks/AccordionContainerBlock';
import { StyledAccordionItemBlock } from './StyledAccordionItemBlock';

const AccordionIcon = (): ReactElement => (
  <span className="icon">
    <AccordionItemState>
      {({ expanded }) => (expanded ? <FaChevronUp /> : <FaChevronDown />)}
    </AccordionItemState>
  </span>
);
interface IAccordionHeading {
  item: AccordionItemBlockProps;
  openedAccordions: string[];
  setOpenedAccordions: React.Dispatch<React.SetStateAction<string[]>>;
}
const AccordionHeading = ({
  item,
  openedAccordions,
  setOpenedAccordions,
}: IAccordionHeading): ReactElement => (
  <AccordionItemHeading
    id={item.anchorId}
    onClick={() => {
      const index = openedAccordions.indexOf(item.uuid);
      const newArr: string[] = [...openedAccordions];
      if (index >= 0) {
        newArr.splice(index, 1);
      } else {
        newArr.push(item.uuid);
      }
      setOpenedAccordions(newArr);
    }}
  >
    <AccordionItemButton>
      <AccordionIcon />
      <EpiProperty
        name={item.epiTitle.name}
        className="label"
        value={item.epiTitle.value}
        isEditMode={item.epiTitle.isEditMode}
        outerTag="span"
      />
    </AccordionItemButton>
  </AccordionItemHeading>
);

interface IAccordionItem {
  item: AccordionItemBlockProps;
  lightTheme?: boolean;
  openedAccordions: string[];
  setOpenedAccordions: React.Dispatch<React.SetStateAction<string[]>>;
}
const AccordionItem = ({
  item,
  lightTheme = false,
  openedAccordions,
  setOpenedAccordions,
}: IAccordionItem): ReactElement => (
  <StyledAccordionItemBlock className={lightTheme ? 'theme-light' : 'no-theme'}>
    <AccordionItemComp
      uuid={item.uuid}
      dangerouslySetExpanded={
        !!(openedAccordions.filter(acc => acc === item.uuid).length > 0)
      }
    >
      <AccordionHeading
        item={item}
        openedAccordions={openedAccordions}
        setOpenedAccordions={setOpenedAccordions}
      />
      <AccordionItemPanel>
        <EpiProperty
          name={item.epiContentArea.name}
          value={item.epiContentArea.value}
          isEditMode={item.epiContentArea.isEditMode}
          outerTag="div"
        />
      </AccordionItemPanel>
    </AccordionItemComp>
  </StyledAccordionItemBlock>
);

export default AccordionItem;
