import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import theme, { breakpoint } from '../../../lib/theme';
import '../../../lib/fontawesome';

const StyledFilter = styled.button<{ active: boolean }>`
  font-size: 1rem;
  padding: 0.9rem 2rem;
  border-radius: 2rem;
  border: solid 1px ${theme.colors.backgroundDark};
  background-color: ${({ active }): string =>
    active ? theme.colors.backgroundDark : theme.colors.bgLight};
  color: ${({ active }): string =>
    active ? theme.colors.textLight : theme.colors.textDark};
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  ${breakpoint.greaterThan('md')`
    padding: 1rem 2rem;
  `}

  &:hover {
    background-color: ${theme.colors.backgroundDark};
    color: ${theme.colors.textLight};
  }

  svg {
    margin: 0 ${theme.spacing.xs} 0 0;
    height: 0.9rem;
    width: 0.9rem;
  }
`;

export interface FilterProps {
  active?: boolean;
  className?: string;
  icon?: IconName;
  label: string;
  handleFilterClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

function Filter({
  active,
  className,
  icon,
  label,
  handleFilterClick,
}: FilterProps): ReactElement {
  return (
    <StyledFilter
      className={className}
      active={active}
      onClick={handleFilterClick}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {label}
    </StyledFilter>
  );
}

export default Filter;
