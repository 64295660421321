import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';
import Button from '../../atoms/Button';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';

export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.md} 0;

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.lg} 0;
  `}
`;

export const StyledListingPageRightColumnLayout = styled.div`
  .page-layout__inner {
    ${breakpoint.greaterThan('md')`
      flex-direction: column-reverse;
    `};
  }
`;

export const StyledListingPageLeftColumn = styled.div`
  margin: 0 0 ${theme.spacing.lg} 0;

  a {
    color: ${theme.colors.linkColor};
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0 0 ${theme.spacing.md} 0;
  }
`;

export const StyledListingPageRightColumn = styled.div`
  ${breakpoint.greaterThan('lg')`
    margin-bottom: ${theme.spacing.md};
  `}

  .listing-page__right-content-area {
    margin: ${theme.spacing.sm} 0;

    ${breakpoint.greaterThan('lg')`
      margin: ${theme.spacing.md} 0;
    `}
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;

  ${breakpoint.greaterThan('md')`
      display: none;
  `}
`;

export const StyledContentListingGroup = styled.div`
  width: 100%;
  margin: 50px 0;
  > div {
    display: flex;
    flex-wrap: wrap;

    ${breakpoint.greaterThan('sm')`
      > div {
        width: calc(50% - 10px);
        padding: 0 20px;
      }
    `};
    ${breakpoint.greaterThan('md')`
      > div {
        width: calc(50% - 25px);
        padding: 0 50px;
      }
    `};
    @supports (display: grid) {
      display: -ms-grid;
      display: grid;
      -ms-grid-template-columns: 1fr;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      > div {
        width: auto;
        padding: 0;
      }
      ${breakpoint.greaterThan('sm')`
        -ms-grid-template-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px 40px;
      `};
      ${breakpoint.greaterThan('md')`
        grid-gap: 70px 50px;
        gap: 70px 50px;
      `};
    }
  }
`;

export const FilterSectionStyles = styled.div`
  display: none;
  &.mobile {
    ${breakpoint.lessThan('md')`
      display: block;
    `};
  }
  &.desktop {
    ${breakpoint.greaterThan('mdplus1')`
      display: block;
    `};
  }
  .listing-page__filters {
    display: none;
    ${breakpoint.greaterThan('md')`
      display: block;
    `}
  }
  .listing-page__filters--active {
    display: block;
  }
`;
