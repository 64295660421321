/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react';

import { ImageProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageHero from '../../molecules/PageHero';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import {
  StyledHeader,
  StyledMain,
  StyledSiteLandingPageLayout,
} from './styles';

interface SiteLandingPageProps {
  header: PageTemplateProps['header'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  footer: PageTemplateProps['footer'];
  breadcrumbs?: NavBreadcrumbsProps['items'] | null;
  epiTitle: EpiProps;
  epiSummary: EpiProps;
  image?: ImageProps;
  video?: {
    source: string;
  };
  epiMainContentArea: EpiProps;
  fullWidthBackground?: boolean;
  titleOnly?: boolean;
  alert?: PageTemplateProps['alert'];
  caption?: string;
  attributionTo?: string;
  attributionLink?: string;
}

const SiteLandingPage = ({
  header,
  ctaBlock,
  footer,
  breadcrumbs = null,
  epiTitle,
  epiSummary,
  image = null,
  video,
  epiMainContentArea,
  fullWidthBackground = true,
  titleOnly = null,
  alert,
  caption = '',
  attributionTo = '',
  attributionLink = '',
}: SiteLandingPageProps): ReactElement => {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <StyledMain>
        <StyledHeader>
          <PageHero
            breadcrumbs={breadcrumbs}
            epiTitle={epiTitle}
            epiDescription={epiSummary}
            image={image}
            video={video}
            fullWidthBackground={fullWidthBackground}
            titleOnly={titleOnly}
            caption={caption}
            attributionTo={attributionTo}
            attributionLink={attributionLink}
          />
        </StyledHeader>
        <StyledSiteLandingPageLayout>
          <Container>
            <EpiProperty
              name={epiMainContentArea.name}
              value={epiMainContentArea.value}
              isEditMode={epiMainContentArea.isEditMode}
              outerTag="div"
            />
          </Container>
        </StyledSiteLandingPageLayout>
      </StyledMain>
    </PageTemplate>
  );
};

export default SiteLandingPage;
