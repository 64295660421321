import React, { useContext, useEffect, useRef, useState } from 'react';

import { TravelMapJourneyStyled } from './TravelMapJourneyStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { TravelMapContext } from '../../organisms/TravelMapDashboard';
import Input from '../../atoms/Input';
import theme from '../../../lib/theme';
import { FaCalendarAlt, FaMapMarkerAlt, FaPlus, FaRedoAlt } from 'react-icons/fa';
import { intToLetter, uniqKey } from '../../../lib/util';
import Accordian from '../../atoms/Accordian';
import TravelMapDirections from '../TravelMapDirections';
import TravelMapRouteAlerts from '../TravelMapRouteAlerts';

export interface ITravelMapJourney {
}

// Geofence to western australia
// Bounds collected from https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/utils/geocoder/#place_id%3DChIJ0YTziS4qOSoRmaMAMt9KDm4
const westernAustraliaBounds = {
  north: -13.689492,
  south: -35.134846,
  east: 129.001854,
  west: 112.921114,
};

// Define options with geofence
const autocompleteOptions = {
  fields: ['formatted_address', 'geometry'],
  componentRestrictions: {
    country: ['AU'],
  },
  strictBounds: true,
  types: ['street_address', 'route', 'locality', 'administrative_area_level_1', 'administrative_area_level_2'],
}

const TravelMapJourney = ({
}: ITravelMapJourney) => {
  const dateRef = useRef(null);
  const [autocompletes, setAutocompletes] = useState([]);
  const { state, dispatch } = useContext(TravelMapContext);
  const { google, journey: { date, directions, routeAlerts, waypoints = [{ location: null, title: '' }] } } = state;

  // Init array for multiple input refs
  // https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks/56063129#56063129
  const inputsRef = useRef([]);

  // Functions to update state
  const setJourneyDate = (newDate) => {
    if (newDate !== date) {
      const action = { type: 'JOURNEY_SET_DATE', value: newDate };
      dispatch(action);
    }
    if (newDate) {
      dateRef.current.classList.add('filled');
    } else {
      dateRef.current.classList.remove('filled');
    }
  }

  const setJourneyWaypoints = (newWaypoints) => {
    const action = { type: 'JOURNEY_SET_WAYPOINTS', value: newWaypoints };
    dispatch(action);
  }

  // Functions to update waypoints then update state
  const resetJourney = () => {
    // Loop from last waypoint index and deleteautocomplete until last waypoint
    const newAutocompletes = [...autocompletes];
    for (let i = waypoints.length - 1; i > 1; i--) {
      const { autocomplete, listener } = autocompletes[i];
      google.maps.event.removeListener(listener);
      google.maps.event.clearInstanceListeners(autocomplete);
      newAutocompletes.splice(i, 1);
    }
    setAutocompletes(newAutocompletes);

    // Reset waypoint state
    const action = { type: 'JOURNEY_SET_DATA', value: {
      date: '',
      waypoints: [{
        title: '',
        location: '',
      }],
      directions: null,
      route: 0,
      routeAlerts: {},
    } };
    dispatch(action);
    inputsRef.current[0].value = '';
    if (dateRef?.current?.type) dateRef.current.type = 'text';
  }

  const updateJourneyWaypoint = (value) => {
    const action = { type: 'JOURNEY_UPDATE_WAYPOINT', value };
    dispatch(action);
  }

  const addJourneyWaypoint = () => {
    const newWaypoints = [...waypoints, {
      id: uniqKey(),
      location: null,
      title: ''
    }];
    setJourneyWaypoints(newWaypoints);
  }

  const removeJourneyWaypoint = (index) => {
    // Filter waypoints for empty inputs
    const filteredWaypoints = waypoints.filter(waypt => waypt?.title?.trim() && waypt?.location);
    if (filteredWaypoints.length === waypoints.length) {
      // Reset Journey Directions
      const action = { type: 'JOURNEY_SET_DATA', value: {
        directions: null,
        route: 0,
        routeAlerts: {},
      } };
      dispatch(action);
    }

    // Remove autocomplete
    const autocompleteAtIndex = autocompletes?.[index]
    if (google && autocompleteAtIndex) {
      const { autocomplete, listener } = autocompleteAtIndex;
      if (listener) google.maps.event.removeListener(listener);
      if (autocomplete) google.maps.event.clearInstanceListeners(autocomplete);
      const newAutocompletes = [...autocompletes];
      newAutocompletes.splice(index, 1);
      setAutocompletes(newAutocompletes);
    }

    // Remove waypoints and upadte context
    const newWaypoints = [...waypoints];
    newWaypoints.splice(index, 1);
    setJourneyWaypoints(newWaypoints);
  }

  // Handle enter key being pressed in journey input box to refresh waypoints
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setJourneyWaypoints(waypoints);
    }
  }

  // Init first places autocomplete when google js is loaded
  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, waypoints.length);
    if (google) {
      // Create new autocomplete for input if does not already have one
      for (let i = 0; i < waypoints.length; i++) {
        if (!inputsRef.current[i].className.includes('pac-target-input')) {
          const autocomplete = new google.maps.places.Autocomplete(inputsRef.current[i], autocompleteOptions);
          const listener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            const title = place?.formatted_address ?? '';
            const location = place?.geometry?.location ?? null;
            // If place not undefined update waypoint context state
            updateJourneyWaypoint({
              id: waypoints[i].id,
              title,
              location,
            })
          });
          setAutocompletes([...autocompletes, {
            autocomplete,
            listener,
          }])
        }
        // Catch to update input values to maintain state when remounting componenet
        if (waypoints[i].title.trim() && !inputsRef.current[i].value) {
          inputsRef.current[i].value = waypoints[i].title
        }
      }
    }
  }, [google, waypoints]);

  return (
    <TravelMapJourneyStyled>
      <h4>Plan your trip</h4>
      <ul>
        <li>
          <Input id='travel-map-journey__date'
            icon={<FaCalendarAlt />}
            iconBgColor={theme.colors.inputBg}
            label='Trip Date'
          >
            <input
              id='travel-map-journey__date'
              ref={dateRef}
              type={'date'}
              value={date}
              placeholder='Today'
              onChange={(e) => { setJourneyDate(e.target.value) }}
              title='Trip Date'
            />
          </Input>
          <button className={'travel-map-journey__button travel-map-journey__button--reset'} onClick={() => { resetJourney() }} aria-label="Reset Journey">
            <FaRedoAlt />
          </button>
        </li>
        {waypoints.map((waypoint, i) => (
          <li key={`travel-map-journey__waypoint${waypoint.id}`}>
            <Input
              id={`travel-map-journey__input${i}`}
              icon={<>
                <FaMapMarkerAlt />
                <span className="travel-map-journey-input__icon-letter">{intToLetter(i, true)}</span>
              </>}
              label={'Trip Location ' + intToLetter(i, true)}
            >
              <input
                id={`travel-map-journey__input${i}`}
                ref={el => inputsRef.current[i] = el}
                placeholder={i ? 'Where to?' : 'Where from?'}
                onKeyDown={handleKeyDown}
                title={'Trip Location ' + intToLetter(i, true)}
                aria-labelledby={`travel-map-journey__input${i}`}
              />
            </Input>
            {i === 0 ? (
              <button className={'travel-map-journey__button'} onClick={() => { addJourneyWaypoint() }} disabled={waypoints.length > 11} aria-label="Add Waypoint">
                <FaPlus />
              </button>
            ) : (
              <button className={'travel-map-journey__button travel-map-journey__button--remove'} onClick={() => { removeJourneyWaypoint(i) }} aria-label="Remove Waypoint">
                <FaPlus />
              </button>
            )}
          </li>
        ))}
        <TravelMapRouteAlerts/>
        <Accordian
          className={directions ? 'travel-map-journey__directions--active' : 'travel-map-journey__directions'}
          title={'Directions'}
          defaultActive={false}
        >
          <TravelMapDirections />
        </Accordian>
      </ul>
    </TravelMapJourneyStyled>
  );
};

export default TravelMapJourney;