import React, { Fragment, ReactElement } from 'react';

import { LinkProps } from '../../../types';
import { StyledLeftMenuContent } from './styles';

// Sidebar takes an array of these props
export interface LinkListProps {
  title: string;
  href: string;
  items: LinkProps[];
}

type LeftMenuWidgetProps = {
  links: LinkListProps[];
};

type ListTitleProps = {
  // href: string;
  title: string;
};

type ListItemProps = {
  href: string;
  target: string;
  label: string;
  active?: boolean;
};

const ListTitle = ({ title }: ListTitleProps): ReactElement => (
  <h2>
    <span>{title}</span>
  </h2>
);
const ListItem = ({
  href,
  target,
  label,
  active,
}: ListItemProps): ReactElement => (
  <li className={active ? 'active' : undefined}>
    <a href={href} target={target}>
      {label}
    </a>
  </li>
);

const LeftMenuWidget = ({ links }: LeftMenuWidgetProps): ReactElement => (
  <StyledLeftMenuContent>
    {links.map((item, i) => (
      <Fragment key={i}>
        <ListTitle title={item.title} />
        <ul>
          {item.items.map((entry, j) => (
            <ListItem
              key={j}
              href={entry.href}
              target={entry.target}
              label={entry.label}
              active={entry.active}
            />
          ))}
        </ul>
      </Fragment>
    ))}
  </StyledLeftMenuContent>
);

export default LeftMenuWidget;
