import React, { useContext, useEffect } from 'react';
import { TravelMapSidebarStyled } from './TravelMapSidebarStyles';
import TravelMapFilters from '../TravelMapFilters';
import Button from '../../atoms/Button';
import theme from '../../../lib/theme';
import TravelMapAlerts from '../TravelMapAlerts';
import TravelMapJourney from '../TravelMapJourney';
import { debounce } from 'lodash';
import { FaAngleDown, FaTimes } from 'react-icons/fa';
import { TravelMapContext } from '../../organisms/TravelMapDashboard';
export interface ITravelMapSidebar {
  open: boolean;
  setOpen: Function;
  panel: boolean;
  setPanel: Function;
}

const TravelMapSidebar = ({
  open,
  setOpen,
  panel,
  setPanel,
}: ITravelMapSidebar) => {
  const { state, dispatch } = useContext(TravelMapContext)
  const { mobile } = state;

  const updateMobile = () => {
    if (window?.innerWidth > 961) {
      const action = { type: 'MOBILE_SET', value: false };
      dispatch(action);
    } else {
      const action = { type: 'MOBILE_SET', value: true };
      dispatch(action);
    }
  };

  useEffect(() => {
    // add listener for window resize to update mobile state
    window.addEventListener('resize', debounce(() => updateMobile(), 100));
    updateMobile();
  }, [])

  return (
    <TravelMapSidebarStyled className={`travel-map-sidebar__main ${open ? 'travel-map-sidebar__main--active' : ''}`}>
      <div className={`travel-map-sidebar__inner ${panel ? 'travel-map-sidebar__inner--filter' : ''}`}>
        <div className={`travel-map-sidebar__panel ${!panel ? 'travel-map-sidebar__panel--active' : ''}`}>
          {!mobile && (
            <div className="travel-map-sidebar__desktop-journey" >
              <section>
                {/* <Button
                  color={theme.colors.travelMapFilterBg}
                  iconEnd="angle-right"
                  handleOnClick={(): void => { setPanel(!panel) }}
                  theme={'travel'}
                  className={'travel-map-sidebar__filter-toggle'}
                >
                  Filters & legend
                </Button> */}
              </section>
              <TravelMapJourney/>
            </div>
          )}
          <button
            className={'travel-map-sidebar__open-toggle'}
            onClick={() => { setOpen(!open) }}
          >
            <h3>Alerts and Roadworks</h3>
            <FaAngleDown />
          </button>
          <TravelMapAlerts />
        </div>
        <div className={`travel-map-sidebar__panel ${panel ? 'travel-map-sidebar__panel--active' : ''}`}>
          <button
            className={'travel-map-sidebar__filters-close'}
            onClick={() => { setPanel(false) }}
          >
            <FaTimes />
            <h3>Map Filters</h3>
          </button>
            <div className="travel-map-sidebar__body" >
            {mobile && <TravelMapJourney />}
            <TravelMapFilters />
          </div>
        </div>
      </div>
    </TravelMapSidebarStyled>
  );
};

export default TravelMapSidebar;