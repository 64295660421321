import React, { ReactElement, ReactNode } from 'react';

import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';

type HomePageProps = {
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  hero?: ReactNode;
  feature?: ReactNode;
  footer: PageTemplateProps['footer'];
  links?: ReactNode;
  news?: ReactNode;
  promo?: ReactNode;
  searchbar?: ReactNode;
  epiMainContentArea: EpiProps;
  alert?: PageTemplateProps['alert'];
};

const HomePage = ({
  ctaBlock,
  feature,
  footer,
  hero,
  header,
  links,
  news,
  promo,
  searchbar,
  epiMainContentArea,
  alert,
}: HomePageProps): ReactElement => {
  return (
    <PageTemplate
      className="is-homepage"
      ctaBlock={ctaBlock}
      header={header}
      footer={footer}
      alert={alert}
    >
      {hero}
      {promo}
      {searchbar}
      {feature}
      {news}
      {links}
      <EpiProperty
        name={epiMainContentArea.name}
        value={epiMainContentArea.value}
        isEditMode={epiMainContentArea.isEditMode}
        outerTag="div"
      />
    </PageTemplate>
  );
};

export default HomePage;
