import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const TravelMapSidebarStyled = styled.div`
  grid-area: sidebar;
  position: relative;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 1;
  background-color: white;

  ${breakpoint.greaterThan('lg')`
    height: 100vh;
    overflow-y: auto;
  `}

  @media print {
    position: relative;
    display: block;
    height: max-content;
  }

  .travel-map-sidebar__inner {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 200%;
    height: 100%;
    transform: translate(0%);
    transition-property: transform, -moz-transfrom, -webkit-transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
    &.travel-map-sidebar__inner--filter {
      transform: translate(-50%);
    }

    .travel-map-sidebar__panel {
      display: grid;
      grid-template-rows: auto minmax(0,1fr);
      padding: 0;
      overflow: hidden;
      height: 100%;

      ${breakpoint.greaterThan('lg')`
        padding: 0 0 1rem 0;
        &:first-child {
            overflow-y: auto; 
            grid-template-rows: auto auto minmax(0, 1fr);
        }
      `}

      .travel-map-sidebar__body {
        overflow-y: auto;
        > section {
          margin: 0rem 1.5rem 1rem 1.5rem;
          border-bottom: 1px solid ${theme.colors.borderMediumLight};
        }
      }

      > section, .travel-map-sidebar__desktop-journey > section  {
        margin: 0rem 1.5rem 1rem 1.5rem;

        ${breakpoint.greaterThan('md')`
          margin: 1rem 2.25rem 0rem 2.25rem;
        `}

        border-bottom: 1px solid ${theme.colors.borderMediumLight};

        &:first-child {
          padding: 0;
          .travel-map-sidebar__filter-toggle {
            font-size: 1rem;
          }
          display: flex;
          justify-content: center;
          border-width: 0;
          @media print {
            display: none;
          }
        }

        &:last-child {
          border-bottom: 0px solid ${theme.colors.borderMediumLight};
        }
      }
        
      .travel-map-sidebar__dektop-journey {
        ${breakpoint.lessThan('lg')`
          display: none;
        `}
      }

      .travel-map-sidebar__open-toggle {
        all: unset;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 1.5rem 1rem 1.5rem;

        ${breakpoint.greaterThan('md')`
          margin: 1rem 2.25rem 1rem 2.25rem;
        `}

        >h3 {
          margin: 0
        }

        >svg {
          width: 2rem;
          height: 2rem;
          transform: rotate(-180deg);
          transition-property: transform, -ms-transform, -moz-transform, -webkit-transform;
          transition-duration: 300ms;
          transition-timing-function: cubic-bezier(0, 0.45, 0.45, 0.84);
          @media print {
            display: none;
          }
        }
        
        ${breakpoint.greaterThan('lg')`
          display: none;
        `}
      }

      .travel-map-sidebar__filters-close {
        all: unset;
        position: relative;
        cursor: pointer;
        align-items: center;
        margin: 1rem 1.5rem 1rem 1.5rem;
        box-sizing: border-box;

        ${breakpoint.greaterThan('lg')`
          margin: 1.5rem 2.25rem 1rem 2.25rem;
        `}

        >h3 {
          margin: 0;
          text-align: center;
        }
        >svg {
          position: absolute;
          top: 0rem;
          left: 0rem;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  &.travel-map-sidebar__main--active {
    
    .travel-map-sidebar__inner>.travel-map-sidebar__panel>.travel-map-sidebar__open-toggle>svg {
      transform: rotate(0deg);
    }
  }
`;
