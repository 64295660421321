import styled from 'styled-components';
import theme from '../../../lib/theme';

export const ListItem = styled.div`
  position: relative;
  padding: 1em 1.5625em;
  border-radius: 15px;
  background: #fff;
  transition: box-shadow 100ms ease-out, transform 100ms ease-out;
  box-shadow: ${theme.elevation[1]};
  &:hover {
    box-shadow: ${theme.elevation[2]};
    transform: translate(0, -2px);
  }
  &:active {
    box-shadow: ${theme.elevation[0]};
    transform: translate(0, 1px);
  }
  h3 {
    font-size: 1.125em;
    margin: 0;
    font-weight: ${theme.fontWeight.black};
  }
  p {
    margin: 0.5em 0 0 0;
    font-weight: ${theme.fontWeight.regular};
    line-height: 1.125;
  }
  a {
    text-decoration: underline;
    color: inherit;
    font-weight: inherit;
  }
`;
