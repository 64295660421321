import React, { ReactElement, useEffect, useState } from 'react';
import {
  GoogleMap,
  GoogleMapProps,
  LoadScript,
  Marker,
  MarkerProps,
  OverlayView,
  Polygon,
  PolygonProps,
} from '@react-google-maps/api';

import regionsData from '../../../data/mainRoadsRegions.json';
import { StyledClearingProjectsMapWrap } from './StyledClearingProjectsMapWrap';

const MAP_DEFAULT_CENTER = {
  lat: -25,
  lng: 121,
};

const MAP_OPTIONS: GoogleMapProps['options'] = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: true,
  // TODO: We need to load the script before trying to access this method
  // zoomControlOptions: {
  //   position: window.google.maps.ControlPosition.LEFT_BOTTOM(),
  // },
  styles: [
    {
      elementType: 'geometry',
      stylers: [{ color: '#cccccc' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#696969' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#d6d6d6' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }, { color: '#b3b3b3' }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.fill',
      stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { weight: 1.8 }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#d7d7d7' }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [{ color: '#a7a7a7' }],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [{ visibility: 'on' }, { color: '#737373' }],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ visibility: 'off' }], // Hides lot numbers
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [{ visibility: 'on' }, { color: '#dadada' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ visibility: 'on' }, { color: '#efefef' }],
    },
    {
      featureType: 'landscape.man_made',
      stylers: [{ visibility: 'off' }], // Hides buildings
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#cccccc' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }],
    },
  ],
};

const POLYGON_OPTIONS: PolygonProps['options'] = {
  strokeColor: '#0C8072',
  strokeOpacity: 1,
  strokeWeight: 4,
  fillColor: 'transparent',
  fillOpacity: 0.2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  // radius: 30000,
  zIndex: 0,
};

const REGION_NAMES = [
  {
    lat: -28.394285,
    lng: 124.151147,
    name: 'Goldfields Esperance',
    align: 'center',
  },
  {
    lat: -33.7,
    lng: 118,
    name: 'Great Southern',
    align: 'left',
  },
  {
    lat: -17.357701,
    lng: 126.672854,
    name: 'Kimberley',
    align: 'center',
  },
  {
    lat: -31.97,
    lng: 116,
    name: 'Metropolitan',
    align: 'right',
  },
  {
    lat: -26.247097,
    lng: 116.284936,
    name: 'Mid West Gascoyne',
    align: 'center',
  },
  {
    lat: -21.511065,
    lng: 123.970217,
    name: 'Pilbara',
    align: 'center',
  },
  {
    lat: -33.75,
    lng: 116,
    name: 'South West',
    align: 'right',
  },
  {
    lat: -31.0,
    lng: 117.86,
    name: 'Wheatbelt',
    align: 'center',
  },
];

const REGIONS_DATA: any = regionsData;
const REGION_COORDINATES: any[] = REGIONS_DATA.features
  .map(feature => {
    const coordsnew = feature.geometry.coordinates.flat(1);
    const dataNew = [];
    let dataInner: { lat: number; lng: number }[];

    if (coordsnew[0].length > 2) {
      // region has many sections
      for (let j = 0; j < coordsnew.length; j++) {
        dataInner = [];
        for (let i = 0; i < coordsnew[j].length; i += 1) {
          dataInner.push({
            lng: coordsnew[j][i][0],
            lat: coordsnew[j][i][1],
          });
        }
        dataNew.push(dataInner);
      }
    } else {
      // region has one section
      dataInner = [];
      for (let i = 0; i < coordsnew.length; i += 1) {
        dataInner.push({
          lng: coordsnew[i][0],
          lat: coordsnew[i][1],
        });
      }
      dataNew.push(dataInner);
    }
    return dataNew;
  })
  .flat(1);

const ClearingProjectsMap = ({
  mapMarkers,
  onMapMarkerClick,
  setDefaultZoom = true,
  googleMapsApiKey = null,
  googleMapsClientId = null,
}: {
  mapMarkers: {
    id: string;
    referenceNumber: string;
    color: string;
    marker: {
      title: string;
      position: MarkerProps['position'];
    };
  }[];
  onMapMarkerClick: (value) => void;
  setDefaultZoom?: boolean;
  googleMapsApiKey?: string;
  googleMapsClientId?: string;
}): ReactElement => {
  const [map, setMap] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const onLoad = React.useCallback(function callback(theMap) {
    setMap(theMap);
    setMapLoaded(true);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    if (mapLoaded) {
      map.setZoom(5);
      map.panTo(MAP_DEFAULT_CENTER);
    }
    // eslint-disable-next-line
  }, [setDefaultZoom]);

  return (
    <StyledClearingProjectsMapWrap>
      <LoadScript
        googleMapsApiKey={googleMapsApiKey}
        googleMapsClientId={googleMapsClientId}
      >
        <GoogleMap
          mapContainerClassName="google-map"
          center={MAP_DEFAULT_CENTER}
          zoom={5}
          options={MAP_OPTIONS}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}
          {/* window.google isn't present unless the map has loaded first */}
          {mapLoaded && (
            <>
              {REGION_COORDINATES.map((region, i) => (
                <Polygon key={i} path={region} options={POLYGON_OPTIONS} />
              ))}

              {REGION_NAMES.map((item, i) => (
                <OverlayView
                  key={i}
                  position={{ lat: item.lat, lng: item.lng }}
                  mapPaneName="markerLayer"
                >
                  <div className={`region-label ${item.align}`}>
                    <p>{item.name}</p>
                  </div>
                </OverlayView>
              ))}

              {mapMarkers
                ?.filter(item => !!item.marker)
                .map(item => (
                  <React.Fragment key={'marker' + item?.id}>
                    {item.marker.position && (
                      <Marker
                        position={item.marker.position}
                        title={item.marker.title}
                        onClick={() => {
                          onMapMarkerClick(item?.id || '');
                          map.setZoom(15);
                          map.panTo(item.marker.position);
                        }}
                        icon={{
                          path:
                            'm441.55 133.09c-18.037-58.288-65.454-105.72-123.74-123.76-61.692-19.11-127.33-8.489-177.43 28.427-49.732 36.661-79.419 95.389-79.419 157.09 0 42.567 13.466 83.008 38.933 116.94l156.12 200.21 156.12-200.22c38.113-50.816 48.839-115.95 29.409-178.69z',
                          fillColor: item.color || '#0C8072',
                          fillOpacity: 1,
                          anchor: new window.google.maps.Point(256, 512),
                          strokeWeight: 2,
                          strokeColor: '#000000',
                          scale: 0.06,
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
            </>
          )}
        </GoogleMap>
      </LoadScript>
    </StyledClearingProjectsMapWrap>
  );
};

export default React.memo(ClearingProjectsMap);
