import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledLinkStepBack } from './styles';
import { BaseBlockProps } from '../../../types'

// BackLink
interface LinkStepBackProps extends BaseBlockProps {
  className?: string;
  label: string;
  href: string;
};

const LinkStepBack = ({
  className,
  label,
  href,
  anchorId
}: LinkStepBackProps): ReactElement => {
  return (
    <StyledLinkStepBack id={anchorId} className={className} href={href}>
      <FontAwesomeIcon icon="long-arrow-alt-left" />
      <span>{label}</span>
    </StyledLinkStepBack>
  );
};

export default LinkStepBack;
