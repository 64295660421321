import React, { ReactElement } from 'react';

import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import PageTitle from '../../atoms/PageTitle';
import { FileLinkProps } from '../../blocks/FileLink';
import FileLinksList from '../../blocks/FileLinksList';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import {
  StyledButton,
  StyledLinkStepBack,
  StyledNavBreadcrumbs,
  StyledPageLayout,
  StyledSubmitCommentButton,
} from './styles';

interface ClearingProjectsPageProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  epiTitle: EpiProps;
  epiMainContent: EpiProps;
  epiMainContentArea: EpiProps;
  epiSubmissionForm?: EpiProps;
  backtoRootLink?: {
    href: string;
    title: string;
  };
  project: any;
  finalReport?: FileLinkProps;
  varianceReport?: FileLinkProps;
  files?: FileLinkProps[];
  alert?: PageTemplateProps['alert'];
}

const ClearingProjectDetailsPage = ({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  epiMainContent,
  epiMainContentArea,
  epiSubmissionForm,
  backtoRootLink,
  project,
  finalReport,
  varianceReport,
  files,
  alert,
}: ClearingProjectsPageProps): ReactElement => {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <Container>
        <StyledPageLayout phaseColor={project.color}>
          <aside className="page-layout__aside">
            {breadcrumbs && <StyledNavBreadcrumbs items={breadcrumbs} />}
            {backtoRootLink && (
              <StyledLinkStepBack
                href={backtoRootLink.href}
                label={`${backtoRootLink.title}`}
              />
            )}
            <div className="section-heading-wrap">
              <h2>
                <EpiProperty
                  name={epiTitle.name}
                  value={epiTitle.value}
                  isEditMode={epiTitle.isEditMode}
                  outerTag="span"
                />
              </h2>
            </div>
            <div className="project-detail">
              {project.referenceNumber && (
                <>
                  <p className="project-detail--label">Reference number:</p>
                  <p className="project-detail--data">
                    {project.referenceNumber}
                  </p>
                </>
              )}
              {project.roadName && (
                <>
                  <p className="project-detail--label">Road Name:</p>
                  <p className="project-detail--data">{project.roadName}</p>
                </>
              )}
              {project.clearingLocation && (
                <>
                  <p className="project-detail--label">
                    Central Location of Clearing:
                  </p>
                  <p className="project-detail--data">
                    {project.clearingLocation}
                  </p>
                </>
              )}
              {project.localGov && (
                <>
                  <p className="project-detail--label">Local Government:</p>
                  <p className="project-detail--data">{project.localGov}</p>
                </>
              )}
              {project.region && (
                <>
                  <p className="project-detail--label">Region:</p>
                  <p className="project-detail--data">{project.region}</p>
                </>
              )}
              {project.clearingSize && (
                <>
                  <p className="project-detail--label">Size of Clearing:</p>
                  <p className="project-detail--data">{project.clearingSize}</p>
                </>
              )}
              {project.openDate && (
                <>
                  <p className="project-detail--label">Submission open date:</p>
                  <p className="project-detail--data">{project.openDate}</p>
                </>
              )}
              {project.cutOffDate && (
                <>
                  <p className="project-detail--label">
                    Submission cut off date:
                  </p>
                  <p className="project-detail--data">{project.cutOffDate}</p>
                </>
              )}
              {project.closedDate && (
                <>
                  <p className="project-detail--label">
                    Submission close date:
                  </p>
                  <p className="project-detail--data">{project.closedDate}</p>
                </>
              )}
              {project.lastUpdated && (
                <>
                  <p className="project-detail--label">
                    Date page last updated:
                  </p>
                  <p className="project-detail--data">{project.lastUpdated}</p>
                </>
              )}
              {project.clearingStartDate && (
                <>
                  <p className="project-detail--label">Clearing start date:</p>
                  <p className="project-detail--data">
                    {project.clearingStartDate}
                  </p>
                </>
              )}
              {project.clearingEndDate && (
                <>
                  <p className="project-detail--label">Clearing end date:</p>
                  <p className="project-detail--data">
                    {project.clearingEndDate}
                  </p>
                </>
              )}
            </div>
            {finalReport && (
              <StyledButton
                icon="file"
                href={finalReport.href}
                title={finalReport.label}
                ghost={true}
              >
                {finalReport.label}
              </StyledButton>
            )}
            {varianceReport && (
              <StyledButton
                icon="file"
                href={varianceReport.href}
                title={varianceReport.label}
                ghost={true}
              >
                {varianceReport.label}
              </StyledButton>
            )}
            {files && <FileLinksList list={files} />}
            {project.phase === 'open' && (
              <StyledSubmitCommentButton href="#formanchor">
                Submit Comment
              </StyledSubmitCommentButton>
            )}
          </aside>
          <div className="page-layout__main">
            <Container>
              <div className="section-heading-wrap">
                <PageTitle>
                  {project.projectType !== 'actual' && (
                    <span className="phase-indicator" />
                  )}
                  <EpiProperty
                    name={epiTitle.name}
                    value={epiTitle.value}
                    isEditMode={epiTitle.isEditMode}
                    outerTag="span"
                  />
                </PageTitle>
                {project.projectType !== 'actual' && (
                  <p>
                    Phase:
                    <span>{project.phaseText}</span>
                  </p>
                )}
                {project.phase === 'finalised' && (
                  <>
                    <p className="number-submissions">
                      {`Number of submissions ${project.numberOfSubmissions}`}
                    </p>
                  </>
                )}
              </div>
              <EpiProperty
                name={epiMainContent.name}
                value={epiMainContent.value}
                isEditMode={epiMainContent.isEditMode}
                isHTMLContent={epiMainContent.isHTMLContent}
                outerTag="div"
              />
              {epiSubmissionForm && (
                <EpiProperty
                  name={epiSubmissionForm.name}
                  value={epiSubmissionForm.value}
                  isEditMode={epiSubmissionForm.isEditMode}
                  isHTMLContent={epiSubmissionForm.isHTMLContent}
                  outerTag="div"
                />
              )}
              <EpiProperty
                name={epiMainContentArea.name}
                value={epiMainContentArea.value}
                isEditMode={epiMainContentArea.isEditMode}
                outerTag="div"
              />
            </Container>
          </div>
        </StyledPageLayout>
      </Container>
    </PageTemplate>
  );
};

export default ClearingProjectDetailsPage;
