import React from 'react';

import DownloadTile, { IDownloadTile } from '../../molecules/DownloadTile';
import { DownloadsGridStyles } from './DownloadsGridStyles';

const DownloadsTilesBlock = ({ columns, downloads, anchorId }: { columns: number; downloads: IDownloadTile[]; anchorId?: string; }) => {
  return (
    <DownloadsGridStyles id={anchorId} className={`cols-${columns}`}>
      {downloads.map(download => (
        <DownloadTile theme={download.theme} file={download.file} />
      ))}
    </DownloadsGridStyles>
  );
};

export default DownloadsTilesBlock;
