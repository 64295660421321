import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';

const DashboardLinkStyles = styled.section`
  border-radius: 15px;
  background: #2d2f32;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.22);
  padding: 1.25em 2.25em;
  a {
    font-size: 1.5em;
    color: #fff;
    margin: 0;
    font-weight: ${theme.fontWeight.black};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const DashboardLinkBlock = ({
  href,
  title,
  anchorId
}: {
  href: string;
  title: string;
  anchorId?: string;
}): ReactElement => {
  return (
    <DashboardLinkStyles id={anchorId}>
      <a href={href}>{title}&nbsp;»</a>
    </DashboardLinkStyles>
  );
};

export default DashboardLinkBlock;
