export function round(value: number, precision: number): number {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const fileSizetoString = (fileSize: number): string => {
  let theString = '';
  if (fileSize > 900) {
    theString = `${round(fileSize / 1024, 2)} KB`;
  }
  if (fileSize > 900000) {
    theString = `${round(fileSize / 1024 / 1024, 2)} MB`;
  }
  if (fileSize > 900000000) {
    theString = `${round(fileSize / 1024 / 1024 / 1024, 2)} GB`;
  }
  return theString;
};

// Takes an int from 0 - 25 and outputs letter at index of alphabet, either uppercase or lowercase
export const intToLetter = (int, uppercase) => {
  const startChar = uppercase ? 'A' : 'a'
  if (int < 26) {
    const code = startChar.charCodeAt(0);
    return String.fromCharCode(code + int);
  } else {
    return int
  }
}

// WARNING not truly unique, unique enough for front end react map keys
export const uniqKey = function(){
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

// Return current url search params without specified param
export const getUrlOtherSearchParams = (param) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  urlSearchParams.delete(param);
  return '?' + urlSearchParams.toString();
}

export const isHexColorRegex: RegExp = /^#([0-9a-f]{6}|[0-9a-f]{3})$/i;
export const isRGBaColorRegex: RegExp = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
