import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

// eslint-disable-next-line import/prefer-default-export
export const StyledSlideshow = styled.div`
  h2 {
    font-size: 1.875rem;
  }

  .keen-slider {
    height: 0 !important;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 20px;
  }

  .keen-slider__slide {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: contain;
    }
  }
  .keen-slider__sub {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .keen-slider__controls {
    display: none;

    ${breakpoint.greaterThan('sm')`
      min-height: 55px;
      display: flex;
      float: none;
      justify-content: flex-end;
    `}

    button {
      background: none;
      border: none;
      font-size: 2rem;
      -webkit-appearance: none;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: ${theme.colors.linkHover};
      }

      &:disabled {
        color: ${theme.colors.textMid};
      }

      &:disabled:hover {
        color: ${theme.colors.textMid};
      }
    }
  }

  .keen-slider__thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    button {
      padding: 0;
      background: none;
      border: none;
      -webkit-appearance: none;
    }
  }

  .keen-slider__thumbnail {
    width: 70px;
    height: 55px;
    border-radius: 5px;
    cursor: pointer;
    filter: grayscale(0.75);
    transition: filter 250ms ease-out, border-color 250ms ease-out;
    border: 1px solid transparent;
    &.active {
      filter: none;
      border: solid 1px ${theme.colors.linkFocus};
    }
  }
`;
