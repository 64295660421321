import styled from 'styled-components';
import { breakpoint } from '../../../lib/theme';

export const TravelMapDashboardStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: "map"
                        "sidebar";
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, auto) 4rem;
  overflow: hidden;
  transition-property: grid;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
  /* Add safe area inset for mobile browser url bar compatibility  */
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  width: 100%;

  &.travel-map__main--active {
    grid-template-rows: minmax(0, auto) 50%;
  }

  ${breakpoint.greaterThan('lg')`
    grid-template-areas: "sidebar map";
    grid-template-columns: minmax(500px, 1fr) 2fr;
  `};

  .travel-map-dashboard__loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    h6 {
      font-size: 1.5rem;
      font-weight: 100;
      opacity: 0.5;
      margin: 0.5rem 0 0 0;
      color: #3e3e3e;
      &:after {
        white-space: pre;
        content: '...';
        animation: progress-ellipsis 2s infinite;
        width: 0.5rem;
        display: inline-block;
      }
      @keyframes progress-ellipsis {
        0% {
            content: '   ';
        }
        30% {
            content: '.  ';
        }
        60% {
            content: '.. ';
        }
        90% {
            content: '...';
        }
      }
    }
    > div {
      width: 5rem;
      height: 5rem;
      overflow: hidden;
      opacity: 0.3;
      user-select: none;
      svg {
        font-size: 4em;
      }
    }
  }

  @media print {
    height: auto;
    grid-template-areas: "map"
                        "sidebar";
    grid-template-columns: 1fr;
    grid-template-rows: 0vh max-content !important;
    width: 100%;

    >div:last-child {
      max-height: 100vh;
      overflow: hidden;
    }

    >button {
      display: none;
    }

    // Missing tiles on print issue css fix:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=426294
    #map_canvas div > img {
      position: absolute;
    }

    .gm-style div > img {
      position: absolute;
    }
  }
`;
