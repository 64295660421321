import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledPromoBlock = styled.div`
  background-color: ${theme.colors.backgroundLight};
  flex: 1 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 15px;
  box-shadow: ${theme.elevation[1]};
  transition: box-shadow 250ms ease-out;
  &:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.22);
    box-shadow: ${theme.elevation[2]};
  }
  ${breakpoint.greaterThan('md')`
    min-height: 360px;
    `};

  &.promo-block__compact {
    min-height: auto;
    .promo-block__title {
      /* padding: 4.25rem 1.5rem; */
    }
  }

  &.do-show-watermark {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
  &.promo-color--red {
    background-color: ${theme.colors.promoBlockTitleBgRed};
    /* &.do-show-watermark {
      background-image: url('/images/watermark_mainroads.svg');
    } */
  }
  &.promo-color--blue {
    background-color: ${theme.colors.promoBlockTitleBgBlue};
    /* &.do-show-watermark {
      background-image: url('/images/watermark_announce.svg');
    } */
  }
  &.promo-color--green {
    background-color: ${theme.colors.promoBlockTitleBgGreen};
    /* &.do-show-watermark {
      background-image: url('/images/watermark_chat.svg');
    } */
  }
  &.promo-color--black {
    background-color: ${theme.colors.promoBlockTitleBgBlack};
  }

  .promo-block__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding: 1rem 1.5rem; */
    /* padding: 24px 30px; */
    padding: 1.55rem 1.875rem;
    color: ${theme.colors.textLight};
    font-size: 1.25em;

    ${breakpoint.greaterThan('md')`
      font-size: 1.5em;
    `};
    ${breakpoint.greaterThan('lg')`
      font-size: 2em;
    `};

    h2 {
      margin: 0;
      padding: 0;
      font-size: 1em;
    }

    a {
      color: ${theme.colors.textLight};
      &:hover {
        text-decoration: underline;
      }
    }

    h3 {
      margin: 0;
      margin-top:0.75rem;
      font-size: 0.75rem;
      padding-top: 0.55rem;
      ${breakpoint.greaterThan('md')`
        font-size: 1rem;
      `}
    }
  }
  .promo-block__title__icon {
    font-size: 1.33em;
    max-height: 1em;
    svg {
      display: block;
      margin: 0 1rem 0 0;
    }
  }
`;
export const PromoBlockSummaryStyles = styled.div`
  flex: 1 auto;
  padding: 1.5rem;
  padding: 1.5rem 1.875rem;
  p {
    margin: 0 0 1.5rem 0;
    color: ${theme.colors.textLight};
    font-size: 0.75em;
    font-weight: 700;
    ${breakpoint.greaterThan('md')`
      font-size: 1.125em;
    `};
  }
`;

export const PromoBlockButtonStyles = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: flex-end;
  padding: 1.5rem 1.875rem;
`;
