import { Cluster, ClusterStats, Renderer } from "@googlemaps/markerclusterer";

export class CustomClusterRenderer implements Renderer {
  /**
   * References Default Clusterer:
   * https://github.com/googlemaps/js-markerclusterer/blob/71a864c/src/renderer.ts#L65
   */
  public render(
    { count, position }: Cluster,
    stats: ClusterStats
  ): google.maps.Marker {
    // change color if this cluster has more markers than the mean cluster
    const color =
      count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";

    // create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `/images/travel-map/icon_cluster.png`,
        scaledSize: new google.maps.Size(34, 34),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "12px",
      },
      title: `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  }
}