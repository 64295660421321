import React from 'react';

import FactTile, { IFactTile } from '../../molecules/FactTile';
import { FactTilesGridStyles } from './FactTilesGridStyles';

const FactsTilesBlock = ({
  columns,
  facts,
  anchorId
}: {
  columns: number;
  facts: IFactTile[];
  anchorId?: string;
}) => {
  return (
    <FactTilesGridStyles id={anchorId} className={`cols-${columns}`}>
      {facts.map(fact => (
        <FactTile
          title={fact.title}
          smallTitle={fact.smallTitle}
          icon={fact.icon}
          caption={fact.caption}
          theme={fact.theme}
        />
      ))}
    </FactTilesGridStyles>
  );
};

export default FactsTilesBlock;
