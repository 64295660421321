import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const TravelMapJourneyStyled = styled.section`
  .travel-map-journey__directions {
    display: none;
    &--active {
      display: block;
    }
  }
  
  @media print {
    display: none;
  }

  h6 {
    font-size: 1.2rem;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      display: grid;
      grid-template-columns: 1fr auto;
      list-style-type: none;
      align-items: center;
      padding: 0 0 0.5rem 0;

      .travel-map-journey-input__icon-letter {
        position: absolute;
        bottom: 0;
        right: 0.3rem;
        font-weight: 600;
        user-select: none;
      }
      .travel-map-journey__spacer {
        width: 2rem;
        height: 2rem;
        margin: 0 0.5rem;
      }
      .travel-map-journey__button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border:none;
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        margin: 0 0.5rem;
        border-radius: 100%;
        color: ${theme.colors.white};
        background-color: ${theme.colors.travelMapButtonBg};
        transition-property: background-color;
        transition-duration: 300ms;
        transition-timing-function: cubic-bezier(0.29, 0.67, 0.62, 0.98);
        svg {
          position: absolute;
          width: 0.875em;
          height: 0.875em;
        }
        &:focus {
          outline: 2px solid ${theme.colors.inputFocus};
        }
        &--remove svg {
          transform: rotate(45deg);
        }
        &--reset {
          background-color: transparent;
          color: ${theme.colors.travelMapButtonBg};
          :hover {
            background-color: #f5f5f5;
          }
        }
        &:disabled {
          opacity: 0.3;
        }

        @media print {
          display: none;
        }
      }
    }
  }
`;
