import { css } from 'styled-components';

import { breakpoint } from './theme';

/* https:// codepen.io/dgca/pen/QExbKN */
const FlexboxGrid = css`
  .flex-container {
    box-sizing: border-box;
  }

  .flex-row {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    /* align-items: flex-start; */
    align-items: normal;
    flex-wrap: wrap;
    margin-bottom: 1em;
    gap: 0.8125em;

    @supports not (aspect-ratio: 1 / 1) {
      > * {
        margin: 0.40625em 0;
        ${breakpoint.greaterThan('md')`
          margin: 0.40625em;
        `};
      }
    }

    ${breakpoint.greaterThan('md')`
      flex-wrap: nowrap;
    `}
  }

  .flex-row--align-v-center {
    align-items: center;
  }

  .flex-row--align-v-bottom {
    align-items: flex-end;
  }

  .flex-row--align-h-center {
    justify-content: center;
  }

  .flex-row--align-h-right {
    justify-content: flex-end;
  }

  .flex-row--reverse {
    flex-direction: row-reverse;
  }

  .flex-col {
    display: flex;
  }

  .flex-col > div {
    /* width: 100%;
    flex: 1 auto;
    display: flex;
    flex-direction: column; */
  }

  .flex-col-xs-1,
  .flex-col-sm-1,
  .flex-col-md-1,
  .flex-col-lg-1 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-1 {
      width: calc(100% * (1 / 12));
      width: 8.333%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-1 {
      width: calc(100% * (1 / 12));
      width: 8.333%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-1 {
      width: calc(100% * (1 / 12));
      width: 8.333%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-1 {
      width: calc(100% * (1 / 12));
      width: 8.333%;
    }
  }

  .flex-col-xs-2,
  .flex-col-sm-2,
  .flex-col-md-2,
  .flex-col-lg-2 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-2 {
      width: calc(100% * (2 / 12));
      width: 16.667%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-2 {
      width: calc(100% * (2 / 12));
      width: 16.667%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-2 {
      width: calc(100% * (2 / 12));
      width: 16.667%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-2 {
      width: calc(100% * (2 / 12));
      width: 16.667%;
    }
  }

  .flex-col-xs-3,
  .flex-col-sm-3,
  .flex-col-md-3,
  .flex-col-lg-3 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-3 {
      width: calc(100% * (3 / 12));
      width: 25%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-3 {
      width: calc(100% * (3 / 12));
      width: 25%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-3 {
      width: 25%;
      width: calc(100% * (3 / 12));
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-3 {
      width: 25%;
      width: calc(100% * (3 / 12));
    }
  }

  .flex-col-xs-4,
  .flex-col-sm-4,
  .flex-col-md-4,
  .flex-col-lg-4 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-4 {
      width: calc(100% * (4 / 12));
      width: 33.33%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-4 {
      width: calc(100% * (4 / 12));
      width: 33.33%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-4 {
      width: calc(100% * (4 / 12));
      width: 33.33%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-4 {
      width: calc(100% * (4 / 12));
      width: 33.33%;
    }
  }

  .flex-col-xs-5,
  .flex-col-sm-5,
  .flex-col-md-5,
  .flex-col-lg-5 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-5 {
      width: calc(100% * (5 / 12));
      width: 41.667%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-5 {
      width: calc(100% * (5 / 12));
      width: 41.667%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-5 {
      width: calc(100% * (5 / 12));
      width: 41.667%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-5 {
      width: calc(100% * (5 / 12));
      width: 41.667%;
    }
  }

  .flex-col-xs-6,
  .flex-col-sm-6,
  .flex-col-md-6,
  .flex-col-lg-6 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-6 {
      width: calc(100% * (6 / 12));
      width: 50%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-6 {
      width: calc(100% * (6 / 12));
      width: 50%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-6 {
      width: calc(100% * (6 / 12));
      width: 50%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-6 {
      width: calc(100% * (6 / 12));
      width: 50%;
    }
  }

  .flex-col-xs-7,
  .flex-col-sm-7,
  .flex-col-md-7,
  .flex-col-lg-7 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-7 {
      width: calc(100% * (7 / 12));
      width: 58.333%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-7 {
      width: calc(100% * (7 / 12));
      width: 58.333%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-7 {
      width: calc(100% * (7 / 12));
      width: 58.333%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-7 {
      width: calc(100% * (7 / 12));
      width: 58.333%;
    }
  }

  .flex-col-xs-8,
  .flex-col-sm-8,
  .flex-col-md-8,
  .flex-col-lg-8 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-8 {
      width: calc(100% * (8 / 12));
      width: 66.666%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-8 {
      width: calc(100% * (8 / 12));
      width: 66.666%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-8 {
      width: calc(100% * (8 / 12));
      width: 66.666%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-8 {
      width: calc(100% * (8 / 12));
      width: 66.666%;
    }
  }

  .flex-col-xs-9,
  .flex-col-sm-9,
  .flex-col-md-9,
  .flex-col-lg-9 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-9 {
      width: calc(100% * (9 / 12));
      width: 75%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-9 {
      width: calc(100% * (9 / 12));
      width: 75%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-9 {
      width: calc(100% * (9 / 12));
      width: 75%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-9 {
      width: calc(100% * (9 / 12));
      width: 75%;
    }
  }

  .flex-col-xs-10,
  .flex-col-sm-10,
  .flex-col-md-10,
  .flex-col-lg-10 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-10 {
      width: calc(100% * (10 / 12));
      width: 83.333%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-10 {
      width: calc(100% * (10 / 12));
      width: 83.333%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-10 {
      width: calc(100% * (10 / 12));
      width: 83.333%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-10 {
      width: calc(100% * (10 / 12));
      width: 83.333%;
    }
  }

  .flex-col-xs-11,
  .flex-col-sm-11,
  .flex-col-md-11,
  .flex-col-lg-11 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-11 {
      width: calc(100% * (11 / 12));
      width: 91.667%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-11 {
      width: calc(100% * (11 / 12));
      width: 91.667%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-11 {
      width: calc(100% * (11 / 12));
      width: 91.667%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-11 {
      width: calc(100% * (11 / 12));
      width: 91.667%;
    }
  }

  .flex-col-xs-12,
  .flex-col-sm-12,
  .flex-col-md-12,
  .flex-col-lg-12 {
    box-sizing: border-box;
    width: 100%;
  }

  @media (min-width: 576px) {
    .flex-col-xs-12 {
      width: calc(100% * (12 / 12));
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .flex-col-sm-12 {
      width: calc(100% * (12 / 12));
      width: 100%;
    }
  }

  @media (min-width: 960px) {
    .flex-col-md-12 {
      width: calc(100% * (12 / 12));
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .flex-col-lg-12 {
      width: calc(100% * (12 / 12));
      width: 100%;
    }
  }
`;

export default FlexboxGrid;
