import styled, { css } from 'styled-components';
import { breakpoint } from '../../../lib/theme';

const LeftAlignedGrid = css`
  &:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;

    grid-row: span 2;
    grid-column: span 2;
    -ms-grid-column-span: 2;
    -ms-grid-row-span: 2;
    > div:first-child {
      padding: 0;
      height: 100%;
    }
  }
  &:nth-child(2) {
    grid-column: 3;
    grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }
  &:nth-child(3) {
    -ms-grid-column: 4;
    -ms-grid-row: 1;
    grid-column: 4;
    grid-row: 1;
  }
  &:nth-child(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
    grid-column: 3;
    grid-row: 2;
  }
  &:nth-child(5) {
    -ms-grid-column: 4;
    -ms-grid-row: 2;
    grid-column: 4;
    grid-row: 2;
  }
`;
const CentreAlignedGrid = css`
  &:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;
  }
  &:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    grid-row: span 2;
    grid-column: span 2;
    -ms-grid-column-span: 2;
    -ms-grid-row-span: 2;
    > div:first-child {
      padding: 0;
      height: 100%;
    }
  }
  &:nth-child(3) {
    -ms-grid-column: 4;
    -ms-grid-row: 1;
    grid-column: 4;
    grid-row: 1;
  }
  &:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    grid-column: 1;
    grid-row: 2;
  }
  &:nth-child(5) {
    -ms-grid-column: 4;
    -ms-grid-row: 2;
    grid-column: 4;
    grid-row: 2;
  }
`;
const RightAlignedGrid = css`
  &:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }
  &:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    grid-column: span 2;
    grid-row: span 2;
    > div:first-child {
      padding: 0;
      height: 100%;
    }
  }
  &:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    grid-column: 1;
    grid-row: 2;
  }
  &:nth-child(5) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    grid-column: 2;
    grid-row: 2;
  }
`;

const ContentBlockGridStyled = styled.div<{ alignment: string }>`
  > * {
    margin-bottom: 24px;
    ${breakpoint.greaterThan('md')`
      padding: 12px;
    `};
  }
  @supports (display: grid) {
    gap: 24px;
    > * {
      ${breakpoint.greaterThan('md')`
        margin-bottom: 0;
        padding: 0; 
      `};
    }
  }

  ${props => breakpoint.greaterThan('md')`
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    > * {
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
      ${
        props.alignment === 'right'
          ? RightAlignedGrid
          : props.alignment === 'left'
          ? LeftAlignedGrid
          : CentreAlignedGrid
      };
    }
  `};

  ${breakpoint.greaterThan('md')`
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`;

export default ContentBlockGridStyled;
