import React, { ReactElement } from 'react';
import { LinkProps } from '../../../types';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import RelatedContentTile from '../../molecules/RelatedContentTile';
import { StyledRelatedContentBlock } from './StyledRelatedContentBlock';
import { BaseBlockProps } from '../../../types'

export interface RelatedContentBlockProps extends BaseBlockProps {
  title: EpiProps;
  items: {
    title: string;
    link: LinkProps;
    thumbnail: {
      url: string;
      alt?: string;
      width?: number;
      height?: number;
    };
  }[];
}

// for now this block has full width, it would have a parent block where the container
// component will be used and grids will be set.
const RelatedContentBlock = ({
  title,
  items,
  anchorId
}: RelatedContentBlockProps): ReactElement => {
  return (
    <StyledRelatedContentBlock id={anchorId}>
      <EpiProperty
        name={title.name}
        value={title.value}
        isEditMode={title.isEditMode}
        outerTag="h2"
      />
      <ul>
        {items.map(item => (
          <li key={item.title}>
            <RelatedContentTile
              thumbnail={item.thumbnail}
              title={item.title}
              link={item.link}
            />
          </li>
        ))}
      </ul>
    </StyledRelatedContentBlock>
  );
};

export default RelatedContentBlock;
