import React, { ReactElement } from 'react';

import Container from '../../atoms/Container';
import { DashboardStyles } from './DashboardStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';

export interface IProjectsPageDashboard {
  intro?: string;
  column1: EpiProps;
  column2: EpiProps;
  column3: EpiProps;
}

const ProjectsPageDashboard = ({
  intro,
  column1,
  column2,
  column3,
}: IProjectsPageDashboard) => {
  return (
    <DashboardStyles>
      <Container>
        {intro && (
          <div className="dashboard__intro">
            <p>{intro}</p>
          </div>
        )}
        <div className="dashboard__grid">
          <EpiProperty
            name={column1.name}
            value={column1.value}
            isEditMode={column1.isEditMode}
            outerTag="div"
          />
          <div className="wide">
            <EpiProperty
              name={column2.name}
              value={column2.value}
              isEditMode={column2.isEditMode}
              outerTag="div"
            />
          </div>
          <EpiProperty
            name={column3.name}
            value={column3.value}
            isEditMode={column3.isEditMode}
            outerTag="div"
          />
        </div>
      </Container>
    </DashboardStyles>
  );
};

export default ProjectsPageDashboard;
