import styled from 'styled-components';

import { StyledMainWithSideBar } from '../../../lib/StyledMainWithSideBar';
import theme, { breakpoint } from '../../../lib/theme';
import Button from '../../atoms/Button';
import SearchFormWidget from '../../atoms/SearchFormWidget';
import FilterList from '../../molecules/FilterList';
import NavBreadcrumbs from '../../molecules/NavBreadcrumbs';
import NavSub from '../../molecules/NavSub';

export const StyledPageLayout = styled.div`
  ${StyledMainWithSideBar({ flow: 'aside-main', element: 'main' })};
  .page-layout__main {
    article {
      margin: 0 0 ${theme.spacing.sm} 0;
    }
  }
  .document-list__head {
    display: flex;
    flex-wrap: wrap;
  }
  .document-list__number  {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-size: 1rem;
    flex: 100%;
    ${breakpoint.greaterThan('md')`
      font-size: 1.125rem;
      text-align: right;
      flex: 50%;
    `}
  }
  .document-list__updated  {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-size: 1rem;
    flex: 50%;
    ${breakpoint.greaterThan('md')`
      font-size: 1.125rem;
      text-align: left;
      flex: 50%;
    `}
  }
  .document-list__filters {
    display: none;
    h2 {
      margin-top: 1em;
    }
    .form__input-group__label {
      margin: 0;
    }
    ${breakpoint.greaterThan('md')`
      display: block;
    `}
  }
  .document-list__filters--active {
    display: block;
  }
`;

export const StyledHVSTravelImpactList = styled.div`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledNavBreadcrumbs = styled(NavBreadcrumbs)`
  margin: ${theme.spacing.sm} 0 ${theme.spacing.sm} 0;
  ${breakpoint.greaterThan('sm')`
    margin: ${theme.spacing.md} 0 ${theme.spacing.md} 0;
  `};

  ${breakpoint.greaterThan('lg')`
    margin: ${theme.spacing.lg} 0 ${theme.spacing.md} 0;
  `}
`;

export const SearchFormColors = {
  inputBg: theme.colors.textLight,
  placeholderTextColor: theme.colors.textDark,
  activeInputBg: theme.colors.textLight,
  activePlaceholderTextColor: theme.colors.textDark,
};

export const StyledNavSub = styled(NavSub)`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledFilterList = styled(FilterList)`
  margin: 0 0 ${theme.spacing.lg} 0;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 0 ${theme.spacing.md} 0;

  ${breakpoint.greaterThan('md')`
    display: none;
  `}
`;

export const StyledClearButton = styled(Button)`
  width: 100%;
  margin: 0 0 ${theme.spacing.md} 0;
`;

export const StyledTypeFilterListButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1rem;
  color: ${theme.colors.navSubText};
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 250ms ease-in-out;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: ${theme.colors.navSubBg};
  &.active {
    background-color: ${theme.colors.linkHover};
    span {
      padding: 0 1em;
    }
  }
  span {
    border-bottom: dotted 0px ${theme.colors.navSubText};
    flex: 1 auto;
    text-align: left;
  }
  &:hover {
    color: #ffffff;
    background-color: ${theme.colors.linkHover};
  }
`;

const CategoryFilterList = styled.ul`
  margin: 0;
  padding: 0;
  li {
    margin: 0 0 ${theme.spacing.xs} 0;
    list-style: none;
    &.is-active {
      ~ li {
        /*margin-left: 20px;*/
        font-size: 1em;
        a {
          padding: 0.5em 1em;
        }
        &.is-active {
          ~ li {
            margin-left: 40px;
            font-size: 0.9em;
            a {
              padding: 0.4em 1em;
            }
            &.is-active {
              ~ li {
                margin-left: 60px;
                font-size: 0.875em;
                &.is-active {
                  ~ li {
                    margin-left: 80px;
                    &.is-active {
                      ~ li {
                        margin-left: 100px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  button {
    width: 100%;
  }
`;

export const StyledNodeFilterList = styled.ul`
  list-style: none;
  margin: 0 0 30px 0;
  padding: 30px 45px;
  border: 1px solid #aaaaaa;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  li {
    margin-bottom: 1em;
    &.is-active {
      ~ li {
        /*margin-left: 20px;*/
        font-size: 1em;
        a {
          padding: 0.5em 1em;
        }
        &.is-active {
          ~ li {
            margin-left: 40px;
            font-size: 0.9em;
            a {
              padding: 0.4em 1em;
            }
            &.is-active {
              ~ li {
                margin-left: 60px;
                font-size: 0.875em;
                &.is-active {
                  ~ li {
                    margin-left: 80px;
                    &.is-active {
                      ~ li {
                        margin-left: 100px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  button {
    width: 100%;
  }
  ${breakpoint.greaterThan('md')`
    border: none;
    padding: 0;
    grid-gap: 9px 4px;
    border-bottom: none;
`};
  ${breakpoint.greaterThan('lg')`
    display: flex;
    flex-wrap: wrap;
  `};
`;

export const StyledStatusFilterList = styled.ul`
  list-style: none;
  margin: 0 0 30px 0;
  padding: 30px 45px;
  border: 1px solid #aaaaaa;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 1em;
    margin-bottom: 1em;
  }
  button {
    width: 100%;
  }
  ${breakpoint.greaterThan('md')`
    border: none;
    padding: 0;
    grid-gap: 9px 4px;
    border-bottom: none;
`};
  ${breakpoint.greaterThan('lg')`
    display: flex;
    flex-wrap: wrap;
  `};
`;

export const StyledSearchFormWidget = styled(SearchFormWidget)`
  margin-bottom: 40px;
`;

export const StyledHVSImpactTypeFilterList = styled(CategoryFilterList)`
  margin-bottom: 40px;
`;

export const CatWrapper = styled.div`
  position: relative;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const StyledSelectInput = styled.select`
  width: 100%;
  display: block;
  font-size: 1.125rem;
  line-height: 1.3;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  font-size: 0.875rem;
  margin: 0.5rem 0;
  padding: 1rem 2.5rem 1rem 1rem;
`;
