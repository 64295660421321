import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';
import Button from '../../atoms/Button';
import SearchForm from '../../atoms/SearchForm';

// STYLE TYPES
type HideableProps = {
  doShow: boolean;
};

// GENERAL WRAPPER STYLES
export const StyledHeading = styled.header`
  background-color: ${theme.colors.backgroundLight};
  padding-top: 10px;
  padding-bottom: 25px;
  h1 {
    font-size: 1.25em;
    font-weight: ${theme.fontWeight.black};
    margin-bottom: 0;
    em {
      font-style: normal;
      color: ${theme.colors.titleColorEmphasis};
    }
  }
  p {
    margin: 0;
  }
  ${breakpoint.greaterThan('md')`
    padding-top: 50px;
    padding-bottom: 30px;
    h1 {
      font-size: 1.875em;
    }
  `};
  ${breakpoint.greaterThan('lg')`
    padding-top: 75px;
    padding-bottom: 1em;
    h1 {
      font-size: 2.75em;
    }
  `};
`;

export const StyledResults = styled.section`
  display: block;
  ${breakpoint.greaterThan('md')`
    padding-top: 50px;
  `};
`;

export const StyledResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @supports (display: grid) {
    display: grid;
  }
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-gap: ${theme.spacing.sm};
  > div {
    padding-top: 20px;
    order: 2;
  }
  > aside {
    width: 100%;
  }
  ${breakpoint.greaterThan('md')`
    grid-gap: ${theme.spacing.md};
    gap: ${theme.spacing.md};
    -ms-grid-template-columns: auto 300px;
    grid-template-columns: auto 300px;
    flex-direction: row;
    > div {
      padding-top: 0;
      order: 0;
    }
    > aside {
      min-width: 300px;
      width: auto;
    }
  `};
  ${breakpoint.greaterThan('lg')`
    grid-gap: ${theme.spacing.lg};
    gap: ${theme.spacing.lg};
    -ms-grid-template-columns: auto 400px;
    grid-template-columns: auto 400px;
  `};
`;

// MOBILE FILTER BUTTON TOGGLERIZER

export const StyledFilterToggleButton = styled(Button)`
  margin-top: 30px;
  width: 100%;
  font-size: 1em;
  svg {
    margin: 0;
    position: absolute;
    left: 0;
    top: 50%;
    left: 50%;
    transform: translate(-100px, -50%);
  }
  ${breakpoint.greaterThan('md')`
    display: none;
  `};
`;

// SEARCH ITEM TYPE FILTER
export const StyledSearchTypeFilter = styled.section<HideableProps>`
  ${props => breakpoint.lessThan('md')`
    display: ${props.doShow ? 'block' : 'none'};
    `};
  ${breakpoint.greaterThan('md')`
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #d0d0d0;
  `}
`;
export const StyledSearchTypeFilterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 30px 45px;
  border: 1px solid #aaaaaa;
  border-bottom: none;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 0.25em;
    margin-bottom: 0.625em;
  }
  button {
    width: 100%;
  }
  ${breakpoint.greaterThan('md')`
    border: none;
    padding: 0;
    grid-gap: 9px 4px;
  `};
`;

// TOPIC FILTER
export const StyledTopicFilter = styled.aside<HideableProps>`
  border: 1px solid #aaaaaa;
  padding: 30px 45px;

  h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: 400;
  }
  order: 1;
  content: '${props => (props.doShow ? 'do show' : 'do not show')}';
  ${props => breakpoint.lessThan('md')`
    display: ${props.doShow ? 'block' : 'none'};
  `};
  ${breakpoint.greaterThan('md')`
    order: 0;
  `};
`;
export const StyledTopicFilterList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 1em;
  }
  button {
    white-space: nowrap;
  }
  a {
    color: #000;
    font-weight: 300;
    text-decoration: none;
    border-bottom: 1px dotted;
    white-space: nowrap;
    &:hover {
      color: #008072;
    }
  }
  ${breakpoint.greaterThan('sm')`
    display: -ms-grid;
    display: grid;
    -ms-grid-template-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 0 1em;
  `};
  ${breakpoint.greaterThan('md')`
    display: block;
  `};
`;

export const StyledTopicFilterListButton = styled.button`
  background-color: transparent;
  padding: 0;
  text-decoration: none;
  border: none;
  border-bottom: 1px dotted;
  cursor: pointer;
  position: relative;
  color: #000;
  font-weight: 300;
  &.active {
    color: #008072;
    font-weight: 900;
    svg {
      display: block;
      position: absolute;
      top: 0;
      left: -1.5em;
    }
  }
  svg {
    display: none;
  }
  &:hover {
    color: #008072;
  }
`;

// STYLED ITEM
export const StyledResultItem = styled.div`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  &:not(:first-child) {
    border-top: 1px solid #d0d0d0;
  }
  h4 {
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
  }
  > a {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    & ~ div h4 {
      &:after {
        content: '\f054';
        display: inline;
        font-family: 'Font Awesome 5 Free';
        font-size: 0.75em;
        text-align: right;
        font-weight: 900;
        padding-left: 0.65em;
      }
    }
    &:hover {
      & ~ div h4 {
        text-decoration: underline;

        &:after {
          content: '\f061';
          padding-left: 0.33em;
        }
      }
    }
  }
  p {
    font-size: 1em;
  }
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
  time {
    position: relative;
    z-index: 1100;
    display: inline-block;
  }
  ${breakpoint.greaterThan('sm')`
    h4 {
      font-size: 1.25em;
    }
  `};
`;

export const StyledFileIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDateline = styled.p`
  color: #707070;
  margin-top: 0;
`;

export const StyledSearchForm = styled(SearchForm)`
  padding: 0 0 10px 0;
  font-size: 1em;
  form {
  }
  label {
    display: none;
  }
  ${breakpoint.greaterThan('sm')`
    padding: 0 0 30px 0;
  `};
  ${breakpoint.greaterThan('md')`
    // font-size: 1.125em;
    max-width: 66.66%;
  `};
  ${breakpoint.greaterThan('lg')`
    font-size: 1.625em;
  `};
`;
