import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';
import NavMobileItem, { NavMobileItemProps } from '../../atoms/NavMobileItem';

const StyledNavMobile = styled.nav`
  background: ${theme.colors.navMobileBg};

  ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }
`;

export interface NavMobileProps {
  items: NavMobileItemProps[];
}

function NavMobile({ items }: NavMobileProps): ReactElement {
  return (
    <StyledNavMobile className="nav-mobile" role="navigation" aria-label="Main">
      <ul>
        {items.map(({ active, href, target = '_self', title }, i) => (
          <NavMobileItem
            key={i}
            href={href}
            target={target}
            title={title}
            active={active}
          />
        ))}
      </ul>
    </StyledNavMobile>
  );
}

export default NavMobile;
