/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';

import { breakpoint } from '../../../lib/theme';

export const StyledImage = styled.div<{
  paddingBottom?: string;
  paddingBottomTablet?: string;
}>`
  .imagecaptiongridblock & {
    height: 100%;
  }
  position: relative;
  padding-bottom: ${({ paddingBottom }: any): number => paddingBottom};
  overflow: hidden;

  ${breakpoint.lessThan('lg')`
    padding-bottom: ${({ paddingBottomTablet }: any): number =>
      paddingBottomTablet};
    padding-bottom: min(${({ paddingBottomTablet }: any): number =>
      paddingBottomTablet}, 50vh);
    `}
`;

export const StyledImageLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: none;
  &:hover {
    border: none;
  }
  img {
    position: absolute;
    height: auto;
    width: 100%;

    @supports (object-fit: cover) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: none;
    }
  }
`;

export const StyledImageSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: ${({ borderRadius }: any): number => borderRadius};
  border: none;
  img {
    position: absolute;
    height: auto;
    width: 100%;
    box-shadow: ${({ boxShadow }: any): string => boxShadow};

    @supports (object-fit: cover) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: none;
    }
  }
`;
