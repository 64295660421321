import React, { ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../../lib/theme';

const StyledNavLinkList = styled.nav`
  h2 {
    margin: 0 0 1.25em 0;
    padding: 0;
    color: ${theme.colors.white};
    font-size: 1.125rem;
    font-weight: ${theme.fontWeight.black};
  }

  a {
    color: ${theme.colors.white};
    border: none;
    &:hover {
      text-decoration: underline;
      border: none;
      h2 {
        color: ${theme.colors.primary};
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0 0 0.75rem 0;
    line-height: 1;

    &:last-of-type {
      margin: 0;
    }
  }

  li a {
    font-weight: ${theme.fontWeight.regular};
    font-size: 0.75rem;
  }
`;

export interface NavLinkListProps {
  href: string;
  target?: string | '_self' | '_blank';
  title: string;
  items: {
    href: string;
    target?: '_self' | '_blank';
    title: string;
  }[];
  label?: string;
}

function NavLinkList({
  href,
  target,
  title,
  items,
  label = undefined,
}: NavLinkListProps): ReactElement {
  return (
    <StyledNavLinkList
      aria-label={label}
    >
      <a href={href} target={target}>
        <h2>{title}</h2>
      </a>
      <ul>
        {items.map((item, i) => (
          <li key={i}>
            <a href={item.href} target={item.target}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </StyledNavLinkList>
  );
}

export default NavLinkList;
