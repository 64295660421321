import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const GroupedProjectsStyles = styled.section`
  background-color: #ffffff;
  &.compact {
    background-color: red;
  }
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.22);
  header {
    background-color: #008072;
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-wrap: wrap;
    padding: 1.25em 1.25em;
    gap: 10px;
    ${breakpoint.greaterThan('sm')`
      padding: 1.25em 2.25em;
    `}
    h2,
    p {
      color: #ffffff;
      margin: 0;
      font-weight: ${theme.fontWeight.black};
      line-height: 1.125;
      white-space: nowrap;
    }
    h2 {
      font-size: 1.25em;
      ${breakpoint.greaterThan('md')`
        font-size: 1.5em;
      `}
    }
    p {
      font-size: 1.125em;
      ${breakpoint.greaterThan('md')`
        font-size: 1.375em;
      `}
    }
    .title {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;

      ${breakpoint.greaterThan('md')`
        gap: 7px;
      `}
      button {
        border-radius: 100px;
        padding: 0;
        margin: 0;
        height: 1em;
        width: 1em;
        border: 1px solid #ffffff;
        background-color: transparent;
        cursor: pointer;
        @supports not (aspect-ratio: 1 / 1) {
          margin: 0 3px;
          ${breakpoint.greaterThan('md')`
            margin: 0 4px;
          `};
        }
        &.navigation {
          &:first-of-type {
            margin-right: 8px;
          }
          &:last-of-type {
            margin-left: 8px;
          }
          &:hover {
            transform: scale(1.125);
          }
        }
        &:not(.active):not(.navigation):hover {
          background-color: rgba(255, 255, 255, 0.5);
        }
        &.active {
          background-color: #ffffff;
        }
      }
      .navigation {
        display: none;
        ${breakpoint.greaterThan('sm')`
          font-size: 1.25em;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          svg{
            font-size: 0.666em;
            color: #008072;
            width: 1em;
            height: 1em;
          }
        `};
      }
    }
  }
`;

export const GroupedProjectsFlippers = styled.div`
  display: none;
  ${breakpoint.greaterThan('md')`
    display: block;
    position: relative;
    width: 100%;
    button {
      position: absolute;
      z-index: 1000;
      top: 0;
      height: 450px;
      border: 1px solid transparent;
      background-color: transparent;
      width: 35px;
      cursor: pointer;
    }
    .flipper__left {
      left: 0;
    }
    .flipper__right {
      right: 0;
    }
  `};
`;

export const GroupedProjectListSection = styled.div`
  /* padding: 1.25em 1.25em; */
  position: relative;
  background-color: #008072;
  ${breakpoint.greaterThan('sm')`
    // padding: 1.25em 2.25em;x
    // padding: 0;
  `}
  > div {
    direction: rtl;
    height: 450px;
    max-height: 450px;
  }
  /* &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 20px;
    width: 15px;
    height: 100%;
    background-color: #00807178;
  } */
  .keen-slider {
    max-height: 450px;
  }
  .projects__lists {
    direction: ltr;
    height: 450px;
  }
  .projects__slide {
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    padding: 1.25em 2.25em 1.25em 1.25em;
    height: 450px;
    ${breakpoint.greaterThan('sm')`
      padding: 1.25em 2.25em;
    `};
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 20px;
      width: 15px;
      height: 100%;
      background-color: #008071;
    }
  }
  .projects__slide--inner {
    direction: rtl;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 0.5em;
    padding-right: 0.5em;
    height: 450px;
    height: calc(450px - 2.5em);
    ${breakpoint.greaterThan('sm')`
      padding-left: 1.5em;
      padding-right: 1.5em;
    `};
  }
  ul {
    direction: ltr;
    margin: 0;
    padding: 0 0 1em 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  li {
    margin: 0;
    padding: 0;
    * {
      direction: inherit;
    }
  }
`;

export const ProjectListsItemStyles = styled.div`
  display: flex;
  gap: 1.5em;
  position: relative;
  padding: 0.5em;
  box-shadow: ${theme.elevation[0]};
  transition: box-shadow 100ms ease-out;
  background-color: #ffffff;
  border-radius: 15px;
  &:hover {
    box-shadow: ${theme.elevation[1]};
  }
  &.compact {
    figure {
      display: none;
    }
  }
  figure {
    display: none;
    .is-small & {
      display: none;
    }
    ${breakpoint.greaterThan('md')`
      display: block;
      position: relative;
      width: 100px;
      height: 90px;
      margin: 0;
      padding: 0;
      border-radius: 15px;
      border: 4px solid #fff;
      box-shadow: ${theme.elevation[1]};
      overflow: hidden;
      img {
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
      }
    `};
  }
  h3,
  p {
    margin: 0;
    line-height: 1.25;
  }
  h3 {
    font-size: 1.125em;
    margin-bottom: 0.2em;
    ${breakpoint.greaterThan('sm')`
      font-size: 1.25em;
      margin-bottom: 0.4em;
    `}
  }
  p {
    font-size: 1em;
    ${breakpoint.greaterThan('sm')`
      font-size: 1.125em;
    `}
  }
  > div {
    flex: 1;
    padding-top: 2px;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
`;

export const LoadingStyles = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  > div {
    display: inline-block;
    margin: 0 auto;
  }
  svg {
    font-size: 2em;
  }
`;
