import React from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { FaCheck, FaStar } from 'react-icons/fa';
import {
  TimelineListStyles,
  TimelineBlockStyles,
  TimelineItemStyles,
} from './TimelineListStyles';
import { BaseBlockProps } from '../../../types'

export interface ITimelineItem extends BaseBlockProps {
  title: string;
  caption?: EpiProps;
  date: string;
  status:
    | 'COMPLETED'
    | 'IN_PROGRESS'
    | 'FUTURE'
    | 'DEADLINE'
    | 'PROJECT_COMPLETED';
}
const TimelineItem = ({ title, caption, date, status, anchorId }: ITimelineItem) => {
  return (
    <TimelineItemStyles id={anchorId} className={`status-${status.toLowerCase()}`}>
      <div className="gutter">
        <div className="icon">
          {status === 'COMPLETED' && <FaCheck />}
          {status === 'IN_PROGRESS' && <FaStar />}
          {status === 'PROJECT_COMPLETED' && <FaCheck />}
        </div>
      </div>
      <div className="context">
        <p className="title">{title}</p>
        <p className="time">
          <time>{date}</time>
        </p>
        {caption && <EpiProperty
          name={caption.name}
          value={caption.value}
          isEditMode={caption.isEditMode}
          isHTMLContent={caption.isHTMLContent}
          outerTag="div"
          className="caption"
        />}
      </div>
    </TimelineItemStyles>
  );
};
export interface ITimelineBlock {
  title?: string;
  timeline: ITimelineItem[];
  isSidebar?: boolean;
}
const TimelineBlock = ({
  title,
  timeline,
  isSidebar = false,
}: ITimelineBlock) => {
  return (
    <TimelineBlockStyles className={isSidebar ? 'is-sidebar' : ''}>
      {title && <h2>{title}</h2>}
      <TimelineListStyles>
        {timeline.map((data, i) => (
          <TimelineItem
            key={i}
            title={data.title}
            caption={data.caption}
            date={data.date}
            status={data.status}
          />
        ))}
      </TimelineListStyles>
    </TimelineBlockStyles>
  );
};

export default TimelineBlock;
