import styled from 'styled-components';
import theme from '../../../lib/theme';

type StyledToggleCheckboxProp = {
  toggleColor?: string;
  checkedColor?: string;
};

const StyledToggleCheckbox = styled.div<StyledToggleCheckboxProp>`
  position: relative;
  display: flex;
  margin: 5px 20px 5px 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0;
  label {
    flex: 2;
    line-height: 1;
    padding: 0;
    color: ${theme.colors.textDark};
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  input {
    cursor: pointer;
    width: 0;
    opacity: 0;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: box-shadow 0.3s cubic-bezier(0.5, 0, 0, 1);
    }
    &:focus {
      ~ .custom-checkbox {
        box-shadow: 0 0 0 2px #5e9ed6;
      }
    }
    &:checked {
      ~ .custom-checkbox {
        background-color: ${theme.colors.backgroundLight};
        &:after {
          transform: translateX(0);
          background-color: ${(props): string => props.toggleColor};
        }
      }
    }
  }

  .custom-checkbox {
    position: relative;
    display: block;
    pointer-events: none;
    height: 23px;
    width: 50px;
    top: -1px;
    margin-right: 15px;
    border-radius: 12px;
    border: 1px solid ${theme.colors.paginationText};
    background-color: ${theme.colors.backgroundDark};
    transition: background-color 0.2s 0.1s linear;
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 3px;
      height: 17px;
      width: 17px;
      border-radius: 100%;
      background-color: ${theme.colors.breadcrumb};
      border: 1px solid ${theme.colors.paginationText};
      transform: translateX(25px);
      transition: transform 0.3s cubic-bezier(0.5, 0, 0, 1),
        background-color 0.3s linear;
    }
  }
`;

export default StyledToggleCheckbox;
