import React, { ReactElement } from 'react';

import { LinkProps } from '../../../types';
import { TileStyles } from './TileStyles';

interface IRelatedContentTile {
  title: string;
  link: LinkProps;
  thumbnail: {
    url: string;
  };
}

const RelatedContentTile = ({
  title,
  link,
  thumbnail,
}: IRelatedContentTile): ReactElement => {
  return (
    <TileStyles>
      <figure>
        <img src={thumbnail.url} alt="placeh" />
      </figure>
      <h3 title={title}>{title}</h3>
      <a href={link.href} target={link.target}>
        Learn more&nbsp;»
      </a>
    </TileStyles>
  );
};

export default RelatedContentTile;
