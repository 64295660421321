/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { boolean } from '@storybook/addon-knobs';
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import theme from '../../../lib/theme';

import { DropdownStyled } from './styles';

export interface DropdownProps {
  name: string;
  buttonText?: string;
  multi?: boolean;
  options: string[];
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  dropdownActive?: boolean;
  right?: boolean;
}

function Dropdown({
  name,
  buttonText = 'Dropdown Text',
  multi = false,
  selected,
  setSelected,
  options,
  dropdownActive = null,
  right = false,
}: DropdownProps): ReactElement {
  const [active, setActive] = useState(dropdownActive ?? false);
  const [height, setHeight] = useState(500);
  const dropdownPanel = useRef(null);

  useEffect(() => {
    if (dropdownActive !== null) {
      console.log('Dropdown??d');
      setActive(!!dropdownActive);
    }
  }, [dropdownActive])

  const optionChanged = (option) => {
    if (multi) {
      // If checkbox check for option is selected and remove at index if it is else add to selection
      let index = selected.indexOf(option);
      const newSelected = [...selected];
      if (index >= 0) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(option);
      }
      setSelected(newSelected);
    } else {
      setSelected([option])
    }
  }

  const updateMaxHeight = () => {
    if (dropdownPanel?.current?.scrollHeight) {
      setHeight(dropdownPanel?.current?.scrollHeight);
    }
  };

  useEffect(() => {
    updateMaxHeight();
  }, [active])

  return (
    <DropdownStyled className={`dropdown__main ${active ? 'dropdown__main--active' : ''}`} dropdownHeight={height} right={right}>
      <button onClick={() => { setActive(!active) }}>
        {multi ? buttonText : selected[0]}
        <FaAngleDown />
      </button>
      <ul ref={dropdownPanel}>
        {options.map((option, i) => (
          <li key={i}>
            <input
              type={multi ? 'checkbox' : 'radio'}
              id={`dropdown__${name}-option${i}`}
              name={`dropdown__${name}-option${i}`}
              value={option}
              checked={selected.includes(option)}
              onChange={() => { optionChanged(option) }}
              tabIndex={active ? 0 : -1}
            />
            <label
              className={`dropdown__option-input ${!multi ? 'dropdown__option-input--radio' : ''}`}
              htmlFor={`dropdown__${name}-option${i}`}
            >
              <span></span>
              {option}
            </label>
          </li>
        ))}
        <div className="dropdown__hidden-close" onClick={() => { setActive(false) }}></div>
      </ul>
    </DropdownStyled>
  );
}

export default Dropdown;
