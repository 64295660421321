import React, { ReactElement, ReactNode } from 'react';
import ScrollTop from 'react-scrolltop-button';

import Container from '../../atoms/Container';
import CTABlock, { CTABlockProps } from '../../blocks/CTABlock';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import Footer, { FooterProps } from '../../widgets/Footer';
import Header, { HeaderProps } from '../../widgets/Header';
import { StyledNavBreadcrumbs, StyledPageTemplate } from './StyledPageTemplate';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import AlertBannerBlock, { AlertBannerBlockProps } from '../../blocks/AlertBannerBlock';

export interface PageTemplateProps {
  breadcrumbs?: NavBreadcrumbsProps['items'];
  children: ReactNode;
  className?: string;
  ctaBlock: CTABlockProps;
  footer: FooterProps;
  header: HeaderProps;
  isSearchPage?: boolean;
  alert?: AlertBannerBlockProps;
}

const PageTemplate = ({
  breadcrumbs,
  className,
  children,
  ctaBlock,
  footer,
  header,
  isSearchPage = false,
  alert,
}: PageTemplateProps): ReactElement => (
  <StyledPageTemplate className={className}>
    <a href="#main-content" className="visually-hidden focusable skip-link">
      Skip to main content
    </a>
    {alert && (
      <AlertBannerBlock alert={alert.alert} colour={alert.colour}></AlertBannerBlock>
    )}
    <Header
      govLink={header.govLink}
      mrwaLink={header.mrwaLink}
      navItems={header.navItems}
      utilItems={header.utilItems}
      searchAction={header.searchAction}
      isSearchPage={isSearchPage}
      searchPlaceholder={header.searchPlaceholder}
    />
    {breadcrumbs && (
      <Container>
        <StyledNavBreadcrumbs items={breadcrumbs} />
      </Container>
    )}
    <main id="main-content" className="site__content" tabIndex={-1}>
      {children}
    </main>
    {ctaBlock && (
      <CTABlock
        contentTitle={ctaBlock.contentTitle}
        contentText={ctaBlock.contentText}
        calloutTitle={ctaBlock.calloutTitle}
        calloutText={ctaBlock.calloutText}
        calloutLink={ctaBlock.calloutLink}
      />
    )}
    <Footer
      copyright={footer.copyright}
      footerNavLists={footer.footerNavLists}
      footerUtils={footer.footerUtils}
      footerLogo={footer.footerLogo}
    />
    <ScrollTop distance={3000} />
  </StyledPageTemplate>
);

export default PageTemplate;
