import React, { ReactElement } from 'react';

import { ImageProps } from '../../../types';
import Container from '../../atoms/Container';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import ContactDetailsBlock, {
  ContactDetailsBlockProps,
} from '../../blocks/ContactDetailsBlock';
import { NavBreadcrumbsProps } from '../../molecules/NavBreadcrumbs';
import PageHero from '../../molecules/PageHero';
import PageTemplate, { PageTemplateProps } from '../../templates/PageTemplate';
import RightColumnLayout from '../../templates/RightColumnLayout';
import { StyledMain, StyledHeader, StyledContactPageLayout } from './styles';

interface LeftContentProps {
  epiContactForm: EpiProps;
  epiMainContentArea: EpiProps;
}

interface RightContentProps {
  contactDetailsBlockProps?: ContactDetailsBlockProps;
  epiRightContentArea: EpiProps;
}

type ContactPageProps = {
  epiTitle: EpiProps;
  epiSubtitle: EpiProps;
  ctaBlock: PageTemplateProps['ctaBlock'];
  header: PageTemplateProps['header'];
  footer: PageTemplateProps['footer'];
  breadcrumbs?: NavBreadcrumbsProps['items'];
  image?: ImageProps;
  fullWidthBackground?: boolean;
  titleOnly?: boolean;
  alert?: PageTemplateProps['alert'];
  caption?: string;
  attributionTo?: string;
  attributionLink?: string;
};

export function LeftContent({
  epiContactForm,
  epiMainContentArea,
}: LeftContentProps): ReactElement {
  return (
    <>
      <EpiProperty
        name={epiContactForm.name}
        value={epiContactForm.value}
        isEditMode={epiContactForm.isEditMode}
        outerTag="div"
      />
      <EpiProperty
        name={epiMainContentArea.name}
        value={epiMainContentArea.value}
        isEditMode={epiMainContentArea.isEditMode}
        outerTag="div"
      />
    </>
  );
}

export function RightContent({
  contactDetailsBlockProps,
  epiRightContentArea,
}: RightContentProps): ReactElement {
  return (
    <>
      <ContactDetailsBlock
        epiTitle={contactDetailsBlockProps.epiTitle}
        epiSummary={contactDetailsBlockProps.epiSummary}
        epiAddress={contactDetailsBlockProps.epiAddress}
        epiPhone={contactDetailsBlockProps.epiPhone}
        epiEmail={contactDetailsBlockProps.epiEmail}
        epiPostalAddress={contactDetailsBlockProps.epiPostalAddress}
        epiAbn={contactDetailsBlockProps.epiAbn}
        facebookUrl={contactDetailsBlockProps.facebookUrl}
        instagramUrl={contactDetailsBlockProps.instagramUrl}
        linkedInUrl={contactDetailsBlockProps.linkedInUrl}
        youTubeUrl={contactDetailsBlockProps.youTubeUrl}
        twitterUrl={contactDetailsBlockProps.twitterUrl}
        twitterUrl2={contactDetailsBlockProps.twitterUrl2}
        epiMediaTitle={contactDetailsBlockProps.epiMediaTitle}
        epiMediaPhone={contactDetailsBlockProps.epiMediaPhone}
        epiMediaEmail={contactDetailsBlockProps.epiMediaEmail}
        epiContactInformation={contactDetailsBlockProps.epiContactInformation}
      />
      <EpiProperty
        name={epiRightContentArea.name}
        value={epiRightContentArea.value}
        isEditMode={epiRightContentArea.isEditMode}
        outerTag="div"
      />
    </>
  );
}

const ContactPage = ({
  breadcrumbs,
  ctaBlock,
  footer,
  header,
  epiTitle,
  image,
  epiSubtitle,
  epiContactForm,
  epiMainContentArea,
  contactDetailsBlockProps,
  epiRightContentArea,
  fullWidthBackground = true,
  titleOnly = null,
  alert,
  caption = '',
  attributionTo = '',
  attributionLink = '',
}: ContactPageProps & LeftContentProps & RightContentProps): ReactElement => {
  return (
    <PageTemplate ctaBlock={ctaBlock} header={header} footer={footer} alert={alert}>
      <StyledMain>
        <StyledHeader>
          <PageHero
            breadcrumbs={breadcrumbs}
            epiTitle={epiTitle}
            epiDescription={epiSubtitle}
            image={image}
            fullWidthBackground={fullWidthBackground}
            titleOnly={titleOnly}
            caption={caption}
            attributionTo={attributionTo}
            attributionLink={attributionLink}
          />
        </StyledHeader>
        <StyledContactPageLayout>
          <Container>
            <RightColumnLayout
              leftColumn={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <LeftContent
                  epiContactForm={epiContactForm}
                  epiMainContentArea={epiMainContentArea}
                />
              }
              rightColumn={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <RightContent
                  epiRightContentArea={epiRightContentArea}
                  contactDetailsBlockProps={contactDetailsBlockProps}
                />
              }
            />
          </Container>
        </StyledContactPageLayout>
      </StyledMain>
    </PageTemplate>
  );
};

export default ContactPage;
