/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import theme, { breakpoint } from '../../../lib/theme';

export const StyledTravelMapDashboard = styled.div`
    margin: ${theme.spacing.sm} 0;

    border-top: 1px solid ${theme.colors.borderMediumLight};
    border-bottom: 1px solid ${theme.colors.borderMediumLight};

    ${breakpoint.greaterThan('md')`
        margin: ${theme.spacing.md} 0;
    `}
`;
