import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react';

import { TravelMapDashboardStyled } from './TravelMapDashboardStyles';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { Loader } from '@googlemaps/js-api-loader';
import TravelMapSidebar from '../../molecules/TravelMapSidebar';
import axios from 'axios';
import TravelMapView from '../../molecules/TravelMapView';
import restAreaData from './RestAreaData';
//import dataSource from './testData'; // Local representation of api return data for testing
import { getUrlOtherSearchParams, uniqKey } from '../../../lib/util';
import Loaderizer from '../../atoms/Loaderizer';

export interface ITravelMapDashboard {
  googleMapsApiKey: string;
  mapConfigurationUrl: string;
}

const initialState = {
  loaded: false,
  mobile: false,
  google: null,
  map: null,
  lastUpdated: null,
  dataLoad: [],
  info: {
    id: null,
  },
  traffic: false,
  journey: {
    date: '',
    waypoints: [{
      id: uniqKey(),
      title: '',
      location: '',
    }],
    directions: null,
    route: 0,
    routeAlerts: {}
  },
  alerts: {
    search: '',
    features: [],
    alerts: [],
    filters: [],
    filtersRestArea: [],
    filteredFeatures: [],
  }
};

// Filter by finding feature name and check if filterOn, and check for prop condition if rest area
const filterFeatures = (features, filters, filtersRestArea) => {
  // Filter feature group initially by primary filters
  const initialFilter = features.filter(featureGroup => {
    // Find filter based on filter name match and if active
    return filters?.find(filter => filter?.DisplayName === featureGroup?.[0]?.featureType?.DisplayName)?.filterOn
  })

  // Check if RestAreas are included as a primary filter, if so, return feature else filter rest areas by amenity
  const restAreaCheck = initialFilter.findIndex(featureGroup => featureGroup?.[0]?.featureType?.Name === 'RestAreas');
  // CHhck if any rest area filters are on
  const activeRestAreaFilters = filtersRestArea.filter(filter => filter?.filterOn);

  // If rest area not primary and there are rest area filters enabled
  if (restAreaCheck === -1 && activeRestAreaFilters.length) {
    // Find index of rest area array
    const index = features.findIndex(featureGroup => featureGroup[0]?.featureType?.Name === 'RestAreas');
    // Filter rest area features by amenity 
    const restAreasFiltered = features[index].filter(feature => {
      // Find matching active rest area filter for each rest area
      return activeRestAreaFilters?.find(filter => {
        // Check if filter prop based on count, else match value in rest area data
        if (filter.value === 'Count') {
          return feature?.properties?.[filter.prop] > 0;
        } else {
          return feature?.properties?.[filter.prop] === filter.value;
        }
      })
    })
    // Update rest area feature array
    initialFilter[index] = restAreasFiltered;
  }
  return initialFilter
}


// Filter rest areas and merge arrays
const filterAlerts = (features) => [].concat.apply([], features.filter((feature) => feature?.[0]?.featureType?.Name !== 'RestAreas'))

// Context state reducer
const reducer = (state, action) => {
  console.log('DISPATCH: ', action.type, action.value);
  switch (action.type) {
    case 'ALERTS_SET_SEARCH':
      return { ...state, alerts: { ...state.alerts, search: action.value } }
    case 'JOURNEY_SET_DATE':
      return { ...state, journey: { ...state.journey, date: action.value } }
    case 'JOURNEY_UPDATE_WAYPOINT':
      const newWaypoints = [...state.journey.waypoints]
      const waypointIndex = newWaypoints.findIndex(waypnt => waypnt.id === action.value.id)
      if (waypointIndex !== -1) {
        newWaypoints[waypointIndex] = action.value
      }
      return { ...state, journey: { ...state.journey, waypoints: newWaypoints } }
    case 'JOURNEY_SET_WAYPOINTS':
      // Spread waypoints value to trigger state change
      return { ...state, journey: { ...state.journey, waypoints: [...action.value] } }
    case 'JOURNEY_SET_ALERTS':
      // Spread waypoints value to trigger state change
      return { ...state, journey: { ...state.journey, routeAlerts: { ...action.value } } }
    case 'JOURNEY_SET_ROUTE':
      return { ...state, journey: { ...state.journey, route: action.value } }
    case 'JOURNEY_SET_DIRECTIONS':
      // If infowindow open, close and remove url param
      return { ...state, info: { id: null }, journey: { ...state.journey, directions: action.value, route: 0, } }
    case 'JOURNEY_SET_DATA':
      return { ...state, journey: { ...state.journey, ...action.value } }
    case 'TRAFFIC_TOGGLE':
      return { ...state, traffic: !state.traffic }
    case 'ALERTS_TOGGLE_FILTER':
      const { name = '', restArea = false } = action.value;
      const filters = [...state.alerts.filters];
      const filtersRestArea = [...state.alerts.filtersRestArea];

      // Update filter states dpending on filter update type
      if (restArea) {
        const index = filtersRestArea?.findIndex(filter => filter.name === name);
        if (index !== -1) filtersRestArea[index].filterOn = !filtersRestArea[index]?.filterOn
      } else {
        const index = filters?.findIndex(filter => filter.Name === name);
        if (index !== -1) filters[index].filterOn = !filters[index]?.filterOn
      }

      // Filter with new filter state
      const filteredFeatures = filterFeatures(state.alerts.features, filters, filtersRestArea);

      // Update filters and filtered features
      return { ...state, alerts: { ...state.alerts, filters, filtersRestArea, filteredFeatures } }
    case 'ALERTS_UPDATE_DATA':
      const updatedFeatures = action.value
      // Update only updated features
      const newFeatures = [...state.alerts.features].map(featureGroup => {
        const updatedFeatureIndex = updatedFeatures.findIndex(features => features?.[0]?.featureType?.Name === featureGroup?.[0]?.featureType?.Name);
        if (updatedFeatureIndex === -1) {
          return featureGroup
        } else {
          return updatedFeatures[updatedFeatureIndex]
        }
      })

      // Set new data based on update
      const updatedAlertsData = {
        features: newFeatures,
        alerts: filterAlerts(newFeatures),
        filteredFeatures: filterFeatures(newFeatures, state.alerts.filters, state.alerts.filtersRestArea),
      }
      return { ...state, lastUpdated: new Date(), alerts: { ...state.alerts, ...updatedAlertsData } }
    case 'ALERTS_SET_DATA':
      return { ...state, lastUpdated: new Date(), alerts: { ...state.alerts, ...action.value } }
    case 'INFO_SET':
      // Manage info window URL on state change.
      // If new info window id is legit number and a new info window => update url with id
      if ((action.value.id || action.value.id === 0) && state.info.id !== action.value.id) {
        const url = getUrlOtherSearchParams('fid') + '&fid=' + action.value.id;
        window.history.pushState({}, document.title, url);
      } else if ((state.info.id || state.info.id === 0) && state.info.id !== action.value.id) {
        // Else if window is open and id is set to close, remove id form url
        const url = getUrlOtherSearchParams('fid');
        window.history.pushState({}, document.title, url);
      }

      return { ...state, ...{ info: { ...action.value } } }
    case 'MAP_SET':
      return { ...state, map: action.value }
    case 'GOOGLE_SET':
      return { ...state, google: action.value, loaded: true }
    case 'MOBILE_SET':
      return { ...state, mobile: action.value }
    case 'DATA_SET':
      return { ...state, dataLoad: action.value }
    default:
      return state
  }
}

export const TravelMapContext = createContext({ state: null, dispatch: null });

const TravelMapDashboard = ({
  googleMapsApiKey,
  mapConfigurationUrl
}: ITravelMapDashboard) => {

  const loader = new Loader({
    apiKey: googleMapsApiKey,
    version: 'quarterly',
    libraries: ['places']
  });

  // Setup state and reducer
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  const { info, mobile, loaded } = state;

  const [open, setOpen] = useState(true);
  const [panel, setPanel] = useState(false);

  // =================================
  // Loader for individual feature group
  // =================================
  const dataLoader = async (dataLoadOptions) => {
    try {
      const { url, query, featureTypeData } = dataLoadOptions;
      const path = `${url}/${query}`;
      return await axios(path)
        .then(res => res.data.features.map((feature) => ({
          ...feature,
          // If feature is of Alert type and was updated within 24 hrs (or 8.64e+7ms) set as recent Alert
          // Time condition is now removed to always prioritise alerts and road closed, by request of client
          // Uncomment line to renable
          recentAlert: (featureTypeData.DisplayName === 'Alert' || featureTypeData.DisplayName === 'Road Closed')
          // && (8.64e+7 > (new Date().getTime() - parse(feature?.properties?.UpdateDate, 'dd/MM/yyyy HH:mm:ss', new Date()).getTime()))
          ? true : false,
          featureType: featureTypeData
        })))
        .catch(error => { throw error });
    } catch (error) {
      console.log('URL: ', dataLoadOptions.url, ' ERROR: ', error);
    }
  }

  // =================================
  // Data load manger for initial load and timeout loads
  // =================================
  const dataLoadManager = async (dataSourceArray, url, mrwaUrl, initFilters, initFiltersRestArea) => {
    // Load data
    const featurePromises = [];

    // Group data load promises by cache timing to reduce state change updates
    const dataLoadGroups = []

    for (let i = 0; i < dataSourceArray.length; i++) {
      if (dataSourceArray[i]?.LoadByDefault) {
        const dataLoadOptions = {
          url: dataSourceArray[i]?.UseMrwa ? mrwaUrl : url,
          query: dataSourceArray[i].Query,
          featureTypeData: dataSourceArray[i]
        }
        featurePromises.push(dataLoader(dataLoadOptions));

        // Set up cahche timing interval only if duration specified
        if (dataSourceArray[i]?.CacheDurationSec) {

          // Check if there is a data load group with same duration time, if not then create new data load group, else add to existing group
          const dataLoadGroupIndex = dataLoadGroups.findIndex(group => group.time === dataSourceArray[i]?.CacheDurationSec);

          if (dataLoadGroupIndex === -1) {
            dataLoadGroups.push({
              time: dataSourceArray[i]?.CacheDurationSec,
              data: [dataLoadOptions],
              interval: null
            })
          } else {
            dataLoadGroups[dataLoadGroupIndex].data.push(dataLoadOptions)
          }
        }
      }
    }

    console.log(dataLoadGroups)

    for (let i = 0; i < dataLoadGroups.length; i++) {

      // Create interval for data load group
      const dataLoadInterval = setInterval(() => {
        console.log('DATA REFRESH')
        // Load all data as array of promises
        const dataGroupPromises = dataLoadGroups[i].data.map(dataOptions => {
          return dataLoader(dataOptions);
        })

        // Settle all and update state
        Promise.allSettled(dataGroupPromises)
          .then((results) => {
            // Filter out non fulfilled and undefined repsonses, requires type ignore due to issues with typescript compiler and allSettled
            // @ts-ignore: Unreachable code error
            const features = results?.filter((result): any[] => result.status === 'fulfilled' && result.value).map(result => (result as any)?.value);
            // Update State
            const action = {
              type: 'ALERTS_UPDATE_DATA', value: features
            };
            dispatch(action);
          })
          .catch(error => { console.error('Data Reload Error: ', error) })

      }, (dataSourceArray[i]?.CacheDurationSec ?? 120) * 1000);

      // Update reference to data load interval
      dataLoadGroups[i].interval = dataLoadInterval;
    }

    // Set data load reference to global context
    const action = {
      type: 'DATA_SET', value: dataLoadGroups
    };
    dispatch(action);

    // Settle all promises for inital data load update state
    await Promise.allSettled(featurePromises)
      .then((results) => {
        // Filter out non fulfilled and undefined repsonses, requires type ignore due to issues with typescript compiler and allSettled
        // @ts-ignore: Unreachable code error
        const features = results?.filter((result): any[] => result.status === 'fulfilled' && result.value).map(result => (result as any)?.value);
        // Update State
        const action = {
          type: 'ALERTS_SET_DATA', value: {
            features,
            alerts: filterAlerts(features),
            filteredFeatures: filterFeatures(features, initFilters, initFiltersRestArea),
          }
        };
        dispatch(action);
      })
      .catch(error => { console.error('Inital Data Load Error: ', error) })
  }

  // =================================
  // Load config data and setup data load manager
  // =================================
  const loadData = async () => {
    try {
      // Load data source from api endpoint
      const apiUrl = mapConfigurationUrl;
      const dataSource = await axios(apiUrl).catch(error => { throw error });
      const { FeaturePointGroups = null, FeatureLineGroups = null, ArcGisEndpoint = null, ArcGisMrwaEndpoint = null } = dataSource.data;
      // Catch missing data
      if (!FeaturePointGroups || !FeatureLineGroups || !ArcGisEndpoint || !ArcGisMrwaEndpoint) throw 'Unable to load full API configuration'

      // Setup filters
      const initFilters = FeaturePointGroups.map(featureGroup => {
        return {
          ...featureGroup,
          filterOn: !featureGroup?.HideByDefault ?? false,
        }
      });

      const initFiltersRestArea = restAreaData.map(filter => {
        return {
          ...filter,
          filterOn: false,
        }
      });

      // Update Filter State
      const action = {
        type: 'ALERTS_SET_DATA', value: {
          filters: initFilters,
          filtersRestArea: initFiltersRestArea,
        }
      };
      dispatch(action);

      // Initialise data load manager
      dataLoadManager([...FeaturePointGroups, ...FeatureLineGroups], ArcGisEndpoint.Url, ArcGisMrwaEndpoint.Url, initFilters, initFiltersRestArea);
    } catch (error) {
      console.log('DATA LOAD ERROR', error)
    }
  }

  // Listen for info changes to close menu on mobile
  useEffect(() => {
    if (mobile) {
      if (info.id || info.id === 0) {
        setOpen(false);
      }
    }
  }, [info]);

  // Load google and add to context state
  useEffect(() => {
    loader.load().then((google) => {
      const action = { type: 'GOOGLE_SET', value: google };
      dispatch(action);
    });

    loadData();
  }, []);

  return (
    <TravelMapDashboardStyled className={`travel-map__main ${open ? 'travel-map__main--active' : ''}`}>
      <TravelMapContext.Provider value={contextValue}>
        {loaded ? (
          <>
            <TravelMapSidebar open={open} setOpen={setOpen} panel={panel} setPanel={setPanel} />
            <TravelMapView panel={panel} setOpen={setOpen} setPanel={setPanel} />
          </>
        ) : (
          <div className="travel-map-dashboard__loader">
            <div>
              <Loaderizer />
            </div>
            <h6>Loading</h6>
          </div>
        )
      }
      </TravelMapContext.Provider>
    </TravelMapDashboardStyled>
  );
};

export default TravelMapDashboard;