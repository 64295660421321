import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKeenSlider } from 'keen-slider/react';
import React, { ReactElement, useEffect, useState } from 'react';
import 'keen-slider/keen-slider.min.css';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import { StyledSlideshow } from './styles';
import { BaseBlockProps } from '../../../types'

interface SlideshowBlockProps extends BaseBlockProps {
  epiTitle: EpiProps;
  epiContent: EpiProps;
  images?: {
    src: string;
    alt: string;
  }[];
}

const SlideshowBlock = ({
  epiTitle,
  epiContent,
  images,
  anchorId
}: SlideshowBlockProps): ReactElement => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [options, setOptions] = useState({});
  const [sliderRef, slider]: [any, any] = useKeenSlider(options);

  useEffect(() => {
    setTimeout(() => {
      setOptions({
        initial: currentSlide,
        slideChanged(s) {
          setCurrentSlide(s.details().relativeSlide);
        },
      });
    }, 10);
  }, []);

  return (
    <StyledSlideshow id={anchorId}>
      <EpiProperty
        name={epiTitle.name}
        value={epiTitle.value}
        isEditMode={epiTitle.isEditMode}
        outerTag="h2"
      />
      <EpiProperty
        name={epiContent.name}
        value={epiContent.value}
        isEditMode={epiContent.isEditMode}
        outerTag="p"
      />

      {slider && images && Array.isArray(images) && (
        <>
          <div ref={sliderRef} className="keen-slider">
            {images?.map((image, i) => (
              <div className="keen-slider__slide" key={i}>
                <img src={image.src} alt={image.alt} />
              </div>
            ))}
          </div>
          <div className="keen-slider__sub">
            <div className="keen-slider__thumbnails">
              {images &&
                images?.map((image, i) => {
                  return (
                    <button
                      key={i}
                      onClick={(): void => {
                        slider.moveToSlide(i);
                      }}
                      type="button"
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className={`keen-slider__thumbnail${
                          currentSlide === i ? ' active' : ''
                        }`}
                      />
                    </button>
                  );
                })}
            </div>
            <div className="keen-slider__controls">
              <button
                aria-label="Previous Image"
                onClick={e => {
                  if (e) {
                    e.stopPropagation();
                  }
                  return slider.prev();
                }}
                disabled={currentSlide === 0}
                type="button"
              >
                <FontAwesomeIcon icon="chevron-left" />
              </button>

              <button
                aria-label="Next Image"
                onClick={e => {
                  if (e) {
                    e.stopPropagation();
                  }
                  return slider.next();
                }}
                disabled={currentSlide === slider.details().size - 1}
                type="button"
              >
                <FontAwesomeIcon icon="chevron-right" />
              </button>
            </div>
          </div>
        </>
      )}
    </StyledSlideshow>
  );
};

export default SlideshowBlock;
