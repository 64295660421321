import styled from 'styled-components';

export const StyledPrintFriendlyButton = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  margin-bottom: 1em;
  padding: 0;
  color: #6c6c6c;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: 250ms ease-out color;
  &:hover {
    color: #000;
  }
  svg {
    font-size: 1em;
    margin-right: 0.5em;
  }
  span {
    font-size: 1.125em;
    display: block;
    line-height: 1;
  }
  @media print {
    display: none;
  }
`;
