import styled from 'styled-components';

import theme, { breakpoint } from '../../../lib/theme';

export const StyledFeaturedCTASection = styled.div`
  background-color: ${theme.colors.backgroundDark};
  color: ${theme.colors.textLight};
  &.light {
    background-color: ${theme.colors.bgLight};
    color: ${theme.colors.textDark};
  }
  margin-top: 60px;
  margin-bottom: 60px;
  ${breakpoint.greaterThan('sm')`
    margin-top: 80px;
  `}
  ${breakpoint.greaterThan('md')`
    margin-top: 100px;
  `}
  .featured-cta-section__inner {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    ${breakpoint.greaterThan('md')`
      // padding: 0 50px;
    `}
    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 25px;
    }
    ${breakpoint.greaterThan('md')`
      gap: 50px;
    `}
  }
`;

export const ImageSectionStyles = styled.figure`
  border: 4px solid #fff;
  box-shadow: ${theme.elevation[1]};
  border-radius: 2.1875em;
  margin: 0;
  height: 0;
  padding: 100% 0 0 0;
  max-width: 500px;
  transform: translate(0px, -37px);
  background-color: #000;
  overflow: hidden;

  @media screen and (min-width: 360px) {
    border-width: 5px;
    padding: 0;
    width: 100%;
    height: 50vh;
    max-width: 360px;
    max-height: 360px;
  }

  ${breakpoint.greaterThan('sm')`
    transition: transform 250ms cubic-bezier(0.5, 0, 0, 1);
    &:hover{
      transform: translate(0px, -47px);
    }
  `};
  @media screen and (min-width: 768px) {
    transform: translate(0px, -50px);
    flex: 1;
    width: 500px;
    height: 500px;
    max-height: 50vw;
    max-height: calc((100vw - 3rem - 25px) / 2);
    max-width: 50vw;
    max-width: calc((100vw - 3rem - 25px) / 2);
    transform: translate(0px, -50px);
    &:hover {
      transform: translate(0px, -60px);
    }
  }

  ${breakpoint.greaterThan('md')`
    width: 500px;
    height: 500px;
    max-height: 33vw;
    max-width: 33vw;
  `}

  ${breakpoint.greaterThan('lg')`
    width: 500px;
    height: 500px;
    max-width: 500px;
    max-width: min(500px, 33vw);
    max-height: 500px;
    max-height: min(500px, 33vw);
  `}
  img {
    width: 100%;
    height: auto;
    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 361px) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const ContentStyles = styled.div`
  width: 100%;
  flex: 1;
  /* > div:first-child {
    flex: 1;
  } */
  padding-bottom: 50px;
  ${breakpoint.greaterThan('sm')`
    margin: 0 auto;
  `};

  @media screen and (min-width: 768px) {
    padding-top: 65px;
  }
  ${breakpoint.greaterThan('md')`
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    
    align-content: center;
    justify-content: center;

    .featured-cta-content{ 
      display: flex;
      flex: auto;
      flex-direction: column;
      // justify-content: center;
      justify-content: flex-end;
    }
  `}

  h3 {
    margin: 0 0 ${theme.spacing.xs} 0;
    font-size: 1em;
    font-weight: ${theme.fontWeight.bold};

    ${breakpoint.greaterThan('md')`
      font-size: 1.125em;
    `}

    ${breakpoint.greaterThan('lg')`
      font-size: 1.25em;
    `}
  }

  h2 {
    margin: 0 0 ${theme.spacing.sm} 0;
    font-size: 1.5em;
    line-height: 1.1;
    font-weight: ${theme.fontWeight.black};

    ${breakpoint.greaterThan('md')`
      font-size: 1.75em;
    `}

    ${breakpoint.greaterThan('lg')`
      font-size: 1.875em;
    `}
  }

  p {
    margin: 0;
    font-size: 0.75em;

    ${breakpoint.greaterThan('md')`
      font-size: 1em;
    `}

    ${breakpoint.greaterThan('lg')`
      font-size: 1.125em;
    `}
  }

  span {
    display: none;
    opacity: 0;

    ${breakpoint.greaterThan('md')`
      padding: 0 1.5em;
      display: block;
      opacity: 1;
    `}
  }

  a {
    display: block;
    width: 100%;
    padding: 1em 2em;
    line-height: 1.25;
    background-color: ${theme.colors.primary};
    &.light {
      background-color: ${theme.colors.buttonBg};
    }
    color: ${theme.colors.buttonText};
    border-radius: 50px;
    font-size: 0.875em;
    font-weight: ${theme.fontWeight.bold};
    text-align: center;
    cursor: pointer;
    border: none;
    transition: color 50ms ease-out, background-color 50ms ease-out;

    &:hover {
      border: none;
      color: ${theme.colors.linkActive};
      background-color: ${theme.colors.backgroundLight};
      &.light {
        color: ${theme.colors.buttonText};
        background-color: ${theme.colors.primary};
      }
    }

    ${breakpoint.greaterThan('sm')`
      padding: 1em 1.5em;
      font-size: 0.8125em;
    `};
    ${breakpoint.greaterThan('md')`
      width: auto;
      padding: 1em 2em;
      font-size: 0.875em;
      display: inline-block;
    `}
  }
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    margin-top: 2em;
    span {
      padding: 1em 1em;
      font-size: 0.875em;
      line-height: 1.25;
    }
    @supports not (aspect-ratio: 1 / 1) {
      > * {
        margin: 0.5em 0;
        ${breakpoint.greaterThan('md')`
          margin: 0.5em;
        `};
      }
    }
    ${breakpoint.greaterThan('md')`
      flex: auto;
      margin-top: 50px;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    `}
  }
`;
