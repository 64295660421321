import '../../../lib/fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import StyledDocumentListItem from './styles';

export const StyledDateline = styled.p`
  color: #707070;
  margin-top: 0;
`;

// get the correct font awesome icon, based on the supplied content type
const getIcon = (contentType: string) => {
  // For more icon options to map to content types, see font-awesome 5 website
  const iconPropOptions = new Map()
    .set('link', 'globe-asia')
    .set('doc', 'file-word')
    .set('zip', 'file-download')
    .set('pdf', 'file-pdf')
    .set('annual report', 'paperclip')
    .set('document', 'paperclip')
    .set('fact sheet', 'paperclip')
    .set('document type', 'paperclip');

  const iconName: IconProp =
    iconPropOptions.get(contentType.toLowerCase()) || 'paperclip';
  return <FontAwesomeIcon icon={iconName} />;
};

export interface DocumentListItemProps {
  query: string;
  breadcrumbs: {
    href?: string;
    title: string;
  }[];
  lastUpdated?: string;
  lastUpdatedLabel?: string;
  files: { href: string; filetype: string; label: string }[];
  // slug: string;
  summary: string;
  thumbnail: string;
  tags: {
    href: string;
    title: string;
  }[];
  title: string;
  documentType: string;
  fileSize: string;
  revisionInfo?: string;
}
const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const fileSizetoString = (fileSize: number): string => {
  let theString = '';
  if (fileSize > 900) {
    theString = `${round(fileSize / 1024, 2)} KB`;
  }
  if (fileSize > 900000) {
    theString = `${round(fileSize / 1024 / 1024, 2)} MB`;
  }
  if (fileSize > 900000000) {
    theString = `${round(fileSize / 1024 / 1024 / 1024, 2)} GB`;
  }
  return theString;
};

function DocumentListItem({
  query,
  breadcrumbs,
  summary,
  files,
  lastUpdated,
  lastUpdatedLabel,
  thumbnail,
  title,
  // slug,
  documentType,
  fileSize,
  revisionInfo,
}: DocumentListItemProps): ReactElement {
  return (
    <StyledDocumentListItem>
      <div className="document-list-item__icon">
        {thumbnail && <img src={thumbnail} alt={`thumbnail for ${title} `} />}
      </div>
      <div className="document-list-item">
        <h2 className="document-list-item__title">
          <span>{title}</span>
        </h2>
        <ul className="document-list-item__breadcrumb">
          {breadcrumbs.map((breadcrumb, i) => (
            <li key={i}>
              {breadcrumb.href && (
                <a
                  href={`${query}&node=${encodeURIComponent(breadcrumb.title)}`}
                >
                  {breadcrumb.title}
                </a>
              )}
              {!breadcrumb.href && <span>{breadcrumb.title}</span>}
            </li>
          ))}
        </ul>
        <StyledDateline>
          {!!revisionInfo && `Current version number: ${revisionInfo} | `}
          {lastUpdatedLabel && `${lastUpdatedLabel}: `}
          <time dateTime={lastUpdated} title={`Last updated: ${lastUpdated}`}>
            {lastUpdated}
          </time>
          {documentType && ` | ${documentType}`}
          {fileSize && ` | ${fileSizetoString(Number(fileSize))}`}
        </StyledDateline>
        <p className="document-list-item__description">{summary}</p>
        {files?.length > 0 && (
          <ul className="document-list-item__downloads">
            {files.map(
              (file, i) =>
                file && (
                  <li key={i}>
                    {file.filetype === 'link' ? (
                      <a href={file.href} title={file.label}>
                        <span>{getIcon(file.filetype)}</span>
                        {file.label}
                      </a>
                    ) : (
                      <a
                        href={file.href}
                        target="_blank"
                        rel="noreferrer nooopener"
                        title={file.label}
                      >
                        <span>{getIcon(file.filetype)}</span>
                        {file.label}
                      </a>
                    )}
                  </li>
                ),
            )}
          </ul>
        )}
      </div>
    </StyledDocumentListItem>
  );
}

export default DocumentListItem;
