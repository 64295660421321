import React, { ReactElement } from 'react';
import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import Container from '../../atoms/Container';
import theme from '../../../lib/theme';

export interface ContainerBlockProps {
  epiBlockContentArea: EpiProps;
  padding?: boolean;
  marginTop?: number;
  marginBottom?: number;
  paddingTop?: number;
  paddingBottom?: number;
  highlighted?: boolean;
}

const ContainerBlock = ({
  epiBlockContentArea,
  padding = true,
  marginTop = 0,
  marginBottom = 0,
  paddingTop = 0,
  paddingBottom = 0,
  highlighted = false,
}: ContainerBlockProps): ReactElement => {
  return (
    <Container
      padding={padding}
      marginTop={marginTop}
      marginBottom={marginBottom}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      highlighted={highlighted}
    >
      <EpiProperty
        name={epiBlockContentArea.name}
        value={epiBlockContentArea.value}
        isEditMode={epiBlockContentArea.isEditMode}
        outerTag="div"
      />
    </Container>
  );
};

export default ContainerBlock;
