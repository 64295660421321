import styled from 'styled-components';

import { breakpoint } from '../../../lib/theme';

export const StyledContentListingGroup = styled.div`
  display: -ms-grid;
  display: grid;
  gap: 1.25em;
  padding-bottom: 20px;
  ${breakpoint.greaterThan('sm')`
    // display: flex;
    // flex-wrap: wrap;
    
    // &:not(.content-list__item--compact),
    &:not(.compact){
      -ms-grid-template-columns: 1fr;
      grid-template-columns: 1fr;
      ${breakpoint.greaterThan('sm')`
        -ms-grid-template-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      `}  
      ${breakpoint.greaterThan('lg')`
        -ms-grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      `}
    }

    &.compact {
      // display: block;
    }
  `};

  .content-list__item {
    display: grid;
    > div {
      height: 100%;
    }
    &:not(.content-list__item--compact) {
      ${breakpoint.greaterThan('sm')`
        // width: 50%;
        // flex: 1;
      `}

      ${breakpoint.greaterThan('lg')`
        // width: 25%;
        // flex: 1;
      `}
    }
  }
`;
