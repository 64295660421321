import React, { ReactElement } from 'react';
import moment from 'moment';
import Container from '../../atoms/Container';
import { PageMetaInfo } from './PageMetaInfo';

type PageLastReviewedProps = {
  lastReviewed?: string;
  lastUpdated?: string;
  pageType: 'page' | 'document';
};
const PageLastReviewed = ({
  lastReviewed,
  lastUpdated,
  pageType = 'page',
}: PageLastReviewedProps): ReactElement => {
  moment.locale('en-au');
  /* const lastReviewedFormatted =
    lastReviewed && moment(lastReviewed).format('MMMM Do YYYY');
  const lastUpdatedFormatted =
    lastUpdated && moment(lastUpdated).format('MMMM Do YYYY'); */
  const lastReviewedFormatted = lastReviewed && lastReviewed;
  const lastUpdatedFormatted = lastUpdated && lastUpdated;

  if (!lastReviewedFormatted) {
    return null;
  }
  return (
    <PageMetaInfo>
      <Container>
        <p>
          {pageType === 'page' ? 'Page' : 'Document'}
          &nbsp;last reviewed:&nbsp;
          <time
            dateTime={lastReviewedFormatted}
            title={`Last updated: ${lastReviewedFormatted}`}
          >
            {lastReviewedFormatted}
          </time>
          {lastUpdatedFormatted && (
            <>
              &nbsp;| Last updated:&nbsp;
              <time
                dateTime={lastUpdatedFormatted}
                title={` Last updated: ${lastUpdatedFormatted}`}
              >
                {lastUpdatedFormatted}
              </time>
            </>
          )}
        </p>
      </Container>
    </PageMetaInfo>
  );
};

export default PageLastReviewed;
