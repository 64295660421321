import React, { ReactElement } from 'react';

import ContentListItem, {
  ContentListItemProps,
} from '../../molecules/ContentListItem';
import { StyledContentListingGroup } from './styles';

export interface ContentListGroupProps {
  compact?: boolean;
  list: ContentListItemProps[];
}

const ContentListGroup = ({
  list,
  compact,
}: ContentListGroupProps): ReactElement => {
  return (
    <StyledContentListingGroup className={compact ? 'compact' : ''}>
      {list.map((listItem, i) => (
        <div
          className={`content-list__item${
            compact ? ' content-list__item--compact' : ''
          }`}
          key={i}
        >
          <ContentListItem
            thumbnail={listItem.thumbnail}
            title={listItem.title}
            summary={listItem.summary}
            compact={compact}
            lastModifiedDate={listItem.lastModifiedDate}
          />
        </div>
      ))}
    </StyledContentListingGroup>
  );
};

export default ContentListGroup;
