import to from 'await-to-js';
import axios from 'axios';
import NProgress from 'nprogress';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import EpiProperty, { EpiProps } from '../../atoms/EpiProperty';
import SearchFormWidget, {
  SearchFormProps,
} from '../../atoms/SearchFormWidget';
import MainRoadsList from '../../molecules/MainRoadsList';
import {
  StyledWhatWeManageContent,
  StyledWhatWeManageForm,
  StyledWhatWeManageResults,
  StyledWhatWeManageWidget,
} from './StyledWhatWeManageWidget';

export interface WhatWeManageWidgetProps {
  title: EpiProps;
  summary: EpiProps;
  summaryHref?: string;
  searchAction?: SearchFormProps['action'];
  searchPlaceholder?: SearchFormProps['placeholder'];
  managedRoadsProps?: {
    title?: EpiProps;
    footerContent?: EpiProps;
  };
  notManagedRoadProps?: {
    title?: EpiProps;
    footerContent?: EpiProps;
  };
  noResultsProps?: {
    title?: EpiProps;
    footerContent?: EpiProps;
  };
}

interface ApiResponseProps {
  managedRoads: string[];
  notManagedRoads: string[];
}

const WhatWeManageWidget = ({
  title,
  summary,
  summaryHref,
  searchPlaceholder,
  searchAction,
  managedRoadsProps,
  noResultsProps
}: WhatWeManageWidgetProps): ReactElement => {
  const [apiResponse, setApiResponse] = useState<
    ApiResponseProps | undefined
  >();
  const [hasMounted, setHasMounted] = useState(false);
  const [canOpenResults, setCanOpenResults] = useState(false);
  const targetElement = useRef(null);

  const [timeoutId, setTimeoutId] = useState(null);
  const updateSearchResults = async (query: string): Promise<void> => {
    if (query && query.length > 3) {
      NProgress.start();
      const APIURL = searchAction.replace('{keyword}', query);
      const [err, res] = await to(axios(APIURL));
      if (err) {
        NProgress.done();
        return;
      }
      setApiResponse(res.data);

      clearTimeout(timeoutId);
      const tId = setTimeout(() => {
        const dataLayer = window['dataLayer'] || [];
        dataLayer.push({
          event: 'search',
          searchInput: 'whatwemanageblock',
          searchTerm: query,
        });
      }, 4000);
      setTimeoutId(tId);

      NProgress.done();
    }
  };

  const onBodyClickHandler = ev => {
    const targetEl = targetElement.current;
    let clickedEl = ev.target;

    do {
      if (clickedEl === targetEl) {
        setCanOpenResults(true);
        return;
      }
      clickedEl = clickedEl.parentNode;
    } while (clickedEl);
    setCanOpenResults(false);
  };

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      document.body.addEventListener('click', onBodyClickHandler);
    }

    return () => {
      document.body.removeEventListener('click', onBodyClickHandler);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <StyledWhatWeManageWidget ref={targetElement}>
      <div className="what-we-manage-widget__inner">
        <StyledWhatWeManageContent>
          {title && (
            <EpiProperty
              name={title.name}
              value={title.value}
              isEditMode={title.isEditMode}
              outerTag="h2"
            />
          )}
          {summaryHref && (
            <a href={summaryHref} title={summary.value} target="_self">
              <EpiProperty
                name={summary.name}
                value={`${summary.value}&nbsp;»`}
                isEditMode={summary.isEditMode}
                outerTag="span"
              />
            </a>
          )}
          {!summaryHref && (
            <EpiProperty
              name={summary.name}
              value={summary.value}
              isEditMode={summary.isEditMode}
              outerTag="span"
            />
          )}
        </StyledWhatWeManageContent>

        <StyledWhatWeManageForm>
          <SearchFormWidget
            placeholder={searchPlaceholder}
            action={searchAction}
            searchIconBefore
            searchIconClickable={false}
            theme="light"
            searchInputWidth="100%"
            typeAheadSearch={updateSearchResults}
          />

          {apiResponse && canOpenResults && (
            <StyledWhatWeManageResults>
              {apiResponse.managedRoads?.length > 0 ? (
                <MainRoadsList
                  title={managedRoadsProps.title}
                  roads={apiResponse.managedRoads}
                  footerContent={managedRoadsProps.footerContent}
                />
              ) : (
                <MainRoadsList
                  title={managedRoadsProps.title}
                  footerContent={noResultsProps.footerContent}
                />
              )}
            </StyledWhatWeManageResults>
          )}
        </StyledWhatWeManageForm>
      </div>
    </StyledWhatWeManageWidget>
  );
};

export default WhatWeManageWidget;
